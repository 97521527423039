export const artecoVars = {
  body_bg: '#000000',
  primary: '#ffffff',
  info: '#161616',
  secondary: '#262626',
  secondary_transparent: 'rgba(38, 38, 38, 0.9)',
  arteco_red: '#990000',
  arteco_red_light: '#b90101',
  device_online: '#cccccc',
  font_size_text: '12px',
  device_offline: '#666666',
  hover_color: '#ffffff',
  nohover_color: '#ccc',
  border_grey: '#666',
  ptz_panel_height: '266px',
  ptz_panel_height_mobile: '246px',
  arteco_yellow: '#f2dc00',
  chat_icon_green: '#27DC27',
  transparent_background: 'rgba(0, 0, 0, 0.80)',
  hover_background: '#343A40',
  light_grey: '#555F69',
  btn_color: '#4A525A',
  snap_yellow: '#ffc700',
  border_radius: '4px',
  red_highlight: '#ff0001',
  red_active: '#c00d0e',
  hover_background: '#343A40',
  light_grey: '#555F69',
  btn_color: '#4A525A',
  snap_yellow: '#ffc700',
  border_radius: '4px',
  red_highlight: '#ff0001',
  red_active: '#c00d0e',
  login_background: '#171717',
  //reskin primary yellow color hierachy, 2 dark, 1 lighter
  reskin_yellow_1: '#ffc700',
  reskin_yellow_2: '#8F720F',

  //reskin primary green color hierachy, 2 dark, 1 lighter
  reskin_green_2: '#00A100',
  reskin_green_1: '#27dc27',

  //reskin primary red color hierachy, 2 dark, 1 lighter
  reskin_red_2: '#8c1817',
  reskin_red_2_box_shadow: '#741616',
  reskin_red_1: '#E30613',

  //reskin primary grey color hierachy, 4 dark, 1 lighter
  reskin_grey_4: '#171717',
  reskin_grey_3: '#323232',
  reskin_grey_2: '#3C3C3C',
  reskin_grey_1: '#484848'
}

export const TimePickerTheme = {
  typography: {
    fontFamily: "'Open Sans'",
    fontSize: 12
  },
  palette: {
    mode: 'dark',
    primary: {
      main: artecoVars.hover_color,
    },
    secondary: {
      main: artecoVars.hover_color,
    },
    background: {
      default: artecoVars.body_bg,
      paper: artecoVars.body_bg,
    },
  },
  zIndex: {
    mobileStepper: 99999000,
    fab: 99999050,
    speedDial: 99999050,
    appBar: 99999100,
    drawer: 99999200,
    modal: 10000000000,
    snackbar: 99999400,
    tooltip: 99999500
  }
}

export const TabTheme = {
  typography: {
    fontFamily: "'Open Sans'",
    fontSize: 12
  },
  palette: {
    mode: 'dark',
    primary: {
      main: artecoVars.arteco_red,
    },
    secondary: {
      main: artecoVars.hover_color,
    },
    background: {
      default: artecoVars.body_bg,
      paper: artecoVars.body_bg,
    },
  },
    components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },

}

export const TimeTunerTheme = {
  typography: {
    fontFamily: "'Open Sans'",
    fontSize: 12
  },
  palette: {
    mode: 'dark',
    primary: {
      main: artecoVars.nohover_color,
    },
    secondary: {
      main: artecoVars.nohover_color,
    },
    background: {
      default: artecoVars.body_bg,
      paper: artecoVars.body_bg,
    },
  },
  zIndex: {
    mobileStepper: 99999000,
    fab: 99999050,
    speedDial: 99999050,
    appBar: 99999100,
    drawer: 99999200,
    modal: 99999300,
    snackbar: 99999400,
    tooltip: 99999500
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        valueLabel: ({theme}) => ({
          ...theme,
          backgroundColor: artecoVars.arteco_yellow,
          color: artecoVars.info
        })
      }
    }
  }
}