export function processIntegrations(integrations, server) {
  return integrations.map(integration => {

    return {
      ...integration,
      serverCodename: server.codeName
    }
  })
}

export function updateArraybyKey(array, newObject, key) {
  // update 'array' based on 'key' using newObject
  if(key == undefined || array == undefined || newObject == undefined){return}
  let index = array.findIndex(item => item[key] === newObject[key]);
  
  if (index !== -1) {
      array[index] = newObject;
  } else {
      array.push(newObject);
  }
  
  return array;
}