import React, { Component } from 'react'
import Draggable from 'react-draggable'; // The default

class ClapprNerdStats extends Component {

  endOfVideo = () => {
    const { simulateEndOfVideo } = this.props
    simulateEndOfVideo();
  }

  progReached = () => {
    const { simulateProgressReached } = this.props
    simulateProgressReached()
  }

  render() {
    const { instantInterval, source, device, playlistId } = this.props

    return (
      <Draggable>
        <div className="stats-box">
          <div className='controls'>
            <button className='btn' onClick={() => { navigator.clipboard.writeText(source) }}>Copy Source</button>
            <button className='btn' onClick={this.endOfVideo} >Simulate end</button>
            <button className='btn' onClick={this.progReached} >Simulate prog reach</button>
          </div>
          <div className='info'>
            <p>playlistId: {playlistId}</p>
            <p>id = {device.id}</p>
            <p>descr = {device.descr}</p>
            <a href={`http://${device["ip-address"]}`} target="_blank" >{device["ip-address"]}</a>
            <p>From:{instantInterval.from}</p>
            <p>To:{instantInterval.to}</p>
          </div>
        </div>
      </Draggable>

    )
  }
}

export default ClapprNerdStats
