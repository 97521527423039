import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

//import translations
import { withTranslation } from 'react-i18next';
import ArtecoSwitch from '../ArtecoSwitch/ArtecoSwitch';
import { getDefaultData, getExportActionCallback, getExportDevices } from '../../reducers/featuresReducer';
import { isCredentialEnabled } from '../../reducers/serversReducer';
import { CamerasPrivacyBlur, CamerasTimestampOverlay, CamerasWatermarkOverlay } from '../../helpers/serverCredentials';
import { ArrowDownLeft, ArrowDownRight, ArrowUpLeft, ArrowUpRight } from 'react-feather';
import { isExportPrivacySupported, isTimestampAndWatermarkSupported } from '../../reducers/userPrefsReducer';
import { getPlaylists, checkIfPlaylistByDevicesAndIntervalGotPrivacy, getPlaylistsByDevices, theresPrivacyInPlayslists } from '../../reducers/playlistsReducer';
import { getExportInterval } from '../../reducers/recordingsReducer';
import { jsDateToServerDate, toMoment } from '../../helpers/timeHelpers';

export class ExportFeaturesPicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      privacy: false,
      watermark: false,
      showTimeStamp: false,
      timestampPosition: "upLeft",
      addCameraName: false,
      timestampPositionX: '10',
      timestampPositionY: '10',
      timestampColor: 'black',
      timestampBoxEnabled: '1',
      timestampBoxColor: 'yellow@0.5',
      timestampFontSize: '(h/50)',
    }

  }

  setTimestampPosition = (e, timestampPosition) => {

    switch (timestampPosition) {
      case "upLeft":
        this.setState({
          timestampPositionX: '10',
          timestampPositionY: '10',
        })
        break;
      case "upRight":
        this.setState({
          timestampPositionX: '(w-text_w-10)',
          timestampPositionY: '0',
        })
        break;
      case "downRight":
        this.setState({
          timestampPositionX: '(w-text_w-10)',
          timestampPositionY: '(h-text_h-10)',
        })
        break;
      case "downLeft":
        this.setState({
          timestampPositionX: '0',
          timestampPositionY: '(h-text_h-10)',
        })
        break;
      default:
        this.setState({
          timestampPositionX: '10',
          timestampPositionY: '10',
        })
        break;
        break;
    }

    this.setState({
      timestampPosition
    })

    e.preventDefault();

  }

  setFontSize = (e, fontSize) => {
    this.setState({
      timestampFontSize: fontSize
    })
    e.preventDefault();
  }

  handleSwitchChange = (switchType) => {

    this.setState({
      [switchType]: !this.state[switchType],
    });

  };

  submitChanges = (e) => {
    const { submitCallback, exportActionCallback } = this.props;

    submitCallback();
    exportActionCallback && exportActionCallback(this.state);
    e.preventDefault();
    return false;
  }


  componentDidMount = () => {
    const { exportDefaultData } = this.props;
    if (exportDefaultData != undefined) {
      this.setState({
        privacy: exportDefaultData.privacy,
        watermark: exportDefaultData.watermark,
        showTimeStamp: exportDefaultData.showTimeStamp,
        timestampPosition: exportDefaultData.timestampPosition,
        addCameraName: exportDefaultData.addCameraName,
        timestampPositionX: exportDefaultData.timestampPositionX,
        timestampPositionY: exportDefaultData.timestampPositionY,
        timestampColor: exportDefaultData.timestampColor,
        timestampBoxEnabled: exportDefaultData.timestampBoxEnabled,
        timestampBoxColor: exportDefaultData.timestampBoxColor,
        timestampFontSize: exportDefaultData.timestampFontSize,
      })
    }

    if (this.gotAnyCameraThatDoesntAllowToRemovePrivacy()) {
      // se una dei server (a cui fanno riferimento i device) non permette di rimuovere la privacy allora l'attivo a true e la disabilito nel JSX
      this.setState({
        privacy: true
      });
    }
  }

  isThereAnyDeviceWithPrivacyEnabled = () => {
    const { exportDevices } = this.props;
    // controllo se almeno uno dei device ha la privacy attiva come plugin
    const filteredDevices = exportDevices.filter(device => device?.privacyPlgEnabled === 1);
    return filteredDevices.length > 0;
  }

  shouldShowPrivacyButton = () => {
    const { exportPrivacySupported, playlistsByDevices } = this.props

    
    if (Object.keys(playlistsByDevices).length == 0) {
      // controllo se uno dei device da esportare ha la privacy attiva come plugin
      return exportPrivacySupported && this.isThereAnyDeviceWithPrivacyEnabled();
    }
   
  }

  gotAnyCameraThatDoesntAllowToRemovePrivacy = () => {

    const { exportDevices } = this.props
    // check credenziali per tutti i server di tutti i dispositivi, se anche solo uno ha
    // la credenziale attiva alla devo inibire la possibilità di disabilitare la privacy
    return exportDevices.some(device => !isCredentialEnabled(CamerasPrivacyBlur, device));
  }

  render() {
    const { t, exportDevices, exportTimeStampWatermarkSupported } = this.props;
    const { privacy, watermark, showTimeStamp } = this.state;

    const additionalClass = 'arteco-exportfeatures-popup-container';
    const showPrivacyButton = this.shouldShowPrivacyButton();
    const showAlertText = (showPrivacyButton && privacy || showTimeStamp);
    const cannotAvoidBlur = this.gotAnyCameraThatDoesntAllowToRemovePrivacy();

    const privacyButton = (
      <div className="form-group user-switch">
        <div>{t('EXPORT_INCLUDE_PRIVACY')}</div>
        <div>
          <ArtecoSwitch
            id={"privacy"}
            onHandleColor="#fff"
            offHandleColor="#ccc"
            onChange={() => this.handleSwitchChange('privacy')}
            checked={this.state.privacy || false}
            disabled={cannotAvoidBlur}
          />
        </div>
      </div>
    );

    const timeStampButton = (
      <div className="form-group user-switch">
        <div>{t('EXPORT_INCLUDE_TIMESTAMP')}</div>
        <div>
          <ArtecoSwitch
            id={"showTimeStamp"}
            onHandleColor="#fff"
            offHandleColor="#ccc"
            onChange={() => this.handleSwitchChange('showTimeStamp')}
            checked={this.state.showTimeStamp || false}
          />
        </div>
        {
          this.state.showTimeStamp && (
            <div className='timestamp-options'>
              <div>{t('EXPORT_INCLUDE_TIMESTAMP_POSITION')}</div>
              <div className='arrows'>
                <button className={`timestampPosition-selector btn ${this.state.timestampPosition == "upLeft" ? 'selected' : ''}`} onClick={(e) => this.setTimestampPosition(e, 'upLeft')}><ArrowUpLeft /></button>
                <button className={`timestampPosition-selector btn ${this.state.timestampPosition == "upRight" ? 'selected' : ''}`} onClick={(e) => this.setTimestampPosition(e, 'upRight')}><ArrowUpRight /></button>
                <button className={`timestampPosition-selector btn ${this.state.timestampPosition == "downRight" ? 'selected' : ''}`} onClick={(e) => this.setTimestampPosition(e, 'downRight')}><ArrowDownRight /></button>
                <button className={`timestampPosition-selector btn ${this.state.timestampPosition == "downLeft" ? 'selected' : ''}`} onClick={(e) => this.setTimestampPosition(e, 'downLeft')}><ArrowDownLeft /></button>
              </div>
              {/* <div>{t('EXPORT_INCLUDE_TIMESTAMP_FONTSIZE')}</div> */}
              {/* <div className='font-sizes'>
                <button className={`fontSizes-selector btn ${this.state.timestampFontSize == '(h/50)' ? 'selected' : ''}`} onClick={(e) => this.setFontSize(e, '(h/50)')}>{t('EXPORT_INCLUDE_TIMESTAMP_FONTSIZE_AUTO')}</button>
                 <div className='separator'></div>
                <button className={`fontSizes-selector btn ${this.state.timestampFontSize == '10' ? 'selected' : ''}`} onClick={(e) => this.setFontSize(e, '10')}>{t('EXPORT_INCLUDE_TIMESTAMP_FONTSIZE_SMALL')}</button>
                <button className={`fontSizes-selector btn ${this.state.timestampFontSize == '30' ? 'selected' : ''}`} onClick={(e) => this.setFontSize(e, '30')}>{t('EXPORT_INCLUDE_TIMESTAMP_FONTSIZE_MEDIUM')}</button>
                <button className={`fontSizes-selector btn ${this.state.timestampFontSize == '50' ? 'selected' : ''}`} onClick={(e) => this.setFontSize(e, '50')}>{t('EXPORT_INCLUDE_TIMESTAMP_FONTSIZE_BIG')}</button> 
              </div> */}
              <div>{t('EXPORT_INCLUDE_TIMESTAMP_CAMERA_DETAILS')}</div>
              <div>
                <ArtecoSwitch
                  id={"addCameraName"}
                  onHandleColor="#fff"
                  offHandleColor="#ccc"
                  onChange={() => this.handleSwitchChange('addCameraName')}
                  checked={this.state.addCameraName || false}
                />
              </div>
            </div>
          )
        }
      </div>
    );

    const watermarkButton = (
      <div className="form-group user-switch">
        <div>{t('EXPORT_INCLUDE_WATERMARK')}</div>
        <div>
          <ArtecoSwitch
            id={"watermark"}
            onHandleColor="#fff"
            offHandleColor="#ccc"
            onChange={() => this.handleSwitchChange('watermark')}
            checked={this.state.watermark || false}
          />
        </div>
        <div>{t('EXPORT_INCLUDE_WATERMARK_DETAILS')}</div>
      </div>
    );

    return (
      <div className={`${additionalClass}`}>
        <form noValidate onSubmit={(e) => this.submitChanges(e)}>
          {showPrivacyButton && privacyButton}
          {exportTimeStampWatermarkSupported && timeStampButton}
          {/* {exportTimeStampWatermarkSupported && watermarkButton} */}
          {!showPrivacyButton && !exportTimeStampWatermarkSupported && <p>{t('EXPORT_NO_AVAILABLE_METADATA')}</p>}
          {showAlertText && <p className='alert'>{t('EXPORT_WITH_META_TAKES_MORE_TIME')}</p>}
          <div className='cta'>
            <button type="submit" className="btn">
              {t('OK')}
            </button>
          </div>
        </form>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const exportActionCallback = getExportActionCallback(state);
  const exportPrivacySupported = isExportPrivacySupported(state);
  const exportTimeStampWatermarkSupported = isTimestampAndWatermarkSupported(state);
  const exportDefaultData = getDefaultData(state);
  const exportInterval = getExportInterval(state);
  const exportDevices = getExportDevices(state);
  const playlistsByDevices = getPlaylistsByDevices(state, exportDevices, jsDateToServerDate(toMoment(exportInterval?.start)), jsDateToServerDate(toMoment(exportInterval?.end)));
  const gotPrivacyInPlaylists = theresPrivacyInPlayslists(playlistsByDevices);

  return {
    exportActionCallback,
    exportPrivacySupported,
    exportTimeStampWatermarkSupported,
    exportDefaultData,
    exportDevices,
    exportInterval,
    gotPrivacyInPlaylists,
    playlistsByDevices
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ExportFeaturesPicker))