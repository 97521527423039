import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { drawMeta, rectangleClick, resizeCanvas } from './DrawUtilities';
import { getMetaPending } from '../../reducers/playlistsReducer';
import Spinner from '../Spinner/Spinner';
import { showOnvifDataPopup } from '../../actions/featuresActions';
import { appPaths } from '../../config/paths';
import { getCurrentObjectId, getOnvifData } from '../../reducers/featuresReducer';

export class OnvifMetaDisplay extends Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.observerInitialized = false;   
    
    this.listenerAdded = false;  

    this.state = {
      rectangles: [],  // Array per memorizzare i dettagli dei rettangoli      
      lastTime: null,
    };
  }

  componentDidMount() {
    const { videoWidth, videoHeight, nodeRef, metadataLoaded} = this.props
    const { lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight } = this.state    
    
    document.addEventListener("playerClock", this.attachTimeListener);    
    if(this.canvasRef.current) {      
      resizeCanvas(videoWidth, videoHeight, nodeRef, metadataLoaded, lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight, (newState) => this.setState(newState), this.canvasRef);      
    }
  }

  attachTimeListener = (event) => {    
    this.handleMetaUpdate(event);    
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { videoWidth, videoHeight, nodeRef, metadataLoaded, liveOrRec, isWebRTC, shouldAddMetaLayer, containerSize, isWaitingMeta } = this.props;
    
    const containerSizeString = JSON.stringify(containerSize);
    const containerSizeNextString = JSON.stringify(nextProps.containerSize);


    if (nextProps.videoWidth !== videoWidth ||
        nextProps.videoHeight !== videoHeight ||
        containerSizeString !== containerSizeNextString ||
        nextProps.nodeRef.clientWidth !== nodeRef.clientWidth ||
        nextProps.nodeRef.clientHeight !== nodeRef.clientHeight ||
        nextProps.metadataLoaded !== metadataLoaded ||
        nextProps.liveOrRec !== liveOrRec ||
        nextProps.isWebRTC !== isWebRTC ||
        nextProps.isWaitingMeta !== isWaitingMeta ||
        nextProps.shouldAddMetaLayer !== shouldAddMetaLayer
        ) {
      return true;
    }
  
    return false;
  }
  

  componentDidUpdate() {
    const { videoWidth, videoHeight, nodeRef, metadataLoaded } = this.props
    const { lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight } = this.state

    
    if(this.canvasRef.current) {      
      resizeCanvas(videoWidth, videoHeight, nodeRef, metadataLoaded, lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight, (newState) => this.setState(newState), this.canvasRef);          
    }

    // Aggiunge il listener solo se non è già stato aggiunto e se canvasRef.current esiste
    if(this.canvasRef.current) {
      this.canvasRef.current.removeEventListener('click', this.handleCanvasClick);
      this.canvasRef.current.addEventListener('click', this.handleCanvasClick);
      this.listenerAdded = true;
    }
  }

  componentWillUnmount() {    
    document.removeEventListener("playerClock", this.attachTimeListener);

    // Rimuove l'event listener quando il componente viene smontato
    if (this.canvasRef.current) {
      this.canvasRef.current.removeEventListener('click', this.handleCanvasClick);
      this.listenerAdded = false;
    }
  }  


  handleMetaUpdate = (event) => {
    const { video, traceBorder, liveOrRec, onvifMetadataHistory, onvifData, currentObjectId} = this.props
    // evento di cambio dell'orario
    const { artecoId } = this.props;      
    const detail = event.detail;


    if (this.canvasRef.current && detail.artecoId === artecoId && detail.currentServerTime) {     
      drawMeta(detail.currentServerTime, video, traceBorder, this.canvasRef, (newState) => this.setState(newState), liveOrRec, this.metaBuffer, onvifMetadataHistory, onvifData, currentObjectId);      
    }
  };  
  

  // Gestore per il click del mouse sul canvas
  handleCanvasClick = (event) => {
    const { showOnvifDataPopup, video, server, device, onvifData, liveOrRec, traceBorder, onvifMetadataHistory, isPopup } = this.props;
    const { lastTime } = this.state
    const isInBrowser = window.location.pathname === appPaths.recordings;
    const popupMode = isInBrowser ? 'browser' : 'rec';
    const showActionsInOnvifPopup = !isPopup;
    rectangleClick(server, device, event, this.canvasRef, this.state.rectangles, showOnvifDataPopup, video, popupMode, this.props.currentObjectId, showActionsInOnvifPopup);
    if(liveOrRec !== 'live') {
      drawMeta(lastTime, video, traceBorder, this.canvasRef, (newState) => this.setState(newState), liveOrRec, this.metaBuffer, onvifMetadataHistory, onvifData, this.props.currentObjectId);      
    }
  }

  shouldComponentRender = () => {
    const { nodeRef, metadataLoaded } = this.props;

    if(!nodeRef || !metadataLoaded) return false;
    return true;
  }

  render() {
    const { isWaitingMeta, t } = this.props
    if(!this.shouldComponentRender()) return <></>     
    
    if(isWaitingMeta) {
      return (
        <div className='meta-loader'>
          <Spinner />
          <p>{t('LOADING_METADATA')} ...</p>
        </div>
      )
    }

    return (
      <canvas className={`meta-display`} ref={this.canvasRef}>
      </canvas>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const isWaitingMeta = getMetaPending(state, ownProps.device.artecoId);
  const onvifData = getOnvifData(state);
  const currentObjectId = getCurrentObjectId(state);

  return {
    isWaitingMeta,
    onvifData,
    currentObjectId
  };
}

export default connect(
  mapStateToProps,
  {showOnvifDataPopup}
)(withTranslation()(OnvifMetaDisplay))