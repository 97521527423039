import React, { Component } from 'react'
import { ReactComponent as ArrowBack } from '../../components/svgIcons/tiArrowBack.svg';
import { ReactComponent as TimerOutline } from '../../components/svgIcons/biHistory.svg';
import { DownloadCloud as Download } from "react-feather";
import { Loader as LoaderIcon } from "react-feather";
import { Download as DownloadReady } from "react-feather";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isCredentialEnabled } from '../../reducers/serversReducer';
import { ExportVideo, InstantPlayer } from '../../helpers/serverCredentials';
import OutsideClickHandler from 'react-outside-click-handler';

export class InstantPlayerPlugin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: null
    }
  }

  openMenu = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { isPlayback, label, switchToInstantAction, switchToLiveAction, exportVideoAction, exportStatus, getFileAction, userPrefs, downloadProgress, isDownloading,device } = this.props.children;
    const {open} = this.state;
    const icon = (!isPlayback) ? <TimerOutline size={16} /> : '';
    const btnClass = (!isPlayback) ? "instant-player-button" : 'backlive-player-button';
    const openClass = (open) === null ? "": (open) ? 'open' : 'close';
    let DownloadButton = (<></>);

    if (userPrefs && userPrefs.exportServiceEndpoint && userPrefs.exportServiceChecked && userPrefs.exportServiceEnabled) {
      switch (exportStatus) {
        case undefined:
          DownloadButton = (
            <button type="button" className={`media-control-button media-control-icon export-button`} aria-label="export video" onClick={exportVideoAction}>
              <Download className="export" />
            </button>
          )
          break;
        case 'pending':
        case 'in progress':
          DownloadButton = (
            <button type="button" className={`media-control-button media-control-icon export-button`} aria-label="wait">
              <LoaderIcon className="loading" />
            </button>
          )
          break;
        case 'complete':
          DownloadButton = (
            <button type="button" className={`button media-control-button media-control-icon export-button ready`} aria-label="wait" onClick={getFileAction}>
              <DownloadReady className="ready" />
            </button>
          )
          break;
        case 'error':
          DownloadButton = (
            <button type="button" className={`button media-control-button media-control-icon export-button ready`} aria-label="wait">
              {/* <ErrorIcon className="ready" /> */}
            </button>
          )
          break;
        default:
          DownloadButton = (<></>)
          break;
      }

      if(downloadProgress && isDownloading) {
        DownloadButton = (
          <button type="button" className={`button media-control-button media-control-icon export-button ready`} aria-label="wait">
            <DownloadReady className="downloading" />
          </button>
        )
      }
    }



    return (isPlayback) ?
      (
        <>
          <button type="button" className={`media-control-button media-control-icon ${btnClass}`} aria-label="instant player" onClick={switchToLiveAction}>
            <ArrowBack />
          </button>
          {isCredentialEnabled(ExportVideo,device) && DownloadButton}
        </>
      )
      :
      (
        isCredentialEnabled(InstantPlayer,device) &&
        <OutsideClickHandler onOutsideClick={() => { 
          if (open ) {
            this.setState({
              open: false
            })
            
          }}}>
        <div className={`instant-player-menu ${openClass}`}>
          <button type="button" className={`media-control-button media-control-icon instant-player-trigger`} aria-label="instant player" onClick={this.openMenu}>
            {icon}
          </button>
          <div className={`instant-player-options ${openClass}`}>
            <button type="button" className={`media-control-button media-control-icon ${btnClass}`} aria-label="instant player" onClick={() => { switchToInstantAction(30,0) }}>
            30'
            </button>
            <button type="button" className={`media-control-button media-control-icon ${btnClass}`} aria-label="instant player" onClick={() => { switchToInstantAction(5,0) }}>
             5'
            </button>
            {/* TODO:: ROLLBACK!!! */}
            <button type="button" className={`media-control-button media-control-icon ${btnClass}`} aria-label="instant player" onClick={() => { switchToInstantAction(1,0) }}>
             1'
            </button>
            <button type="button" className={`media-control-button media-control-icon ${btnClass}`} aria-label="instant player" onClick={() => { switchToInstantAction(0,30) }}>
             30''
            </button>
            <button type="button" className={`media-control-button media-control-icon ${btnClass}`} aria-label="instant player" onClick={() => { switchToInstantAction(0,15) }}>
             15''
            </button>
          </div>
        </div>
        </OutsideClickHandler>
      )
  }
}

export default InstantPlayerPlugin
