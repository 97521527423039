import React, { Component } from 'react'
import { connect } from "react-redux";
import axios from "axios";
import { getLanAddress } from '../../../helpers/network';
import { getDeviceByArtecoId, getParentServerByArtecoId } from '../../../reducers/serversReducer';
import { getActiveLayout, getChannelStreamMode, getActiveLayoutLock } from '../../../reducers/layoutsReducer';
import { CIVETWEB_ENTRYPOINT } from '../../../config/backend';
import { withTranslation } from 'react-i18next';
import { getDomeByArtecoId, getDomesPending } from '../../../reducers/domesReducer';
import { ArtecoSelect } from '../../recordings/ArtecoSelect/ArtecoSelect';
import { isMobile } from 'react-device-detect';

import { ReactComponent as Right } from '../../svgIcons/bsArrowRight.svg';
import { ReactComponent as Left } from '../../svgIcons/bsArrowLeft.svg';
import { ReactComponent as Up } from '../../svgIcons/bsArrowUp.svg';
import { ReactComponent as Down } from '../../svgIcons/bsArrowDown.svg';
import { Square as Stop } from 'react-feather';
import { ZoomIn as ZoomInIcon } from 'react-feather';
import { ZoomOut as ZoomOutIcon } from 'react-feather';
import { X as CloseIcon } from 'react-feather';
import { ReactComponent as DomeIcon } from '../../../components/svgIcons/cgController.svg';

import Spinner from '../../Spinner/Spinner';
import { forceLogin } from '../../../actions/serverActions';
import { artecoVars } from '../../../config/MUIThemes';
import { getAuthentication } from '../../../reducers/authReducer';

const moveActions = {
  UP: "up",
  DOWN: "down",
  LEFT: "left",
  RIGHT: "right",
  ZOOM_IN: "zoom_tele",
  ZOOM_OUT: "zoom_wide",
  STOP: "stop",
}
class DomeController extends Component {

  constructor() {
    super();
    this.state = {
      selectedSequence: null,
      error: null,
      loginProgress: false,
      showPTZpanel: true,
      hide: false
    }
  }

  componentDidMount() {
    const { auth } = this.props;
  }
  componentDidUpdate(prevProps, prevState) {
    const { error, loginProgress } = this.state;
    const { currentDome, forceLogin, server } = this.props;

    if (error !== prevState.error && !loginProgress) {
      this.setState({
        loginProgress: true,
      }, () => {
        forceLogin({ _id: currentDome.serverId });
      })
    }

    if (loginProgress && server) {
      if (server.sessionId != prevProps.server.sessionId) {
        this.setState({
          error: null,
          loginProgress: false,
        })
      }
    }

    if (prevProps.containerHeight !== this.props.containerHeight) {
      this.checkFit();
    }
  }

  checkFit = () => {
    const { containerHeight } = this.props;

    const ptz_panel_height_mobile = parseInt(artecoVars.ptz_panel_height_mobile.replace("px", ""));
    const ptz_panel_height = parseInt(artecoVars.ptz_panel_height.replace("px", ""));

    if (containerHeight < (ptz_panel_height_mobile + 50)) {
      this.setState({
        hide: true
      })
      return false;
    }

    if (isMobile && containerHeight < (ptz_panel_height + 50)) {
      this.setState({
        hide: true
      })

      return false;
    }

    this.setState({
      hide: false
    })
    return true;
  }

  getMainUrl = () => {
    const { server, device } = this.props;
    const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;
    return `${server.protocol}://${serverIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/ptz?SessionId=${server.sessionId}`;
  }

  movePTZ = (action, speed) => {
    const { device } = this.props;
    let ptzSpeed = speed ? speed : 40;
    let API = this.getMainUrl() + `&action=${action}&chId=${device.id}&speed=${ptzSpeed}`;
    this.sendCommand(API);
  }

  goToPreset = (value) => {
    let API = this.getMainUrl() + value.call_preset;
    this.sendCommand(API);
  }

  startSequence = (value) => {
    let API = this.getMainUrl() + value.start_action;
    this.sendCommand(API);
    this.setState({
      selectedSequence: value,
    })
  }

  stopSequence = () => {
    const { selectedSequence } = this.state;
    if (!selectedSequence) { return; }
    this.setState({
      selectedSequence: null
    }, () => {
      let API = this.getMainUrl() + selectedSequence.stop_action;
      this.sendCommand(API);

    })
  }

  sendCommand = (url) => {

    const { domeIsPending } = this.props

    if (domeIsPending) {
      return;
    }
    axios
      .post(url)
      .then(res => {
        const mainNode = res.data.root;
        if (mainNode.result.code == 5) { // il 6 va rimosso
          this.setState({
            error: mainNode.result, // setto l'errore
          })
        }
        if (mainNode.result.code == 6) { // il 6 va rimosso
          // ops .. non è una PTZ !!
        }
      })
      .catch(error => {

      })
  }

  moveRight = () => {
    this.movePTZ(moveActions.RIGHT);
  }
  moveLeft = () => {
    this.movePTZ(moveActions.LEFT);
  }
  moveUp = () => {
    this.movePTZ(moveActions.UP);

  }
  moveDown = () => {
    this.movePTZ(moveActions.DOWN);
  }
  zoomWide = () => {
    this.movePTZ(moveActions.ZOOM_OUT);
  }
  zoomTele = () => {
    this.movePTZ(moveActions.ZOOM_IN);
  }
  stop = () => {
    this.movePTZ(moveActions.STOP);

  }

  getListOfPresets = () => {
    const { currentDome } = this.props;
    if (!currentDome) { return []; }
    const values = currentDome.presets;
    const list = [];
    if (values) {
      values.map((dome) => {

        /*
          "id": 3,
          "name": "iPad",
          "call_preset": "&chId=2&action=preset&preset_idx=3"
        */

        const value = dome.id;
        const label = dome.name;
        const call_preset = dome.call_preset;

        list.push({ value, label, call_preset });
      })
    }
    return list;
  }

  getListOfSequences = () => {
    const { currentDome } = this.props;
    if (!currentDome) { return []; }
    const values = currentDome.sequences;
    const list = [];
    if (values) {
      values.map((dome) => {

        /*
            "id": 1,
            "name": "Cupertino",
            "start_action": "&chId=2&action=start_sequence&sequence_idx=1",
            "stop_action": "&chId=2&action=stop_sequence"
        */

        const value = dome.id;
        const label = dome.name;
        const start_action = dome.start_action;
        const stop_action = dome.stop_action;

        list.push({ value, label, start_action, stop_action });
      })
    }
    return list;
  }

  errorChanged(nextError, prevError) {
    if (!nextError && !prevError) return false;

    if ((nextError && !prevError) || (!nextError && prevError)) return true;

    if ((nextError.code !== prevError.code)) return true;

    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.server?.sessionId !== this.props.server?.sessionId ||
      nextProps.currentDome !== this.props.currentDome && nextProps.currentDome != undefined ||
      nextState.selectedSequence !== this.state.selectedSequence ||
      this.errorChanged(nextState.error, this.state.error) ||
      nextState.showPTZpanel !== this.state.showPTZpanel ||
      nextProps.containerHeight !== this.props.containerHeight ||
      nextState.hide !== this.state.hide
    ) {
      return true;
    }
    return false;
  }

  shouldComponentRender() {
    const { server } = this.props;
    if (!server?.capabilities?.ptz == 1 || !server?.capabilities?.ptz) {
      return false;
    }
    return true;
  }

  componentHasError = () => {
    const { error } = this.state;
    if (error) {
      return true;
    }
    return false;
  }


  showPTZPanel = () => {
    this.setState({
      showPTZpanel: true
    })
  }

  hidePTZPanel = () => {
    this.setState({
      showPTZpanel: false
    })
  }

  render() {
    const { t } = this.props;
    const { selectedSequence, showPTZpanel, hide } = this.state;

    const showTriggerClass = showPTZpanel ? 'inactive' : '';
    const hideTriggerClass = showPTZpanel ? 'active' : '';

    if (!this.shouldComponentRender()) {
      return <></>
    }


    const stopBtnClass = selectedSequence ? 'active' : 'disabled';
    const showPanelClass = showPTZpanel ? 'show' : '';

    if (hide) {
      return (
        <></>
      )
    }

    return (
      <>
        <div className={`ptz-controller-trigger ${showTriggerClass}`} onClick={this.showPTZPanel}>
          <DomeIcon />
        </div>
        <div className={`ptz-controller-hide ${hideTriggerClass}`} onClick={this.hidePTZPanel}>
          <CloseIcon size={16}/>
        </div>
        <div className={`ptz-controller ${showPanelClass}`}>
          {
            this.componentHasError() && <Spinner />
          }

          {
            !this.componentHasError() && (
              <>
                <div className='controls'>

                  <div className="upper">
                    <button
                      onMouseDown={this.moveUp}
                      onMouseUp={this.stop}
                      onTouchStart={this.moveUp}
                      onTouchEnd={this.stop}
                    >
                      <Up />
                    </button>
                  </div>
                  <div className="medium">
                    <button
                      onMouseDown={this.moveLeft}
                      onMouseUp={this.stop}
                      onTouchStart={this.moveLeft}
                      onTouchEnd={this.stop}
                    >
                      <Left />
                    </button>
                    <button
                      onClick={this.stopSequence}
                      className={stopBtnClass}

                    >
                      <Stop size={16}/>
                    </button>
                    <button
                      onMouseDown={this.moveRight}
                      onMouseUp={this.stop}
                      onTouchStart={this.moveRight}
                      onTouchEnd={this.stop}
                    >
                      <Right />
                    </button>
                  </div>
                  <div className="lower">
                    <button
                      onMouseDown={this.moveDown}
                      onMouseUp={this.stop}
                      onTouchStart={this.moveDown}
                      onTouchEnd={this.stop}
                    >
                      <Down />
                    </button>
                  </div>
                  <div className="zoom">
                    <button
                      onMouseDown={this.zoomTele}
                      onMouseUp={this.stop}
                      onTouchStart={this.zoomTele}
                      onTouchEnd={this.stop}
                    >
                      <ZoomInIcon size={16}/>
                    </button>
                    <button
                      onMouseDown={this.zoomWide}
                      onMouseUp={this.stop}
                      onTouchStart={this.zoomWide}
                      onTouchEnd={this.stop}
                    >
                      <ZoomOutIcon size={16}/>
                    </button>
                  </div>
                </div>
                <div className='preset_sequences'>
                  <ArtecoSelect
                    isSearchable={false}
                    isMulti={false}
                    options={this.getListOfPresets()}
                    onChange={this.goToPreset}
                    placeholder={t('DOMECONTROLLER_PRESET_PLACEHOLDER')}
                  />
                  <ArtecoSelect
                    isSearchable={false}
                    isMulti={false}
                    options={this.getListOfSequences()}
                    onChange={this.startSequence}
                    placeholder={t('DOMECONTROLLER_PRESET_SEQUENCES')}
                  />
                </div>
              </>
            )
          }
        </div>
      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const device = getDeviceByArtecoId(state, ownProps.artecoId);
  const server = getParentServerByArtecoId(state, ownProps.artecoId);
  const activeLayout = getActiveLayout(state);
  const activeLayoutLocked = getActiveLayoutLock(state);
  const channelStreamMode = getChannelStreamMode(state, ownProps.artecoId, 0, server);
  const auth = getAuthentication(state);
  const currentDome = getDomeByArtecoId(state, ownProps.artecoId);
  const domeIsPending = getDomesPending(state);

  return {
    device,
    server,
    activeLayout,
    activeLayoutLocked,
    channelStreamMode,
    auth,
    currentDome,
    domeIsPending
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forceLogin: (server) => dispatch(forceLogin(server)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DomeController))
