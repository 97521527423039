import axios from "axios";

import { getLanAddress } from '../helpers/network';
import { CIVETWEB_ENTRYPOINT } from "../config/backend";
import { getMeta, getPlaylist } from "../helpers/server";
import { isIOS, isMobileOnly } from 'react-device-detect';
import { FETCH_PLAYLIST_PENDING, FETCH_PLAYLIST_SUCCESS, FETCH_PLAYLIST_ERROR, FETCH_META_PENDING, FETCH_META_SUCCESS, FETCH_META_ERROR } from "./types";
import LZString from 'lz-string';
import { convertUTCToLocal, serverStringToDate } from "../helpers/timeHelpers";

const loggerScope = "fetchPlaylist";


const isIphone = isIOS && isMobileOnly;

function fetchPlaylistPending(artecoId) {
  return {
    type: FETCH_PLAYLIST_PENDING,
    payload: {
      artecoId
    }
  }
}

function fetchPlaylistSuccess(events) {
  return {
    type: FETCH_PLAYLIST_SUCCESS,
    payload: events
  }
}

export function fetchPlaylistError(error) {
  return {
    type: FETCH_PLAYLIST_ERROR,
    error: error
  }
}

function fetchMetaPending(artecoId) {
  return {
    type: FETCH_META_PENDING,
    payload: {
      artecoId
    }
  }
}

function fetchMetaSuccess(events) {
  return {
    type: FETCH_META_SUCCESS,
    payload: events
  }
}

function fetchMetaError(error) {
  return {
    type: FETCH_META_ERROR,
    error: error
  }
}



const groupByStringTime = (array) => {
  return array.reduce((acc, item) => {
    const timeStamp = item.FrameTime.StringTime;

    if (!acc[timeStamp]) {
      acc[timeStamp] = [];
    }
    acc[timeStamp].push(item);

    return acc;
  }, {});
};



function aggregateMetadata(inputData, rounding, shiftAmount) {
  if (rounding === null || rounding === undefined || !inputData) {
    return inputData;
  }

  const expectedTimestampLength = 17;
  const truncateAt = expectedTimestampLength - rounding;
  const aggregatedData = {};

  inputData.forEach(({ timeStamp, metadata }) => {
    const paddedInitialTimestamp = timeStamp.padEnd(expectedTimestampLength, '0');
    const paddedTimeStamp = paddedInitialTimestamp.slice(0, truncateAt).padEnd(expectedTimestampLength, '0');

    if (!aggregatedData[paddedTimeStamp]) {
      aggregatedData[paddedTimeStamp] = [];
    }

    metadata.forEach((meta) => {
      const objectId = meta.ObjectId;
      const existingMeta = aggregatedData[paddedTimeStamp].find(
        (item) => item.ObjectId === objectId
      );

      if (!existingMeta) {
        aggregatedData[paddedTimeStamp].push(meta);
      }
    });
  });

  const result = Object.keys(aggregatedData).map((ts) => {
    let shiftedTimestamp = ts;
    if (shiftAmount !== null && shiftAmount !== undefined) {
      const originalTimestamp = new Date(
        parseInt(ts.slice(0, 4)), // Anno
        parseInt(ts.slice(4, 6)) - 1, // Mese (0-based)
        parseInt(ts.slice(6, 8)), // Giorno
        parseInt(ts.slice(8, 10)), // Ore
        parseInt(ts.slice(10, 12)), // Minuti
        parseInt(ts.slice(12, 14)), // Secondi
        parseInt(ts.slice(14, 17)) // Millisecondi
      );

      if (!isNaN(originalTimestamp.getTime())) {
        let shiftedTime = originalTimestamp.getTime();
        if (shiftAmount >= 0) {
          shiftedTime += shiftAmount * 1000; // Converti in millisecondi
        } else {
          shiftedTime -= -shiftAmount * 1000; // Converti in millisecondi
        }

        const shiftedDate = new Date(shiftedTime);
        const shiftedYear = shiftedDate.getFullYear().toString().padStart(4, '0');
        const shiftedMonth = (shiftedDate.getMonth() + 1).toString().padStart(2, '0');
        const shiftedDay = shiftedDate.getDate().toString().padStart(2, '0');
        const shiftedHours = shiftedDate.getHours().toString().padStart(2, '0');
        const shiftedMinutes = shiftedDate.getMinutes().toString().padStart(2, '0');
        const shiftedSeconds = shiftedDate.getSeconds().toString().padStart(2, '0');
        const shiftedMilliseconds = shiftedDate.getMilliseconds().toString().padStart(3, '0');

        shiftedTimestamp = shiftedYear + shiftedMonth + shiftedDay + shiftedHours + shiftedMinutes + shiftedSeconds + shiftedMilliseconds;
      }
    }

    return {
      timeStamp: shiftedTimestamp,
      metadata: aggregatedData[ts],
    };
  }).sort((a, b) => a.timeStamp.localeCompare(b.timeStamp));

  return result;
}



function metaArrayToObject(inputData) {

  let timestampKeyedObject = {};

  if(inputData){
    inputData.forEach(({ timeStamp, metadata }) => {
      timestampKeyedObject[timeStamp] = metadata;
    });
  }

  return timestampKeyedObject;
}




function fetchPlaylist(playRequest) {

  return dispatch => {
    dispatch(fetchPlaylistPending(playRequest.artecoId));
    
    const serverIp = playRequest.serverIsLocal ? getLanAddress(playRequest.serverIp) : playRequest.serverIp;

    let req = {
      "serverProtocol": playRequest.serverProtocol,
      "serverIp": serverIp,
      "serverPort": playRequest.serverPort,
      "sessionId": playRequest.sessionId,
      "access_token": playRequest.access_token,
      "chId": playRequest.deviceId,
      "DVR": playRequest.dvr,
      "from": playRequest.startTime,
      "to": playRequest.endTime,
      "server": playRequest.server
    }
    getPlaylist(req)
      .then(res => {
        if (res.error) {
          throw (res.error);
        }

        const response = res.root;

        //error handling!!
        if (response.result.code !== 0) {
          dispatch(fetchPlaylistError({
            artecoId: playRequest.artecoId,
            reason: response.result.msg
          }));
          return response.result.msg;
        } else {

          const playlistResponse = response.info;
          
          let recPathParsed = playlistResponse.recpath || playlistResponse.recPath;
          let recPath = recPathParsed.replace(/\\/g, "/");

          const isTranscoding = recPath.includes("_M"); // convention over configuration, se c'è _M è una playlist ricodificata da FFMPEG

          /* REMOVE THIS ASAP (old backend check) */
          const containsStreamDefinition = recPath.includes("/stream.m3u8"); // TODO  se c'è stream.m3u8 allora è una playlist vecchio metodo, lagacy code da rimuovere.

          if (!containsStreamDefinition && isTranscoding) {
            recPath += "/stream.m3u8";
          }

          const playlistData = {
            recPath: (playRequest.server.nodeServer && !isIphone) ? `${playRequest.serverProtocol}://${serverIp}:${playRequest.serverPort}/${recPath}` : `${playRequest.serverProtocol}://${serverIp}:${playRequest.serverPort}/${CIVETWEB_ENTRYPOINT}/${recPath}`,
            artecoId: playRequest.artecoId,
            artecoEventId: playRequest.artecoEventId,
            from: playlistResponse.actualFrom ? playlistResponse.actualFrom : playRequest.startTime,
            to: playlistResponse.actualTo ? playlistResponse.actualTo : playRequest.endTime,
            playlistId: `${playRequest.artecoId}_${playRequest.startTime}_${playRequest.endTime}`,
            mode: 'live', // live or vod
            buffering: false, // is buffering ?
            playing: false,
            isTranscoding,
            serverCodename: playRequest.serverCodename,
            mediaSecret: playRequest.mediaSecret,
            serverIp: serverIp,
            serverPort: playRequest.serverPort,
            serverProtocol: playRequest.serverProtocol,
            sessionId: playRequest.sessionId,
            access_token: playRequest.access_token,
            nodeServer: playRequest.server?.nodeServer,
            serverIsLocal: playRequest.serverIsLocal,
            actualInfo: {
              // info più di debug che di reale utilità
              actualFrom: playlistResponse.actualFrom ? playlistResponse.actualFrom : "TBD", // "TBD" -> server che non ha fornito l'info
              actualTo: playlistResponse.actualTo ? playlistResponse.actualTo : "TBD",// "TBD" -> server che non ha fornito l'info
            },
            metadataHistory: playlistResponse.metadataHistory,
            descr: playRequest.descr,
            serverTimeZone: playRequest.server.timezone
          }


          dispatch(fetchPlaylistSuccess(playlistData));

          //start meta request
          if(playRequest.hasMeta) {
            dispatch(fetchMetaPending(playRequest.artecoId));
      
            getMeta(req)
              .then(res => {
                if (res.error) {
                  throw (res.error);
                }
      
                                const response = res.root;
      
                if (response.result.code !== 0) {
                  dispatch(fetchMetaError({
                    artecoId: playRequest.artecoId,
                    reason: response.result.msg
                  }));
                  return response.result.msg;
                } else {
                  const playlistResponse = response.info;
                  
                  //decompress meta            
                  const meta = playlistResponse.onvifMetadataHistory && JSON.parse(LZString.decompressFromEncodedURIComponent(playlistResponse.onvifMetadataHistory));
                  const sampledMeta = meta && aggregateMetadata(meta, 2, -0.6);
                  const metaObject = sampledMeta ? metaArrayToObject(sampledMeta) : {};
                  
                  const playlistData = {
                    serverCodename: playRequest.serverCodename,              
                    artecoId: playRequest.artecoId,
                    artecoEventId: playRequest.artecoEventId,              
                    playlistId: `${playRequest.artecoId}_${playRequest.startTime}_${playRequest.endTime}`,              
                    mediaSecret: playRequest.mediaSecret,
                    serverIp: serverIp,
                    serverPort: playRequest.serverPort,
                    serverProtocol: playRequest.serverProtocol,
                    sessionId: playRequest.sessionId,
                    access_token: playRequest.access_token,
                    nodeServer: playRequest.server?.nodeServer,
                    serverIsLocal: playRequest.serverIsLocal,
                    onvifMetadataHistory: metaObject
                  }
      
                  dispatch(fetchMetaSuccess(playlistData));
                  return playlistData;
                }
      
              })
          }

          return playlistData;

        }
      })
      .catch(error => {
        dispatch(fetchPlaylistError({
          artecoId: playRequest.artecoId,
          reason: error
        }));
        dispatch(fetchMetaError({
          artecoId: playRequest.artecoId,
          reason: error
        }));
      })

  }
}

export default fetchPlaylist;