import {
  FETCH_MAPS_PENDING,
  FETCH_MAPS_SUCCESS,  
  RESET_MAPS,
  FETCH_MAPS_ERRORS,
  MAP_SERVER_REMOVED,
  MAP_SHOULD_ZOOM,
  RESET_MAPS_ERRORS,
  RESET_SERVER_MAPS,
} from "../actions/types";
import {info, logger} from '../helpers/logger';

const initialState = {
  pendingMaps: false,
  maps: [],
  errors: {}
};

export function MapsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAPS_PENDING:
      return {
        ...state,
        pendingMaps: true
      }
    case FETCH_MAPS_SUCCESS:            
      const firstFetch = !(Array.isArray(state.maps) && state.maps.length > 0);
      let mergedMaps = [];

      if(firstFetch) {
        mergedMaps =  action.payload != null ? action.payload : state.maps;

      } else {      
        mergedMaps = action.payload != null ? state.maps.concat(action.payload) : state.maps;
      }

      // removing error if present

      delete state.errors[action.server_id];

      logger(info, "MapsReducer","Rimuovo elemento con id = ' " + action.server_id  + " '");
          
      return {
        ...state,
        pendingMaps: false,
        errors: state.errors,
        maps: mergedMaps.map(map => {
          return {
            ...map,
            artecoId: `${map.serverCodename}_${map.id}`
          }
        })
      }

    case RESET_MAPS:      
      return {
        ...state,
        maps: []
      }

    case RESET_SERVER_MAPS:

    // removing maps from state if matching with certain server

    const currentMapInState = state.maps;
    const mapsWithoutCertainServer = currentMapInState.filter(item => item.serverCodename != action.server.codeName)

      return {
        ...state,
        maps: mapsWithoutCertainServer
      }

    case RESET_MAPS_ERRORS:      

    return {
        ...state,
        errors: []
      }
    case FETCH_MAPS_ERRORS:

      var dictOfErrors = new Object();

      dictOfErrors = state.errors;

      dictOfErrors[action.server_id] =  {
           error: action.error
      };

      
      return {
        ...state,
        pendingMaps: false,
        errors: dictOfErrors
      }
    case MAP_SERVER_REMOVED:      
      let deletedMaps = Array.isArray(state.maps) ? state.maps.filter((item) => (item.owner !== action.payload.owner || item.serverId !== action.payload.serverId)) : [];
      return {
        ...state,
        pendingMaps: false,
        maps: deletedMaps
      }
    case MAP_SHOULD_ZOOM: 
    return {
      ...state,
      shouldZoom: action.payload.shouldZoom,
      devicesToZoom: action.payload.devicesToZoom,
      shouldScroll: action.payload.shouldScroll
    }
    default:
    return state;
  }
}

export const getMaps = state => state.maps;
export const getMapsPending = state => state.maps.pendingMaps;
export const getMapsError = state => state.maps.errors;

export const mapShouldZoom = state => state.maps.shouldZoom;
export const deviceShouldZoom = (state, artecoId) => {
  if(!state.maps.shouldZoom) return false;
  return !!state.maps.devicesToZoom.find(device => device.artecoId === artecoId);
}
export const treeShouldScroll = state => state.maps.shouldScroll;
export const devicesToZoomTo = state => state.maps.devicesToZoom;
