import axios from "axios";

export const FETCH_PLUGINS_PENDING = 'FETCH_PLUGINS_PENDING';
export const FETCH_PLUGINS_SUCCESS = 'FETCH_PLUGINS_SUCCESS';
export const FETCH_PLUGINS_ERROR = 'FETCH_PLUGINS_ERROR';

function fetchPluginsPending() {
  return {
    type: FETCH_PLUGINS_PENDING
  }
}

function fetchPluginsSuccess(tags) {
  return {
    type: FETCH_PLUGINS_SUCCESS,
    payload: tags
  }
}

function fetchPluginsError(error) {
  return {
    type: FETCH_PLUGINS_ERROR,
    error: error
  }
}

function fetchPlugins(userId, artecoId) {

  return dispatch => {
    dispatch(fetchPluginsPending());

    let req = {
      "artecoId": artecoId
    }

    const API = '/api/plugins/get-all';

    return axios
      .post(API, req)
      .then(res => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(fetchPluginsSuccess({
          plugins: res.data,
        }));
        return res.data;
      })
      .catch(error => {
        dispatch(fetchPluginsError(error));
      })
  }
}

export default fetchPlugins;