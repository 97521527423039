import {
  FETCH_PLAYLIST_PENDING,
  FETCH_PLAYLIST_SUCCESS,
  FETCH_PLAYLIST_ERROR,
  FETCH_META_PENDING,
  FETCH_META_SUCCESS,
  FETCH_META_ERROR,
  SHOW_TIME_SEARCH_PANEL,
  SAY_IM_READY,
  UPDATE_PLAYLIST,
  RESET_PLAYLISTS,
  EXPORT_IS_PENDING,
  EXPORT_IS_READY,
  EXPORT_IS_DELETED,
  SET_CHANNEL_MODE,
  SET_CHANNELS_MODE,
  SET_EDGE_PLAYING,
  RESET_PLAYLISTS_ERRORS_BY_ARTECOID
} from "../actions/types";
import { info, logger } from "../helpers/logger";

const initialState = {
  pendingPlaylists: true,
  pendingMetas: {},
  playlists: {},
  readyPlaylists: {},
  showTimeSearch: false,
  errors: null,
  metaErrors: null,
  errorCameras: {},
  edgeIsPlaying: false,
};

export function PlaylistsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_META_PENDING:
      const updatedPendingMeta = { ...state.pendingMetas };
      updatedPendingMeta[action.payload.artecoId] = true;

      return {
        ...state,
        pendingMetas: updatedPendingMeta
      }
    case FETCH_PLAYLIST_PENDING:

      const pendingErrors = {
        ...state.errors
      }

      if (action.payload.artecoId && pendingErrors[action.payload.artecoId]) {
        delete pendingErrors[action.payload.artecoId]
      }

      return {
        ...state,
        pendingPlaylists: true,
        readyPlaylists: {},
        errors: pendingErrors
      }
    case FETCH_META_SUCCESS:
      const metaPlaylistId = action.payload.playlistId;
      const updatedMetaPlaylists = {
        ...state.playlists,
        [metaPlaylistId]: {
          ...state.playlists[metaPlaylistId],
          onvifMetadataHistory: action.payload.onvifMetadataHistory
        }
      }

      const cleanErrorsMeta = {
        ...state.errors
      }

      const artecoIdMeta = action.payload.playlistId.split('_')[0];

      if (cleanErrorsMeta[artecoIdMeta]) {
        delete cleanErrorsMeta[artecoIdMeta]
      }

      const successPendingMeta = { ...state.pendingMetas };
      successPendingMeta[action.payload.artecoId] = false;

      return {
        ...state,
        pendingPlaylists: false,
        pendingMetas: successPendingMeta,
        playlists: updatedMetaPlaylists,
        metaErrors: cleanErrorsMeta
      }
    case FETCH_PLAYLIST_SUCCESS:
      const existingPlaylist = state.playlists[action.payload.playlistId];

      const updatedPlaylists = {
        ...state.playlists,
        [action.payload.playlistId]: {
          ...action.payload,
          exportStatus: existingPlaylist ? existingPlaylist.exportStatus : undefined,
          path: existingPlaylist ? existingPlaylist.path : undefined,
          from: action.payload.from,
          to: action.payload.to,
          metadataHistory: action.payload.metadataHistory
        }
      }

      const cleanErrors = {
        ...state.errors
      }

      const artecoId = action.payload.playlistId.split('_')[0];

      if (cleanErrors[artecoId]) {
        delete cleanErrors[artecoId]
      }

      return {
        ...state,
        pendingPlaylists: false,
        playlists: updatedPlaylists,
        errors: cleanErrors
      }
    case SET_CHANNELS_MODE: {
      if (action.payload.mode.mode === 'live') {
        return {
          ...state,
          pendingMetas: {}
        }
      }
      return {
        ...state
      }
    }
    case SET_CHANNEL_MODE: {
      if (action.payload.mode.mode === 'live') {
        // let noMetaPlaylists = {
        //   ...state.playlists
        // }
        const cleanPendingMeta = { ...state.pendingMetas };
        cleanPendingMeta[action.payload.artecoId] = false;

        //erase metadata from existing playlists ?
        // const playlistIds = Object.values(state.playlists).filter(playlist => playlist.artecoId === action.payload.artecoId).map(p => p.playlistId)
        // if(playlistIds && playlistIds.length > 0) {
        //   playlistIds.map(id => {
        //     noMetaPlaylists = {
        //       ...state.playlists,
        //       [id]: {
        //         ...state.playlists[id],
        //         metadataHistory: undefined
        //       }
        //     }
        //   })
        // }
        return {
          ...state,
          //playlists: noMetaPlaylists,
          pendingMetas: cleanPendingMeta
        }
      }
      return {
        ...state
      }
    }
    case EXPORT_IS_PENDING:
      const preExportPlaylists = {
        ...state.playlists,
        [action.payload]: {
          ...state.playlists[action.payload],
          exportStatus: 'pending'
        }
      }

      return {
        ...state,
        pendingPlaylists: false,
        playlists: preExportPlaylists
      }
    case EXPORT_IS_READY:
      const exportPlaylists = {
        ...state.playlists,
        [action.payload.playlistId]: {
          ...state.playlists[action.payload.playlistId],
          exportStatus: action.payload.status,
          path: action.payload.path,
        }
      }

      return {
        ...state,
        pendingPlaylists: false,
        playlists: exportPlaylists
      }
    case EXPORT_IS_DELETED:
      const exportDeletedPlaylists = {
        ...state.playlists,
        [action.payload.playlistId]: {
          ...state.playlists[action.payload.playlistId],
          exportStatus: undefined,
        }
      }

      return {
        ...state,
        pendingPlaylists: false,
        playlists: exportDeletedPlaylists
      }
    case FETCH_PLAYLIST_ERROR:
      const updatedErrors = {
        ...state.errors,
        [action.error.artecoId]: action.error.reason
      }
      return {
        ...state,
        pendingPlaylists: false,
        errors: updatedErrors
      }
    case FETCH_META_ERROR:
      const updatedMetaErrors = {
        ...state.errors,
        [action.error.artecoId]: action.error.reason
      }
      const errorPendingMeta = { ...state.pendingMetas };
      errorPendingMeta[action.error.artecoId] = false;

      return {
        ...state,
        pendingMetas: errorPendingMeta,
        metaErrors: updatedMetaErrors
      }
    case SHOW_TIME_SEARCH_PANEL:
      return {
        ...state,
        showTimeSearch: action.payload
      }

    case SAY_IM_READY:
      let newReady = { ...state.readyPlaylists };
      newReady[action.payload] = 'READY';
      return {
        ...state,
        readyPlaylists: newReady
      }


    case SET_EDGE_PLAYING:

      return {
        ...state,
        edgeIsPlaying: action.payload
      }


    case UPDATE_PLAYLIST:

      let newPlaylists = { ...state.playlists };



      if (newPlaylists[action.payload.playlistId]) { // controllo se la playlist esiste già e se posso aggiornarla 

        logger(info, 'playlistsReducer', "UPDATE_PLAYLIST playlist playlistId " + action.payload.playlistId)

        const prova = (action.payload.to !== undefined) ? action.payload.to : newPlaylists[action.payload.playlistId].to;
        logger(info, 'playlistsReducer', "UPDATE_PLAYLIST playlist prova " + prova)



        newPlaylists[action.payload.playlistId] = {
          ...newPlaylists[action.payload.playlistId],
          mode: (action.payload?.mode !== undefined) ? action.payload.mode : newPlaylists[action.payload.playlistId].mode,
          playing: (action.payload.playing !== undefined) ? action.payload.playing : newPlaylists[action.payload.playlistId].playing,
          isError: (action.payload.isError !== undefined) ? action.payload.isError : newPlaylists[action.payload.playlistId].isError,

          // aggiornamento from/to

          from: (action.payload.from !== undefined) ? action.payload.from : newPlaylists[action.payload.playlistId].from,
          to: (action.payload.to !== undefined) ? action.payload.to : newPlaylists[action.payload.playlistId].to,

        }
      }

      return {
        ...state,
        playlists: newPlaylists
      }

    case RESET_PLAYLISTS:
      return {
        ...state,
        pendingPlaylists: true,
        pendingMetas: {},
        playlists: {},
        readyPlaylists: {},
        showTimeSearch: false,
        errors: null,
        errorCameras: {},
      }

    case RESET_PLAYLISTS_ERRORS_BY_ARTECOID:
      const myArtecoId = action.artecoId;
      const cleanedErrors = { ...state.errors };

      // Cicla sugli errori attuali e rimuove quelli associati all'artecoId specificato
      Object.keys(cleanedErrors).forEach(key => {
        if (key.includes(myArtecoId)) {
          delete cleanedErrors[key];
        }
      });

      return {
        ...state,
        errors: cleanedErrors
      };


    default:
      return state;
  }
}


export const getPlaylists = state => state.playlists;

export const getPlaylistsPending = state => state.playlists.pendingPlaylists;
export const getMetaPending = (state, artecoId) => state.playlists.pendingMetas[artecoId];

export const getPlaylistsErrors = state => {
  const errors = state.playlists.errors;
  return errors;
}

export const getPlaylistError = (state, artecoId) => {
  const errors = state.playlists.errors
  const error = errors ? state.playlists.errors[artecoId] : null;
  return error;
}

export const getPlaylistById = (state, playlistId) => {
  return state.playlists.playlists[playlistId];
}


export const isTimeSearchShown = (state) => { return state.playlists.showTimeSearch ? state.playlists.showTimeSearch : false; }
export const getExportStatus = (state, playlistId) => {
  const playlist = getPlaylistById(state, playlistId);
  return playlist ? playlist.exportStatus : undefined;
}

export const getExportPath = (state, playlistId) => {
  const playlist = getPlaylistById(state, playlistId);
  return playlist ? playlist.path : undefined;
}


export const getPlaylistsNum = state => { return Object.keys(state.playlists.playlists).length; }

export const listOfVODCameras = (state) => { return Object.values(state.playlists.playlists).filter(currentPlaylist => (currentPlaylist?.mode == 'vod')); }

export const getListOfPlayingCameras = (state) => {

  const playlists = state.playlists.playlists;
  const output = Object.values(playlists).filter(currentPlaylist => (currentPlaylist?.playing == true));

  return output;
}

export const getListOfErrorsCameras = (state) => { return Object.values(state.playlists.playlists).filter(currentPlaylist => (currentPlaylist.isError == true)); }


export const allCamerasStopped = state => {
  return (!getListOfPlayingCameras(state).length > 0);
}

export const allowPauseCameras = state => {

  const qtyOfPlayingCams = getListOfPlayingCameras(state).length;
  const qtyOfPlayingVodCams = listOfVODCameras(state).length;

  const artecoPlayback = ((qtyOfPlayingCams > 0 && qtyOfPlayingVodCams > 0) && (qtyOfPlayingCams == qtyOfPlayingVodCams));

  const edgePlayback = state.playlists.edgeIsPlaying;

  return artecoPlayback || edgePlayback;

}

export const getPlaylistsByArtecoId = (state, artecoId) => {
  return Object.values(state.playlists.playlists).filter(obj => obj?.artecoId == artecoId);
}

export const checkIfPlaylistGotPrivacy = (playlist) => {
  // check if current playlist got any privacy on it
  if (playlist == undefined) {
    return false;
  }

  const gotMetadataHistory = playlist.hasOwnProperty("metadataHistory") && Array.isArray(playlist.metadataHistory) && playlist.metadataHistory.length > 0;

  if (gotMetadataHistory) {
    const gotPrivacy = playlist?.metadataHistory.find(meta => meta.metadata.find(meta => meta.metadataType === 'PrivacyZone')) != undefined ? true : false;
    if (gotPrivacy) {
      // found some privacy !
      return true;
    }
  }
  return false;
}

export const getPlaylistsInRange = (state, startTime, endTime) => {
  // get the playlists gived a certain time interval
  const playlistsInRange = {};

  if ((startTime == null || isNaN(startTime)) && (endTime == null || isNaN(endTime))) {

    return playlistsInRange;
  }

  const playlists = state.playlists.playlists;

  for (const playlistId in playlists) {
    const playlist = playlists[playlistId];

    if(playlist == undefined || playlist?.from   == undefined|| playlist?.to == undefined){
      return playlistsInRange;
    }
    
    const playlistStartTime = parseInt(playlist.from);
    const playlistEndTime = parseInt(playlist.to);

    if (playlistStartTime <= parseInt(endTime) && playlistEndTime >= parseInt(startTime)) {
      playlistsInRange[playlist.playlistId] = playlist;
    }
  }
  // deve uscire come dizionario con artecoId come chiave
  return playlistsInRange;
}


export const getPlaylistsByDevices = (state, devices, startTime = null, endTime = null) => {

  // if provided time interval will list all playlists of a certain devices of that interval, otherwise will give all playlists of that devices

  const outPlaylists = [];


  if (devices == null | devices == undefined | Array.isArray(devices) && devices.length == 0 ) {
    return outPlaylists;
  }

  const playlistsToBeUsed = ((startTime == null || isNaN(startTime)) && (endTime == null || isNaN(endTime))) ? state.playlists.playlists : getPlaylistsInRange(state, startTime, endTime);

  for (let item of devices) {
    for (const [key, playlist] of Object.entries(playlistsToBeUsed)) {
      if (playlist.artecoId === item.artecoId) {
        outPlaylists[key] = playlistsToBeUsed[key];
      }
    }
  }

  return outPlaylists;

}

export const theresPrivacyInPlayslists = (playlists) => {
  // check if there privacy in one or more playlists
  if (playlists == null) {
    return false;
  }
  for (const [key, playlist] of Object.entries(playlists)) {
    if (checkIfPlaylistGotPrivacy(playlist)) {
      return true;
    }
  }
  return false;
}