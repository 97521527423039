import React from 'react';
import { Helmet } from 'react-helmet';

import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import {appPaths} from '../../config/paths';

function NotFound(props) {


    const { t } = props;
    const history = useHistory();

    const backToDashBoard = () => {
        history.push(appPaths.dashboard);
    }

    const title= document.title.split('|')[0].trim();
    return (

        <div className="not-found">
            <Helmet>
                <title>{title} | 404</title>
            </Helmet>
            <div className="col-12">
                <h1>{t('404_PAGE_NOT_FOUND_TITLE')}</h1>
                <p>{t('404_PAGE_NOT_FOUND')}</p>
            </div>
            <div className="col-12">

                <div className="row">
                    <div className="col-sm-3 col-xs-6">
                        <div className="form-group">

                            <button
                                className="btn btn-full-width"
                                type="button"
                                onClick={backToDashBoard}
                            >
                                {t('BACK_TO_DASHBOARD')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default withTranslation()(NotFound);