import React, { Component } from 'react'
import { connect } from 'react-redux'

//import translations
import { withTranslation } from 'react-i18next';
import { getCurrentObjectId, getOnvifData } from '../../reducers/featuresReducer';
import { addInterval, getCurrentServerTime, serverDateToFormattedDate, serverDateToUnixDate, serverTimeDiff, subtractInterval } from '../../helpers/timeHelpers';
import { ReactComponent as TimerOutline } from '../../components/svgIcons/biHistory.svg';
import { setChannelMode } from '../../actions/layoutActions';
import { setTimeMarker } from '../../actions/recordingsActions';
import { SkipBack as BackIcon} from 'react-feather';
import { SkipForward as ForwardIcon} from 'react-feather';
import Spinner from '../Spinner/Spinner';

export class OnvifDataDisplay extends Component {
  constructor(props) {
    super(props);
    
    this.MAX_TIME_MINUTES = 30;
  }

  replay = (appearanceTime, leaveTime) => {
    const { closeCB, setChannelMode, onvifData, setTimeMarker } = this.props;
    const {popupMode, artecoId} = onvifData;

    const permanence = serverTimeDiff(appearanceTime, leaveTime, 'minutes');
    let to = leaveTime;
    if(permanence > this.MAX_TIME_MINUTES) {
      to = addInterval(appearanceTime, `00:${this.MAX_TIME_MINUTES.toString().padStart(2, '0')}:00`)
    }

    const currentTime = getCurrentServerTime();
    const isInTheFuture = (serverTimeDiff(currentTime, to, 'minutes') > 0);

    if(isInTheFuture) {
      to = currentTime;
    }

    const mode = {
      mode: `instant`,
      from: appearanceTime,
      to: to
    }

    setChannelMode(mode, artecoId)    
    closeCB && closeCB();
  }

  seek = (time) => {
    const { closeCB, onvifData, setTimeMarker } = this.props;

    setTimeMarker(serverDateToUnixDate(time)); // forzo posizione all'inizio 
    
    closeCB && closeCB();
  }

  shouldComponentRender() {
    const { onvifData, currentObjectId } = this.props

    if (!onvifData) return false;

    if (onvifData.objectId !== currentObjectId) return false;

    return true;
  }

  formatSeconds = (seconds) => {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let timeString = "";

      if(hrs > 0) {
          timeString += hrs.toString().padStart(2, '0') + ":";
      }

      timeString += mins.toString().padStart(2, '0') + ":";

      timeString += secs.toString().padStart(2, '0');

      return timeString;
  }
  

  render() {
    const { t, onvifData, currentObjectId } = this.props;

    const additionalClass = 'arteco-onvifdata-popup-container';

    if (!this.shouldComponentRender()) return <Spinner />

    //rimuovo i millisecondi
    const appearanceTime = onvifData.FrameTime?.StringTime && onvifData.FrameTime.StringTime.padEnd(17, '0').slice(0, -3);
    const leaveTime = onvifData.lastOccurrence.FrameTime?.StringTime && onvifData.lastOccurrence.FrameTime.StringTime.padEnd(17, '0').slice(0, -3);
    
    const permanenceSeconds = serverTimeDiff(appearanceTime, leaveTime, 'seconds');
    const stays = this.formatSeconds(permanenceSeconds);

    //prendo la description dall'ultima occorrenza per vedere se le % sono più fedeli
    const description = onvifData.lastOccurrence ? onvifData.lastOccurrence.Descr.text : onvifData.Descr.text;

    const buttonList = (onvifData.popupMode !== 'browser') ? (
      <button className='btn icon-btn' onClick={() => this.replay(appearanceTime, leaveTime)}>
        <TimerOutline />
        Replay
      </button>
    ) : (
      <>
        <button className='btn icon-btn' onClick={() => this.seek(appearanceTime)}>
          <BackIcon />
          {serverDateToFormattedDate(appearanceTime, true)}
        </button>
        <button className='btn icon-btn' onClick={() => this.seek(subtractInterval(leaveTime, `00:00:03`))}>
          <ForwardIcon />
          {serverDateToFormattedDate(leaveTime, true)}
        </button>
      </>
    )

    return (
      <div className={`onvifdata-display ${additionalClass}`}>
        <div className='onvifdata-body arteco-form-inner'>
          <img className='onvifdata-image' src={onvifData.imgUrl} alt={currentObjectId} />
          <div className='onvifdata-display-data'>
            <div className='onvifdata-display-data-entry'>
              <label className='key'>{t('META_DESCRIPTION')}</label>
              <label className='key'>{description}</label>
            </div>
            <div className='onvifdata-display-data-entry'>
              <label className='key'>{t('META_APPEARS_AT')}</label>
              <label className='key'>{serverDateToFormattedDate(appearanceTime, true)}</label>
            </div>
            <div className='onvifdata-display-data-entry'>
              <label className='key'>{t('META_LEAVES_AT')}</label>
              <label className='key'>{serverDateToFormattedDate(leaveTime, true)}</label>
            </div>
            {
              stays && (
                <div className='onvifdata-display-data-entry'>
                  <label className='key'>{t('META_PERMANENCE')}</label>
                  <label className='key'>{stays}</label>
                </div>
              )
            }
          </div>
        </div>
        <div className='onvifdata-footer'>
            {onvifData.showActionsInOnvifPopup && buttonList}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  onvifData: getOnvifData(state),
  currentObjectId: getCurrentObjectId(state)
})

const mapDispatchToProps = dispatch => {
  return {
    setChannelMode: (mode, artecoId) => dispatch(setChannelMode(mode, artecoId)),
    setTimeMarker: (time) => dispatch(setTimeMarker(time)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OnvifDataDisplay))