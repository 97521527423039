import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import ShareLayoutForm from '../layouts/ShareLayoutForm';


//actions
import { getSharingLayout, isShareLayoutPopupShown } from '../../reducers/featuresReducer';
import { showShareLayoutPopup } from '../../actions/featuresActions';
import { getActiveLayoutShareList } from '../../reducers/layoutsReducer';

export class ShareLayout extends Component {
  

  closePopup = () => {
    const { showShareLayoutPopup } = this.props;

    showShareLayoutPopup(false);
  }

  render() {
    const { isShareLayoutPopupShown, sharingLayout, shareList } = this.props;

    return (
      <Popup
        open={isShareLayoutPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='arteco-popupediting-popup'>          
          <ShareLayoutForm 
            isPopup={true}
            layoutToShare={sharingLayout}
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}
            shareList={shareList}
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isShareLayoutPopupShown: isShareLayoutPopupShown(state),
  sharingLayout: getSharingLayout(state),
  shareList: getActiveLayoutShareList(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showShareLayoutPopup: (show) => dispatch(showShareLayoutPopup(show)),
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShareLayout)