import {  
  ADD_NEW_LISTENER,
  REMOVE_NEW_LISTENER
} from "../actions/types";

const initialState = {
  listeners: {}  
};

export function DocumentReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_LISTENER:
      const updatedListeners = {
        ...state.listeners
      }

      updatedListeners[action.payload.listener] = {
        callback: action.payload.callback
      }

      return {
        ...state,
        listeners: updatedListeners  
      };

    case REMOVE_NEW_LISTENER:
      const { [action.payload.listener]: _, ...remainingListeners } = state.listeners;

      return {
        ...state,
        listeners: remainingListeners
      };
    
    default:
      return state;
  }
}

export const listenerExists = (state, listener) => {
  return !!state.listeners?.listeners[listener];
}

export const getListeners = (state) => {
  return state.listeners.listeners;
}