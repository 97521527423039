import React, { Component, lazy, Suspense } from 'react';
import AppSection from './AppSection';

class Pages extends Component {
  render() {
    const { socket, pageType } = this.props;
    let title= document.title.split('|')[0].trim();

    let pageProps = {
      pageTitle: '',
      additionalClasses: '',
      wrapperAdditionalClasses: '',
      sidebarScope: 'none',
      configMode: false,
      socket: socket,
      mainComponent: '',
      tagBehaviour: undefined,
    };

    switch (pageType) {
      case 'tags':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | TagsDashboard`,
          additionalClasses: 'arteco-taglist',
          sidebarScope: 'tags',
          mainComponent: 'tags',
          tagBehaviour: 2,
        };
        break;
      case 'dashboard':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Dashboard`,
          sidebarScope: 'devices',
          mainComponent: 'dashboard',
        };
        break;
      case 'recordings':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Recordings`,
          mainComponent: 'recordings',
          sidebarScope: 'devices',
        };
        break;
      case 'myAccount':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | My Account`,
          wrapperAdditionalClasses: 'my-account',
          mainComponent: 'myAccount',
        };
        break;
      case 'layouts':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Layouts`,
          mainComponent: 'layouts',
        };
        break;
      case 'integration':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Integration`,
          wrapperAdditionalClasses: 'integration-wrapper',
          mainComponent: 'integration',
          sidebarScope: 'devices'
        };
        break;
      case 'configuration':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Configuration`,
          wrapperAdditionalClasses: 'configuration-wrapper',
          sidebarScope: 'devices',
          configMode: true,
          mainComponent: 'configuration',
        };
        break;
      case 'events':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Events page`,
          sidebarScope: 'devices',
          dontOpen: true,
          mainComponent: 'events',
        };
        break;
      case 'auditing':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Auditing page`,
          mainComponent: 'auditing',
        };
        break;
      case 'kseniaDashboard':
        pageProps = {
          ...pageProps,
          pageTitle: `${title} | Ksenia`,
          mainComponent: 'kseniaDashboard',
        };
        break;
      case 'ufollowGui':
        pageProps = {
          ...pageProps,
          pageTitle:`${title} | u-Follow`,
          wrapperAdditionalClasses:"ufollow-gui",
          sidebarScope:"devices",
          mainComponent:"ufollowGui",
        };
        break;
      case 'uFollowConfiguration':
        pageProps = {
          ...pageProps,
          pageTitle:`${title}| u-Follow Configuration`,
          wrapperAdditionalClasses:"uFollow",
          sidebarScope:"devices",
          configMode:true,
          mainComponent:"uFollowConfiguration",
        };
        break;
        case 'plugins':
          pageProps = {
            ...pageProps,
            pageTitle: 'uSee | Plugins',
            mainComponent: 'plugins',
          };
          break;
        case 'plugins_ksenia':
          pageProps = {
            ...pageProps,
            pageTitle: 'uSee | Plugins | Ksenia',
            mainComponent: 'kseniaDashboard',
          };
          break;
      //Dear developer, add cases for other pages

      default:
        break;
    }

    return <AppSection {...pageProps} />;

  }
}

export default Pages;
