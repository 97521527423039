import React, { Component, lazy, Suspense } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

//components
import { Helmet } from 'react-helmet';

//servers fetch
import { getConnectedServers, OmniaLight, serversNotAssignedToSites, serversWithEmptyPassword } from '../../../reducers/serversReducer';

//layouts fetch
import { arePeripheralsShown, arePeripheralLarge ,  getActiveLayoutChannels} from '../../../reducers/layoutsReducer';

//events
import { isEventPanelLocked,isThumbPanelLocked, isSidebarShown, isSidebarLarge } from "../../../reducers/eventsReducer";
import { checkExpiredUser, getAuthentication } from "../../../reducers/authReducer";
import { isUserAllowed, logoutUser } from "../../../actions/authActions";

//lazy import
import ComponentLoader from '../../ComponentLoader/ComponentLoader';
//labels translation
import { withTranslation } from 'react-i18next'

import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

import { isIOS, isTablet } from 'react-device-detect';
import SitesInformationPopup from '../SitesInformationPopup/SitesInformationPopup';
import { showSitesInformationPopup } from '../../../reducers/featuresReducer';
import PopupQueryString from '../../Popup/PopupQueryString';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { appPaths } from '../../../config/paths';
import { showComingSoonPopup } from '../../../actions/featuresActions';

const ContentManager = lazy(() => import('../LayoutTabs/ContentManager'));
const Popup = lazy(() => import('../../Popup/Popup'));
const SidebarContainer = lazy(() => import('../Sidebar/SidebarContainer/SidebarContainer'));
const Navbar = lazy(()=> import('../Navbar/Navbar'));

class Dashboard23 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccountOpen: false,
    }
  }

  // componentDidMount() {
  //   const {location} = this.props;
  //   if(location.state?.from == appPaths.ufollowGui){
  //   localStorage.setItem("ufollowTab", false);
  //   }
  // }

  componentWillMount() {
    const { showComingSoonPopup } = this.props;

    
    const popupEnabled = process.env.REACT_APP_COMING_SOON_ENABLED==="true";
    const hasSecondStep = process.env.REACT_APP_COMING_SOON_LINK;
    const hasThirdStep = process.env.REACT_APP_COMING_SOON_CTA;
    const shouldShowComingSoon = (
      popupEnabled && (
        !localStorage.useeComingSoonShown1  ||
        hasSecondStep && !localStorage.useeComingSoonShown2 ||
        hasThirdStep && !localStorage.useeComingSoonShown3 
      )
    )

    if(shouldShowComingSoon) {        
      showComingSoonPopup(true);
    }
  

    if (this.props.checkExpiredUser == true) {
      this.setState({
        isAccountOpen: false
      });

      this.props.logoutUser();
    }
    const user = {
      ID: this.props.auth.user.id,
      display_name: this.props.auth.user.name,
      user_email: this.props.auth.user.email,
      user_password: this.props.auth.user.password,
      omnia_web: this.props.auth.user.omnia_web,
    }
    this.props.isUserAllowed(user);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.eventPanelLocked !== prevProps.eventPanelLocked ||
      this.props.thumbPanelLocked !== prevProps.thumbPanelLocked ||
      this.props.sidebarShown !== prevProps.sidebarShown
    ) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }
  }


  render() {
    const { t, eventPanelLocked, sidebarShown, channels ,peripheralsShown,thumbPanelLocked, largePeripherals, isSidebarLarge, serversNotAssignedToSites, serversWithEmptyPassword, SitesInformation, light, location } = this.props;
    const { pageTitle, additionalClasses, sidebarScope, configMode, socket, mainComponent, tagBehaviour, wrapperAdditionalClasses, dontOpen } = this.props;

    const isUFollowGui = window.location.pathname === appPaths.ufollowGui 
    const playerNum = channels?.length
    const singleCameraClass = playerNum == 1 ? 'single-camera' : '';
    const eventLockClass = eventPanelLocked && !isUFollowGui  ? 'events-locked' : '';
    const thumbnailLockClass = thumbPanelLocked && !isUFollowGui ? 'thumbnail-locked' : '';
    const sidebarShownClass = isUFollowGui ? 'sidebar-closed' : (sidebarShown ? 'sidebar-open' : 'sidebar-closed');
    const sidebarLargeClass = sidebarShown && isSidebarLarge ? 'large-sidebar' : '';
    const isPadClass = (isIOS && isTablet) ? 'is-ipad' : '';
    const isDashboard = location.pathname === appPaths.dashboard;
    const breadcrumbsClass = isDashboard ? 'no-breadcrumbs' : '';

    const excludeTabs = ["myAccount", "integration", "configuration", "layouts", "events", "auditing", "uFollowConfiguration"];
    const shouldIncludeTabs = !excludeTabs.includes(mainComponent);
    
    const excludePeripherals =[...excludeTabs, "recordings", "auditing", "tags"]
    const shouldIncludePeripherals = !excludePeripherals.includes(mainComponent);

    const peripheralsShownClass = (peripheralsShown && shouldIncludePeripherals ) && !isUFollowGui ? 'peripherals-shown' : '';
    const peripheralsLargeClass = (peripheralsShown && shouldIncludePeripherals) && largePeripherals && !isUFollowGui ? 'peripherals-large' : '';

    const popupEnabled = process.env.REACT_APP_COMING_SOON_ENABLED==="true";
    const hasSecondStep = process.env.REACT_APP_COMING_SOON_LINK;
    const hasThirdStep = process.env.REACT_APP_COMING_SOON_CTA;
    const shouldShowComingSoon = (
      popupEnabled && (
        !localStorage.useeComingSoonShown1  ||
        hasSecondStep && !localStorage.useeComingSoonShown2 ||
        hasThirdStep && !localStorage.useeComingSoonShown3 
      )
    )

    return (
      <div className={`app ${isPadClass} ${breadcrumbsClass} ${eventLockClass} ${thumbnailLockClass} ${sidebarShownClass} ${sidebarLargeClass} ${peripheralsShownClass} ${peripheralsLargeClass} ${additionalClasses} ${singleCameraClass}`}>
        <Helmet>
          <title>{`${light ? pageTitle.replace('uSee', 'Omnia Web') : pageTitle}`}</title>
        </Helmet>
        {
          ((serversNotAssignedToSites.length > 0 || serversWithEmptyPassword.length > 0) || SitesInformation.serverNotAuthorzied || SitesInformation.serverUsernameFiledEmpty || SitesInformation.serverPasswordFieldEmpty) && mainComponent !== "ufollowGui"  && (
            <SitesInformationPopup serversNotAssignedToSites={serversNotAssignedToSites} serversWithEmptyPassword={serversWithEmptyPassword} />
          )
        }
        <Suspense fallback={<ComponentLoader what="Sidebar" />}>
          <SidebarContainer shouldIncludeTabs={shouldIncludeTabs} scope={sidebarScope} dontOpen={dontOpen} configMode={configMode} />
        </Suspense>
        <div className={`app-wrapper ${wrapperAdditionalClasses}`}>
          <ErrorBoundary>
            <Suspense fallback={<ComponentLoader what="Navbar" />}>
              <Navbar show={mainComponent} socket={socket} tagBehaviour={tagBehaviour} />
            </Suspense>
            <Suspense fallback={<ComponentLoader what={`${mainComponent}`} />}>
              <ContentManager shouldIncludeTabs={shouldIncludeTabs} socket={socket} show={mainComponent} tagBehaviour={tagBehaviour} />
            </Suspense>
            <Suspense fallback={<ComponentLoader what="Popup manager" />}>
              <Popup />
              <PopupQueryString />
            </Suspense>
          </ErrorBoundary>
        </div>

      </div>
    );
  }

}



const mapStateToProps = (state, ownProps) => ({
  servers: getConnectedServers(state),
  auth: getAuthentication(state),
  eventPanelLocked: isEventPanelLocked(state),
  thumbPanelLocked: isThumbPanelLocked(state),
  sidebarShown: isSidebarShown(state),
  isSidebarLarge: isSidebarLarge(state),
  checkExpiredUser: checkExpiredUser(state),
  peripheralsShown: arePeripheralsShown(state),
  largePeripherals: arePeripheralLarge(state),
  serversNotAssignedToSites: serversNotAssignedToSites(state),
  serversWithEmptyPassword: serversWithEmptyPassword(state),
  channels: getActiveLayoutChannels(state, ownProps.tagBehaviour),
  SitesInformation: showSitesInformationPopup(state),
  light: OmniaLight(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutUser: logoutUser,
  isUserAllowed: isUserAllowed,
  showComingSoonPopup: showComingSoonPopup

}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(Dashboard23)))