import { customEvents } from "../config/eventsAndStorage";

import {
  ADD_NEW_LISTENER,
  REMOVE_NEW_LISTENER
} from './types';

export const addCustomListener = (listener, callback) => dispatch => {    

  document.addEventListener(customEvents[listener], (payload) => {
    callback(payload.detail);
  });
  dispatch({
    type: ADD_NEW_LISTENER,
    payload: {
      listener: listener,
      callback: callback      
    }
  })
}

export const removeCustomListener = (listener, callback, listeners) => dispatch => {  

  document.removeEventListener(customEvents[listener], listeners[listener]?.callback);
  dispatch({
    type: REMOVE_NEW_LISTENER,
    payload: {
      listener: listener,
      callback: callback 
    }
  })
}