/**
 * @format
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

//actions
import { addLayout } from '../../actions/layoutActions';

//labels translation
import { withTranslation } from 'react-i18next';

import { appPaths } from '../../config/paths';
import ArtecoSwitch from '../ArtecoSwitch/ArtecoSwitch';
import { info, logger } from '../../helpers/logger';
import { OmniaLight } from '../../reducers/serversReducer';
import { getAuthentication } from '../../reducers/authReducer';
import { getErrrors } from '../../reducers/errorReducer';

class Add extends Component {
  constructor() {
    super();

    this.state = {
      ip: '',
      label: '',
      visible: false,
      active: false,
      errors: {},
      isSubmitting: false,
      isActive: false,
      timer: 20,
      checked: false
    };
    this.isEnabled = this.isEnabled.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.popupTimer = this.popupTimer.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  isEnabled = () => {
    this.setState({
      isActive: true,
    });
  };
  isDisabled = () => {
    this.setState({
      isActive: false,
    });
  };
  popupTimer = (e) => {
    this.setState({
      timer: e.target.value,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  checkLabel = (e) => {
    const { t } = this.props;
    let modifylabel =  this.formatString(this.state.label);
    if (!modifylabel) {
      this.setState({
        errors: {
          label: t('EMPTY_LAYOUT_NAME'),
        },
      });

      return false;
    }

    this.setState({
      errors: {},
    });

    return true;
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.checkLabel();
    });
  };

  formatString = (stringToFormat) =>{
    let formattedString = stringToFormat.trim();
    formattedString = formattedString.replace(/\s+/g, ' ');
    return formattedString;
  }

  onSubmit = (e) => {
    const { submitCallback, isPopup, groupName } = this.props
    e.preventDefault();

    if (this.checkLabel()) {
      this.setState({
        isSubmitting: true,
      });

      const newLayout = {
        groupName: groupName,
        owner: this.props.auth.user.id,
        ownerName: this.props.auth.user.name,
        label: this.formatString(this.state.label),
        active: true,
        isActive: this.state.isActive,
        time: this.state.timer,
        visible: true,
        layoutMode: 'grid',
      };

      const redirectTo = isPopup ? undefined : this.props.history;
      this.props.addLayout(newLayout, redirectTo, submitCallback);
    }
  };

  handleSwitchChange(checked) {
    this.setState({
      checked
    });
    logger(info, "CHECKED", checked)
    if (checked) {
      this.isEnabled()
    } else {
      this.isDisabled()
    }
  }

  cancel = (e) => {
    e.preventDefault();
    const { isPopup, cancelCallback } = this.props

    if (!isPopup) {
      window.location.href = appPaths.dashboard
    }

    if (cancelCallback) {
      cancelCallback();
    }

    return false;
  }

  render() {
    const { t, isPopup, isOmniaLight } = this.props;
    const { errors } = this.state;

    const additionalClass = isPopup ? 'arteco-popupediting-popup-container' : 'col-md-4 mb-4';
    const additionalHeaderClass = isPopup ? 'arteco-popupediting-popup-wrapper' : '';

    return (
      <div>
        <div className={`generic-popup ${additionalClass}`}>
          <form onSubmit={this.onSubmit}>
            <div className={`arteco-form-group ${additionalHeaderClass}`}>
              <h4 className="arteco-form-title main-title">{t('LAYOUT_CREATE_TITLE')}</h4>
              <div className='arteco-form-inner'>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="ip">{t('LAYOUT_CREATE_LAYOUT_NAME')}</label>
                      <input
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                        onChange={this.onChange}
                        value={this.state.label}
                        error={errors.label}
                        id="label"
                        type="text"
                        className={classnames('form-control ', {
                          invalid: errors.label,
                        })}
                      />
                      <small className="text-danger">{errors.label}</small>
                      {
                        !isOmniaLight && (
                          <div className="arteco-form-group">
                            <h4 className="arteco-form-title">
                              {t('LAYOUT_POPUP_MODE')}
                            </h4>

                            <div key={`default-radio`} className="mb-3">
                              <ArtecoSwitch
                                onHandleColor="#fff"
                                offHandleColor="#ccc"
                                className="form-switch"
                                onChange={this.handleSwitchChange}
                                checked={this.state.checked}
                              />
                            </div>
                            <div>
                              <label htmlFor="popup-timer">
                                {t('LAYOUT_POPUP_TIMER')}
                              </label>
                              <input
                                className={'form-control current-layout-name'}
                                onChange={this.popupTimer}
                                id="popup-timer"
                                type="number"
                                value={this.state.timer}
                                disabled={!this.state.checked}
                              />
                              <small className="text-danger">{this.state.timer < 10 && this.state.checked ? t('LAYOUT_POPUP_TIMER_ERROR') : null}</small>
                              <small className="text-danger">{this.state.timer > 86400 && this.state.isActive ? t('LAYOUT_POPUP_MAX_TIMER_ERROR') : null}</small>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-1 d-flex justify-content-center pt-3">
                <a onClick={this.cancel} className="btn btn-large mr-2">
                  {t('LAYOUT_CREATE_LAYOUT_BTN_CANCEL')}
                </a>
                <button
                  type="submit"
                  className="btn btn-large"
                  disabled={
                    this.state.isSubmitting ||
                    errors.label ||
                    this.state.label == '' || this.state.timer < 10
                  }
                >
                  {t('LAYOUT_CREATE_LAYOUT_BTN_CREATE')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuthentication(state),
  errors: getErrrors(state),
  isOmniaLight: OmniaLight(state)
});

export default connect(mapStateToProps, { addLayout })(
  withRouter(withTranslation()(Add)),
);
