import axios from "axios";
import { EMPTY_PASSWORD_SERVER_UDPATE, SERVERS_REMOVED, SERVER_DISCONNECT, USER_LOADING } from "./types";
import { error, logger } from "../helpers/logger";
import { deleteDuplicates } from "./serverActions";

export const FETCH_SERVERS_PENDING = 'FETCH_SERVERS_PENDING';
export const FETCH_SERVERS_SUCCESS = 'FETCH_SERVERS_SUCCESS';
export const FETCH_SERVERS_ERROR = 'FETCH_SERVERS_ERROR';
export const FETCH_SERVERS_UPDATE = 'FETCH_SERVERS_UPDATE';

function fetchServersPending() {
  return {
    type: FETCH_SERVERS_PENDING
  }
}


function fetchServersError(error) {
  return {
    type: FETCH_SERVERS_ERROR,
    error: error
  }
}

export const setServerPassword = (id, servers, password) => (dispatch) => {
  axios
    .put('/api/servers/password-update/', {
      owner: id,
      servers,
      password
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: EMPTY_PASSWORD_SERVER_UDPATE,
          payload: {
            id,
            servers,
            password
          }
        })
      }
    })
    .catch((err) => {
      logger(error, "fetchServer", 'password-update')
    });
}
export function fetchServersSuccess(data) {
  return {
    type: FETCH_SERVERS_SUCCESS,
    payload: {
      servers: data.servers,
      isSync: data.isSync
    }
  }
}

function fetchServers(user, showPoup) {
  return dispatch => {


    dispatch(fetchServersPending());

    let req = {
      "owner": user.id
    }

    const API = '/api/servers/get-by-user';

    return axios
      .post(API, req)
      .then(res => {
        if (res.error) {
          throw (res.error);
        }

        //check for duplicate servers
        let serverList = res.data;
        let serversToRemove = [];
        const checkResult = deleteDuplicates(serverList, serversToRemove);
        let outputServers = checkResult.outputServers;     

        if (serversToRemove.length > 0) {
          logger(error, "fetchServers", 'duplicate servers for user ' + user.email + ' found');

          // no need to remove servers from the store, cause we'll replace the server list with the new one
          // const serversToRemoveIds = serversToRemove.map(server => server._id);
          // dispatch({
          //   type: SERVERS_REMOVED,
          //   payload: {
          //     serverList: serversToRemoveIds
          //   }
          // })

          dispatch(fetchServersSuccess({servers: outputServers}));  
        } else {
          dispatch(fetchServersSuccess({servers: res.data}));
        }       
 
        return res.data;
      })
      .catch(error => {
        dispatch(fetchServersError(error));
      })
  }
}
export default fetchServers;



