import axios from "axios";

export const FETCH_USERPREFS_PENDING = 'FETCH_USERPREFS_PENDING';
export const FETCH_USERPREFS_SUCCESS = 'FETCH_USERPREFS_SUCCESS';
export const FETCH_USERPREFS_ERROR = 'FETCH_USERPREFS_ERROR';

function fetchUserPrefsPending() {
    return {
        type: FETCH_USERPREFS_PENDING
    }
}

function fetchUserPrefsSuccess(userPrefs) {
    return {
        type: FETCH_USERPREFS_SUCCESS,
        payload: userPrefs
    }
}

function fetchUserPrefsError(error) {
    return {
        type: FETCH_USERPREFS_ERROR,
        error: error
    }
}

function fetchUserPrefs(userId) {    

    return dispatch => {
        dispatch(fetchUserPrefsPending());        

        let req = {
            "owner": userId
        }        

        const API = '/api/userprefs/get-by-user';

        axios
        .post(API, req)        
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            
            const payload = Array.isArray(res.data) ? res.data[0] : res.data;
            dispatch(fetchUserPrefsSuccess(payload));
            return res.data;
        })
        .catch(error => {
            dispatch(fetchUserPrefsError(error));
        })
    }
}

export default fetchUserPrefs;