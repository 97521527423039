import React, { Component } from "react";

class Spinner extends Component {

  render() {

    const { size } = this.props
    return (
      <div className="dot-loader">
        <div className="loader-dots" >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}

export default Spinner