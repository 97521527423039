import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { getEventByArtecoEventId } from '../../reducers/eventsReducer'
import { showInPopup } from '../../actions/eventsAction';
import { fetchRecordings } from '../../actions/fetchRecordings';
import { getServerByCodeName } from '../../reducers/serversReducer';
import { forceLogin } from '../../actions/serverActions';
export class PopupQueryString extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
      serverIsOnline: false,
      fetched:false
    }
  }

  hideQS = () => {
    const currentUrl = window.location.href;
    const parsedUrl = queryString.parseUrl(currentUrl);
    // Rimuovi il parametro artecoEventId
    delete parsedUrl.query.artecoEventId;
    // Costruisci il nuovo URL senza il parametro
    const newUrl = queryString.stringifyUrl(parsedUrl);
    // Utilizza il nuovo URL senza ricaricare la pagina
    window.history.replaceState({}, document.title, newUrl);

  }

  showEventPopup = (event) => {
    const { shown } = this.state
    const { showInPopup } = this.props;

    if (!shown) {
      const popupData = {
        serverCodename: event.serverCodename,
        artecoId: event.artecoId,
        events: [event],
        closeCallback: this.hideQS,
        configuration: {
          style: 'complete',
          show: {
            snapshot: true,
            eventData: true,
            video: true,
            notes: true
          }
        }
      }

      this.setState({ shown: true }, () => {
        showInPopup(popupData)
      })
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchRecordings, server, selectedEvent, forceLogin } = this.props;
    const { serverIsOnline, fetched } = this.state;

    if (selectedEvent) 
    {
      this.showEventPopup(selectedEvent)
    }

    if (server) {
      const { artecoEventId } = queryString.parse(window.location.search);

      if (!serverIsOnline && server.status == 'offline') {

        this.setState({
          serverIsOnline: true
        }, () => {
          forceLogin({ _id: server._id });
        });
        
      }

      if (artecoEventId && server.status == 'online' && !fetched ) {

        const searchParams = {
          eventId: artecoEventId.split('_')[2],
          server: {
            codeName: server.codeName,
            protocol: server.protocol,
            ip: server.ip,
            isLocal: server.isLocal,
            port: server.port,
            sessionId: server.sessionId,
            serverId: server._id,
            serverName: server.name,
            mediaSecret: server.MediaSecret,
            access_token: server.access_token,
            nodeServer: server.nodeServer
          }
        }

        this.setState({
          fetched:true
        }, ()=>{
          fetchRecordings(searchParams);
        })
      }
    }
  }

  render() {
    return (
      <></>
    )
  }
}


const mapStateToProps = (state) => {
  const { artecoEventId } = queryString.parse(window.location.search);

  const serverCodename = artecoEventId && artecoEventId.split('_')[0];

  return {
    server: serverCodename ? getServerByCodeName(state, serverCodename) : undefined,
    selectedEvent: artecoEventId ? getEventByArtecoEventId(state, artecoEventId) : undefined
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRecordings: (search) => dispatch(fetchRecordings(search)),
    showInPopup: (popupData) => dispatch(showInPopup(popupData)),
    forceLogin: (server) => dispatch(forceLogin(server)),
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PopupQueryString)));