import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';

//icons
import { X as XIcon } from "react-feather";

//actions
import { getCurrentObjectId, getOnvifData, isOnvifDataPopupShown } from '../../reducers/featuresReducer';
import { showOnvifDataPopup } from '../../actions/featuresActions';

import { withTranslation } from 'react-i18next'
import OnvifDataDisplay from '../Popup/OnvifDataDisplay';

export class OnvifDataPopup extends Component {


  closePopup = () => {
    const { showOnvifDataPopup } = this.props;

    showOnvifDataPopup(false);
  }

  render() {
    const { isOnvifDataPopupShown, t, onvifData, currentObjectId} = this.props;

    return (
      <Popup
        open={isOnvifDataPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='generic-popup arteco-onvif-data-popup'>  
          <div className='popup-header'>          
            <h4>{t('ONVIF_DATA')}</h4>
            <div className="close" onClick={this.closePopup}>
              <XIcon size={22} />
            </div>
          </div>   
          {
            (onvifData && isOnvifDataPopupShown) ? (
              <>
                <p>{`ID: ${currentObjectId}`}</p>         
                <div>
                  <OnvifDataDisplay 
                    closeCB={this.closePopup}                    
                  />
                </div>
              </>
            ):(
              <p>{t('NO_DATA_FOUND')}</p>
            )
          }  
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isOnvifDataPopupShown: isOnvifDataPopupShown(state),
  onvifData: getOnvifData(state),
  currentObjectId: getCurrentObjectId(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showOnvifDataPopup: (show) => dispatch(showOnvifDataPopup(show)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OnvifDataPopup));