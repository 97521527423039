import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { lazy } from "react";
import { getNetworkStatus } from "../../reducers/networkStatusReducer";
import Pages from "../dashboard/AppSection/Pages";
import { OmniaLight } from "../../reducers/serversReducer";
import { getAuthentication } from "../../reducers/authReducer";

const DashboardPage = (props) => <Pages {...props} pageType="dashboard" />;


const PrivateRoute = ({ component: Component, auth, networkStatus, path, isOmniaLight, socket, ...rest }) => {
  const allowedPaths = ['/layouts', '/configuration', '/tags'];
  const shouldRedirect = isOmniaLight && allowedPaths.includes(path)

  useEffect(() => {
    if (auth.isAuthenticated !== true || networkStatus.offline) {
      socket.send('logout', {});
    }
  }, [auth.isAuthenticated, networkStatus.offline]);

  return (
    <Route
      {...rest}
      render={origProps => {
        const props = { ...origProps, ...rest, socket };
        return auth.isAuthenticated === true && !networkStatus.offline ? (
          shouldRedirect ? <Redirect to='/dashboard' /> : <Component {...props} />
        ) : auth.isAuthenticated === true && networkStatus.offline ? <DashboardPage socket={socket} /> : (
          <Redirect to={`/login${origProps.location.search}`} />
        )
      }
      }
    />
  )
};

const mapStateToProps = state => ({
  auth: getAuthentication(state),
  networkStatus: getNetworkStatus(state),
  isOmniaLight: OmniaLight(state)

})

export default connect(mapStateToProps)(PrivateRoute);