import {
  SERVER_EVENTS_PENDING_REQUEST,
  SERVER_EVENTS_REQUEST_COMPLETE,
  SERVER_DISCONNECT,
  SERVER_DISCONNECT_ALL,
} from '../actions/types';

const initialState = {
  pendingRequests: {
    events: {},
    maps: {},
    recordings: {}
  },
  error: null
}


export function PendingRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case SERVER_EVENTS_PENDING_REQUEST:

      let pendingRequestsUpdated = {
        ...state.pendingRequests
      }

      if (Array.isArray(pendingRequestsUpdated["events"][action.payload._id])) {
        pendingRequestsUpdated["events"][action.payload._id].push(action.payload.data);
      } else {
        pendingRequestsUpdated["events"][action.payload._id] = [action.payload.data];
      }

      return {
        ...state,
        pendingRequests: pendingRequestsUpdated
      }
    case SERVER_EVENTS_REQUEST_COMPLETE:

      let completeEventsPendingRequests = { ...state.pendingRequests }
      delete completeEventsPendingRequests["events"][action.payload._id];


      return {
        ...state,
        pendingRequests: completeEventsPendingRequests
      }
    case SERVER_DISCONNECT:
      
      if(!state.pendingRequests["events"][action.payload._id]) {
        return {
          ...state
        }
      }

      let serverEventsPendingRequests = [...state.pendingRequests["events"][action.payload._id]];

      if(!serverEventsPendingRequests) {
        return {
          ...state
        }
      }

      serverEventsPendingRequests.map(source => {
        source.cancel('Operation canceled by the user');
      })      

      let cleanServerEventsPendingRequests = { ...state.pendingRequests };
      delete cleanServerEventsPendingRequests["events"][action.payload._id];

      return {
        ...state,
        pendingRequests: cleanServerEventsPendingRequests
      }
    case SERVER_DISCONNECT_ALL:      
      return {
        ...initialState
      }
    default:
      return state;
  }
}

