import axios from 'axios';
import { timeoutPromise } from '../helpers/network';

import {
  SET_ERRORS,
  FETCH_USERPREFS_PENDING,
  ENDPOINT_CHECKING,
  USERPREFS_UPDATED,
  ENDPOINT_CHECK_RESULT,
  DOWNLOAD_RESET_ERRORS,
  ACTIVE_TAB
} from './types';



export const checkEndpointAndUpdate = (updatedUserPrefs, userId) => dispatch => {
  const PING_TIMEOUT = 5000;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let checkedUserPrefs = { ...updatedUserPrefs }

  dispatch({
    type: ENDPOINT_CHECKING
  });
  timeoutPromise(PING_TIMEOUT, performEndpointCheck(updatedUserPrefs.exportServiceEndpoint, userId, source), new Error('timeout'))
    .then(res => {
      if (res.data.result === 200) {
        checkedUserPrefs.exportServiceChecked = true;
        checkedUserPrefs.exportServiceFeatures = res.data.features ? res.data.features : [];
      } else {
        checkedUserPrefs.exportServiceChecked = false;
        checkedUserPrefs.exportServiceFeatures = [];
      }

      performUpdate(checkedUserPrefs, dispatch);

    })
    .catch(err => {
      source.cancel('Operation canceled by the user');
      checkedUserPrefs.exportServiceChecked = false;
      checkedUserPrefs.exportServiceFeatures = [];

      performUpdate(checkedUserPrefs, dispatch);
    })

}

function performUpdate(updatedUserPrefs, dispatch) {
  axios
    .post('/api/userprefs/update/', updatedUserPrefs)
    .then(res => {
      dispatch({
        type: USERPREFS_UPDATED,
        payload: res.data
      })
      dispatch({
        type: DOWNLOAD_RESET_ERRORS
      })
    })
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}

export const updateUserPrefs = (updatedUserPrefs) => dispatch => {
  performUpdate(updatedUserPrefs, dispatch);
}

function performEndpointCheck(endpoint, owner, source) {
  return axios.post(`${endpoint}/service/ping`, { owner: owner });
}

export const updateActiveTab = (tab) => dispatch =>{
  
  dispatch({type: ACTIVE_TAB, payload: tab})
}

export const endpointCheck = (endpoint, owner) => dispatch => {

  //reset check
  dispatch({
    type: ENDPOINT_CHECKING
  });

  const PING_TIMEOUT = 5000;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  timeoutPromise(PING_TIMEOUT, performEndpointCheck(endpoint, owner, source), new Error('timeout'))
    .then(res => {
      dispatch({
        type: ENDPOINT_CHECKING,
        payload: false
      });

      if (res.data.result === 200) {
        dispatch({
          type: ENDPOINT_CHECK_RESULT,
          payload: {
            result: true,
            features: res.data.features
          }
        })
      } else {
        dispatch({
          type: ENDPOINT_CHECK_RESULT,          
          payload: {
            result: false,
            features: []
          }
        })
      }
    })
    .catch(err => {
      source.cancel('Operation canceled by the user');
      dispatch({
        type: ENDPOINT_CHECK_RESULT,
        payload: {
          result: false,
          features: []
        }
      })
    }
    )
}