//those are used to dispatch data inside the application

export const customEvents = {
    sendEventsInBetweenData: "sendEventsInBetweenData", // searching for events in date
    updatePlayersTime: "updatePlayersTime", // update players time
    playerControl: "playerControl",
    removeSyncPanelFromView: "removeSyncPanelFromView",
    openExportPanel: "openExportPanel",
    onKeyDownLiveCamera: "onKeyDownLiveCamera",
    focusRecSearchTo: "focusRecSearchTo",
    focusRecSearchFrom: "focusRecSearchFrom",
    resetSearchFilters: "resetSearchFilters",
    popupClosing: "popupClosing",
    timeLineZoomControl: "timeLineZoomControl",
    debugMode: "debugMode",
    activateLayout: "activateLayout",
    sendLayoutUpdates: "sendLayoutUpdates",    
    searchEdgeRecordings: "searchEdgeRecordings",
    isFullScreen : "isFullScreen",
}

export const socketEvents = {
    peripheralEvent: "peripheralEvent",
    controlPeripheral: "controlPeripheral",
    outgoingChatMessage: "outgoingChatMessage",
    requestExitFS: "requestExitFS",
    eventManagement: "eventManagement", // generate VA event now !
    controlPTZ: "controlPTZ",
    configureChannel: "configureChannel",
    configureCreateChannel: "configureCreateChannel"
}

//those are used to dispatch data inside the application

export const localStorageVars = {
    recordingsSyncModeEnabled: "recordingsSyncModeEnabled",
    recordingsFilePrefix:"recordingsFilePrefix",
    eventViewMode:"eventViewMode"
}

export const mapEvents = {    
    newEventOnMarker: "newEventOnMarker"
}

export const serviceWorkerEvents = {
    serviceWorkerUpdated: "sw-updated",
}

export const externalCommands = {
    kseniaSendCommand: "kseniaSendCommand",
}
