import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { drawMeta, rectangleClick, resizeCanvas} from './DrawUtilities';
import { getMetaPending } from '../../reducers/playlistsReducer';
import Spinner from '../Spinner/Spinner';
import { PHP_ENTRYPOINT } from '../../config/backend';
import { getLanAddress } from '../../helpers/network';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { info, logger } from '../../helpers/logger';
import { showOnvifDataPopup } from '../../actions/featuresActions';
import { getCurrentObjectId, getOnvifData } from '../../reducers/featuresReducer';
import { serverTimeDiff, serverTimeDiffMilliseconds } from '../../helpers/timeHelpers';


export class OnvifLiveMetaDisplay extends Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.observerInitialized = false;    
    this.eventSource = null;
    this.lastDisplayedTimestamp = 0;

    this.metaBuffer = {}; 
    this.deltas = [];
    
    this.state = {
      rectangles: [],  // Array per memorizzare i dettagli dei rettangoli      
    };
    
    this.listenerAdded = false;  
    this.deltaHistory = [];
    this.maxDeltaHistory = 30;
    this.lastMetaTime = null;
    this.timeOffset = 0;
  }

  componentDidMount() {
    const { videoWidth, videoHeight, nodeRef, metadataLoaded } = this.props
    const { lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight } = this.state

    this.initializeSSEConnection();
    if(this.canvasRef.current) {            
      resizeCanvas(videoWidth, videoHeight, nodeRef, metadataLoaded, lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight, (newState) => this.setState(newState), this.canvasRef);
      
      this.canvasRef.current.addEventListener('click', this.handleCanvasClick);
    }
  }

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }

    // Rimuove l'event listener quando il componente viene smontato
    if (this.canvasRef.current) {
      this.canvasRef.current.removeEventListener('click', this.handleCanvasClick);
      this.listenerAdded = false;
    }
  }

  // Gestore per il click del mouse sul canvas
  handleCanvasClick = (event) => {
    const { showOnvifDataPopup, video, server, device } = this.props;

    const showActionsInOnvifPopup = true;
    rectangleClick(server, device,  event, this.canvasRef, this.state.rectangles, showOnvifDataPopup, video, 'live', this.props.currentObjectId, showActionsInOnvifPopup);
  }

  handleMessage = (e) => {
    const { device,server } = this.props;

    
    const meta = JSON.parse(e.data).root.data;

    //device.id must be a number otherwise the comparison will fail. for hypernode server should be a string 
    const device_Id = server.capabilities?.isHyperNode == 1 ? device.id : parseInt(device.id);
    if( device_Id !== meta.ChannelId) return false;

    // Padding dello StringTime a 17 cifre
    let timestampStr = meta.FrameTime.StringTime.padEnd(17, '0');

    
    // Aggiunge l'offset al timestamp
    timestampStr = this.addOffsetToStringTime(timestampStr);
    
    
    // Arrotonda il timestamp al decimo di secondo
    timestampStr = timestampStr.slice(0, -2) + "00";    

    // Se non esiste un array per questo timestamp, lo crea
    if ( !this.metaBuffer || (this.metaBuffer && !this.metaBuffer[timestampStr])) {
      this.metaBuffer = this.metaBuffer || {};
      this.metaBuffer[timestampStr] = [];
    }

    const existingMeta = this.metaBuffer[timestampStr].find((m) => m.ObjectId === meta.ObjectId);

    if (existingMeta) {
      // Rimuovi l'elemento precedente con lo stesso ObjectId dal buffer
      this.metaBuffer[timestampStr] = this.metaBuffer[timestampStr].filter((m) => m.ObjectId !== meta.ObjectId);
    }

    // Aggiunge il metadato all'array del buffer con il timestamp come chiave
    this.metaBuffer[timestampStr].push(meta);    
    this.lastMetaTime = timestampStr;

  }

  handleOpen = () => {
    const { server } = this.props;
    logger(info, 'sse', ">>> server " + server.name + " Ready to receive live meta Onvif");


    document.addEventListener("playerWebRTCClock", this.attachTimeListener);   
  }


  attachTimeListener = (event) => {    
    this.handleMetaUpdate(event);    
  }

  avgDeltas(array) {
    var somma = array.reduce(function(s, val) {
        return s + val;
    }, 0);

    var media = parseInt(somma / array.length);

    return media;
}

handleMetaUpdate = (event) => {
  const { video, traceBorder, liveOrRec, onvifMetadataHistory, onvifData, currentObjectId } = this.props;
  const { artecoId } = this.props;
  const { detail } = event;

  if (!this.lastMetaTime) {
    this.lastMetaTime = detail.currentServerTime;
    return;
  }

  const delta = serverTimeDiffMilliseconds(detail.currentServerTime, this.lastMetaTime);

  //console.log('current time offset', this.timeOffset);

  this.deltaHistory.push(delta);
  if (this.deltaHistory.length > this.maxDeltaHistory) {
    this.deltaHistory.shift();
  }

  const totalWeight = this.deltaHistory.length * (this.deltaHistory.length + 1) / 2;
  const weightedSum = this.deltaHistory.reduce((sum, delta, index) => {
    return sum + delta * (index + 1);
  }, 0);
  const weightedAvgDelta = weightedSum / totalWeight;

  const maxCorrection = 50;
  const correction = Math.max(-maxCorrection, Math.min(maxCorrection, weightedAvgDelta));
  this.timeOffset += correction;

  this.lastMetaTime = detail.currentServerTime;

  // Interpolazione
  const currentTime = Date.now();
  const totalTime = this.nextTime - this.lastTime;
  const elapsed = currentTime - this.lastTime;
  const t = Math.min(1, elapsed / totalTime);

  if (this.canvasRef.current && detail.artecoId === artecoId && detail.currentServerTime) {
    drawMeta(detail.currentServerTime, video, traceBorder, this.canvasRef, (newState) => this.setState(newState), liveOrRec, this.metaBuffer, onvifMetadataHistory, onvifData, currentObjectId, this.prevMeta, t);
  }
};


  handleError = () => {}

  initializeSSEConnection() {
      const { server } = this.props
      
      const V = `${PHP_ENTRYPOINT}/SrvEvtRegister`;
      const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;      
      const API = `${server.protocol}://${serverIp}:${server.port}/${V}`;      
  
      try {
        this.eventSource = new EventSourcePolyfill(API, {
          headers: {
            Authorization: "Bearer " + server.access_token,
          }
        });
      } catch (e) {
        logger(info, 'sse', ">>> server " + server.name + " LiveMeta not available");
      }
  
      if (this.eventSource) {
        this.eventSource.onmessage = this.handleMessage;
        this.eventSource.onopen = this.handleOpen;
        this.eventSource.onerror = this.handleError;
      }


  }


  shouldComponentUpdate(nextProps, nextState) {
    const { videoWidth, videoHeight, nodeRef, metadataLoaded, liveOrRec, isWebRTC, shouldAddLiveMeta, containerSize, isWaitingMeta } = this.props;
    
    const containerSizeString = JSON.stringify(containerSize);
    const containerSizeNextString = JSON.stringify(nextProps.containerSize);


    if (nextProps.videoWidth !== videoWidth ||
        nextProps.videoHeight !== videoHeight ||
        containerSizeString !== containerSizeNextString ||
        nextProps.nodeRef.clientWidth !== nodeRef.clientWidth ||
        nextProps.nodeRef.clientHeight !== nodeRef.clientHeight ||
        nextProps.metadataLoaded !== metadataLoaded ||
        nextProps.liveOrRec !== liveOrRec ||
        nextProps.isWebRTC !== isWebRTC ||
        nextProps.isWaitingMeta !== isWaitingMeta ||
        nextProps.shouldAddLiveMeta !== shouldAddLiveMeta
        ) {
      return true;
    }
  
    return false;
  }
  

  componentDidUpdate() {  
    const { videoWidth, videoHeight, nodeRef, metadataLoaded } = this.props
    const { lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight } = this.state

    // Aggiunge il listener solo se non è già stato aggiunto e se canvasRef.current esiste
    if (!this.listenerAdded && this.canvasRef.current) {
      this.canvasRef.current.addEventListener('click', this.handleCanvasClick);
      this.listenerAdded = true;
    }

    if(this.canvasRef.current) {            
      resizeCanvas(videoWidth, videoHeight, nodeRef, metadataLoaded, lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight, (newState) => this.setState(newState), this.canvasRef);    
    }
  }

  // Funzione per aggiungere l'offset al timestamp
  addOffsetToStringTime = (stringTime) => {
    //console.log("stringTime", stringTime);
    const date = new Date(Date.UTC(
      parseInt(stringTime.substr(0, 4)),
      parseInt(stringTime.substr(4, 2)) - 1,
      parseInt(stringTime.substr(6, 2)),
      parseInt(stringTime.substr(8, 2)),
      parseInt(stringTime.substr(10, 2)),
      parseInt(stringTime.substr(12, 2)),
      parseInt(stringTime.substr(14, 3))
    ));
    date.setTime(date.getTime() + this.timeOffset);

    let newStringTime = date.getUTCFullYear().toString().padStart(4, '0')
      + (date.getUTCMonth() + 1).toString().padStart(2, '0')
      + date.getUTCDate().toString().padStart(2, '0')
      + date.getUTCHours().toString().padStart(2, '0')
      + date.getUTCMinutes().toString().padStart(2, '0')
      + date.getUTCSeconds().toString().padStart(2, '0')
      + date.getUTCMilliseconds().toString().padStart(3, '0');

      //console.log("new string time", newStringTime);
    return newStringTime;
  }

  shouldComponentRender = () => {
    const { nodeRef, metadataLoaded } = this.props;

    if(!nodeRef || !metadataLoaded) return false;
    return true;
  }

  render() {
    const { isWaitingMeta, t } = this.props
    if(!this.shouldComponentRender()) return <></>     
    
    if(isWaitingMeta) {
      return (
        <div className='meta-loader'>
          <Spinner />
          <p>{t('LOADING_METADATA')} ...</p>
        </div>
      )
    }

    return (
      <canvas className={`meta-display`} ref={this.canvasRef}>
      </canvas>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const isWaitingMeta = getMetaPending(state, ownProps.device.artecoId);
  const onvifData = getOnvifData(state);
  const currentObjectId = getCurrentObjectId(state);

  return {
    isWaitingMeta,
    onvifData,
    currentObjectId
  };
}

export default connect(
  mapStateToProps,
  {showOnvifDataPopup}
)(withTranslation()(OnvifLiveMetaDisplay))