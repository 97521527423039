import React, { Component } from 'react'

export class LineLoader extends Component {  
  render() {
    return (
    <div className={`line-loader ${this.props.loaderClass} ${this.props.status}`}>
        <div className="bar"></div>
    </div>
    )
  }
}

export default LineLoader
