import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";

import ServerConnectionManager from './ServerConnectionManager'

import { getUserPrefs, getUserPrefsPending } from "../reducers/userPrefsReducer";
import fetchUserPrefs from '../actions/fetchUserPrefs';
import fetchLayouts from '../actions/fetchLayouts';
import fetchServers from '../actions/fetchServers';
import fetchAdditionalProperties from '../actions/fetchAdditionalProperties';
import { getLayouts, getLayoutsPending } from '../reducers/layoutsReducer';
import { getConnectedServers, getServers, getServersPending, getSites, getSitesPending } from '../reducers/serversReducer';
import { logger, info } from '../helpers/logger';
import { amIOffline } from '../reducers/networkStatusReducer';
import fetchPlugins from '../actions/fetchPlugins';
import { getAllPlugins } from '../reducers/pluginsReducer';
import ExportConnectionManager from './ExportConnectionManager';
import ComunicationManager from './ComunicationManager';
import { getAuthentication } from '../reducers/authReducer';
import { alignServersToLicenseService } from '../actions/serverActions';
import { fetchMaps } from '../actions/fetchMaps';
import { getAdditionalProperties, getAdditionalPropertiesPending } from '../reducers/additionalPropertiesReducer';
import { updateWebGLSupport } from '../actions/fetchConfig';


export class ConnectionsWrapper extends Component {

  constructor(props) {
    super(props);
    this.syncCount = 0;
  }
  
  componentDidMount() {
    this.initApplication();
  }

  initApplication() {
    logger(info, 'APP', 'Omnia Web - INIT APPLICATION');
    const {location, userPrefs, fetchUserPrefs, auth, servers, layouts, fetchLayouts, plugins, fetchPlugins, fetchServers, pendingServers, pendingSites, sites, fetchMaps, fetchAdditionalProperties, additionalProperties, updateWebGLSupport, pendingAdditionalProperties } = this.props;
    const searchParams = new URLSearchParams(location?.search);
    const siteInfoParam = searchParams.get("site_management");
    const showPopup = siteInfoParam === "modified" ? false : true;

    const isLogged = !!this.props.auth?.user?.id;
    if (!userPrefs || Object.keys(userPrefs).length === 0) {
      isLogged && fetchUserPrefs(auth.user.id)
    }

    if(servers.length === 0 && !pendingServers) {
      isLogged && fetchServers(this.props.auth.user, showPopup);      
    }    

    if (layouts.layouts.length === 0) {            
      isLogged && fetchLayouts(this.props.auth.user.id);
    }

    if (!additionalProperties 
        || (
          additionalProperties && (additionalProperties.channels.length === 0 || additionalProperties.channels.length === 0) 
          && !pendingAdditionalProperties
        )        
      ) {
      isLogged && fetchAdditionalProperties(auth.user.id)      
    }

    fetchPlugins(); 
    
    isLogged && fetchMaps(this.props.auth.user.id);

    //check and store webgl support
    const webglEnabled = this.checkWebGLSupport();
    updateWebGLSupport(webglEnabled);

  }

  checkWebGLSupport() {
    
    let isEnabled = false;
    try {
      const canvas = document.createElement('canvas');
      const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      isEnabled = !!(window.WebGLRenderingContext && gl);      
      if (gl) {
        gl.getExtension('WEBGL_lose_context')?.loseContext();
      }
    } catch (e) {
      localStorage.setItem('webglEnabled', false);
    }

    return isEnabled
  }

  alignServerDataToLicenseService() {
    const { sites, alignServersToLicenseService, auth} = this.props
    logger(info, 'APP', 'Omnia Web - RESYNC SITES DATA');
    this.syncCount++;
    if(this.syncCount > 1) {
      logger(info, 'APP', 'Omnia Web - MORE THAN ONE SYNC COUNT');
    }
    alignServersToLicenseService(sites, auth.user);
  }

  componentDidUpdate(prevProps) {
    const { auth, isOffline, pendingSites, pendingServers, sites, servers} = this.props

    if(
      auth.user.id && (prevProps.auth.user.id !== auth.user.id) 
    ) {
      logger(info, "user", "user logged in");
      this.initApplication();
    }

    if(
      !isOffline && prevProps.isOffline
    ) {
      logger(info, "user", "application back online");
      this.initApplication();
    }

    if(
      pendingServers !== prevProps.pendingServers ||
      pendingSites !== prevProps.pendingSites
    ) {
      if(!pendingServers && (!pendingSites && sites)) {
        this.alignServerDataToLicenseService()
      }
    }
  }

  shouldComponentRender() {
    const { pendingUserPrefs, pendingLayouts, pendingServers, isOffline, pendingAdditionalProperties } = this.props    

    const fetchedResources = (!pendingUserPrefs && !pendingLayouts && !pendingServers && !pendingAdditionalProperties);

    if (!fetchedResources && isOffline) return true;

    if (!fetchedResources) return false;

    return true;
  }


  render() {
    const { ServerConnections, userPrefs, socket, userId } = this.props

    if(!this.shouldComponentRender()) {
      return <></>
    }

    return (
      <>      
        <ComunicationManager socket={socket} />
        <ServerConnectionManager ServerConnections={ServerConnections} userPrefs={userPrefs} />
        <ExportConnectionManager userPrefs={userPrefs} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const auth = getAuthentication(state);
  const userId = auth.user.id;
  const pendingUserPrefs = getUserPrefsPending(state);
  const pendingLayouts = getLayoutsPending(state);
  const pendingServers = getServersPending(state);
  const pendingSites = getSitesPending(state);
  const userPrefs = getUserPrefs(state, userId);
  const servers = getConnectedServers(state);  
  const sites = getSites(state);
  const layouts = getLayouts(state);
  const isOffline = amIOffline(state);
  const plugins = getAllPlugins(state);
  const additionalProperties = getAdditionalProperties(state);
  const pendingAdditionalProperties = getAdditionalPropertiesPending(state)

  return {
    auth,
    pendingUserPrefs,
    pendingLayouts,
    pendingServers,
    pendingAdditionalProperties,
    additionalProperties,
    userPrefs, 
    servers,
    layouts,
    isOffline,
    plugins,
    pendingSites,
    sites,
    userId
  };
}


const mapDispatchToProps = dispatch => {
  return {
    fetchUserPrefs: (auth) => dispatch(fetchUserPrefs(auth)),
    fetchLayouts: (auth) => dispatch(fetchLayouts(auth)),
    fetchPlugins: () => dispatch(fetchPlugins()),
    fetchServers: (user, showPopup) => dispatch(fetchServers(user, showPopup)),
    alignServersToLicenseService: (sites, user) => dispatch(alignServersToLicenseService(sites, user)),
    fetchMaps: (userId) => dispatch(fetchMaps(userId)),    
    fetchAdditionalProperties: (userId) => dispatch(fetchAdditionalProperties(userId)),
    updateWebGLSupport: (webGLSupport) => dispatch(updateWebGLSupport(webGLSupport)),
    dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectionsWrapper))