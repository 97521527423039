import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

import classnames from "classnames";

//import translations
import { withTranslation } from 'react-i18next';
import { sendChangedLayoutNameToNode } from "../../actions/layoutActions";
import { appPaths } from '../../config/paths';
import { getActiveLayout } from "../../reducers/layoutsReducer";
import { getAuthentication } from "../../reducers/authReducer";
import { getErrrors } from "../../reducers/errorReducer";
class RenameLayout extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      layout_name: '',
      errors: {},
      isSubmitted: false,
      nameIsTheSame: true,
      empty_name: false,
      connection: '',
      layoutAlreadyExists: false,
      changing: false,
    }
    this.changeNameOfLayout = this.changeNameOfLayout.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
  }

  componentDidMount() {

    const outsideClick = document.querySelector('.outside-click');
    if (outsideClick) {
      document.body.removeChild(outsideClick);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  formatString = (stringToFormat) =>{
    let formattedString = stringToFormat.trim();
    formattedString = formattedString.replace(/\s+/g, ' ');
    return formattedString;
  }

  changeNameOfLayout(e, currentLayout) {    

    const layoutAlreadyExists = new Set(this.props.layouts.layouts.map(layout => this.formatString(layout.label) == this.formatString(e.target.value)));
    if (layoutAlreadyExists.has(true)) {
      this.setState({
        layoutAlreadyExists: true
      })
    }
    else {
      this.setState({
        layoutAlreadyExists: false
      })
    }
    this.setState({
      layout_name: e.target.value.trimStart(),
      nameIsTheSame: this.formatString(e.target.value) == this.formatString(currentLayout.label) ? true : false,
      changing: true
    })

    if (e.target.value == '') {
      this.setState({
        nameIsTheSame: true,
        empty_name: true,
      });
    }
    else {
      this.setState({
        empty_name: false
      })
    }
  }
  submitChanges(e, currentLayout) {
    const { submitCallback, isPopup } = this.props

    e.preventDefault();
    const layoutToUpdate = {
      _id: currentLayout._id,
      name: this.state.layout_name.trim(),
      owner: this.props.auth.user.id
    }
    this.props.sendChangedLayoutNameToNode(layoutToUpdate, submitCallback);
    this.setState({
      isSubmitted: true,
      changing: false,
    });
  }

  cancel = (e) => {
    e.preventDefault();
    const { isPopup, cancelCallback } = this.props

    if (!isPopup) {
      window.location.href = appPaths.dashboard
    }

    if (cancelCallback) {
      cancelCallback();
    }

    return false;
  }

  render() {
    const { t, isPopup, activeLayout, layoutToEdit } = this.props;
    const { errors } = this.state;

    const additionalClass = isPopup ? 'arteco-popupediting-popup-container' : 'col-md-4 mb-4';
    const additionalHeaderClass = isPopup ? 'arteco-popupediting-popup-wrapper' : '';

    const currentLayout = isPopup ? layoutToEdit : activeLayout;
    let title= document.title.split('|')[0].trim();

    if (!currentLayout) {
      return <></>
    }

    return (

      <div>
        <Helmet>
          <title>{title} | {t("LAYOUT_RENAME_LAYOUT_BTN_RENAME")}</title>
        </Helmet>
        <div className={`generic-popup ${additionalClass}`}>
          <form noValidate onSubmit={(e) => this.submitChanges(e, currentLayout)}>
            <div className={`arteco-form-group ${additionalHeaderClass}`}>
              <h4 className="arteco-form-title main-title">{t('LAYOUT_RENAME_TITLE')}</h4>
              <div className="arteco-form-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div key={currentLayout._id}>
                        <label htmlFor="ip" ref={this.ref}>{t('LAYOUT_CURRENT_NAME')}</label>
                        <input className={classnames("form-control current-layout-name", {
                          invalid: errors.label
                        })}
                          placeholder={currentLayout.label}
                          onClick={() => { this.ref.current.innerHTML = t('LAYOUT_RENAME_TITLE') }}
                          onBlur={() => { this.ref.current.innerHTML = `${t('LAYOUT_CURRENT_NAME')}: ${currentLayout.label.substring(0, 40) + '...'}` }}
                          onChange={(e) => this.changeNameOfLayout(e, currentLayout)}
                          defaultValue={this.state.layout_name == '' ? currentLayout.label : this.state.layout_name}
                        />
                      </div>
                      {this.state.nameIsTheSame && this.state.changing || this.state.layoutAlreadyExists ?
                        <small className="text-danger">
                          {this.state.layout_name == '' ? t("LAYOUT_RENAME_EMPTY") : t("LAYOUT_RENAME_USING_CURRENT_NAME")}
                        </small>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-1 d-flex justify-content-center pt-3">
                <a onClick={this.cancel} type="button" className="btn btn-large mr-2">
                  {t("LAYOUT_RENAME_LAYOUT_BTN_CANCEL")}
                </a>
                <button type="submit" className="btn btn-large" disabled={this.state.nameIsTheSame || this.state.layoutAlreadyExists ? true : false}>
                  {t("LAYOUT_RENAME_LAYOUT_BTN_EDIT_RENAME")}
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: getAuthentication(state),
  layouts: state.layouts,
  activeLayout: getActiveLayout(state),
  errors: getErrrors(state)
});

export default connect(
  mapStateToProps,
  { sendChangedLayoutNameToNode }
)(withRouter(withTranslation()(RenameLayout)));