

import {
  SET_OFFLINE,
  RESET_STATE_ON_OFFLINE
} from './types';


const setOffline = (offline) => ({
  type: SET_OFFLINE,
  payload: offline,
});


const resetState = () => ({
  type: RESET_STATE_ON_OFFLINE,
})

export const offlineState = (offline) => dispatch => {
  return dispatch(setOffline(offline));
}

export const resetStateOnOffline = () => dispatch => {
  return dispatch(resetState());
}