/** @format */

import {
  FETCH_AUDITING_ERROR,
  FETCH_AUDITING_PENDING,
  FETCH_AUDITING_SUCCESS,
  RESET_AUDITINGS,
  RESET_AUDITINGS_ERRORS,
} from "../actions/types";

const initialState = {
  pendingAuditings: 0,
  searchParams: {},
  auditings: {},
  errors: {},
};

export function AuditingsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_AUDITINGS_ERRORS:
      return {
        ...state,
        errors: {},
      };
    case FETCH_AUDITING_PENDING:
      return {
        ...state,
        pendingAuditings: state.pendingAuditings + 1,
      };
    case RESET_AUDITINGS:
      return {
        ...state,
        auditings: {},
        searchParams: {},
        errors: {},
      };
    case FETCH_AUDITING_SUCCESS: // Ottengo la lista degli regeistrationi e la scompongo in 'eventi', 'registrazioni' e 'buchi di registrazione'.
      const currentServer = action.payload.search.server;
      const currentServerCodeName = currentServer.codeName;

      const currentSearch = action.payload.search;

      const newRec = {
        ...state.auditings,
      };

      const serverInfo = {
        ...currentServer,
      };

      newRec[currentServerCodeName] = {
        totalEvents: action.payload.searchResults.length,
        serverInfo,
        audtingsData: action.payload.searchResults,
      };

      delete state.errors[currentServerCodeName];

      return {
        ...state,
        pendingAuditings: state.pendingAuditings - 1, //????
        auditings: newRec,
        searchParams: {
          start: currentSearch.recSearchFormData.start,
          end: currentSearch.recSearchFormData.end,
          url: `from=${currentSearch.startTime}&to=${currentSearch.endTime}`,
        },
      };
    case FETCH_AUDITING_ERROR:
      let listOfErrors = { ...state.errors };

      delete state.auditings[action.payload.serverCodename];

      listOfErrors[action.payload.serverCodename] = {
        message:
          action.payload.code === 200
            ? "SERVER_OBSOLETE"
            : action.payload.errors, //add fix versione Demis // creo errori
        code: action.payload.code,
        serverId: action.payload.serverId,
        serverIp: action.payload.serverIp,
        serverName: action.payload.serverName,
        codeName: action.payload.codeName,
      };

      return {
        ...state,
        pendingAuditings: state.pendingAuditings - 1, //????
        errors: listOfErrors,
      };
    default:
      return state;
  }
}
export const orderByEventTime = (a, b) => {
  if (a.eventTime > b.eventTime) {
    return -1;
  }
  if (a.eventTime < b.eventTime) {
    return 1;
  }
  return 0;
};

export const getAuditings = (state) => state.auditings;
export const getPendingAuditing = (state) => state.auditings.pendingAuditings;
export const getAuditingsError = (state) => state.auditings.errors;
export const getAuditingsSearchParams = (state) => state.auditings.searchParams;
export const getAllServersAuditing = (state) => {
   const auditingsData =  Object.values(state.auditings.auditings)
     .map((obj) => obj.audtingsData)
    .flat();
  
  return auditingsData.sort(orderByEventTime)
};
