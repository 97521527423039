import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
  SET_CURRENT_USER,
  USER_LOADING,
  USER_INFO_UPDATE,
  UPDATE_USER_SITES,
  UPDATE_USER_LIST,
  UPDATE_USER_POSITION,
  STORE_SITES,
  FETCH_SITES_PENDING,
  RESET_AUTHENTICATION_ERROR,
  AUTHENTICATION_ERROR
} from './types';
import { error, info, logger } from '../helpers/logger';


//500 il servizio non è disponibile al momento 
//403 utente non autorizzato 
//401 credenziali errate

const CryptoJS = require("crypto-js");

const isToday = (dateToCheck) => {
  const today = new Date();
  const someDate = new Date(dateToCheck);
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}


//Login arteco-user
export const loginUser = (userData) => dispatch => {
  dispatch(sitesPending())
  axios
    .post("/api/users/login", userData)
    .then(res => {

      if (!res.data || res.data && res.data.success == false && res.data.status == 500) {
        // errore login
        const messageError = {
          code: 500,
          message: 'LOGIN_ERR_SERVER_ERROR'
        }
        dispatch(manageErrors(messageError))
        return;
      }

      if (res.data && res.data.success == false && res.data.status == 401) {
        // errore login
        const messageError = {
          code: 401,
          message: 'LOGIN_ERR_AUTH_ERROR'
        }
        dispatch(manageErrors(messageError))
        return;
      }

      if (res.data && res.data.success == false) {
        // generic error
        const messageError = {
          code:  res.data.status,
          message: 'LOGIN_ERR_SERVER_ERROR'
        }
        dispatch(manageErrors(messageError))
        return;
      }

      if (res.data.success && res.data.sites && res.data.token) {
        // login ok       
        const { token, sites } = res.data;
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
        sites && dispatch(storeSites(sites));
      } 

    })
    .catch(err => {

      const messageError = {
        code: 500,
        message: 'LOGIN_ERR_SERVER_ERROR',
        err: err
      }

      dispatch(manageErrors(messageError))
    });
}


export const sitesPending = () => {
  return {
    type: FETCH_SITES_PENDING
  }
}
export const manageErrors = error => {
  return {
    type: AUTHENTICATION_ERROR,
    payload: error
  }
}
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

export const storeSites = sites => {
  return {
    type: STORE_SITES,
    payload: sites
  }
}

export const setUserLoading = (loading) => {
  return {
    type: USER_LOADING,
    payload: loading,
  }
}

export const resetAuthError = () => {
  return {
    type: RESET_AUTHENTICATION_ERROR
  }
}


export const updateUserSites = (userData) => {
  return {
    type: UPDATE_USER_SITES,
    payload: userData
  }
}

export const fetchUpdateSites = (user) => dispatch => {
  dispatch(sitesPending())
  axios
    .post('/api/users/sites-info', { user })
    .then(res => {
      if (res.status === 200) {
        const { sites } = res.data;

        //update sites in state
        sites && dispatch(storeSites(sites));

        //stop the spinner
        dispatch({
          type: USER_LOADING,
          payload: false,
        })
      } else {
        dispatch(logoutUser());
      }
    })
    .catch(err => {
      if (err.response.status === 404 && err.response.statusText === "Not Found") {
        dispatch(logoutUser());
      }
      dispatch(manageErrors(err))
      dispatch({
        type: USER_LOADING,
        payload: false,
      })
    });
}


//Check if wordpress is up
export const isUserAllowed = (userData) => dispatch => {

  const userToken = localStorage.getItem('jwtToken');
  const decoded = userToken && jwt_decode(userToken);
  const password = decoded && `${decoded.password}${decoded.password}`;

  let needRecheck = false;

  const todayEncrypted = localStorage.getItem("lastLicenseCheck");

  if (!todayEncrypted || !userToken) {
    needRecheck = true;
  } else {

    let data = null;
    try {
      const bytes = CryptoJS.AES.decrypt(todayEncrypted, password);
      data = bytes.toString(CryptoJS.enc.Utf8);
    }
    catch (e) {
      logger(error, 'authAction isUserAllowed', e)
    }


    if (!data) {
      needRecheck = true;
    } else {
      let todayDecrypted = null;
      try {
        todayDecrypted = JSON.parse(data);
        needRecheck = !isToday(todayDecrypted.created);
      }
      catch (err) {
        needRecheck = true;
      }
    }
  }

  //site_users
  const storedUsersString = global.localStorage && localStorage.getItem('site_users');

  let storedUsers = null
  if (storedUsersString) {
    try {
      const bytes = CryptoJS.AES.decrypt(storedUsersString, password);
      const usersTemp = bytes.toString(CryptoJS.enc.Utf8);
      storedUsers = JSON.parse(usersTemp);
    }
    catch (e) {
      logger(error, 'license', 'Missing local users');
    }
  }

  if (!storedUsers) needRecheck = true;
  //site_users

  //new site management for uSee - first time update
  const useeCheckString = global.localStorage && localStorage.getItem('usee_site_check');

  let useeCheck = null
  if (useeCheckString) {
    try {
      const bytes = CryptoJS.AES.decrypt(useeCheckString, password);
      const useeCheckTemp = bytes.toString(CryptoJS.enc.Utf8);
      useeCheck = useeCheckTemp;
    }
    catch (e) {
      logger(error, 'license', 'Missing local users');
    }
  }

  if (!useeCheck) needRecheck = true;
  //new site management for uSee - first time update

  //needRecheck = true;
  if (needRecheck) {
    dispatch(sitesPending());
    axios
      .post("/api/users/user-allowed", {
        ...userData
      })
      .then(res => {

        if (res.data.omnia_web !== true) {
          //remove token from local storage
          localStorage.removeItem('jwtToken');
          //remove auth header
          setAuthToken(false);
          //set current user to empty obj
          dispatch(setCurrentUser({}));
        } else {

          const today = { created: new Date() };
          const todayEncrypted = CryptoJS.AES.encrypt(JSON.stringify(today), password).toString();
          //const todayEncrypted = JSON.stringify(today);
          localStorage.setItem("lastLicenseCheck", todayEncrypted)

          //save site users on localstorage
          const usersEncrypted = CryptoJS.AES.encrypt(JSON.stringify(res.data.site_users), password).toString()
          localStorage.setItem("site_users", usersEncrypted);

          //save new site management has been done on localstorage
          const useeSiteCheck = CryptoJS.AES.encrypt('DONE', password).toString()
          localStorage.setItem("usee_site_check", useeSiteCheck);

          const { sites } = res.data;
          sites && dispatch(storeSites(sites))

          const site_users = res.data.site_users;
          site_users && dispatch(updateUserSites({
            site_users: site_users
          }));

        }
      })
      .catch(err => {
        logger(error, 'authAction user-allowed', err)
        dispatch(updateUserSites({
          site_users: []
        }));

      })
  } else {
    //get last omnia light settings
    logger(info, 'license', 'Reading stored products');
    dispatch(updateUserSites({
      site_users: storedUsers
    }));
  }
}


//log out
export const logoutUser = () => dispatch => {
  //remove token from local storage
  localStorage.removeItem('jwtToken');
  //remove auth header
  setAuthToken(false);

  //remove last license check from local storage
  localStorage.removeItem('lastLicenseCheck');
  localStorage.removeItem('site_users');

  //remove active layout settings from local storage
  global.sessionStorage.removeItem('activeLayout')
  localStorage.removeItem('localLayoutSettings');

  //remove coming soon popup cookies
  localStorage.removeItem('useeComingSoonShown1');
  localStorage.removeItem('useeComingSoonShown2');
  localStorage.removeItem('useeComingSoonShown3');

  //set current user to empty obj
  dispatch(setCurrentUser({}));
}



export const updateUserInfo = (userInfo) => dispatch => {
  dispatch({
    type: USER_INFO_UPDATE,
    payload: userInfo
  })
}

export const updateConnectedUsersAll =
  (userList) => (dispatch) => {
    dispatch({
      type: UPDATE_USER_LIST,
      payload: {
        userList: userList
      },
    });
  };

export const updateUserPosition = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_POSITION,
    payload: data,
  });
};