import React, { useState, useRef,  } from 'react';
import { useDispatch } from 'react-redux';
import { GitBranch } from 'react-feather';
import { addLinkedPeripheralsPopup, closeLinkedPeripheralsPopup } from '../../actions/serverActions';
import OutsideClickHandler from 'react-outside-click-handler';
import { getDeviceLinkedPeripherals } from '../../reducers/serversReducer';
import LinkedPeripherals from './LinkedPeripherals/LinkedPeripherals';

function LinkedPeripheralReskin({ device, server, isOmniaLight }) {
    const [islinkedPeripherals, setIsLinkedPeripherals] = useState(false);
    const [linkedPeripheralData, setLinkedPeripheralData] = useState(null);
    const [showClass, setShowClass] = useState('');
    const dispatch = useDispatch();
    const timeoutIdRef = useRef(null);

    const handleClick = () => {
        const linkedPeripherals = getDeviceLinkedPeripherals(device, server);
        if (!islinkedPeripherals) {
            setShowClass('show'); // fade in transition
            setIsLinkedPeripherals(true);
            setLinkedPeripheralData(linkedPeripherals); // making sure that the close funtion gets the right object to clear
            dispatch(addLinkedPeripheralsPopup(linkedPeripherals));
        } else {
            setShowClass('');
            clearTimeout(timeoutIdRef.current); //cleaning up to make sure there are no memory leaks
            timeoutIdRef.current = setTimeout(() => { // To ensure a smooth transition
                setIsLinkedPeripherals(false);
                dispatch(closeLinkedPeripheralsPopup(linkedPeripheralData));
            }, 400);
        }
    };

    const hasPtzClass = device['is-dome'] === 0 ? '' : 'has-PTZ'; //To ensure the button stays in place when there is a ptz

    return (
        <OutsideClickHandler onOutsideClick={() => {
            if (islinkedPeripherals) {
                setIsLinkedPeripherals(false);
                dispatch(closeLinkedPeripheralsPopup(linkedPeripheralData)); // closing the linkedPeripherals and removing it from memory 
            }
        }}>
            <div className={`linked-peripherals-container ${hasPtzClass} ${islinkedPeripherals ? 'shown' : ''} ${isOmniaLight ? "has-OmniaLight" : ""}`}>
                <div className='linked-peripherals-button-container'>
                    <button onClick={handleClick}><GitBranch /></button>
                </div>
                <div className={`parent-linked-peripherals-list-container ${showClass}`}>
                    {islinkedPeripherals && 
                        <div className={`linked-peripherals-list-container`}>
                            {linkedPeripheralData.map((item, index) => <LinkedPeripherals key={index} peripheral={item} serverId={device.parentServer._id} />)}
                        </div>
                    }
                </div>
            </div>
        </OutsideClickHandler>
    );
}

export default LinkedPeripheralReskin;

