import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import { Target as TargetIcon } from "react-feather";
import { Power as PowerIcon } from "react-feather";
import { wsSetPeripheralPayload } from "../../dashboard/wsActionsPayloads/wsPayloads";
import { socketEvents } from "../../../config/eventsAndStorage";
import { amIInited, getMystatus, isCredentialEnabled  } from "../../../reducers/serversReducer";
import DotLoader from "../../DotLoader/DotLoader";
import { PeripheralsInteraction } from "../../../helpers/serverCredentials";

export class ExternalIO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
      hasOverflow: false
    };
    this.nodeRef = createRef();
    this.containerRef = createRef();
  }

  componentDidMount() {
    this.checkOverflow();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.device !== this.props.device) {
      this.checkOverflow();
    }
  }
  
  checkOverflow() {
    const container = this.containerRef.current;
    if (container) {
      const hasOverflow = container.scrollWidth > container.clientWidth;
      if (hasOverflow !== this.state.hasOverflow) {
        this.setState({ hasOverflow });
      }
    }
  }
  
  changeState = () => {
    const { device, serverId, status, server } = this.props;

    if(!isCredentialEnabled(PeripheralsInteraction, server)) return false;

    this.setState(
      {
        value: status == 1 ? 0 : 1,
      },
      () => {
        const setToValue = this.state.value;
        const payloadToSend = wsSetPeripheralPayload(device.id, setToValue); // invio comando

        const event = new CustomEvent(socketEvents.controlPeripheral, {
          detail: {
            payload: payloadToSend,
            server_id: serverId,
          },
        });
        document.dispatchEvent(event);
      }
    );
  };

  shouldComponentRender = () => {
    const { device } = this.props;
    return device;
  };

  render() {
    const { device, inited, status } = this.props;
    const { hasOverflow } = this.state;

    let iconType = "one-way";

    if (!this.shouldComponentRender()) {
      return <></>;
    }

    if (!inited) {
      return (
        <div data-id={device.id} className="linkedPeripheral-display loader">
          <DotLoader />
        </div>
      );
    }

    if (device.subType && device.subType === 70001) {
      iconType = "two-ways";
    }

    let Icon =
      iconType === "two-ways" ? (
        <PowerIcon /> 
      ) : (
        <TargetIcon />
      );
    let stateClass = status === 1 ? "on" : "off";

    return (
      <>
        <div className="linkedPeripheral-display-box"  onClick={this.changeState}>
         <div className="linkedPeripheral-display-icon">
           <div
            data-id={device.id}
            className={`linked ${stateClass}`}
           >
            {Icon}
          </div>
         </div>
          <div className="descr" ref={this.containerRef}>
            <p className={hasOverflow ? 'text-overflow' :''}>{device.descr}</p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  inited: amIInited(state, ownProps.device.artecoId),
  status: getMystatus(state, ownProps.device.artecoId),
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalIO);
