import axios from "axios";
import { info, logger } from '../helpers/logger';
import { timeoutPromise } from '../helpers/network';
import { 
  SHOW_SIDEBAR,
  SHOW_EVENTS,
  LOCK_EVENTS,
  LARGE_EVENTS,
  EVENTS_PANEL_MODE,
  EVENTS_PANEL_CELL_SIZE,
  EVENTS_PANEL_CELL_SIZE_LARGE,
  EVENTS_SORT_BY_EVENT_TIME,
  EVENTS_SORT_AC_ORDER,
  BLUR_EVENT
 } from "./types";
import { toggleBlurEvent, toggleSortByEventime } from "./eventsAction";

export const FETCH_EVENTS_PENDING = 'FETCH_EVENTS_PENDING';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR';
export const TAG_LAST_TIMESTAMP = 'TAG_LAST_TIMESTAMP';

export const SERVER_EVENTS_PENDING_REQUEST = 'SERVER_EVENTS_PENDING_REQUEST';
export const SERVER_EVENTS_REQUEST_COMPLETE = 'SERVER_EVENTS_REQUEST_COMPLETE';
export const SERVER_EVENTS_REQUEST_ERROR = 'SERVER_EVENTS_REQUEST_ERROR';




function fetchEventsPending(servers) {
  return {
    type: FETCH_EVENTS_PENDING,
    payload: servers
  }
}

function serverPendingRequest(_id, source) {
  return {
    type: SERVER_EVENTS_PENDING_REQUEST,
    payload: {
      _id,
      data: source
    }
  }
}

function fetchEventsSuccess(events) {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: events
  }
}

function serverRequestComplete(_id) {
  return {
    type: SERVER_EVENTS_REQUEST_COMPLETE,
    payload: {
      _id
    }
  }
}

function serverRequestError(_id) {
  return {
    type: SERVER_EVENTS_REQUEST_ERROR,
    payload: {
      _id
    }
  }
}

function tagLastTimestamp(events) {
  return {
    type: TAG_LAST_TIMESTAMP,
    payload: events
  }
}

function performEventsRequest(serverCodename, owner, source) {
  let req = {
    "owner": owner,
    "server": serverCodename
  }

  //route
  const API = '/api/events/get-by-server-user';

  return axios.post(API, req, {
    cancelToken: source.token
  });
}

function fetchEvents(userId, servers) {
 
  logger(info,"Fetching events from mongo for " + servers.length + " servers: ");

  return dispatch => {
    //no connected servers
    if (!Array.isArray(servers) || servers.length == 0) {
      dispatch(fetchEventsSuccess([]));
      return false;
    }


    let fulfilledRequests = [];
    let errorRequests = [];
    const nServerMissing = servers.filter(server => !server.eventsFetched).length;


    servers.map((server, index) => {
      if (!server.eventsFetched) {
        if (index === 0) {
          //dispatch pending, if any
          dispatch(fetchEventsPending(servers));
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let REQ_TIMEOUT = 30000;

        dispatch(serverPendingRequest(server._id, source));
        // if(Math.random() > 0.7) {
        //   REQ_TIMEOUT = 10;
        // }
        timeoutPromise(REQ_TIMEOUT, performEventsRequest(server.codeName, userId, source), server)
          .then(result => {

            fulfilledRequests.push(result.data);
            //tag server as complete
            dispatch(serverRequestComplete(server._id));

            //all requests are complete
            if (fulfilledRequests.length === nServerMissing) {

              fulfilledRequests.map(request => {

                //dispatch(tagLastTimestamp(request.events))                
                dispatch(fetchEventsSuccess({
                  serverCodename: request.serverCodename,
                  serverName: server.name,
                  events: request.events
                }));

              })

            }
          })
          .catch(error => {
            dispatch(serverRequestError(server._id));
          })
      }
    })


  }
}

export function fetchEventPanelSettings() {
  return dispatch => {

    //eventPanelSettings
    let eventPanelVisible = localStorage.getItem('eventPanelVisible');
    if (eventPanelVisible) {
      const panelVisible = JSON.parse(eventPanelVisible);
      dispatch({
        type: SHOW_EVENTS,
        payload: panelVisible
      })
      if(!panelVisible) {
        if (global.localStorage) {
          global.localStorage.setItem('eventPanelLocked', false);
        }
        dispatch({
          type: LOCK_EVENTS,
          payload: false
        })
      }
    }
    let sidebarVisible = localStorage.getItem('sidebarVisible');
    if (sidebarVisible) {
      dispatch({
        type: SHOW_SIDEBAR,
        payload: JSON.parse(sidebarVisible)
      })
    }
    let eventPanelLocked = localStorage.getItem('eventPanelLocked');
    if (eventPanelLocked) {
      dispatch({
        type: LOCK_EVENTS,
        payload: JSON.parse(eventPanelLocked)
      })
    }
    let eventPanelLarge = localStorage.getItem('eventPanelLarge');
    if (eventPanelLarge) {
      dispatch({
        type: LARGE_EVENTS,
        payload: JSON.parse(eventPanelLarge)
      })
    }
    let eventPanelMode = localStorage.getItem('eventPanelMode');
    if (eventPanelMode) {
      dispatch({
        type: EVENTS_PANEL_MODE,
        payload: eventPanelMode
      })
    }
    let eventPanelCellSize = localStorage.getItem('eventPanelCellSize');
    if (eventPanelCellSize) {
      dispatch({
        type: EVENTS_PANEL_CELL_SIZE,
        payload: parseInt(eventPanelCellSize)
      })
    }
    let eventPanelCellSizeLarge = localStorage.getItem('eventPanelCellSizeLarge');
    if (eventPanelCellSizeLarge) {
      dispatch({
        type: EVENTS_PANEL_CELL_SIZE_LARGE,
        payload: parseInt(eventPanelCellSizeLarge)
      })
    }
    let eventsSortByEventTime = localStorage.getItem('eventsSortByEventTime');
    if(eventsSortByEventTime === null) {
      //key doesn't exist yet, setting date sorting by default - save to LS
      dispatch(toggleSortByEventime(true));
    }
    if (eventsSortByEventTime !== undefined && eventsSortByEventTime) {
      dispatch({
        type: EVENTS_SORT_BY_EVENT_TIME,
        payload: JSON.parse(eventsSortByEventTime)
      });
    }

    let eventsSortOrderAC = localStorage.getItem('eventsSortOrderAC');
    if (eventsSortOrderAC !== undefined && eventsSortOrderAC) {
      dispatch({
        type: EVENTS_SORT_AC_ORDER,
        payload: JSON.parse(eventsSortOrderAC)
      }); 
    }

    let blurEvent = localStorage.getItem('blurEvent');
    if (blurEvent !== undefined && blurEvent) {
      dispatch({
        type: BLUR_EVENT,
        payload: JSON.parse(blurEvent)
      }); 
    }else{
      dispatch(toggleBlurEvent(true));
    }

  }
}

export default fetchEvents;