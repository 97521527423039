import {
    SET_FILTERS,
    FETCH_FILTERS_SUCCESS,
    SHOW_FILTERS,
    SHOW_NAV_FILTERS,
    SET_DIAGNOSTIC_FILTERS,
    SEARCH_TERMS_NUM_PLATE
} from "../actions/types";
import { OmniaLight } from "./serversReducer";

const initialState = {
    filterGroups: [],
    filtersShown: false,
    diagnosticFilterGroups: [],
    searchTermsNumPlate: []
};

export function FiltersReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FILTERS_SUCCESS:
            return {
                ...state,
                filterGroups: action.payload,
                filterSaved: false
            };
        case SET_FILTERS:
            return {
                ...state,
                filterGroups: action.payload,
                filterSaved: true
            };
        case SET_DIAGNOSTIC_FILTERS:
            return {
                ...state,
                diagnosticFilterGroups: action.payload,
            };
        case SHOW_FILTERS:
            return {
                ...state,
                showFilters: action.payload
            };
        case SHOW_NAV_FILTERS:
            return {
            ...state,
            filtersShown: action.payload,
            };
        case SEARCH_TERMS_NUM_PLATE:
            return {
                ...state,
                searchTermsNumPlate: action.payload
            }

        default:
            return state;
    }
}

export const getSearchTermsNumPlate = (state) => {
    return state.filters.searchTermsNumPlate;
}

export const getFilters = (state) => {
    return state.filters;
}

export const getDiagnosticFiltersSelected = (state) => {
    const isOmniaLight = OmniaLight(state);
    let servers = [];
    let categories = [];
    let disconnectServersEvent = false;

    if (isOmniaLight) {
        return {
            servers,
            categories,
            disconnectServersEvent
        };
    }

    state.filters.diagnosticFilterGroups.map((group) => {
        group.filters.map(filter => {
            switch(group.type) {
                case 'servers':
                    if(filter.codeName && filter.codeName !== 'disconnectServers' && filter.checked) servers.push(filter.codeName);
                    if(filter.codeName === 'disconnectServers' && filter.checked) disconnectServersEvent = true;
                    break;
                case 'categories':
                    if(filter.checked) categories.push(...filter.categories);
                    break;
                default:
                    break;
            }
        })

        return false;
    });
    return {
        servers,
        categories,
        disconnectServersEvent
    };
}
const getOldFilterSelected = (state) => {
    const isOmniaLight = OmniaLight(state);
    let categories = [];
    let servers = [];
    let vcaClasses = [];
    let vcaColors = [];
    let intrusionStatus = [];
    let intrusionTypes = [];
    let onlyActiveLayoutEvents = false;

    if(isOmniaLight) {
        return {
            categories,
            servers,
            vcaClasses,
            vcaColors,
            intrusionStatus,
            intrusionTypes,
            onlyActiveLayoutEvents
        };    
    }

    // VCA -> vca
    // KSENIA -> Intrusion (alarm)
    // IMPRO -> Access Control
    state.filters.filterGroups.map((group) => {
        group.filters.map(filter => {
            if(filter.categories && filter.checked) {
                categories.push(...filter.categories);
            }
            /**Note:
             * the filter that display only the event belongin to the active layout devices was insert between the servers type 
             * just to display in the servers accordion (required by busines). for this reason it is exclude from 
             * logic of selected servers and is managed using the boolean onlyActiveLayoutEvents
             */
            if(filter.codeName && filter.codeName !== 'onlyInActiveLayoutButton' && filter.checked) servers.push(filter.codeName);
            if(filter.brand === 'vca' && filter.property === 'class' && filter.checked) Array.isArray(filter.value) ? vcaClasses.push(...filter.value) : vcaClasses.push(filter.value);
            if(filter.brand === 'vca' && filter.property === 'color' && filter.checked)  Array.isArray(filter.value) ? vcaColors.push(...filter.value) : vcaColors.push(filter.value); 
            if(filter.brand === 'ksenia' && filter.property === 'status' && filter.checked) intrusionStatus.push(filter.value); 
            if(filter.brand === 'ksenia' && filter.property === 'type' && filter.checked) intrusionTypes.push(filter.value); 
            if(filter.codeName === 'onlyInActiveLayoutButton' && filter.checked) onlyActiveLayoutEvents = true;

            return false;
        })
        
        return false;
    });
    return {
        categories,
        servers,
        vcaClasses,
        vcaColors,
        intrusionStatus,
        intrusionTypes,
        onlyActiveLayoutEvents
    };

}

const getNewFilterSelected = (state) => {
    const isOmniaLight = OmniaLight(state);
    const selectedFilters = {
        'VIDEO ANALYSIS EVENTS': {
            categories: [],
            vcaClasses: [],
            vcaColors: [],
            lprCatgories: []

        },
        'IO EVENTS': {
            categories: []
        },
        'INTRUSION EVENTS': {
            intrusionTypes: [],
            intrusionStatus: []
        },
        'servers': {
            codeNames: [],
            onlyActiveLayoutEvents: false
        },
        'ACCESS CONTROL EVENTS': {
            categories: [],
            accessControlEventTypes: [],
            accessControlEventDirection: []
        }
    };
    if (isOmniaLight) {
        return selectedFilters;
    }
    state.filters.filterGroups.forEach(filterGroup => {
        const type = filterGroup.type;
        const selected = { categories: [], vcaClasses: [], vcaColors: [], intrusionTypes: [], intrusionStatus: [], accessControlEventTypes: [], accessControlEventDirection: [], codeNames: [], onlyActiveLayoutEvents: false , lprCatgories: []};

        filterGroup.filters.forEach(filter => {
            if (filter.checked) {
                switch (filter.type) {
                    case 'internal':
                        if (filter.categories) {
                            selected.categories.push(...filter.categories);
                        }
                        break;
                    case '3rdparty':
                        switch (filter.property) {
                            case 'class':
                                selected.vcaClasses.push(...filter.value);
                                break;
                            case 'color':
                                selected.vcaColors.push(...filter.value);
                                break;
                            case 'type':
                                selected.intrusionTypes.push(filter.value);
                                break;
                            case 'status':
                                selected.intrusionStatus.push(filter.value);
                                break;
                            case 'eventType':
                                if(filter.name === "EVT_TYPE_ALL_ACCESS_CONTROL_EVENTS"){
                                    selected.categories.push(filter.value);
                                }else{
                                    selected.accessControlEventTypes.push(filter.value);
                                }
                                break;
                            case 'direction':
                                selected.accessControlEventDirection.push(filter.value);
                                break;
                            default:
                                break
                        }
                        break;
                    case 'server':
                        if (filter.name) {
                            selected.codeNames.push(filter.codeName);
                        }
                        break;
                    case 'onlyInActiveLayoutButton':
                        selected.onlyActiveLayoutEvents = filter.checked;
                        break;
                    case 'lprEvents':
                        selected.lprCatgories.push(...filter.categories);
                        break
                    default:
                        break;
                }
            }
        });

        switch (type) {
            case 'VIDEO ANALYSIS EVENTS':
                selectedFilters[type] = {
                    categories: selected.categories,
                    vcaClasses: selected.vcaClasses,
                    vcaColors: selected.vcaColors,
                    lprCatgories: selected.lprCatgories
                };
                break;
            case 'IO EVENTS':
                selectedFilters[type] = {
                    categories: selected.categories
                };
                break;
            case 'INTRUSION EVENTS':
                selectedFilters[type] = {
                    intrusionTypes: selected.intrusionTypes,
                    intrusionStatus: selected.intrusionStatus
                };
                break;
            case 'ACCESS CONTROL EVENTS':
                selectedFilters[type] = {
                    categories: selected.categories,
                    accessControlEventTypes: selected.accessControlEventTypes,
                    accessControlEventDirection: selected.accessControlEventDirection
                };
                break;
            case 'servers':
                selectedFilters[type] = {
                    codeNames: selected.codeNames,
                    onlyActiveLayoutEvents: selected.onlyActiveLayoutEvents
                };
                break;
            default:
                selectedFilters[type] = {
                    categories: selected.categories,
                    vcaClasses: selected.vcaClasses,
                    vcaColors: selected.vcaColors,
                    intrusionTypes: selected.intrusionTypes,
                    intrusionStatus: selected.intrusionStatus,
                    accessControlEventTypes: selected.accessControlEventTypes,
                    accessControlEventDirection: selected.accessControlEventDirection,
                    codeNames: selected.codeNames,
                    onlyActiveLayoutEvents: selected.onlyActiveLayoutEvents
                };
                break;
        }
    });

    return selectedFilters;
}

export const shouldFilterSelected = (state) => {
    return state.filters.filterGroups.some(group => 
        group.filters.some(filter => filter.checked === true)
      );
};


export const getFilterSelected = (state,enableNewEventFilter = false ) => {  
    return enableNewEventFilter ? getNewFilterSelected(state) : getOldFilterSelected(state);   
}

export const areFiltersShown = (state) => {  
    const isOmniaLight = OmniaLight(state);
    if(isOmniaLight) return false;
    
    return state.filters.showFilters ? state.filters.showFilters : false;
}

export const areNavFiltersShown = (state)=>{
    return state.filters.filtersShown ? state.filters.filtersShown : false;
}