import {
  SHOW_DOWNLOADS,
  FETCH_DOWNLOADS_SUCCESS,
  FETCH_DOWNLOADS_PENDING,
  FETCH_DOWNLOADS_ERROR,
  DOWNLOAD_READY,
  DOWNLOAD_IN_PROGRESS,
  DOWNLOAD_DELETING,
  DOWNLOAD_ABORTING,
  DOWNLOAD_DELETED,
  DOWNLOAD_UPDATE_WATCHLIST,
  DOWNLOAD_UPDATE_DOWNLOADLIST,
  DOWNLOAD_RESET_ERRORS,
  DOWNLOAD_UPDATE,
  DOWNLOAD_SSE_NOT_AVAILABLE,
  FILE_IS_DOWNLOADING,
  FILE_DOWNLOAD_PROGRESS,
  USERPREFS_UPDATED
} from "../actions/types";
import { CamerasPrivacyBlur, ExportVideo } from "../helpers/serverCredentials";
import { getServerByCodeName, isCredentialEnabled } from "./serversReducer";

const initialState = {
  pendingDownloads: true,
  downloads: [],
  showDownloads: false,
  error: null,
  sseNotAvailable: false,
  activeFiles: {}
};

export function DownloadsReducer(state = initialState, action) {
  switch (action.type) {
    case USERPREFS_UPDATED:
      return {
        ...state,
        sseNotAvailable: action.payload.exportServiceChecked ? false : true
      }
    case DOWNLOAD_SSE_NOT_AVAILABLE:
      return {
        ...state,
        sseNotAvailable: true
      }
    case DOWNLOAD_IN_PROGRESS:
      let inProgressDownload = action.payload;

      let inProgressDownloads = state.downloads.map(download => {
        if (download.path === inProgressDownload.path) {
          return {
            ...download,
            inProgress: true
          }
        }
        return download
      })
      return {
        ...state,
        downloads: inProgressDownloads
      }
    case DOWNLOAD_UPDATE_WATCHLIST:
      let watchlistDownload = action.payload;

      let watchlistDownloads = state.downloads.map(download => {
        if (download.path === watchlistDownload.path) {
          return {
            ...download,
            watchlist: watchlistDownload.watchlist
          }
        }
        return download
      })
      return {
        ...state,
        downloads: watchlistDownloads
      }
    case DOWNLOAD_UPDATE_DOWNLOADLIST:
    case SHOW_DOWNLOADS:
      return {
        ...state,
        showDownloads: action.payload
      }
    case DOWNLOAD_RESET_ERRORS:
      return {
        ...state,
        error: null
      }
    case FILE_IS_DOWNLOADING:
      const updatedFiles = {
        ...state.activeFiles,
        [action.payload.filename]: {
          ...state.activeFiles[action.payload.filename],
          isDownloading: action.payload.isDownloading,
          abortController: action.payload.abortController
        }
      }

      return {
        ...state,
        activeFiles: updatedFiles
      }
    case FILE_DOWNLOAD_PROGRESS:
      const progressFiles = {
        ...state.activeFiles,
        [action.payload.filename]: {
          ...state.activeFiles[action.payload.filename],
          downloadProgress: action.payload.downloadProgress
        }
      }

      return {
        ...state,
        activeFiles: progressFiles
      }
    case FETCH_DOWNLOADS_SUCCESS:
      return {
        ...state,
        pendingDownloads: false,
        downloads: action.payload
      }
    case DOWNLOAD_READY:
      let readyDownload = action.payload.file;

      let readyDownloads = state.downloads.map(download => {
        if (download._id === readyDownload._id) {
          return readyDownload
        }
        return download
      })
      return {
        ...state,
        downloads: readyDownloads
      }
    case DOWNLOAD_DELETED:
      let deletedDownload = action.payload;

      let downloads = [
        ...state.downloads
      ];

      let deletedDownloads = downloads.filter(download => {
        return download.path !== deletedDownload
      })

      return {
        ...state,
        downloads: deletedDownloads
      }
    case DOWNLOAD_DELETING:
      let deletingDownload = action.payload;

      let deletingDownloads = state.downloads.map(download => {
        if (download.path === deletingDownload) {
          return {
            ...download,
            deleting: true
          }
        }
        return download
      })

      return {
        ...state,
        downloads: deletingDownloads
      }
      case DOWNLOAD_ABORTING:
        let abortingDownload = action.payload;
  
        let abortingDownloads = state.downloads.map(download => {
          if (download.path === abortingDownload) {
            return {
              ...download,
              aborting: true
            }
          }
          return download
        })
  
        return {
          ...state,
          downloads: abortingDownloads
        }
    case DOWNLOAD_UPDATE:
      const updatedDownloadAction = action.payload;
      const { exportData } = updatedDownloadAction;

      //don't show the exported thumbs in the panel
      if(exportData.isThumbRequest) {
        return {
          ...state
        }
      }

      const existingDownloadIndex = state.downloads.findIndex((download) => download._id === exportData._id);

      let updatingDownload;

      if (existingDownloadIndex !== -1) {
        // l'oggetto esiste già, aggiornarlo
        const updatedDownload = {
          ...state.downloads[existingDownloadIndex],
          progress: updatedDownloadAction.status === 'complete' ? 100 : exportData.progress,
          status: updatedDownloadAction.status,
          path: exportData.path
        };
        updatingDownload = [
          ...state.downloads.slice(0, existingDownloadIndex),
          updatedDownload,
          ...state.downloads.slice(existingDownloadIndex + 1),
        ];
      } else {
        // l'oggetto non esiste, inserirlo solo se non è un error (significa che è un download già abortito)
        updatingDownload = exportData.status === 'error' ? state.downloads : [...state.downloads, exportData];
      }

      return {
        ...state,
        downloads: updatingDownload,
      };


    case DOWNLOAD_UPDATE_WATCHLIST:

      let watchedDownloads = state.downloads.map(download => {
        const inTheWatchList = action.payload.find(watchedDownload => watchedDownload.path === download.path);
        if (inTheWatchList) {
          return {
            ...download,
            watchList: inTheWatchList.watchList
          }
        }
        return download;
      });

      return {
        ...state,
        downloads: watchedDownloads
      }
    case DOWNLOAD_UPDATE_DOWNLOADLIST:

      let downloadedDownloads = state.downloads.map(download => {
        if (download.path === action.payload.path) {
          return {
            ...download,
            downloadList: action.payload.downloadList
          }
        }
        return download;
      });

      return {
        ...state,
        downloads: downloadedDownloads
      }
    case FETCH_DOWNLOADS_PENDING:
      return {
        ...state,
        pendingDownloads: true
      }
    case FETCH_DOWNLOADS_ERROR:
      return {
        ...state,
        pendingDownloads: false,
        error: action.error
      }
    default:
      return state;
  }
}

export const areDownloadsShown = (state) => {
  return state.downloads.showDownloads ? state.downloads.showDownloads : false;
}

export const getDownloads = (state, devices) => {
  
  let where2Find = state.downloads.downloads || state.downloads || [];
  if (!Array.isArray(where2Find)) {
    where2Find = [];
  }
  const exportVideoDownloads = where2Find?.filter(item => {
    const serverDetails = getServerByCodeName(state,item.serverCodename);
    return isCredentialEnabled(ExportVideo,serverDetails);
  });

  if (!exportVideoDownloads || !Array.isArray(exportVideoDownloads) || exportVideoDownloads.length === 0) return [];

  const filteredDownloads = exportVideoDownloads.filter(download => {
    const myDownload = devices[download.artecoId];
  
    const serverDetails = getServerByCodeName(state,download.serverCodename);
    const isPrivacyBlurEnabled = isCredentialEnabled(CamerasPrivacyBlur,serverDetails);

    if (isPrivacyBlurEnabled || !download.privacy || (myDownload && !download.unlocked)) {
      return myDownload;
    } else {
      return myDownload && download.unlocked;
    }
  });

  return filteredDownloads;
}

export const getDownloadsNum = (state, devices) => {
 const downloads = getDownloads(state, devices);

 return downloads ? downloads.length : 0;
}

export const getUnwatchedDownloadsNum = (state, userId, devices) => {

  const filteredDownloads = getDownloads(state, devices);

  if(!filteredDownloads || filteredDownloads.length === 0) return 0;

  let watchedArray = filteredDownloads.map(download => {
    return !isInWatchList(userId, download.watchList) ? 1 : 0
  });

  if (watchedArray.length === 0) return 0;

  var unwatchedDownloadsNum = watchedArray.reduce((a, b) => a + b);

  return unwatchedDownloadsNum;
}


export const checkDownloadIsDeleting = (state, artecoId) => {
  const download = state.downloads.downloads.filter(download => download.artecoId === artecoId);
  if (!download[0]) return false;

  return !!download[0].deleting;
}

export const checkDownloadIsAborting = (state, artecoId) => {
  const download = state.downloads.downloads.filter(download => download.artecoId === artecoId);
  if (!download[0]) return false;

  return !!download[0].aborting;
}

export const getDownloadsPending = state => state.downloads.pendingDownloads;
export const getDownloadsError = state => {
  return state.downloads.error && state.downloads.error.message;
}

function isInWatchList(userId, watchList) {
  if (!watchList) return false;

  const watchListEntries = Object.keys(watchList);

  let isIn = false;

  if (watchListEntries.length === 0) {
    isIn = false;
    return isIn;
  }

  watchListEntries.map(entry => {
    if (watchList[entry].userId === userId) isIn = true;
  });

  return isIn;
}

export const getDownloadStatus = (state, _id) => {
  const where2Find = state.downloads.downloads || state.downloads;

  if (where2Find.length === 0) return 0;

  const download = where2Find.find(download => download._id === _id);

  return download;
}

export const isSSEAvailable = (state) => {
  return !state.downloads.sseNotAvailable;
}

export const fileIsDownloading = (state, filename) => {
  return state.downloads.activeFiles && state.downloads.activeFiles[filename] && state.downloads.activeFiles[filename].isDownloading;
}

export const someDownloadInProgress = (state) => {
  if (!state.downloads.activeFiles) return false;
  const downloads = Object.values(state.downloads.activeFiles);
  return downloads.some(download => download.isDownloading);
}

export const fileDownloadProgress = (state, filename) => {
  return state.downloads.activeFiles && state.downloads.activeFiles[filename] && state.downloads.activeFiles[filename].downloadProgress;
}

export const getDownloadAbortController = (state, filename) => {
  return state.downloads.activeFiles && state.downloads.activeFiles[filename] && state.downloads.activeFiles[filename].abortController;
}