import React, { Component } from 'react';
import { connect } from "react-redux";
import { getMediaUri } from "../../reducers/serversReducer";
import { withTranslation } from 'react-i18next';

import ClapprPlayer from './ClapprPlayer';

const loggerScope = "PunctualPlayer";

class PunctualPlayer extends Component {

  constructor(props) {
    super(props)
  }

  shouldComponentRender() {
    const {  playlist } = this.props;
    return (playlist != undefined);
  }

  render() {

    const {
      key,
      device,
      isPopup,
      allowOnlyPIP,
      playlist,
      playerClass,
      hideContextMenu,
      disableZoom,
      allowBackToLive,
      liveErrors,
      reachedEndOfVideo,
      requestNextPlaylist,
      secondsBeforeRequest,
      maxVideoDuration,
      forcePlayBackMode,
      timelineMode,
      recMedia,
      metadataHistory,
      onvifMetadataHistory,
      autoFetchOnEnd,
      hidePrivacy,
      isPrivacyPopUpOn
    } = this.props


    if (!this.shouldComponentRender()) {
      return <></>
    }

    return (
      
      <ClapprPlayer
        key={key}
        device={device}
        isPopup={isPopup}
        allowOnlyPIP={allowOnlyPIP}
        source={recMedia}
        playerClass={playerClass}
        hideContextMenu={hideContextMenu}
        disableZoom={disableZoom}
        // props valide solo per il registrato
        playlistId={playlist.playlistId}
        instantInterval={{
          from: playlist.from,
          to: playlist.to,
        }}
        allowBackToLive={allowBackToLive}
        isTranscoding={playlist.isTranscoding}
        liveErrors={liveErrors}
        reachedEndOfVideo={reachedEndOfVideo} // raggiunta fine del video hls
        requestNextPlaylist={requestNextPlaylist} // richiesta di una nuova playlists
        secondsBeforeRequest={secondsBeforeRequest} // soglia di richiesta della playlist
        maxVideoDuration={maxVideoDuration} // durata massima dei video
        forcePlayBackMode={forcePlayBackMode}
        timelineMode={timelineMode}
        autoFetchOnEnd={autoFetchOnEnd}
        metadataHistory={metadataHistory}
        onvifMetadataHistory={onvifMetadataHistory}
        hidePrivacy={hidePrivacy}
        isPrivacyPopUpOn={isPrivacyPopUpOn}
      />
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const recMedia = (ownProps.playlist?.recPath) ? getMediaUri(state, ownProps.device.serverCodename, ownProps.playlist?.recPath) : undefined;

  return {
    recMedia,
  }
}


export default connect(
  mapStateToProps,
  null,
)(withTranslation()(PunctualPlayer));

