import {
  FETCH_DOMES_PENDING,
  FETCH_DOMES_SUCCESS,
  RESET_DOMES,
  FETCH_DOMES_ERRORS,
  DOME_SERVER_REMOVED,
  RESET_DOMES_ERRORS,
  RESET_SERVER_DOMES,
} from "../actions/types";

const initialState = {
  pendingDomes: false,
  domes: [],
  errors: {}
};

export function DomesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOMES_PENDING:
      return {
        ...state,
        pendingDomes: true
      }
    case FETCH_DOMES_SUCCESS:
     
    const newDome = {
        ...state.domes
      }
      
      action.payload.map(singleDome => {
        newDome[singleDome.artecoId] = singleDome;
      })
 
      delete state.errors[action.server_id];

      return {
        ...state,
        pendingDomes: false,
        errors: state.errors,
        domes: newDome
      }

    case RESET_DOMES:
      return {
        ...state,
        domes: [],
        errors: {}

      }

    case RESET_SERVER_DOMES:
      // removing domes from state if mathing with certain server
      const domesWithoutCertainServer = Object.values(state.domes).filter(dome => dome.serverCodename != action.server.codeName)

      return {
        ...state,
        domes: domesWithoutCertainServer
      }

    case RESET_DOMES_ERRORS:
      return {
        ...state,
        errors: {}

      }
    case FETCH_DOMES_ERRORS:
      var dictOfErrors = new Object();
      dictOfErrors = state.errors;
      dictOfErrors[action.server_id] = {
        error: action.error
      };
      return {
        ...state,
        pendingDomes: false,
        errors: dictOfErrors
      }
    case DOME_SERVER_REMOVED:
      let deletedDomes = Array.isArray(state.domes) ? state.domes.filter((item) => ( item.serverId !== action.payload.serverId)) : [];
      return {
        ...state,
        pendingDomes: false,
        domes: deletedDomes,
        errors: {}
      }
    default:
      return state;
  }
}

export const getDomes = state => state.domes;
export const getDomesPending = state => state.domes.pendingDomes;
export const getDomesErrors = state => state.domes.errors;
export const getDomeByArtecoId = (state, artecoId) => {
  return state.domes.domes[artecoId];
}