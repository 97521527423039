import axios from "axios";
import {logger, info} from '../helpers/logger';

export const FETCH_USERLIST_PENDING = 'FETCH_USERLIST_PENDING';
export const FETCH_USERLIST_SUCCESS = 'FETCH_USERLIST_SUCCESS';
export const FETCH_USERLIST_ERROR = 'FETCH_USERLIST_ERROR';

function fetchUserlistPending() {
    return {
        type: FETCH_USERLIST_PENDING
    }
}

function fetchUserlistSuccess(layouts) {
    return {
        type: FETCH_USERLIST_SUCCESS,
        payload: layouts
    }
}

function fetchUserlistError(error) {
    return {
        type: FETCH_USERLIST_ERROR,
        error: error
    }
}

function fetchUserlist(layout) {    
     logger(info, "shareLayouts", "Fetching layouts ...");

    return dispatch => {
        dispatch(fetchUserlistPending());        

        let req = layout;

        const API = '/api/layouts/get-userlist-by-id';

        axios
        .post(API, req)        
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            
            dispatch(fetchUserlistSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(fetchUserlistError(error));
        })
    }
}

export default fetchUserlist;