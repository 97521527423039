const { logger, info } = require("../../../../helpers/logger");

var WebRtcStreamer = (function () {

    /** 
     * Interface with WebRTC-streamer API
     * @constructor
     * @param {string} videoElement - id of the video element tag
     * @param {string} srvurl -  url of webrtc-streamer (default is current location)
    */
    var WebRtcStreamer = function WebRtcStreamer(videoElement, srvurl, turnServerCredentials, defaultDelay = 1.5, artecoId = null, cameraName = null) {
        if (typeof videoElement === "string") {
            this.videoElement = document.getElementById(videoElement);
        } else {
            this.videoElement = videoElement;
        }
        this.srvurl = srvurl || window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
        this.pc = null;

        this.artecoId = artecoId;
        this.cameraName = cameraName;

        this.mediaConstraints = { offerToReceiveAudio: true, offerToReceiveVideo: true };

        this.iceServers = null;
        this.earlyCandidates = [];
        this.turnServerCredentials = turnServerCredentials;
        this.defaultDelay = defaultDelay;
        this.streamUrl = null;

        // Soglie 
        this.jitterThreshold = 0.085;
        this.jitterThresholdMax = 0.11;        
        
        this.packetLossThreshold = 2;
        this.fpsThreshold = 0;
        this.bitrateThreshold = 0;
        

        // Aggiunta: Inizializzazione del listener per l'emissione di time updates
        this.setupTimeUpdateListener();

        // Test: disconnection after 15 seconds (commentato)
        // setTimeout(() => {
        // 	this.disconnect();
        // 	const errorEvent = new CustomEvent('webrtcError', {
        // 		'detail': {
        // 			errorType: 'disconnected',
        // 			streamUrl: this.streamUrl 
        // 		}
        // 	});
        // 	this.videoElement.dispatchEvent(errorEvent);
        // }
        // , 15000);
    }

    WebRtcStreamer.prototype._handleHttpErrors = function (response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    /** 
     * Connect a WebRTC Stream to videoElement 
     * @param {string} videourl - id of WebRTC video stream
     * @param {string} audiourl - id of WebRTC audio stream
     * @param {string} options -  options of WebRTC call
     * @param {string} stream  -  local stream to send
    */
    WebRtcStreamer.prototype.connect = function (videourl, audiourl, options, localstream) {
        this.streamUrl = videourl;

        // getIceServers is not already received
        if (!this.iceServers) {

            const iceServersList = [];
            if (process.env.REACT_APP_STUN) {
                iceServersList.push({ urls: JSON.parse(process.env.REACT_APP_STUN) })
            }
            if (this.turnServerCredentials) {
                iceServersList.push(this.turnServerCredentials)
            }
            this.iceServers = { iceServers: iceServersList };
            this.onReceiveGetIceServers.call(this, this.iceServers, videourl, audiourl, options, localstream);

        } else {
            this.onReceiveGetIceServers(this.iceServers, videourl, audiourl, options, localstream);
        }
    }
    
    function calculatePacketLossPercentage(totalPacketsReceived, totalPacketsLost) {
    return (totalPacketsReceived > 0) ? (totalPacketsLost / totalPacketsReceived) * 100 : 0;
    }

    // Funzione per verificare le soglie di qualità
    function checkQualityThresholds(totalJitter, packetLossPercentage, fps, bitrate, bind) {        

        const thresholds = {
            jitter: bind.jitterThresholdMax,
            packetLoss: bind.packetLossThreshold,
            fps: bind.fpsThreshold,
            bitrate: bind.bitrateThreshold
        };

        const reasons = [];
        const lastValues = {};

        if (totalJitter > thresholds.jitter) {
            reasons.push("FALLBACK_REASONS_JITTER");
            lastValues["FALLBACK_REASONS_JITTER"] = totalJitter;
        }
        if (packetLossPercentage > thresholds.packetLoss) {
            reasons.push("FALLBACK_REASONS_PACKET_LOSS");
            lastValues["FALLBACK_REASONS_PACKET_LOSS"] = packetLossPercentage;
        }
        if (fps < thresholds.fps) {
            reasons.push("FALLBACK_REASONS_FPS");
            lastValues["FALLBACK_REASONS_FPS"] = fps;
        }
        if (bitrate < thresholds.bitrate) {
            reasons.push("FALLBACK_REASONS_BITRATE");
            lastValues["FALLBACK_REASONS_BITRATE"] = bitrate
        }

        return {
            reasons,
            lastValues
        };
    }

    // Funzione per loggare i problemi di qualità
    function logQualityIssues(reasons, cameraName, totalJitter, packetLossPercentage, fps, bitrate) {
    if (reasons.length > 0) {        
        logger(info, 'WebRtcStreamer', "stats > Problemi di qualità rilevati! Ragioni:" + reasons.join(", "));
        logger(info, 'WebRtcStreamer', "stats > Camera: " + cameraName);
        logger(info, 'WebRtcStreamer', "stats > Jitter: " + totalJitter);
        logger(info, 'WebRtcStreamer', "stats > Pacchetti persi: " + packetLossPercentage + "%");
        logger(info, 'WebRtcStreamer', "stats > FPS: " + fps.toFixed(2));
        logger(info, 'WebRtcStreamer', "stats > Bitrate: " + bitrate.toFixed(2) + " kbps");
    }
    }

    WebRtcStreamer.prototype.monitorStreamQuality = function() {
        var bind = this;
    
        if (!this.pc) {            
            return;
        }
    
        this.monitoringInterval = setInterval(function() {
            if (!bind.pc) {
                return;
            }
    
            bind.pc.getStats(null).then(function(stats) {
                var totalPacketsReceived = 0;
                var totalPacketsLost = 0;
                var totalJitter = 0;
                var totalBytesReceived = 0;
                var totalFramesDecoded = 0;
                var fps = 0;
                var bitrate = 0;
    
                var now = performance.now();  // Tempo attuale in millisecondi
                var elapsedTime = (now - (bind.previousTime || now)) / 1000; // Tempo trascorso in secondi
                bind.previousTime = now;
    
                // Evita il calcolo se elapsedTime è 0 o molto piccolo
                if (elapsedTime > 0) {
                    stats.forEach(function(report) {
                        if (report.type === 'inbound-rtp' && report.kind === 'video') {
                            totalPacketsReceived += report.packetsReceived;
                            totalPacketsLost += report.packetsLost;
                            totalJitter = report.jitter;
                            totalBytesReceived += report.bytesReceived;
    
                            // framesDecoded è in inbound-rtp
                            totalFramesDecoded = report.framesDecoded || 0;
                        }
                    });
    
                    // Calcola gli FPS in base al tempo trascorso
                    fps = (totalFramesDecoded - (bind.previousFramesDecoded || 0)) / elapsedTime;
                    bind.previousFramesDecoded = totalFramesDecoded;
    
                    // Calcola il bitrate in kbps
                    var bytesReceived = totalBytesReceived - (bind.previousBytesReceived || 0);
                    bitrate = (bytesReceived * 8) / 1000 / elapsedTime; // Converti bytes in kilobit e dividi per il tempo
                    bind.previousBytesReceived = totalBytesReceived;

    
                    // Verifica soglie di qualità e fallback
                    const packetLossPercentage = calculatePacketLossPercentage(totalPacketsReceived, totalPacketsLost);                                        

                    const {reasons, lastValues } = checkQualityThresholds(totalJitter, packetLossPercentage, fps, bitrate, bind);

                    logQualityIssues(reasons, bind.cameraName, totalJitter, packetLossPercentage, fps, bitrate);

                    // Trigger fallback a un'altra sorgente video se ci sono problemi di qualità
                    if (reasons.length > 0) {
                        bind.triggerFallback(reasons, lastValues);
                    }

                    //send info to the player
                    const qualityEvent = new CustomEvent('webrtcQuality', {
                        'detail': {
                            jitter: totalJitter,
                            packetLoss: packetLossPercentage,
                            fps: fps,
                            bitrate: bitrate,
                            artecoId: bind.artecoId
                        }
                    });
                    bind.videoElement.dispatchEvent(qualityEvent);
                } else {
                    //console.log("stats > Elapsed time troppo piccolo per calcolare FPS e bitrate");
                }
    
            }).catch(function(error) {
                logger(info, 'WebRtcStreamer', "stats > Errore durante il getStats:", error);
            });
    
        }, 5000); // Verifica ogni 5 secondi
    };
    
    
    WebRtcStreamer.prototype.triggerFallback = function(reasons, lastValues) {
        logger(info, 'WebRtcStreamer', "webrtc stats: Attivazione fallback a un'altra sorgente video...");
        this.disconnect(); // Disconnessione dallo stream corrente      
        const errorEvent = new CustomEvent('webrtcError', {
            'detail': {
                errorType: 'disconnected',
                streamUrl: this.streamUrl,
                reasons: reasons,
                lastValues: lastValues
            }
        });
        this.videoElement.dispatchEvent(errorEvent);  
    };
    

    /** 
     * Disconnect a WebRTC Stream and clear videoElement source
    */
    WebRtcStreamer.prototype.disconnect = function () {
        this.removeTimeUpdateListener();
        
        if (this.videoElement) {
            this.videoElement.src = "";
        }
        if (this.pc) {
            fetch(this.srvurl + "/api/hangup?peerid=" + this.pc.peerid)
                .then(() => {
                    return this._handleHttpErrors
                })
                .catch((error) => {
                    return this.onError("hangup " + error)
                })


            try {
                this.pc.close();
            }
            catch (e) {
                logger(info, 'WebRtcStreamer', "Failure close peer connection:" + e);
            }
            this.pc = null;
        }
    }

    /*
    * GetIceServers callback
    */
    WebRtcStreamer.prototype.onReceiveGetIceServers = function (iceServers, videourl, audiourl, options, stream) {
        this.iceServers = iceServers;
        this.pcConfig = iceServers || { "iceServers": [] };
        try {
            this.createPeerConnection();
            this.startDynamicDelayAdjustment(); 

            var callurl = this.srvurl + "/api/call?peerid=" + this.pc.peerid + "&url=" + encodeURIComponent(videourl);
            if (audiourl) {
                callurl += "&audiourl=" + encodeURIComponent(audiourl);
            }
            if (options) {
                callurl += "&options=" + encodeURIComponent(options);
            }

            if (stream) {
                this.pc.addStream(stream);
            }

            // Clear early candidates
            this.earlyCandidates.length = 0;

            // Create Offer
            var bind = this;
            this.pc.createOffer(this.mediaConstraints).then(function (sessionDescription) {

                bind.pc.setLocalDescription(sessionDescription
                    , function () {
                        fetch(callurl, { method: "POST", body: JSON.stringify(sessionDescription) })
                            .then(bind._handleHttpErrors)
                            .then((response) => (response.json()))
                            .catch((error) => bind.onError("call " + error))
                            .then((response) => bind.onReceiveCall.call(bind, response))
                            .catch((error) => bind.onError("call " + error))

                    }
                    , function (error) {
                        logger(info, 'webrtcstreamer', "setLocalDescription error:" + JSON.stringify(error));
                    });

            }, function (error) {
                alert("Create offer error:" + JSON.stringify(error));
            });

        } catch (e) {
            this.disconnect();
            alert("connect error: " + e);
        }
    }


    WebRtcStreamer.prototype.getIceCandidate = function () {
        if (this.pc) {
            fetch(this.srvurl + "/api/getIceCandidate?peerid=" + this.pc.peerid)
                .then(this._handleHttpErrors)
                .then((response) => (response.json()))
                .then((response) => this.onReceiveCandidate.call(this, response))
                .catch((error) => this.onError("getIceCandidate " + error))
        }
    }

    /*
    * Create RTCPeerConnection 
    */
    WebRtcStreamer.prototype.createPeerConnection = function () {
        this.pc = new RTCPeerConnection(this.pcConfig);
        var pc = this.pc;
        pc.peerid = Math.random();

        var bind = this;
        pc.onicecandidate = function (evt) { bind.onIceCandidate.call(bind, evt); };
        pc.ontrack = function (event) {
            if (event.track.kind === 'video') {
                bind.videoElement.srcObject = event.streams[0];
                bind.checkFirstFrameDecoded();

                // Emissione continua dell'evento `playerWebRTCClock` con il timestamp del frame video
                bind.emitTimeUpdate(); // Ogni volta che un nuovo frame viene ricevuto
            }
        };
        pc.oniceconnectionstatechange = function (evt) {
            if (bind.videoElement) {
                bind.updateVideoElementState(pc.iceConnectionState);

                if (pc.iceConnectionState === "connected") {
                    // Set playoutDelayHint when the connection state is connected
                    bind.setDynamicPlayoutDelayHint();                    
                }
            }
        };

        pc.ondatachannel = function (evt) {
            evt.channel.onopen = function () {
                this.send("remote channel openned");
            }
            evt.channel.onmessage = function (event) {
            }
        };

        pc.onicegatheringstatechange = function () {
            if (pc.iceGatheringState === "complete") {
                bind.adjustReceiversAfterGathering();
            }
        };

        try {
            var dataChannel = pc.createDataChannel("ClientDataChannel");
            dataChannel.onopen = function () {
                this.send("local channel openned");
            }
            dataChannel.onmessage = function (evt) {
            }
        } catch (e) {
            logger(info, 'WebRtcStreamer', "DataChannel creation error:" + e);
        }

        return pc;
    }

    WebRtcStreamer.prototype.setDynamicPlayoutDelayHint = function () {
        var pc = this.pc;
        var bind = this;
        var lastSentTimestampStr = null;
        pc.getReceivers().forEach(receiver => {
            if (receiver.track.kind === 'video') {
                // Check current network conditions and adjust playoutDelayHint accordingly
                // Cannot pass receiver because Safari does not support it
                pc.getStats().then(stats => {
                    stats.forEach(report => {
                        if (report.type === 'inbound-rtp' && report.kind === 'video') {
                            var adjustedDelay = bind.calculateOptimalPlayoutDelay(report.jitter, report.packetsLost, report.packetsReceived);
                            receiver.playoutDelayHint = adjustedDelay;
                            if(adjustedDelay > 0.2) {
                                logger(info, 'WebRtcStreamer', "stats >  Camera "+ bind.cameraName+ " Setting delay to: " + adjustedDelay.toFixed(3) + "s | Jitter: " + report.jitter);
                            }
                            
                            // Events for onvifmetadatalive
                            let timestamp = new Date(report.timestamp);

                            // Arrotondamento ai decimi di secondo
                            let milliseconds = Math.round(timestamp.getUTCMilliseconds() / 100) * 100;

                            // Creazione di una stringa nel formato AAAAMMGGHHMMSSmmm in UTC
                            let timestampStr = timestamp.getUTCFullYear() +
                              ("0" + (timestamp.getUTCMonth() + 1)).slice(-2) +
                              ("0" + timestamp.getUTCDate()).slice(-2) +
                              ("0" + timestamp.getUTCHours()).slice(-2) +
                              ("0" + timestamp.getUTCMinutes()).slice(-2) +
                              ("0" + timestamp.getUTCSeconds()).slice(-2) +
                              ("00" + milliseconds).slice(-3);

                            if(timestampStr !== lastSentTimestampStr) {

                                const clockEvent = new CustomEvent("playerWebRTCClock", {
                                detail: {
                                    currentServerTime: timestampStr,
                                    originalTime: timestamp,
                                    artecoId: bind.artecoId
                                }
                                });

                                document.dispatchEvent(clockEvent);

                                // Aggiorna l'ultimo timestamp inviato
                                lastSentTimestampStr = timestampStr;
                            }
                        }
                    });
                });
            }
        });
    };

    WebRtcStreamer.prototype.startDynamicDelayAdjustment = function() {
        var bind = this;
    
        if (this.delayAdjustmentInterval) {
            clearInterval(this.delayAdjustmentInterval);  // Pulisci l'intervallo esistente se impostato
        }
    
        this.delayAdjustmentInterval = setInterval(function() {
            if (bind.pc) {
                bind.setDynamicPlayoutDelayHint();
            }
        }, 5000);  // Ajusta il ritardo ogni 5 secondi
    };
    

    WebRtcStreamer.prototype.calculateOptimalPlayoutDelay = function(jitter, packetsLost, packetsReceived) {
        var baseDelay = 0.1;  // Inizia con un ritardo di base minimo per assicurare un po' di buffering
        var lossPercentage = (packetsReceived > 0) ? (packetsLost / packetsReceived) * 100 : 0;
    
        // Ajusta il ritardo di base in base al jitter; più jitter richiede più buffering
        if (jitter > this.jitterThreshold) {
            baseDelay += jitter;  // Aggiungere direttamente il valore del jitter potrebbe essere troppo aggressivo; considera di scalarlo
        }
    
        // Aumenta ulteriormente il ritardo se la percentuale di perdita di pacchetti è alta
        if (lossPercentage > 2) {  // La soglia per 'alta' perdita di pacchetti potrebbe necessitare di regolazioni
            baseDelay += 0.2 + (0.1 * (lossPercentage / 10));  // Aumenta il ritardo di 0.2s più una frazione basata sulla perdita
        }
    
        // Assicurati di non superare un ritardo massimo di playout ritenuto accettabile
        return Math.min(baseDelay, 0.5);  // Limita il ritardo a un massimo di 500ms per le restrizioni del tempo reale
    };
    

    WebRtcStreamer.prototype.updateVideoElementState = function (iceConnectionState) {
        var opacity = {
            "new": "0.5",
            "checking": "0.5",
            "connected": "1.0",
            "completed": "1.0",
            "disconnected": "0.25",
            "failed": "0.25",
            "closed": "0.25"
        }[iceConnectionState] || "0.5"; // default opacity for unknown states

        if (this.videoElement) {
            this.videoElement.style.opacity = opacity;
            var webrtcEvent = new CustomEvent('webrtcEvent', {
                'detail': {
                    eventType: iceConnectionState
                }
            });
            this.videoElement.dispatchEvent(webrtcEvent);
        }
    };

    WebRtcStreamer.prototype.adjustReceiversAfterGathering = function () {
        var receivers = this.pc.getReceivers();
        receivers.forEach(receiver => {
            if (receiver.track.kind === "video") {
                // Re-apply the playout delay hint after ICE gathering completes
                receiver.playoutDelayHint = this.defaultDelay; // or dynamically calculate as needed
            }
        });
    };



    /*
    * RTCPeerConnection IceCandidate callback
    */
    WebRtcStreamer.prototype.onIceCandidate = function (event) {
        if (event.candidate) {
            if (this.pc.currentRemoteDescription) {
                this.addIceCandidate(this.pc.peerid, event.candidate);
            } else {
                this.earlyCandidates.push(event.candidate);
            }
        }
        else {
        }
    }


    WebRtcStreamer.prototype.addIceCandidate = function (peerid, candidate) {
        fetch(this.srvurl + "/api/addIceCandidate?peerid=" + peerid, { method: "POST", body: JSON.stringify(candidate) })
            .then(this._handleHttpErrors)
            .then((response) => (response.json()))
            .then((response) => { logger(info, 'webrtcstreamer', "addIceCandidate ok:" + response) })
            .catch((error) => this.onError("addIceCandidate " + error))
    }

    /*
    * RTCPeerConnection AddTrack callback
    */
    WebRtcStreamer.prototype.checkFirstFrameDecoded = function () {
        var bind = this;
        var firstFrameDecoded = false;
        var firstAudioTrackDetected = false;

        var checkStats = function () {
            bind.pc && bind.pc.getStats(null).then(function (stats) {
                stats.forEach(function (report) {
                    if (report.type === 'inbound-rtp' && report.kind === 'video') {
                        if (report.framesDecoded > 0 && !firstFrameDecoded) {
                            firstFrameDecoded = true;

                            const webrtcEvent = new CustomEvent('webrtcEvent', {
                                detail: {
                                    eventType: 'framesReady'
                                }
                            });
                            bind.videoElement.dispatchEvent(webrtcEvent);

                            // Avvio monitoraggio qualità solo dopo la decodifica del primo frame
                            bind.monitorStreamQuality(); 
                            // console.log(">>>>>>>>> First video frame decoded and framesReady event dispatched.");
                        }
                    }


                    if(report.kind === 'audio'  && report.bytesReceived > 0 && !firstAudioTrackDetected){
                        firstAudioTrackDetected = true 
                        const webrtcEvent = new CustomEvent('webrtcEvent', {
                            'detail': {
                                eventType: 'audioTrackDetection',
                                hasAudioTrack: true,			  
                            }
                        });					  
                        bind.videoElement.dispatchEvent(webrtcEvent);
                    }
                });

                if (!firstFrameDecoded) {
                    // Continue polling if the first frame has not yet been decoded
                    setTimeout(checkStats, 1000);
                }
            });
        };

        setTimeout(checkStats, 1000);
    };	


    /*
    * AJAX /call callback
    */
    WebRtcStreamer.prototype.onReceiveCall = function (dataJson) {
        var bind = this;
        var descr = new RTCSessionDescription(dataJson);
        if (this.pc) {
            this.pc.setRemoteDescription(descr
                , function () {
                    while (bind.earlyCandidates.length) {
                        var candidate = bind.earlyCandidates.shift();
                        bind.addIceCandidate.call(bind, bind.pc.peerid, candidate);
                    }

                    bind.getIceCandidate.call(bind)
                }
                , function (error) {
                    logger(info, 'webrtcstreamer', "setRemoteDescription error:" + JSON.stringify(error));
                });
        }
    }

    /*
    * AJAX /getIceCandidate callback
    */
    WebRtcStreamer.prototype.onReceiveCandidate = function (dataJson) {
        if (dataJson) {
            for (var i = 0; i < dataJson.length; i++) {
                var candidate = new RTCIceCandidate(dataJson[i]);

                this[candidate.type + candidate.protocol] = true;

                this.pc.addIceCandidate(candidate
                    , function () {}
                    , function (error) { logger(info, 'webrtcstreamer', "addIceCandidate error:" + JSON.stringify(error)); });
            }
            this.pc.addIceCandidate();
            // Sopperisce a una mancanza nell'architettura del signaling di WebRTC Streamer sul server che non li invia
            // tramite websocket, ma li espone su richiesta tramite API rest.
            // Quindi il front-end, anziché rimanere in ascolto in attesa dei peer, ripete la richiesta REST fino a quando
            // non include anche il peer sulla rete pubblica (stun),
            // ma this.pc.addIceCandidate() assicura che per stabilire la connessione vengano provati da subito
            // i peer sulla rete locale senza aspettare quelli sulla rete pubblica.
            // A volte però è più veloce a stabilire la connessione che a ricevere tutti i peer,
            // in questo caso webrtc streamer smette di cercare altri peer e il turn non arriva mai.
            // Per evitare di richiedere all'infinito il peer relativo al turn che non arriverà mai,
            // interrompe le richieste in caso di connessione stabilita (o fallimento definitivo)
            if (this.pc.iceConnectionState !== "connected" && this.pc.iceConnectionState !== "failed") {
                if (this.srflxudp !== true || this.hostudp !== true || this.hosttcp !== true) {
                    setTimeout(function (webrtcstreamer) {
                        webrtcstreamer.getIceCandidate.call(webrtcstreamer);
                    }, 500, this);
                }
            }
        }
    }


    /*
    * AJAX callback for Error
    */
    WebRtcStreamer.prototype.onError = function (status) {
        var bind = this;

        if (status.includes("addIceCandidate")) {
        } else {
            const errorEvent = new CustomEvent('webrtcError', {
                'detail': {
                    errorType: 'disconnected',
                    streamUrl: bind.streamUrl
                }
            });
            bind.videoElement.dispatchEvent(errorEvent);
        }
    }

    WebRtcStreamer.prototype.setDelay = function (delay) {
        if (this.pc) {
            var pc = this.pc;

            var receivers = pc.getReceivers();
            if (delay) {
                receivers[0].playoutDelayHint = delay;
                receivers[1].playoutDelayHint = delay;
            }
        }
    }

    /*
    * Nuova funzione per emettere l'evento di aggiornamento del tempo
    */
    WebRtcStreamer.prototype.emitTimeUpdate = function () {
        var pc = this.pc;
        if(!pc) {            
            return;
        }   

        pc.getStats().then(stats => {
            stats.forEach(report => {
                if (report.type === 'inbound-rtp' && report.kind === 'video') {
                    let timestamp = new Date(report.timestamp);

                    // Arrotonda ai decimi di secondo
                    let milliseconds = Math.round(timestamp.getUTCMilliseconds() / 100) * 100;

                    // Formatta il timestamp in una stringa del tipo AAAAMMGGHHMMSSmmm
                    let timestampStr = timestamp.getUTCFullYear() +
                      ("0" + (timestamp.getUTCMonth() + 1)).slice(-2) +
                      ("0" + timestamp.getUTCDate()).slice(-2) +
                      ("0" + timestamp.getUTCHours()).slice(-2) +
                      ("0" + timestamp.getUTCMinutes()).slice(-2) +
                      ("0" + timestamp.getUTCSeconds()).slice(-2) +
                      ("00" + milliseconds).slice(-3);

                    // Emetti l'evento `playerWebRTCClock`
                    const clockEvent = new CustomEvent("playerWebRTCClock", {
                        detail: {
                            currentServerTime: timestampStr,
                            originalTime: timestamp,
                            artecoId: this.artecoId
                        }
                    });

                    document.dispatchEvent(clockEvent);
                }
            });
        });
    };

    WebRtcStreamer.prototype.removeTimeUpdateListener = function () {
        if (this.videoElement) {
            this.videoElement.removeEventListener('timeupdate', this.emitTimeUpdate);
            this.videoElement.removeEventListener('playerWebRTCClock', this.handlePlayerWebRTCClock);
        }
    };    

    /*
    * Setup listener per emettere `playerWebRTCClock` ad ogni cambio di tempo
    */
    WebRtcStreamer.prototype.setupTimeUpdateListener = function () {
        var bind = this;
    
        this.handlePlayerWebRTCClock = function (event) {
            // Logica per aggiornare le bounding box o altri metadati
            // Ad esempio:
            // updateBoundingBoxes(event.detail.currentServerTime);
        };
    
        if (this.videoElement) {
            this.videoElement.addEventListener('timeupdate', function () {
                bind.emitTimeUpdate();
            });
    
            this.videoElement.addEventListener('playerWebRTCClock', this.handlePlayerWebRTCClock);
        }
    };    

    return WebRtcStreamer;
})();

if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
    window.WebRtcStreamer = WebRtcStreamer;
}
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = WebRtcStreamer;
}
