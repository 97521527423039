
import axios from "axios";
export const info = "info";
export const warn = "warn";
export const error = "error";

export function logger(log, scope, msg) {
  // logging function
  // logLevel = LOCAL = 0 | DEV = 1 | STAGING = 2 | PRODUCTION = 3
  //DEMO
  // logger('info', scope, msg)
  const logs = {
    level: log,
    scope: arguments[1],
    message: arguments[2],
  };
  const appName = "OmniaWeb";
  const currentTime = new Date().toLocaleString();
  const logged =
    appName + " " + currentTime + " -- " + arguments[1] + " | " + arguments[2];
  // local log
  if (process.env.REACT_APP_LOG_LEVEL == 0) {
    console[log](logged);
    
  }
  
  // dev log // staging log
  if (process.env.REACT_APP_LOG_LEVEL == 1 || process.env.REACT_APP_LOG_LEVEL == 2) {
    console[log](logged);
    if (log === "error") {
      
      axios
        .post("/api/log", logs)
        .then((response) => {} )
        .catch((error) => {});
    }
  }
  
  // production log
  if (process.env.REACT_APP_LOG_LEVEL == 3) {
    if (log === "warn" || log === "error") {
      console[log](logged);
    }
    if (log === "error") {
      axios
        .post("/api/log", logs)
        .then((response) => {} )
        .catch((error) => {});
    }
  }
}
