import { 
  UPDATE_INTEGRATIONS,
  SELECT_INTEGRATION,
  DESELECT_INTEGRATION,
  UPDATE_INTEGRATION,
  REMOVE_LINKED_ARTECO_ID
} from "./types"

export const updateIntegrationsList = (integrations, server) => dispatch => {    
  dispatch({
    type: UPDATE_INTEGRATIONS,
    payload: {
      integrations,
      server
    }
  })
}

export const selectIntegration = (integrationId) => dispatch => {
  dispatch({
    type: SELECT_INTEGRATION,
    payload: {
      integrationId
    }
  })
}

export const resetSelectedIntegration = (integrationId) => dispatch => {
  dispatch({
    type: DESELECT_INTEGRATION    
  })
}

export const updateIntegrationStatus = (integrationEvent) => dispatch => {
  dispatch({
    type: UPDATE_INTEGRATION,
    payload: integrationEvent
  })
}

export const removeLinkedArtecoId = (integrationId, artecoId) => dispatch => {  
  dispatch({
    type: REMOVE_LINKED_ARTECO_ID,
    payload: {
      integrationId,
      artecoId
    }
  })
}

export const sendCommand = (central, command) => dispatch => {
  
}