import React, { Component } from 'react';
import { connect } from 'react-redux';
import { offlineState, resetStateOnOffline } from '../../actions/networkActions';
import { info, logger } from '../../helpers/logger';
import OfflineMessage from './OfflineMessage';

class NetworkStatus extends Component {
  constructor() {
    super();
    this.state = {
      isOffline: false,
    }
  }

  componentDidMount() {
    const { resetStateOnOffline, offlineState, socket } = this.props;

    //logger(info, "NetwrokStatus","Socket network status", socket.connected)
    this.offlineDetection();

    socket.on('connect', () => {
      offlineState(false);
      this.setInternalOfflineState(false);
    });

    socket.on('disconnect', () =>{
      this.offlineDetection();
    });
  }

  setInternalOfflineState(isOffline) {
    this.setState({
      isOffline,
    });
  }

  // Wait two seconds before checking if the user is offline
  // This should prevent the case where the socket hasn't connected yet on page load
  offlineDetection() {    
    const oldSocket = this.props.socket;
    
    setTimeout(() => {
      const { socket, resetStateOnOffline, offlineState } = this.props;
      if(!socket) {
        window.location.reload();
      }
      if(!socket || ((socket.connected == oldSocket.connected) && socket.connected == false)) {
        resetStateOnOffline();
        offlineState(true);
        this.setInternalOfflineState(true);
      }
    }, 2000);
  }


  render() {
    return this.state.isOffline && <OfflineMessage />;
  }
}


const mapDispatchToProps = dispatch => ({
  offlineState: (offline) => dispatch(offlineState(offline)),
  resetStateOnOffline: () => dispatch(resetStateOnOffline()),
})

export default connect(null, mapDispatchToProps)(NetworkStatus);
