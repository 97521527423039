import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notifyDownloadDeleted, notifyDownloadUpdate, notifyExportDeleted, notifyExportSSENotAvailable } from '../actions/downloadsActions';
import { getDownloads } from '../reducers/downloadsReducer';
import { getAllDevices, getConnectedServers } from '../reducers/serversReducer';
import fetchDownloads from '../actions/fetchDownloads';
import { error, info, logger } from '../helpers/logger';
import { checkEndpointAndUpdate } from '../actions/userPrefsActions';
import { getUserPrefs } from '../reducers/userPrefsReducer';
import { getAuthentication, getAuthenticationUserId } from '../reducers/authReducer';

export class ExportConnectionManager extends Component {
  eventSource = null;
  reconnectIntervalId = null;  
  RECONNECT_INTERVAL = 10000;

  componentDidMount() {
    this.initializeSSEConnection();  
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userPrefs.exportServiceEndpoint !== this.props.userPrefs.exportServiceEndpoint) {
      this.cleanupSSEConnection();
      this.initializeSSEConnection();
    }
  }

  componentWillUnmount() {
    this.cleanupSSEConnection();
  }

  initializeSSEConnection() {
    const { userPrefs, checkEndpointAndUpdate } = this.props;

    const isExportEnabled = (userPrefs && userPrefs.exportServiceEndpoint && userPrefs.exportServiceChecked && userPrefs.exportServiceEnabled);

    if(!isExportEnabled && (userPrefs && userPrefs.exportServiceEndpoint && userPrefs.exportServiceEnabled)) {
      //the export is just become unchecked, but the service is set, recheck the endpoint
      checkEndpointAndUpdate(this.props.userPrefs, this.props.auth.user.id);
    }

    if(!isExportEnabled) return false;

    //avoid duplicate connections
    if(this.eventSource) return false;

    const SSE_ENDPOINT = `${userPrefs.exportServiceEndpoint && userPrefs.exportServiceEndpoint.replace('/export/', '/export-sse/')}/sse`;

    try {
      this.eventSource = new EventSource(SSE_ENDPOINT);
    } catch (e) {
      // console.log('SSE not available');
      this.props.notifyExportSSENotAvailable();
    }

    if (this.eventSource) {
      this.eventSource.onmessage = this.handleMessage;
      this.eventSource.onopen = this.handleOpen;
      this.eventSource.onerror = this.handleError;
    }

    this.props.fetchDownloads(this.props.servers, this.props.userPrefs);
  }

  cleanupSSEConnection() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource.onmessage = null;
      this.eventSource.onopen = null;
      this.eventSource.onerror = null;
      this.eventSource = null;
    }
    if (this.reconnectIntervalId) {
      clearInterval(this.reconnectIntervalId);
      this.reconnectIntervalId = null;
    }
    this.props.fetchDownloads(this.props.servers, this.props.userPrefs);
  }

  handleMessage = (event) => {
    const data = JSON.parse(event.data);      

    if(data.status === 'deleted') {
      data.exportData.playlistId && this.props.notifyExportDeleted(data.exportData.playlistId);                
      data.exportData.path && this.props.notifyDownloadDeleted(data.exportData.path);
    } else {
      this.props.notifyDownloadUpdate(data, this.props.userPrefs);            
    }
  };

  handleOpen = () => {
    logger(info, 'export', 'SSE connected');
    if (this.reconnectIntervalId) {
      clearInterval(this.reconnectIntervalId);
      this.reconnectIntervalId = null;
    }
  };

  handleError = (err) => {
    const { checkEndpointAndUpdate } = this.props;

    // Controlla se si tratta di un errore HTTP (ad esempio, 404)
    if (err.target.readyState === EventSource.CLOSED) {
      // console.log('SSE error: failed to connect or received HTTP error');
      this.props.notifyExportSSENotAvailable();
      //debugger;
    } else {
      logger(error, 'ExportConnectionManger', `SSE error: ${err}` )
      //debugger;
      this.props.notifyExportSSENotAvailable();
    }
    this.cleanupSSEConnection(); // chiudi la connessione e rimuovi gli event listener
    
    //reconnect after checking the endpoint
    if (!this.reconnectIntervalId) {
      this.reconnectIntervalId = setInterval(() => {
        //check if now the endpoint is available, if not, try again
        if(!this.props.userPrefs.exportServiceChecked) {
          logger(info, 'ExportConnectionManger', `SSE try endpoint check` );
          checkEndpointAndUpdate(this.props.userPrefs, this.props.auth.user.id);
        }
        
        //after check, reconnect SSE
        if(this.props.userPrefs.exportServiceChecked) {
          logger(info, 'ExportConnectionManger', `endpoint is back online, reconnect` );
          this.initializeSSEConnection();
        }        
      }, this.RECONNECT_INTERVAL); // Tenta di riconnettersi ogni 5 secondi
    }
  };
    

  render() {
    return (
      <></>
    )
  }
}

const mapStateToProps = (state) => {    
  const servers = getConnectedServers(state);
  const devices = getAllDevices(state);  
  const downloads = getDownloads(state, devices);  
  const userId = getAuthenticationUserId(state)
  const userPrefs = getUserPrefs(state, userId);
  const auth = getAuthentication(state);

  return {
    servers,
    downloads,
    userPrefs,
    auth
  }
}


const mapDispatchToProps = dispatch => {
  return {
    fetchDownloads: (servers, userPrefs) => dispatch(fetchDownloads(servers, userPrefs)),
    notifyDownloadUpdate: (downloadData, userPrefs) => dispatch(notifyDownloadUpdate(downloadData, userPrefs)),
    notifyDownloadDeleted: (path)  => dispatch(notifyDownloadDeleted(path)),
    notifyExportDeleted: (playlistId)  => dispatch(notifyExportDeleted(playlistId)),
    notifyExportSSENotAvailable: ()  => dispatch(notifyExportSSENotAvailable()),
    checkEndpointAndUpdate: (userPrefs, userId) => dispatch(checkEndpointAndUpdate(userPrefs, userId)),
    dispatch
  }
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(ExportConnectionManager)