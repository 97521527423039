import { customEvents } from "../../config/eventsAndStorage";
import gtag from "ga-gtag";

export function pauseAll() {
  // richiesta di pausa per tutti i player in ascolto
  const event = new CustomEvent(customEvents.playerControl, {
    'detail': {
      command: 'pauseAll',
    }
  });
  document.dispatchEvent(event);
}

export function playAll() {
  // richiesta di play per tutti i player in ascolto
  const event = new CustomEvent(customEvents.playerControl, {
    'detail': {
      command: 'playAll',
    }
  });
  document.dispatchEvent(event);
}

export function refreshPlayers() {
  // richiesta di refreshhio per tutti i player in ascolto
  const event = new CustomEvent(customEvents.playerControl, {
    'detail': {
      command: 'refresh',
    }
  });
  document.dispatchEvent(event);
}

export function seekAll(seekValue) {
  gtag('event', 'recordings_seek_zero');

  // richiesta di seek per tutti i player in ascolto
  // se il seekValue == undefined allora il seek viene fatto secondo l'ultimo dato di seek memorizzato nel player
  const event = new CustomEvent(customEvents.playerControl, {
    'detail': {
      command: 'seekAll',
      seekTo: seekValue
    }
  });
  document.dispatchEvent(event);
}

export function controlSome(listOfArtecoId, action) {
  listOfArtecoId.forEach(artecoId => {
    const event = new CustomEvent(customEvents.playerControl, {
      'detail': {
        command: action,
        artecoId,
      }
    });
    document.dispatchEvent(event);
  });
}

export function seekSome(listOfArtecoId, seekValue) {
  listOfArtecoId.forEach(artecoId => {
    const event = new CustomEvent(customEvents.playerControl, {
      'detail': {
        artecoId,
        command: 'seek',
        seekTo: seekValue
      }
    });
    document.dispatchEvent(event);
  });
}

export function manageTime(timeToManage) {
  // richiesta di manageTime per tutti i player in ascolto
  // viene usata per mandare avanti o indietro il tempo
  const event = new CustomEvent(customEvents.playerControl, {
    'detail': {
      command: 'manageTime',
      timeToManage: timeToManage
    }
  });
  document.dispatchEvent(event);
}