import {
  RESET_RECORDINGS_ERRORS,
  TIMELINE_MARKER,
  RESET_RECORDINGS,
  SET_EXPORT_INTERVAL,
  RESET_EXPORT_INFO,
  SET_SYNC_MODE,
  SET_TIMELINE_MODE,
  TOGGLE_TIMELINE_VISIBILITY,
  REFRESH_RECORDING_EVTS,
  UPDATE_RECORDING_EVENT,
  UPDATE_RECORDING_EVENTS,
  SET_EDGE_MODE
} from '../actions/types';

import moment from 'moment';
import { serverStringToDate, toMoment } from '../helpers/timeHelpers';

export function resetRecordingsErrors() {
  return {
    type: RESET_RECORDINGS_ERRORS,
  }
}

export function resetRecordings() {
  return {
    type: RESET_RECORDINGS,
  }
}

export const setTimeMarker = (time) => {

  return {
    type: TIMELINE_MARKER,
    payload: time
  }
}
export const setExportInterval = (interval) => dispatch => {
  dispatch({
    type: SET_EXPORT_INTERVAL,
    exportInterval: interval
  })
}

export const resetExportData = () => dispatch => {
  dispatch({
    type: RESET_EXPORT_INFO,
  })
}

export const setSyncMode = (mode) => dispatch => {
  dispatch({
    type: SET_SYNC_MODE,
    syncMode: mode
  })
}

export const setTimelineMode = (command) => dispatch => {

  dispatch({
    type: SET_TIMELINE_MODE,
    payload: command
  })
}

export const setEdgeMode = (mode) => dispatch => {
  dispatch({
    type: SET_EDGE_MODE,
    payload: mode
  })
}

export const toggleRecordingTimelineVisibilty = (timelineVisible) => dispatch => {
  dispatch({
    type: TOGGLE_TIMELINE_VISIBILITY,
    payload: timelineVisible
  })
}

export const refreshRecordingEvts = (events) => dispatch => {
  dispatch({
    type: REFRESH_RECORDING_EVTS,
    payload: events
  })
}

export const updateRecordingEvt = (event) => dispatch => {
  dispatch({
    type: UPDATE_RECORDING_EVENT,
    payload: event
  })
}

export const updateRecordingEvts = (event) => dispatch => {
  dispatch({
    type: UPDATE_RECORDING_EVENTS,
    payload: event
  })
}
