
import React, { Component } from 'react'
import { ReactComponent as InstantEvent } from '../svgIcons/instantEvent.svg';
import { Aperture } from 'react-feather';
import { withTranslation } from 'react-i18next'

class ArtecoPlayerShortCutActions extends Component {
  render() {
    const { generateEvent, t } = this.props;
    return (
      <div className='badge shortcut-actions' onClick={generateEvent}>
        <Aperture />
      </div>
    )
  }
}

export default withTranslation()(ArtecoPlayerShortCutActions)