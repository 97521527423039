import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

//labels translation
import { withTranslation } from 'react-i18next'
import {appPaths} from '../../config/paths';
import { getAuthentication } from "../../reducers/authReducer";



class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(appPaths.dashboard);
    }
  }

  render() {
    const {t} = this.props;
    const title= document.title.split('|')[0].trim();
    return (
      <div className="row">
        <Helmet>
          <title>{title} - {t("WELCOME")}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="jumbotron bg-transparent mb-0 p-4">
            <h4>
              <b>Omnia</b> <span className="badge badge-danger"> web</span>
            </h4>
            <p className="flow-text grey-text text-darken-1">
             { t('LOGIN_LANDING') }
            </p>
          <ul className="nav main-sections">
            <li className="nav-item m-1">
              <Link to="/login" type="button" className="btn text-button main-button">{ t('LOGIN_TITLE') }</Link>
            </li>
          </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: getAuthentication(state)
});

export default connect(
  mapStateToProps
)(withTranslation()(Landing));