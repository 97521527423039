import axios from 'axios';
import {error, logger} from '../helpers/logger';

import {
  DOME_SERVER_REMOVED,
} from './types';


export const removeDomesFromThisServer = (serverData) => dispatch => {
  axios
    .post('/api/domes/delete-server/', serverData)
    .then(res => {
      dispatch({
        type: DOME_SERVER_REMOVED,
        payload: serverData
      });
      return res.data;
    })
    .catch(e => {
       logger(error, 'demoesActions', e);
    })
}
