import { connect } from "react-redux";
import { toast, Slide } from 'react-toastify';
import React from 'react';
import { ReactComponent as Error } from '../svgIcons/biError.svg';
import { ReactComponent as Done } from '../svgIcons/mdDone.svg';
import { MessageCircle as ChatMessageIcon, RefreshCw as UpdateIcon, XCircle as CloseIcon } from 'react-feather';
import { getUserPrefs } from '../../reducers/userPrefsReducer';
import { Howl } from 'howler';

import chatMessage from '../../components/dashboard/Chat/Sounds/message.mp3';
import systemMessage from '../../components/dashboard/Chat/Sounds/toast.mp3';

const CustomCloseButton = ({ closeToast }) => (
  <CloseIcon 
    size={12} 
    onClick={e => {
      e.stopPropagation();
      closeToast(e);
  }}
   />
);

const chatMessageSound = new Howl({
  src: [chatMessage],
  loop: false,
  preload: true
})

const systemMessageSound = new Howl({
  src: [systemMessage],
  loop: false,
  preload: true
})

export const ArtecoToast = (type, message, subtitle = '', callback = null, userPrefs = null) => {  

  if(userPrefs) {
    if(userPrefs.audioMsgs || userPrefs.audioToast) {
      if(userPrefs.audioMsgs && type === 'chat') {
        chatMessageSound.play();
      } 
      if(userPrefs.audioToast && type !== 'chat') {
        systemMessageSound.play()
      }
    }
  }



  const DOM = () =>
    <div className='arteco-toast'>
      <Error size={20} />
      <span>{message}</span>
    </div>
    ;

  const ChatMessageToast = () => 
    <div className='chatmessage-toast' onClick={callback}>      
      <span className='chatmessage-toast-from'>{message}</span>
      <span className='chatmessage-toast-preview'>{subtitle}</span>
    </div>
    ;
  const UpdateApp = () => 
    <div className='update-app-toast' >      
      <span className='update-app-toast-from'>{message}</span>
      <span className='update-app-toast-preview'>{subtitle}</span>
    </div>
    ;
    const EventToast = () => 
    <div className='event-app-toast' onClick={callback}>      
      <span className='event-app-toast-from'>{message}</span>
      <span className='event-app-toast-preview'>{subtitle}</span>
    </div>
    ;

  // const UpdateAppToast = () => 
  //   <button 
  //     className='update-app'
  //     onClick={() => {this.updateApp(registration)}}
  //   >
  //     New update available!
  //     Click to update.
  //   </button>
  //   ;

  const errorIcon = {
    icon: <Error />
  }

  const defaultValues = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    onClick: callback,
    pauseOnHover: true,
    progress: false,
    draggable: true,
    pauseOnFocusLoss: false,
    theme: "dark",
    transition: Slide,
    
  }

  const persistentValues = {
    ...defaultValues,
    autoClose: false
  }

  switch (type) {
    case 'warning':
      return toast.warn(message,
        { ...defaultValues, icon: <Error size={20} />, className: 'toast-warning' })
    case 'update-app':
      return toast.dark(UpdateApp,
        { ...defaultValues, icon: <UpdateIcon size={20} />, className: 'update-app-toast-wrapper', closeButton: CustomCloseButton })
    case 'persistent-error':
      return toast.error(message,
        { ...persistentValues, icon: <Error size={20} /> })
    case 'error':
      return toast.error(message,
        { ...defaultValues, icon: <Error size={20} />})
    case 'success':
      return toast.success(message,
        { ...defaultValues, icon: <Done size={20} /> })
    case 'info':
      return toast.info(message,
        { ...defaultValues, icon: <Done size={20} /> })
    case 'layout-update':
      return toast.info(message,
        { ...defaultValues, icon: <UpdateIcon size={20} />})
    case 'dark':
      return toast.dark(message,
        { ...defaultValues, icon: <Done size={20} /> })
    case 'chat':
      return toast.dark(ChatMessageToast,
        { ...defaultValues, icon: <ChatMessageIcon stroke={'#1fa507'} size={20} /> })
    case 'event-error':
      return toast.error(EventToast,
        { ...defaultValues, icon: <Error size={20} />, className: 'error-app-toast-wrapper' })
    default:
      return toast(message,
        { ...defaultValues, icon: <Done size={20} /> })
  }
}

const mapStateToProps = (state) => ({
  userPrefs: getUserPrefs(state),
});

export default connect(
  mapStateToProps,
  null
)(ArtecoToast)
