/** @format */

import { combineReducers } from "redux";
import {AuthReducer} from "./authReducer";
import { LayoutsReducer } from "./layoutsReducer";
import { ServersReducer } from "./serversReducer";
import { DeviceReducer } from "./deviceReducer";
import { EventsReducer } from "./eventsReducer";
import { FiltersReducer } from "./filtersReducer";
import { MapsReducer } from "./mapsReducer";
import { RecordingsReducer } from "./recordingsReducer";
import { PlaylistsReducer } from "./playlistsReducer";
import { ConfigReducer } from "./configReducer";
import { DownloadsReducer } from "./downloadsReducer";
import { UserPrefsReducer } from "./userPrefsReducer";
import { PendingRequestsReducer } from "./pendingRequestsReducer";
import { DomesReducer } from "./domesReducer";
import { ChatReducer } from "./chatReducer";
import { FeaturesReducer } from "./featuresReducer";
import { NetworkStatusReducer } from "./networkStatusReducer";
import { AppStatusReducer } from "./appStatusReducer";
import { TagsReducer } from "./tagsReducer";
import { PluginsReducer } from "./pluginsReducer";
import { DocumentReducer } from "./documentReducer";
import { additionalPropertiesReducer } from "./additionalPropertiesReducer";
import { ufollowReducer } from "./ufollowReducer";
import { AuditingsReducer } from "./auditingsReducer";
import { IntegrationsReducer } from "./integrationsReducer";
import { ErrorsReducer } from "./errorReducer";

export default combineReducers({
  auth: AuthReducer,
  errors: ErrorsReducer,
  servers: ServersReducer,
  layouts: LayoutsReducer,
  device: DeviceReducer,
  events: EventsReducer,
  filters: FiltersReducer,
  maps: MapsReducer,
  recordings: RecordingsReducer,
  playlists: PlaylistsReducer,
  config: ConfigReducer,
  downloads: DownloadsReducer,
  userPrefs: UserPrefsReducer,
  pendingRequests: PendingRequestsReducer,
  domes: DomesReducer,
  chat: ChatReducer,
  networkStatus: NetworkStatusReducer,
  appStatus: AppStatusReducer,
  features: FeaturesReducer,
  tags: TagsReducer,
  listeners: DocumentReducer,
  plugins: PluginsReducer,
  additionalProperties: additionalPropertiesReducer,
  ufollow: ufollowReducer,
  auditings: AuditingsReducer,
  integrations: IntegrationsReducer
});
