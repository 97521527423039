import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import * as serviceWorker from './serviceWorkerRegistration';
import { install } from 'ga-gtag'; // @see https://www.npmjs.com/package/ga-gtag
import { getOemPageTitleFromClientEnv, isProductionFz } from './helpers/network';


const gaMeasurementId = isProductionFz() ? 'G-P01SK3W57H' : '';

install(gaMeasurementId);

//set page title
const oemPageTitle = getOemPageTitleFromClientEnv(window.location.hostname);
const pageTitle = oemPageTitle ? oemPageTitle :'uSee';
document.title = pageTitle;

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>      
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
