import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import LayoutPopup from '../layouts/LayoutPopup';

//actions
import { getEditingLayout, isPopupPropertiesPopupShown } from '../../reducers/featuresReducer';
import { showPopupPropertiesPopup } from '../../actions/featuresActions';
import { gtag } from 'ga-gtag';

export class AddLayout extends Component {


  closePopup = () => {
    const evtName = `cancel_or_submit_menù_to_activate_popup_mode`;
    gtag ('event', evtName);
    const { showPopupPropertiesPopup } = this.props;

    showPopupPropertiesPopup(false);
  }

  render() {
    const { isPopupPropertiesPopupShown, editingLayout } = this.props;

    return (
      <Popup
        open={isPopupPropertiesPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='arteco-popupediting-popup'>          
          <LayoutPopup
            isPopup={true}
            layoutToEdit={editingLayout}
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isPopupPropertiesPopupShown: isPopupPropertiesPopupShown(state),
  editingLayout: getEditingLayout(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showPopupPropertiesPopup: (show) => dispatch(showPopupPropertiesPopup(show)),
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddLayout)