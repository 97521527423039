export const appPaths = {
    layouts: "/layouts",
    root: "/",
    login: "/login",
    logout: "/login",
    login_internal: "/login-internal",
    dashboard: "/dashboard",
    events: "/events", 
    add_server: "/servers/add", 
    edit_server_id: "/servers/edit/:serverId", 
    add_layout: "/layouts/add", 
    configuration: "/configuration",
    recordings: "/recordings", 
    myaccount: "/myaccount",
    tags: "/tags",
    insight: "http://insight.omniaweb.cloud/",
    face_factor : "https://face.arteco-global.com/",
    layout_edit: "/layouts/edit",
    popup_mode: "/popup-mode",    
    omnia_website: "https://omniaweb.cloud",
    PUBLIC_HOST: ".my.omniaweb.cloud",
    LAN_HOST: ".lan.omniaweb.cloud",
    integration: "/integration",
    ufollowConfiguration: "/u-follow-configuration",
    ufollowGui: "/u-follow",
    brandLogos: 'https://arteco-global.github.io/resources/brands/',
    auditings: '/auditings',
    ksenia: '/ksenia',
    arteco_global_lost_password : 'https://www.arteco-global.com/support-2/lostpassword/',
    arteco_global_register_new_user : 'https://www.arteco-global.com/support-area/sign-up/',
    arteco_global_my_arteco : 'https://www.arteco-global.com/support-2/login/',
    arteco_global_support_mail : 'support@arteco-global.com',
    plugins:'/plugins',
    plugins_ksenia: '/plugins/ksenia',
};

export const showEvents = [appPaths.layouts, appPaths.dashboard, appPaths.tags, appPaths.myaccount];
export const showDownloads = [appPaths.layouts, appPaths.dashboard, appPaths.recordings];
export const showInstantPlayer = [appPaths.dashboard];

export const hasSidebar = (path) => {
    return (
        path === appPaths.dashboard ||
        path === appPaths.tags ||
        path === appPaths.recordings
    ) ? 'with-sidebar' : '';
}