import React, { Component } from 'react';
import { connect } from "react-redux";
import gtag from "ga-gtag";

//icons
import { DownloadCloud as Download } from "react-feather";
import { Download as DownloadReady } from "react-feather";
import { X as ErrorIcon } from "react-feather";
import Spinner from '../Spinner/Spinner';

//actions
import { getExportStatus, getExportPath, getPlaylistById } from '../../reducers/playlistsReducer';
import { getMediaUri, getServerByCodeName } from '../../reducers/serversReducer';
import { getFile, updateDownloadList } from '../../actions/downloadsActions';
import { exportVideo } from '../../actions/playlistActions';

//user preferences
import { getUserPrefs, isExportPrivacySupported, isTimestampAndWatermarkSupported } from '../../reducers/userPrefsReducer';

//helpers
import { withTranslation } from 'react-i18next';

import { showExportFeaturesPopup } from '../../actions/featuresActions';
import { ArtecoToast } from '../Toast/ArtecoToast';
import { fileDownloadProgress, fileIsDownloading } from '../../reducers/downloadsReducer';
import { serverDateToUnixDate } from '../../helpers/timeHelpers';
import { getAuthentication, getAuthenticationUserId } from '../../reducers/authReducer';
import { addDiagnosticsEvent } from '../../actions/eventsAction';

class ExportButton extends Component {

  hasPrivacy = (metadataHistory) => {
    return metadataHistory.find(meta => meta.metadata.find(meta => meta.metadataType === 'PrivacyZone'));
  }

  requestVideoExport = () => {
    const { showExportFeaturesPopup, playlist, deviceData, exportTimeStampWatermarkSupported } = this.props;

    if (exportTimeStampWatermarkSupported) {
      showExportFeaturesPopup(true, this.doExport, [deviceData]);
      return;
    }
    
    if (playlist.metadataHistory && playlist.metadataHistory.length > 0 && this.hasPrivacy(playlist.metadataHistory)) {
      showExportFeaturesPopup(true, this.doExport, [deviceData]);
    } else {
      this.doExport();
    }
  }

  doExport = (exportFeaturesPickerPayload = null) => {
    const { playlistId, playlist, auth, exportVideo, recMedia, userPrefs, event, server, exportPrivacySupported, t, disabled, addDiagnosticsEvent } = this.props;

    //GA TRACK    
    gtag('event', 'export_video');

    const hasPrivacy = (exportFeaturesPickerPayload && exportFeaturesPickerPayload.privacy);
    const isUnlocked = (exportFeaturesPickerPayload && !exportFeaturesPickerPayload.privacy);
    const privacyData = hasPrivacy && hasPrivacy(playlist.metadataHistory) ? playlist.metadataHistory : [];

    const exportRequest = {
      playlistId: playlistId,
      artecoId: playlist.artecoId,
      serverCodename: server.codeName,
      recPath: recMedia,
      owner: auth.user.id,
      ownerName: auth.user.name,
      artecoEventId: event && event.artecoEventId,
      token: server.access_token,
      privacyData: privacyData,
      actualFrom: playlist.actualFrom ? playlist.actualFrom : playlist.from,
      actualTo: playlist.actualTo ? playlist.actualTo : playlist.to,
      unlocked: isUnlocked,
      privacy: hasPrivacy,
      showWatermark: exportFeaturesPickerPayload && exportFeaturesPickerPayload.watermark, // TDB, not now      
      showTimeStamp: exportFeaturesPickerPayload && exportFeaturesPickerPayload.showTimeStamp,
      timestampPositionX: exportFeaturesPickerPayload && exportFeaturesPickerPayload.timestampPositionX,
      timestampPositionY: exportFeaturesPickerPayload && exportFeaturesPickerPayload.timestampPositionY,
      timestampColor: exportFeaturesPickerPayload && exportFeaturesPickerPayload.timestampColor,
      timestampBoxEnabled: exportFeaturesPickerPayload && exportFeaturesPickerPayload.timestampBoxEnabled,
      timestampBoxColor: exportFeaturesPickerPayload && exportFeaturesPickerPayload.timestampBoxColor,
      additionalText: exportFeaturesPickerPayload && exportFeaturesPickerPayload.addCameraName ? `${playlist.descr} ` : '',
      timestampFontSize: exportFeaturesPickerPayload && exportFeaturesPickerPayload.timestampFontSize,
      serverTimeZone: playlist.serverTimeZone
    }

    if (hasPrivacy && !exportPrivacySupported) {
      ArtecoToast("error", t('EXPORT_PRIVACY_NOT_SUPPORTED'));
      addDiagnosticsEvent({
        params: "EXPORT_PRIVACY_NOT_SUPPORTED",
        email: this.props.auth.user.email,
        serverCodename: server.codeName,
        category: "system"
      })
    } else {
      exportVideo(exportRequest, userPrefs);
    }
  }

  getFileAction = () => {
    const { exportPath, auth, userPrefs, getFile } = this.props;
    //GA TRACK    
    gtag('event', 'export_save_video');

    getFile(exportPath, auth, userPrefs);
  }

  shouldComponentRender = () => {
    const { playlist, userPrefs } = this.props;
    if (!userPrefs || !userPrefs.exportServiceEndpoint || !userPrefs.exportServiceChecked) return false;
    if (!playlist) return false;
    return true;
  }

  render() {
    const { exportStatus, downloadProgress, isDownloading, disabled } = this.props;
    const { t } = this.props;

    if (!this.shouldComponentRender()) return (<></>);

    let DownloadButton = (<></>);

    const progressLabel = (downloadProgress) ? `${downloadProgress}%` : t('SAVE_MP4');
    const disabledClass = disabled ? 'disabled' : '';

    switch (exportStatus) {
      case undefined:
        DownloadButton = (
          <div className={`${disabledClass} btn icon-button share-button`} onClick={this.requestVideoExport}>
            <Download size={22} className="ready" />
            <span>{t('EXPORT_MP4')}</span>
          </div>
        )
        break;
      case 'pending':
      case 'in progress':
        DownloadButton = (
          <div className={`${disabledClass} btn icon-button share-button`} >
            <Spinner />
          </div>
        )
        break;
      case 'complete':
        DownloadButton = (
          <div className={`${disabledClass} btn icon-button share-button`} onClick={isDownloading ? undefined : this.getFileAction}>
            <DownloadReady size={22} className="ready" />
            <span>{progressLabel}</span>
          </div>
        )
        break;
      case 'error':
        DownloadButton = (
          <div className={`${disabledClass} btn icon-button error-button`}>
            <ErrorIcon size={22} className="ready" />
            <span>Error</span>
          </div>
        )
        break;
      default:
        break;
    }

    return (
      <>
        {DownloadButton}
      </>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  const playlist = getPlaylistById(state, ownProps.playlistId);
  const recMedia = (playlist) ? getMediaUri(state, ownProps.deviceData.serverCodename, playlist.recPath) : '';
  const exportStatus = getExportStatus(state, ownProps.playlistId);
  const exportPath = getExportPath(state, ownProps.playlistId);
  const auth = getAuthentication(state);
  const userId = getAuthenticationUserId(state);
  const userPrefs = getUserPrefs(state, userId);
  const server = getServerByCodeName(state, ownProps.deviceData.serverCodename);
  const exportPrivacySupported = isExportPrivacySupported(state);
  const isDownloading = fileIsDownloading(state, exportPath);
  const downloadProgress = fileDownloadProgress(state, exportPath);
  const exportTimeStampWatermarkSupported = isTimestampAndWatermarkSupported(state);

  return {
    playlist,
    recMedia,
    exportStatus,
    exportPath,
    auth,
    userPrefs,
    server,
    exportPrivacySupported,
    isDownloading,
    downloadProgress,
    exportTimeStampWatermarkSupported
  }
};


const mapDispatchToProps = dispatch => {
  return {
    exportVideo: (exportRequest, userPrefs) => dispatch(exportVideo(exportRequest, userPrefs)),
    showExportFeaturesPopup: (show, callback, devices) => dispatch(showExportFeaturesPopup(show, callback, devices)),
    updateDownloadList: (path, userId, userPrefs) => dispatch(updateDownloadList(path, userId, userPrefs)),
    getFile: (path, auth, userPrefs) => dispatch(getFile(path, auth, userPrefs)),
    addDiagnosticsEvent: (event) => dispatch(addDiagnosticsEvent(event)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ExportButton));

