import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

//components
import Landing from "./components/Landing/Landing";
import Login2023 from "./components/auth/Login2023";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { appPaths } from "./config/paths";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import RenameLayout from "./components/layouts/RenameLayout";
import LayoutPopup from "./components/layouts/LayoutPopup";
import NotFound from "./components/NotFound/NotFound";
import Pages from "./components/dashboard/AppSection/Pages";

import { ToastContainer } from "react-toastify";

//Socket
import SocketContext from "./components/dashboard/Chat/socket-context";

//lazy import
import ComponentLoader from './components/ComponentLoader/ComponentLoader'
import NetworkStatus from './components/ProgressiveWebApp/NetworkStatus';
import LanWanCheck from "./Socket/LanWanCheck";
import ServiceWorkerUpdater from "./components/ProgressiveWebApp/ServiceWorkerUpdater";
import ConnectionsWrapper from "./Socket/ConnectionsWrapper";
import { isDesktop, isIOS, isMacOs, isChrome, isTablet, isMobileOnly } from "react-device-detect";
import PopupWrapper from "./components/PopupWrapper/PopupWrapper";
import { ArtecoSocket } from "./Socket/ArtecoSocket";
import { info, logger } from "./helpers/logger";
import UseeVideoChat from "./components/dashboard/Chat/UseeChat/UseeVideoChat";
import { getOemClass } from "./helpers/network";



const AddServer = lazy(() => import("./components/servers/Add"));
const EditServer = lazy(() => import("./components/servers/Edit"));
const AddLayout = lazy(() => import("./components/layouts/Add"));

function renderPageComponent(pageType) {
  return (props) => <Pages {...props} pageType={pageType} />;
}
//Pages
const DashboardPage = renderPageComponent('dashboard');
const TagsDashboardPage = renderPageComponent('tags');
const ConfigurationPage = renderPageComponent('configuration');
const EventDashboardPage = renderPageComponent('events');
const RecordingsPage = renderPageComponent('recordings');
const MyAccountPage = renderPageComponent('myAccount');
const LayoutsPage = renderPageComponent('layouts');
const IntegrationPage = renderPageComponent('integration');
const AuditingPage = renderPageComponent('auditing');
const KseniaPage = renderPageComponent('kseniaDashboard');
const UFollowConfigurationPage = renderPageComponent('uFollowConfiguration');
const UFollowGuiPage = renderPageComponent('ufollowGui');
const PluginsPage = renderPageComponent('plugins');

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;

  //set token to Auth header
  setAuthToken(token);

  //decode token to get user data
  const decoded = jwt_decode(token);

  //set current user
  logger(info, "App", `>>>>>> set current user ${JSON.stringify(decoded)}`);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());

    //redirect to login
    window.location.href = "./login";
  }
}

let ServerConnections = [];
const currentHost = window.location.host;
const currentProtocol = window.location.protocol;

let socket;
const backendUri = process.env.BACKEND_URI ? process.env.BACKEND_URI : "http://localhost:5000/";
const socketLocalUri = `${backendUri.replace(/^http/, 'ws')}/socket`;

//for connecting to local server with personal devices, example
//const socketLocalUri = `ws://192.168.0.67:5000/socket`;

if (process.env.NODE_ENV === 'development') {
  // Se stai eseguendo il codice in modalità di sviluppo locale
  socket = new ArtecoSocket(socketLocalUri);
} else {
  // Utilizza l'indirizzo corrente come host
  const protocolPrefix = currentProtocol === 'https:' ? 'wss://' : 'ws://';
  socket = new ArtecoSocket(`${protocolPrefix}${currentHost}/socket`);
}

class App extends Component {
  render() {
    let isTouchDevice = require("is-touch-device");
    let touchClass = isTouchDevice() ? "is-touch" : "is-not-touch";
    let iosClass = isIOS ? 'is-ios' : '';
    let macOsClass = isMacOs ? 'is-mac' : '';
    let isDesktopClass = isDesktop ? ' is-desktop' : '';
    let isChromeClass = isChrome ? 'is-chrome' : '';
    let isTabletClass = isTablet ? 'is-tablet' : '';
    let shouldntScroll = !isMobileOnly ? 'no-scroll' : '';
    let isLannding = window.location.pathname === appPaths.login ? 'is-landing' : '';
    const oemClass= getOemClass();

    return (
      <SocketContext.Provider value={{ socket, ServerConnections }}>
        <Provider store={store}>
          <SocketContext.Consumer>
            {({ socket, ServerConnections }) => (
              <>
                <Router>
                  <ServiceWorkerUpdater />
                  <div className={`omnia ${touchClass} ${iosClass} ${macOsClass} ${isDesktopClass} ${isChromeClass} ${isTabletClass} ${shouldntScroll} ${isLannding} ${oemClass}`}>                              
                    {/* <NetworkStatus socket={socket} /> */}
                    <Route exact path={appPaths.root} component={Landing} />
                    <Route
                      exact
                      path={appPaths.login}
                      component={Login2023}
                    />
                    <Suspense fallback={<ComponentLoader what="connections" />}>                        
                      <LanWanCheck />                        
                      <ToastContainer />                      
                      <ConnectionsWrapper ServerConnections={ServerConnections} socket={socket} />   
                      <UseeVideoChat socket={socket} />                                                                                        
                    </Suspense>
                    <Route exact path={appPaths.root} component={Landing} />
                    <ErrorBoundary>
                      <Suspense fallback={<ComponentLoader what="components" />}>
                        <Switch>
                          <PrivateRoute socket={socket} exact path={appPaths.popup_mode} component={LayoutPopup} />                        
                          <PrivateRoute socket={socket} exact path={appPaths.layout_edit} component={RenameLayout} />                       
                          <PrivateRoute socket={socket} exact path={appPaths.add_server} component={AddServer} />
                          <PrivateRoute socket={socket} path={appPaths.edit_server_id} component={EditServer} />
                          <PrivateRoute socket={socket} exact path={appPaths.add_layout} component={AddLayout} />
                          <PrivateRoute socket={socket} exact path={appPaths.tags} component={TagsDashboardPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.events} component={EventDashboardPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.dashboard} component={DashboardPage}  />
                          <PrivateRoute socket={socket} exact path={appPaths.configuration} component={ConfigurationPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.recordings} component={RecordingsPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.myaccount} component={MyAccountPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.layouts} component={LayoutsPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.integration} component={IntegrationPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.auditings} component={AuditingPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.ksenia} component={KseniaPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.ufollowConfiguration} component={UFollowConfigurationPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.ufollowGui} component={UFollowGuiPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.plugins} component={PluginsPage} />
                          <PrivateRoute socket={socket} exact path={appPaths.plugins_ksenia} component={KseniaPage} />
                          <PrivateRoute socket={socket} component={NotFound} />
                        </Switch>
                        <PopupWrapper />
                      </Suspense>
                    </ErrorBoundary>
                  </div>
                </Router>
              </>
            )}
          </SocketContext.Consumer>
        </Provider>
      </SocketContext.Provider>
    );
  }
}

export default App;
