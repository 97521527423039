import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import { Target as TargetIcon } from "react-feather";
import { Power as PowerIcon } from "react-feather";
import { wsSetPeripheralPayload } from "../../dashboard/wsActionsPayloads/wsPayloads";
import { socketEvents } from "../../../config/eventsAndStorage";
import { amIInited, getMystatus, isCredentialEnabled   } from "../../../reducers/serversReducer";
import DotLoader from "../../DotLoader/DotLoader";
import { PINS_TYPE } from "../../dashboard/DeviceList/DeviceInfoVariables";
import { PeripheralsInteraction } from "../../../helpers/serverCredentials";

export class Pin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      hasOverflow: false
    };
    this.nodeRef = createRef();
    this.containerRef = createRef();
  }

  componentDidMount() {
    this.checkOverflow();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.device !== this.props.device) {
      this.checkOverflow();
    }
  }

  checkOverflow() {
    const container = this.containerRef.current;
    if (container) {
      const hasOverflow = container.scrollWidth > container.clientWidth;
      if (hasOverflow !== this.state.hasOverflow) {
        this.setState({ hasOverflow });
      }
    }
  }

  changeState = (pinId) => {
    const { serverId,status , server} = this.props;

    if(!isCredentialEnabled(PeripheralsInteraction, server)) return false;

    this.setState(
      {
        value: status == 1 ? 0 : 1,
      },
      () => {
        const setToValue = this.state.value;
        const payloadToSend = wsSetPeripheralPayload(pinId, setToValue); // invio comando

        const event = new CustomEvent(socketEvents.controlPeripheral, {
          detail: {
            payload: payloadToSend,
            server_id: serverId,
          },
        });

        document.dispatchEvent(event);
      }
    );
  };

  shouldComponentRender = () => {
    const { device, pin } = this.props;
    return device && pin;
  };

  render() {
    const { device, inited, status, pin } = this.props;
    const { hasOverflow } = this.state;

    if (!this.shouldComponentRender()) {
      return <></>;
    }

    if (!inited) {
      return (
        <div data-id={device.id} className="linkedPeripheral-display loader">
          <DotLoader />
        </div>
      );
    }

    let Icon =
      pin.type === PINS_TYPE.OUTPUT ? (
        <PowerIcon  />
      ) : (
        <TargetIcon />
      );
    let stateClass = status === 1 ? "on" : "off";

    return (
      <>
        <div className="linkedPeripheral-display-box " onClick={() => this.changeState(pin.id)}>
        <div className="linkedPeripheral-display-icon">
          <div
            data-id={pin.id}
            className={`linked ${stateClass}`}
          >
            {Icon}
          </div>
          </div>
          <div className="descr" ref={this.containerRef}>
            <p className={hasOverflow ? 'text-overflow' :''}>{pin.descr}</p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  inited: amIInited(state, ownProps.device.artecoId, ownProps.pin.id),
  status: getMystatus(state, ownProps.device.artecoId, ownProps.pin.id),
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pin);
