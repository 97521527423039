import React from 'react'
import VideoChat from './VideoChat'
import { useSelector } from 'react-redux';
import { callInProgress, isCallPanelShown } from '../../../../reducers/chatReducer';
import { isSidebarLarge, isSidebarShown } from '../../../../reducers/eventsReducer';
import { arePeripheralLarge, arePeripheralsShown } from '../../../../reducers/layoutsReducer';
import { appPaths } from '../../../../config/paths';

export default function UseeVideoChat(props) {    
  const { socket } = props;
  const isCallInProgress = useSelector(state => callInProgress(state));
  const isCallPanelOpen = useSelector(state => isCallPanelShown(state));

  const sidebarShown = useSelector(state => isSidebarShown(state));
  const sidebarLarge = useSelector(state => isSidebarLarge(state));  

  const isUFollowGui = window.location.pathname === appPaths.ufollowGui 
  const sidebarShownClass = isUFollowGui ? 'sidebar-closed' : (sidebarShown ? 'sidebar-open' : 'sidebar-closed');
  const sidebarLargeClass = isUFollowGui ? '' : (sidebarShown && sidebarLarge ? 'large-sidebar' : '');

  const isOpenClass = (isCallInProgress && isCallPanelOpen) ? 'open' : '';  

  return (
    <div className={`videochat-container ${isOpenClass} ${sidebarShownClass} ${sidebarLargeClass}`}>
      <div className={`videochat-content`}>
        <VideoChat socket={socket} />
      </div>      
    </div>
  )
}
