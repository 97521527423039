import axios from "axios";
import { CIVETWEB_ENTRYPOINT, PHP_ENTRYPOINT } from "../config/backend";

//helpers
import { info, logger } from "../helpers/logger";
import { getLanAddress } from "../helpers/network";

export const FETCH_MAPS_PENDING = "FETCH_MAPS_PENDING";
export const FETCH_MAPS_SUCCESS = "FETCH_MAPS_SUCCESS";
export const FETCH_MAPS_ERRORS = "FETCH_MAPS_ERRORS";
export const RESET_MAPS = "RESET_MAPS";
export const RESET_MAPS_ERRORS = "RESET_MAPS_ERRORS";
export const RESET_SERVER_MAPS = "RESET_SERVER_MAPS";

function fetchMapsPending() {
  return {
    type: FETCH_MAPS_PENDING,
  };
}

function resetMaps() {
  return {
    type: RESET_MAPS,
  };
}

export function resetServerMaps(server) {
  return {
    type: RESET_SERVER_MAPS,
    server,
  };
}

export function resetMapsErrors() {
  return {
    type: RESET_MAPS_ERRORS,
  };
}

function fetchMapsSuccess(maps, message, server_id) {
  return {
    type: FETCH_MAPS_SUCCESS,
    payload: maps,
    message: message,
    server_id: server_id,
  };
}

function fetchMapsError(error, server_id) {
  return {
    type: FETCH_MAPS_ERRORS,
    error: error,
    server_id: server_id,
  };
}

export const fetchMaps = (userId) => {
  return (dispatch) => {
    dispatch(fetchMapsPending());
    dispatch(resetMaps());

    let req = {
      owner: userId,
    };

    const API = "/api/maps/get-by-user";

    axios
      .post(API, req)
      .then((res) => {
        if (res.error) {
          throw res.error;
        }

        dispatch(
          fetchMapsSuccess(
            res.data,
            "Database connected successefully",
            "database"
          )
        );
        return res.data;
      })
      .catch((error) => {
        dispatch(fetchMapsError("Cannot connect to database", "database"));
      });
  };
};

export const fetchUpdatedMaps = (userId, server) => {
  return (dispatch) => {
    dispatch(fetchMapsPending());
    logger(info, "fetchUpdatedMaps:: done");

    let req = {
      owner: userId,
    };
    const config = {
      headers: {
        Authorization: "Bearer " + server.access_token,
      },
    };

    const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;
    // let  API = `${server.protocol}://${serverIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/maps/?SessionId=${server.sessionId}`;
    const OldServerAPI = `${server.protocol}://${serverIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/maps/?SessionId=${server.sessionId}`;
    const NewServerAPI = `${server.protocol}://${serverIp}:${server.port}/${PHP_ENTRYPOINT}/static-maps`;
    const API = server.nodeServer ? NewServerAPI : OldServerAPI;
    const requestMethod = server.nodeServer ? axios.get : axios.post;

    logger(info, "fetchUpdatedMaps:: About to call" + API);

    requestMethod(API, server.nodeServer ? config : req)
      .then((res) => {
        const mainNode = res.data.root;
        const loginNode = mainNode.result.code;

        const mapNode = mainNode.maps;

        if (mapNode === null && loginNode === 0) {
          // login ok but no maps saved server side
          dispatch(
            fetchMapsSuccess(mapNode, "No maps for this server", server._id)
          );
          return;
        }

        const maps = mapNode.map;

        const mapArray = Array.isArray(maps) ? maps : new Array(maps);

        let updatedMaps = [];

        mapArray.map((map) => {
          // let imagePath =  map.path != undefined ? map.path.replace(/\\/g, "/") : ""; // map path could arrived empty or equal to 0.

          const doesMapHaveBackground = map.path != undefined ? true : false;

          let updatedMap = null;

          const serverCodename = server.codeName || server.codename;

          if (!doesMapHaveBackground) {
            updatedMap = {
              name: map.name,
              id: map.id,

              width: 1280, // fake data  -> in order to view maps invalid
              height: 960, // fake data -> in order to view maps invalid
              path: "/", // fake data -> in order to view maps invalid

              owner: userId,
              serverId: server._id,
              serverCodename: serverCodename,
              serverRealname: server.name,
              serverIp,
              serverPort: server.port,
              serverProtocol: server.protocol,
              elements: [],
            };
          } else {
            let imagePath = map.path.replace(/\\/g, "/");
            let mapElements = map.elements;

            updatedMap = {
              ...map,
              path: `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/${imagePath}`,
              owner: userId,
              serverId: server._id,
              serverCodename: serverCodename,
              serverRealname: server.name,
              serverIp,
              serverPort: server.port,
              serverProtocol: server.protocol,
              elements: mapElements != null ? mapElements.element : [], // elements are null
            };
          }

          updatedMaps.push(updatedMap);
        });

        const request = {
          maps: updatedMaps,
          serverCodename: server.codeName,
          owner: userId,
        };

        // db update
        const DeleteMapsAPI = "/api/maps/refresh-maps";
        axios
        .post(DeleteMapsAPI, request)
        .then((res) => {
          return dispatch(fetchMaps(userId));
        })
        .catch((error) => {
          dispatch(
            fetchMapsError(
                "Error: " + error + " from server: " + server.ip,
                server._id
              )
            );
        });        
      })
      .catch((error) => {
        dispatch(
          fetchMapsError(
            "Error: " + error + " from server: " + server.ip,
            server._id
          )
        );
      });
  };
};
