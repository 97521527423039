import React, { Component } from 'react';
import { connect } from "react-redux";
import adapter from 'webrtc-adapter';
import reactElementToJSXString from 'react-element-to-jsx-string';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import plyr from 'plyr';

import gtag from "ga-gtag";

//actions
import { getDeviceByArtecoId, getParentServerByArtecoId, getPrivacyData, isCredentialEnabled, OmniaLight, getDeviceLinkedPeripherals } from '../../reducers/serversReducer';
import { setChannelMode, setLayoutZoomAndPan, updateChannelSession, updateChannel, updateLayout, setCameraInFullscreen } from "../../actions/layoutActions";
import { getActiveLayout, getActiveLayoutVisualMode, getChannelWebRTCStreamProfile, getChannelZoomAndPan, getChannelWebRTCDelayAmount, getChannel, getChannelPrivacyShown,getIsAudioActiveByArtecoId } from '../../reducers/layoutsReducer';
import { getEventsNumForDevice } from '../../reducers/eventsReducer';

//components
import PlayerContextMenu from "../dashboard/Stage/PlayerContextMenu";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Spinner from '../Spinner/Spinner';
import LinkedPeripheralReskin from './LinkedPeripheralReskin.js';


//helpers
import { getLanAddress } from '../../helpers/network';
import { WEBRTC_ENTRYPOINT } from '../../config/backend';
import DomeController from '../ArtecoPlayer/DomeController/DomeController';
import { addHours, addInterval, diffInHours, getCurrentServerTime, intervalOperations, jsDateToServerDate, serverDateToFormattedDate, serverDateToUnixDate, serverStringToDate, toMoment } from '../../helpers/timeHelpers';
import { isIOS, isMobileOnly, isTablet } from 'react-device-detect';


//icons
import { ReactComponent as ZoomIcon } from "../svgIcons/siZoom.svg";
import { Activity as LowBWIcon, Video } from 'react-feather';

//configuration
import { appPaths } from '../../config/paths';
import DomeSocketController from './DomeController/DomeSocketController';
import LineLoader from '../LineLoader/LineLoader';
import { info, logger } from '../../helpers/logger';
import ResizableContainer from './ResizableContainer';
import PrivacyDisplay from './PrivacyDisplay';
import OnvifLiveMetaDisplay from './OnvifLiveMetaDisplay';
import { getUserPrefs } from '../../reducers/userPrefsReducer';
import { getMostRecentThumb } from '../../helpers/server';
import { CamerasPrivacyBlur, InstantPlayer} from '../../helpers/serverCredentials';
import { isChrome } from 'react-device-detect';
import { customEvents } from '../../config/eventsAndStorage';
import { getEdgeMode, getRecordings, getTimeLineMarker } from '../../reducers/recordingsReducer';
import { setEdgeIsPlaying } from '../../actions/playlistActions';
import ArtecoPlayerTimeBadge from './ArtecoPlayerTimeBadge';
import { deviceShouldZoom } from '../../reducers/mapsReducer';
import JBufferPlayer from '../BufferPlayer/JBufferPlayer.js';
import { getAdditionalPropertiesPerChannel } from '../../reducers/additionalPropertiesReducer.js';
import PlayerControls from '../BufferPlayer/PlayerControls.js';
import { isWebGLEnabled } from '../../reducers/configReducer.js';
import { ArtecoToast } from '../Toast/ArtecoToast.js';
import { addDiagnosticsEvent } from '../../actions/eventsAction.js';
import QualityLogger from './QualityLogger.js';


const WebRtcStreamer = require("../dashboard/Stage/lib/webrtcstreamer.js");
//const kaleidoscope = require('kaleidoscopejs');
const loggerScope = 'WebRtcStreamer'

export class PlayerWebRTC_Styled extends Component {
  constructor(props) {
    super(props);

    this.video = React.createRef();
    this.videoContainer = React.createRef();
    this.parentContainer = React.createRef();
    this.webRtcServer = React.createRef();
    this.zoomWrapperRef = React.createRef();

    this.player = React.createRef();

    this.state = {
      once: true,
      open: false,
      streamSelectorOpen: false,
      bufferMenuOpen: false,
      connected: false,
      framesReady: false,
      lowBW: false,
      loaded: false,
      zoomDisabled: false,
      zoomed: this.props.channelZoomAndPan?.scale > 1,
      streamSelected: this.props.currentWebRTCStreamProfile,
      delayAmount: this.props.webrtcDelayAmount,
      posterImageUrl: '',
      gotEdgeRecUrl: undefined,
      linkedPeriperalBool: undefined,
      hasAudioTrack:false                     
    }

    this.loadingTimer = null;
    this.MAX_LOADING_TIME = 60000;

    this.isIpad = isIOS && isTablet;

    this.playerTimers = [];
  }

  shouldComponentRender() {
    const { device } = this.props;
    return device;
  }


  onIPMenuClick = (instance) => {
    this.setState({
      open: !this.state.open
    }, () => {
      const menu = instance?.elements?.controls?.querySelector('.instant-player-menu');
      const menuContaner = menu.closest('.plyr__controls')
      if(this.state.open) {
        menuContaner.classList.add('open-controls')
      }else{
        menuContaner.classList.remove('open-controls')
      }
      menu && menu.setAttribute('data-open', this.state.open ? 'true' : 'false') 
    })
  }
  handleIPOutsideClick = (e, instance) => {
    const $instantPlayerMenu = e.target.closest('.instant-player-options');
    const $instantPlayerTrigger = e.target.closest('.instant-player-trigger');
    if(this.state.open && !$instantPlayerMenu && !$instantPlayerTrigger){
      this.setState(
        {
          open: false,
        },
        () => {
          const menu = instance?.elements?.controls?.querySelector('.instant-player-menu');
          if (menu) {
            menu && menu.setAttribute('data-open', 'false');
            const menuContaner = menu.closest('.plyr__controls')
            if(!this.state.open) menuContaner.classList.remove('open-controls')
          }
        }
      );
    }
  }

  onStreamSelectorClick = (instance) => {
    this.setState({
      streamSelectorOpen: !this.state.streamSelectorOpen
    }, () => {
      const menu = instance?.elements?.controls?.querySelector('.stream-selector');
      menu && menu.setAttribute('data-open',this.state.streamSelectorOpen === null? 'null' : this.state.streamSelectorOpen ? 'true' : 'false')
      const menuContaner = menu.closest('.plyr__controls')
      if(!this.state.streamSelectorOpen) menuContaner.classList.remove('open-controls')
    })
  }
  handleStreamOutsideClick = (e, instance) => {
    const $streamOptionsMenu = e.target.closest('.stream-selector-options');
    const $streamOpenTrigger = e.target.closest('.stream-selector-trigger');
    if (this.state.streamSelectorOpen && !$streamOptionsMenu && !$streamOpenTrigger) {
      this.setState(
        {
          streamSelectorOpen: false,
        },
        () => {        
          const menu = instance?.elements?.controls?.querySelector('.stream-selector');
          if (menu) {
            menu && menu.setAttribute('data-open', 'false');
            const menuContaner = menu.closest('.plyr__controls')
            if(!this.state.streamSelectorOpen) menuContaner.classList.remove('open-controls')
            
          }
        }
      );
    }
  };

  closeStreamSelector = (instance) => {
    this.setState({
      streamSelectorOpen: false
    }, () => {
      const menu = instance?.elements?.controls?.querySelector('.stream-selector');
      menu && menu.setAttribute('data-open', 'false')
      const menuContaner = menu.closest('.plyr__controls')
      if(!this.state.streamSelectorOpen) menuContaner.setAttribute('style', 'z-index: 9990')
    })
  }

  onBufferMenuClick = (instance) => {
    this.setState({
      bufferMenuOpen: !this.state.bufferMenuOpen
    }, () => {
      const menu = instance?.elements?.controls?.querySelector('.buffer-menu');
      menu && menu.setAttribute('data-open', this.state.bufferMenuOpen ? 'true' : 'false')
    })
  }

  closeBufferMenu = (instance) => {
    this.setState({
      streamSelectorOpen: false
    }, () => {
      const menu = instance?.elements?.controls?.querySelector('.buffer-menu');
      menu && menu.setAttribute('data-open', 'false')
    })
  }

  goInstant = (Minutes, Seconds) => {
    const { artecoId, setChannelMode } = this.props;

    const mode = {
      mode: `instant`,
      from: intervalOperations(getCurrentServerTime(), `00:${Math.floor(Minutes).toString().padStart(2, '0')}:${Math.floor(Seconds).toString().padStart(2, '0')}`, 'subtract'),
      to: getCurrentServerTime()
    }

    //GA TRACK    
    gtag('event', `instant_player_${Minutes}_minutes_${Seconds}_seconds`);
    setChannelMode(mode, artecoId)
  }

  downgradeStream = (stream, instance) => {

    if (parseInt(stream) === 0) {
      this.fallbackToHsl();
      return false;
    }

    const upgradedStream = stream - 1;

    this.switchStream(upgradedStream, instance);
  }

  switchStream = (stream, instance) => {
    const { device, activeLayout, updateChannel, channel } = this.props;
    const { streamSelected } = this.state;

    const hasProfiles = Array.isArray(device.profiles) && device.profiles.length > 0;

    if (!hasProfiles) {
      return false
    }
    if (parseInt(streamSelected) === parseInt(stream)) {
      return false;
    }

    //GA TRACK    
    gtag('event', `instant_player_switch_stream_${stream}`);

    this.setState({
      streamSelected: stream,
      connected: false,
      framesReady: false,
      lowBW: false,
      hasAudioTrack:false,
    }, () => {

      this.loadingTimer = setTimeout(() => {
        if (hasProfiles) {
          this.downgradeStream(stream, instance);
        } else {
          this.fallbackToHsl();
        }

      }, this.MAX_LOADING_TIME);


      const streamProfile = device.profiles.find(profile => profile.id === parseInt(stream));

      const streamUrl = streamProfile && `/${device.id}/${streamProfile.rtspStreamUrl}`

      if (streamProfile === undefined || streamProfile < 0) {
        this.fallbackToHsl();
      } else {

        this.closeStreamSelector(instance);


        //save switch on DB async for next reload
        updateChannel(activeLayout, {
          ...channel,
          webrtcStreamProfile: stream
        })

        this.createWebRTCInstanceAndConnect(streamUrl);

        const streamLabel = instance.elements.controls && instance?.elements?.controls?.querySelector('.stream-selector-trigger span');
        if(streamLabel) {
          streamLabel.textContent = streamProfile.name;
        }
      }


    });
  }

  getPreviousStream() {
    return this.videoContainer.current?.querySelector('.media-control-button.media-control-icon.plyr__control.set-stream.selected');
  }

  getSelectedStreamNode(currentWebRTCStreamProfile) {
    const streamNodes = this.videoContainer.current?.querySelectorAll('.media-control-button.media-control-icon.plyr__control.set-stream');
    return Array.from(streamNodes)?.find(button => button.dataset.stream == currentWebRTCStreamProfile);
  }

  updateStreamClasses(previousStream, selectedStreamNode) {
    previousStream?.classList.remove('selected');
    selectedStreamNode?.classList.add('selected');
  }

  fixedEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  createWebRTCInstanceAndConnect = (streamUrl) => {
    const { server, webrtcDelayAmount, cameraThumbnailPath, device, rtspUrl } = this.props;
    const { gotEdgeRecUrl } = this.state;

    //switch on the local player    
    const userAuth = server.capabilities.rawStream ? '' : `${server.username}:${server.password}@`;    
    let connectionUrl = !rtspUrl ? `rtsp://${this.fixedEncodeURIComponent(server.username)}:${this.fixedEncodeURIComponent(server.password)}@127.0.0.1${streamUrl}` : rtspUrl; //il webrtc streamer aggancia sempre l'AS sulla stessa macchina!

    connectionUrl = gotEdgeRecUrl != undefined ? gotEdgeRecUrl : connectionUrl;

    logger(info, "createWebRTCInstanceAndConnect", "Requesting rtsp " + connectionUrl)
    //turn server configuration
    const turnServerCredentials = process.env.REACT_APP_TURN ? {
      urls: JSON.parse(process.env.REACT_APP_TURN),
      username: process.env.REACT_APP_TURN_USR,
      credential: process.env.REACT_APP_TURN_PWD
    } : null;

    const serverIp = (server.isLocal) ? getLanAddress(server.ip) : server.ip;
    const APIcall = `${server.protocol}://${serverIp}:${server.port}/${WEBRTC_ENTRYPOINT}`;
    this.webRtcServer.current && this.webRtcServer.current.disconnect();
    this.webRtcServer.current = new WebRtcStreamer(this.video.current, APIcall, turnServerCredentials, webrtcDelayAmount, device.artecoId, device.descr);
    this.webRtcServer.current.connect(connectionUrl);

    if (server.nodeServer && server.access_token) {
      fetch(cameraThumbnailPath, {
        headers: {
          'Authorization': `Bearer ${server.access_token}`
        }
      })
        .then(response => {
          if (response.ok) {
            logger(info, 'lazy load', 'Got poster image ' + server.name + ' ' + device.descr);
            return response.blob();
          }
          throw new Error('Network response was not ok');
        })
        .then(blob => {
          this.setState({ posterImageUrl: URL.createObjectURL(blob) });
        })
        .catch(error => {
          logger(info, 'lazy load', 'Error retrieving poster image ' + server.name + ' ' + device.descr + ' ' + error);
        });
    }
  }

  fallbackToHsl = () => {
    const { activeLayout, channel, updateChannelSession, server } = this.props;

    clearTimeout(this.loadingTimer);
    this.loadingTimer = null;

    const isMSESupported = this.isMSESupported();
    const supportsFastPlay = !!(isMSESupported && server.capabilities?.rawStream);    

    if(supportsFastPlay) {
      this.fallbackToMSE();
      return;
    }

    //GA TRACK    
    gtag('event', `player_fallback_hls`);

    updateChannelSession(activeLayout, {
      ...channel,
      streamMode: 'hls'
    })

  }

  fallbackToMSE = () => {    

    this.setState({
      connected: false,
      framesReady: false,
      loaded: false
    })
  }

  renderStreamSelector = () => {

    const { device, currentWebRTCStreamProfile, t } = this.props;

    const hasProfiles = Array.isArray(device.profiles) && device.profiles.length > 0;

    if (hasProfiles) {
      const currentWebRTCStreamProfileData = device.profiles.find(profile => profile.id === parseInt(currentWebRTCStreamProfile));
      const currentWebRTCStreamProfileName = currentWebRTCStreamProfileData && currentWebRTCStreamProfileData.name;

      const streams = device.profiles.map(profile => {

        const selectedStreamClass = this.state.streamSelected == profile.id ? 'selected' : '';

        return (
          <button key={profile.id} type="button" class={`media-control-button media-control-icon plyr__control set-stream ${selectedStreamClass}`} data-stream={`${profile.id}`} aria-label={profile.name}>
            <span>{profile.name}</span>
          </button>
        )
      });


      return reactElementToJSXString(
        <div class='stream-selector' data-open="null">
          <button type="button" class='stream-selector-trigger plyr__control' aria-label="stream-selector">
            <span>{currentWebRTCStreamProfileName}</span>
          </button>
          <div class="stream-selector-options">
            {streams}
          </div>
        </div>
      );
    }

    return reactElementToJSXString(
      <div class='stream-selector' data-open="false">
        <button type="button" class='stream-selector-trigger plyr__control' aria-label="stream-selector">
          <span>Main</span>
        </button>
      </div>
    )

  }

  renderInstantPlayerButton = () => {
    return reactElementToJSXString(
      <div class='instant-player-menu' data-open="null">
        <button type="button" class='instant-player-trigger plyr__control' aria-label="instant player">
          <svg></svg>
        </button>
        <div class="instant-player-options">
          <button type="button" class='media-control-button media-control-icon plyr__control set-instant' data-amount-1="30" data-amount-2="0" aria-label="instant player 30'">
            <span>30'</span>
          </button>
          <button type="button" class='media-control-button media-control-icon plyr__control set-instant' data-amount-1="5" data-amount-2="0" aria-label="instant player 10'">
            <span>5'</span>
          </button>
          <button type="button" class='media-control-button media-control-icon plyr__control set-instant' data-amount-1="1" data-amount-2="0" aria-label="instant player 5'">
            <span>1'</span>
          </button>
          <button type="button" class='media-control-button media-control-icon plyr__control set-instant' data-amount-1="0" data-amount-2="30" aria-label="instant player 5'">
            <span>30''</span>
          </button>
          <button type="button" class='media-control-button media-control-icon plyr__control set-instant' data-amount-1="0" data-amount-2="15" aria-label="instant player 5'">
            <span>15''</span>
          </button>
        </div>
      </div>
    );
  }

  renderBufferButton = () => {
    const { webrtcDelayAmount, t } = this.props;

    return reactElementToJSXString(
      <div class='buffer-menu' data-open="false">
        <button type="button" class='buffer-trigger plyr__control' aria-label="buffer">
          <span>{t(`DELAY_${webrtcDelayAmount || 1}`)}</span>
        </button>
        <div class="buffer-options">
          <button type="button" class='media-control-button media-control-icon plyr__control set-buffer' data-amount="3" aria-label="buffer 3">
            <span>{t(`DELAY_3`)}</span>
          </button>
          <button type="button" class='media-control-button media-control-icon plyr__control set-buffer' data-amount="2" aria-label="buffer 2">
            <span>{t(`DELAY_2`)}</span>
          </button>
          <button type="button" class='media-control-button media-control-icon plyr__control set-buffer' data-amount="1" aria-label="buffer 1">
            <span>{t(`DELAY_1`)}</span>
          </button>
        </div>
      </div>
    );
  }

  renderPipButton = () => {
    const { isOmniaLight, t, channelPrivacyData, channelPrivacyShown } = this.props;

    let pipSelector = '';

    // Check if PiP is supported and privacy settings allow it
    if (document.pictureInPictureEnabled) {
      pipSelector = `<button class="plyr__controls__item plyr__control" type="button" data-plyr="pip">
      <svg aria-hidden="true" focusable="false"><use xlink:href="#plyr-pip"></use></svg>
      <span class="plyr__sr-only">PIP</span>
    </button>`;
    }

    if (channelPrivacyData && channelPrivacyData.metadataType === "PrivacyZone" && channelPrivacyShown) {
      pipSelector = `<button class="plyr__controls__item plyr__control hidden" type="button" data-plyr="pip">
      <svg aria-hidden="true" focusable="false"><use xlink:href="#plyr-pip"></use></svg>
      <span class="plyr__sr-only">PIP</span>
    </button>`;
    }

    // Hide PiP button for mobile/tablet/Omnia Light version
    if (isTablet || isMobileOnly || isOmniaLight) {
      pipSelector = '';
    }

    return pipSelector;
  }


  renderFullscreenButton = () => {
    const { channelPrivacyData } = this.props;

    let fullscreenSelector = `<button type="button" class="plyr__control custom-fullscreen-trigger" data-plyr="fullscreen">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>            
    </button>`;

    // if((channelPrivacyData && channelPrivacyData.zones) && isIOS && isMobileOnly) {
    //     fullscreenSelector = `<button type="button" class="plyr__control custom-fullscreen-trigger hidden" data-plyr="fullscreen">
    //         <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
    //         <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>            
    //     </button>`;
    //   }

    return fullscreenSelector;
  }

  renderAudioButton = () => {
   
    let audioButtonComponent = '';

    const hiddenAttribute = this.state.hasAudioTrack ? '' : 'hidden="hidden"';
    
    if(!this.props.isAudioActive){
      audioButtonComponent = `<button type="button" ${hiddenAttribute} class="plyr__control audio-player-trigger plyr__control--pressed"  data-plyr="mute">
      <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
      <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
      <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
    </button>
    <div class="plyr__volume">
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
    </div>`;
    }
    audioButtonComponent = `<button type="button" ${hiddenAttribute} class="plyr__control audio-player-trigger"  data-plyr="mute">
      <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
      <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
      <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
    </button>
    <div class="plyr__volume">
          <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
      </div>
    `;
    
    return audioButtonComponent;
  }

  renderControls = () => {
    const { device } = this.props
    const controls = `
    <div class="plyr__controls">   
        <div class='live-label'>
          <span class='icon'></span>
        </div>
        ${this.renderAudioButton()}
        ${isCredentialEnabled(InstantPlayer, device) ? this.renderInstantPlayerButton() : ''}   
        <button type="button" class="plyr__control" data-plyr="captions">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-captions-on"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-captions-off"></use></svg>
            <span class="label--pressed plyr__tooltip" role="tooltip">Disable captions</span>
            <span class="label--not-pressed plyr__tooltip" role="tooltip">Enable captions</span>
        </button>
        ${this.renderStreamSelector()}  
        ${this.renderPipButton()}
        ${this.renderFullscreenButton()}
    </div>
    `;

    return controls;
  }

  getAncestor = (element, className) => {
    let currentElement = element.parentElement;
    while (currentElement !== null && !currentElement.classList.contains(className)) {
      currentElement = currentElement.parentElement;
    }
    return currentElement;
  }

  onFullScreenClick = (instance) => {
    const container = this.getAncestor(instance.elements.container, 'arteco-player');
    this.zoomWrapperRef.current && this.resetTransform(this.zoomWrapperRef.current, true);


    //popup case
    const popupContainer = document.querySelector('.popup-content');
    const popupOverlay = document.querySelector('.popup-overlay');

     //chrome ipad (maledetto) exception                  
     if(isChrome && popupContainer) {                    
       popupContainer.classList.add('is-chrome');      
     }
     if(isIOS && popupContainer) {  
       popupContainer.classList.add('is-ios');                                        
     }
     
     if (container.classList.contains('custom-fullscreen')) {
        this.props.setCameraInFullscreen(null)
       if(popupContainer) {
         popupOverlay.classList.remove("custom-fullscreen");
         popupContainer.classList.remove("custom-fullscreen");
         const fullScereenEvent = new CustomEvent(customEvents.isFullScreen, {
           detail: {
             isFullScreen: false,
           },
         });
         document.dispatchEvent(fullScereenEvent);
       }                    
    
     } else {         
        this.props.setCameraInFullscreen(this.props.device.artecoId)
       if(popupContainer) {
         popupOverlay.classList.add('custom-fullscreen');
         popupContainer.classList.add('custom-fullscreen');
         const fullScereenEvent = new CustomEvent(
         customEvents.isFullScreen,
            {
              detail: {
                isFullScreen: true,
              },
            }
          );
          document.dispatchEvent(fullScereenEvent);
       }                               
  
     }


    if (container.classList.contains('custom-fullscreen')) {
      container.classList.remove('custom-fullscreen');
      const fullScereenEvent = new CustomEvent(customEvents.isFullScreen, {
        detail: {
          isFullScreen: false,
        },
      });
      document.dispatchEvent(fullScereenEvent);
    } else {                    
      container.classList.add('custom-fullscreen');
      const fullScereenEvent = new CustomEvent(customEvents.isFullScreen, {
        detail: {
          isFullScreen: true,
        },
      });
      document.dispatchEvent(fullScereenEvent);
    }
  }

  webRTCEventHandler = (e) => {
    if(!this.zoomWrapperRef.current) return;


    if (e.detail.eventType === 'connected') {
      this.setState({
        connected: true
      })
    }

    if (e.detail.eventType === 'framesReady') {          
      this.setState({
        framesReady: true,
      })
    }

    if (e.detail.eventType === 'lowBW') {          
      this.setState({
        lowBW: true
      })
    }

    if (e.detail.eventType === 'bwOk') {          
      this.setState({
        lowBW: false
      })
    }
    if (e.detail.eventType === 'audioTrackDetection') {      
      this.setState({
      hasAudioTrack:e.detail.hasAudioTrack
      });
    }
  }

  componentDidMount() {
    const { auth, server, device, currentWebRTCStreamProfile, index, setEdgeIsPlaying, isEdgeMode, activeLayout,isAudioActive, addDiagnosticsEvent, t } = this.props;

    //if(!server.isLocal) {
    // if (device['is-fisheye']) {
    //   this.fallbackToHsl();
    // }

    const plyrOption = {
      ...this.props.options,
      controls: this.renderControls(),
      fullscreen: {
        enabled: false,
        fallback: false,
        iosNative: false,
        container: '.webrtc-player'
      }
    }

    setEdgeIsPlaying(false);


    // if (device['is-fisheye']) {
    //   const videoContainer = this.videoContainer.current.querySelector('.plyr__video-wrapper');
    //   const viewer = new kaleidoscope.Video({ source: this.video.current, container: this.videoContainer.current });
    //   viewer.render();
    // }

    // default border around the video on mount 
    let foundChannel;
    if (activeLayout) foundChannel = activeLayout.channels.find(channel => channel.artecoId == device.artecoId);
    if(foundChannel && foundChannel.clicked == false && activeLayout.popupMode.active){
      this.parentContainer.current && this.parentContainer.current.classList.remove('no-highlight')
    }else{
      this.parentContainer.current && this.parentContainer.current.classList.add('no-highlight')
    }

    

    this.player.current = new plyr(this.video.current, plyrOption);

    this.player.current.on('timeupdate', (event) => {


      if (!isEdgeMode == undefined) {
        return;
      }

      const instance = event.detail.plyr;
      const eCurrent = Math.floor(instance.currentTime);


      let displayTime = this.props.selectedTime ? addInterval(this.props.selectedTime, `00:00:${eCurrent.toString().padStart(2, '0')}`) : undefined;

      this.currentTime = serverDateToFormattedDate(displayTime, true);
      this.currentServerTime = displayTime;


      // l'evento di cambio orario viene inviato solo se è passato almeno 1 secondo, altrimenti verrebbe inviato decine di volte al secondo
      // const eventToSend = new CustomEvent(customEvents.updatePlayersTime, {
      //   'detail': {
      //     currentTime: serverDateToUnixDate(displayTime),
      //     displayTime: serverDateToFormattedDate(displayTime, true),
      //     artecoId: device.artecoId,
      //     descr: device.descr,
      //     isError: false,
      //     vodCheck: false,
      //     isBuffering: false,
      //     currentServerTime: displayTime
      //   }

      // });

      // document.dispatchEvent(eventToSend);

    });

    const self = this;
    this.player.current.on('ready', (event) => {
      const instance = event.detail.plyr;

      if(this.player.current) {
        this.player.current.volume = this.props.isAudioActive ? 1 : 0;
        this.player.current.muted = !this.props.isAudioActive;
      }
      this.video.current.setAttribute('muted', !this.props.isAudioActive);

      const hasProfiles = Array.isArray(device.profiles) && device.profiles.length > 0;

      const streamProfile = hasProfiles && device.profiles.find(profile => profile.id === parseInt(currentWebRTCStreamProfile));
      let streamUrl = streamProfile ? `/${device.id}/${streamProfile.rtspStreamUrl}` : device.rtspMain;

      const isMSESupported = this.isMSESupported();
      const supportsFastPlay = !!(isMSESupported && server.capabilities?.rawStream);      

      
      const playerTimer = setTimeout(() => {        
        this.createWebRTCInstanceAndConnect(streamUrl);
      }, 100 * index)
      this.playerTimers.push(playerTimer);


      this.video.current.addEventListener('webrtcEvent', this.webRTCEventHandler)

      this.video.current.addEventListener('webrtcError', (e) => {
        if (e.detail.errorType === 'disconnected' || e.detail.errorType === 'failed') {
          // this.fallbackToHsl();
          if (hasProfiles && parseInt(currentWebRTCStreamProfile) > 0) {
            this.downgradeStream(currentWebRTCStreamProfile, instance);
          } else {
            if(e.detail.reasons) {  
              const lastValues = e.detail.lastValues;            
              //const reasonsTranslated = e.detail.reasons.map(reason => this.props.t(reason));

              //need to concatenate the reason with the measured value
              const reasonsCombined = e.detail.reasons.map(reason => {
                return `${this.props.t(reason)} (${lastValues[reason]})`
              })
              
              const fallbackLabel = supportsFastPlay ? t('FALLBACK_TO_MSE') : t('FALLBACK_TO_HLS');

              //ArtecoToast("warning", `${cameraName}: ${reasonsCombined}`);
              addDiagnosticsEvent({
                params: `${fallbackLabel} - ${reasonsCombined}`,                
                email: auth.user.email,
                serial: server.license.serial,
                category: 'network',
                deviceId: device.artecoId,
              });

            }            
            this.fallbackToHsl();
          }
        }
      })

      this.loadingTimer = setTimeout(() => {
        if (hasProfiles) {
          this.downgradeStream(currentWebRTCStreamProfile, instance);
        } else {
          this.fallbackToHsl();
        }

      }, this.MAX_LOADING_TIME)
    });


    this.player.current.on('loadedmetadata', (event) => {

      clearTimeout(this.loadingTimer);
      this.loadingTimer = null;

      if (this.state.once) {
        this.setState({
          once: false,
          loaded: true
        }, () => {

          const instance = event.detail.plyr;
          instance.elements.container.classList.add('loaded');

          const container = instance.elements.container;

          //to fix z-index issues on mobile, and to let the zoomwrapper work
          container.closest('.video-webrtc-container').appendChild(instance.elements.controls);

          const menuTrigger = instance?.elements?.controls?.querySelector('.instant-player-trigger');
          menuTrigger?.addEventListener('click', (e) => { this.onIPMenuClick(instance);})
          
             //close instantPlayer Options with outside menu click
          const instantPlayerOptions = instance?.elements?.controls?.querySelector('.instant-player-options');
          instantPlayerOptions?.addEventListener('click', (e)=> {e.stopPropagation();})
          document.addEventListener('mousedown', (e) => {
            this.handleIPOutsideClick(e, instance)
          })

          const streamSelectorTrigger = instance?.elements?.controls?.querySelector('.stream-selector-trigger');
          streamSelectorTrigger.addEventListener('click', () => { this.onStreamSelectorClick(instance) })
          
          //close streamOptions with outside menu click
          const streamOptions = instance?.elements?.controls?.querySelector('.stream-selector-options');
          streamOptions?.addEventListener('click', (e) => {e.stopPropagation();})
          document.addEventListener('mousedown', (e) => {
            this.handleStreamOutsideClick(e, instance)
          })

          // const bufferMenuTrigger = instance?.elements?.controls?.querySelector('.buffer-trigger');
          // bufferMenuTrigger.addEventListener('click', () => { this.onBufferMenuClick(instance) })

          const ipSelectors = instance?.elements?.controls?.querySelectorAll('.set-instant');
          ipSelectors.forEach(selector => {
            selector.addEventListener('click', () => { this.goInstant(selector.getAttribute('data-amount-1'), selector.getAttribute('data-amount-2')) })
          })

          const streamSelectors = instance?.elements?.controls?.querySelectorAll('.set-stream');
          streamSelectors.forEach(selector => {
            selector.addEventListener('click', () => { this.switchStream(selector.getAttribute('data-stream'), instance) })
          })

          // const bufferSelectors = instance?.elements?.controls?.querySelectorAll('.set-buffer');
          // bufferSelectors.forEach(selector => {
          //   selector.addEventListener('click', () => { this.setDelay(selector.getAttribute('data-amount'), instance) })
          // })

          const fullscreenTrigger = instance?.elements?.controls?.querySelector('.custom-fullscreen-trigger');
          fullscreenTrigger.addEventListener('click', () => { this.onFullScreenClick(instance) })

          const audioTrigger = instance.elements.controls.querySelector('.audio-player-trigger');
          audioTrigger && audioTrigger.addEventListener('click', () => {this.toggleAudio()});
         
        })
      }

    });


    this.player.current.on('enterfullscreen', (event) => {
      this.videoContainer.current.closest('.webrtc-player').classList.add('custom-fullscreen');
      this.zoomWrapperRef.current && this.resetTransform(this.zoomWrapperRef.current, true);
    });

    this.player.current.on('exitfullscreen', (event) => {
      this.videoContainer.current.closest('.webrtc-player').classList.remove('custom-fullscreen');
      this.zoomWrapperRef.current && this.resetTransform(this.zoomWrapperRef.current, true);
    })

    if (this.props.recordings != undefined && this.props.recordings.recordings != undefined && isEdgeMode) {
      this.getRecFromEdge();
    }

    this.setState({linkedPeriperalBool: this.allowLinkedPeripherals()})
  }
  
  getRecFromEdge = () => {
    const { recordings, selectedTime, device } = this.props

    const currentServer = recordings.recordings[device.serverCodename];
    if (!currentServer) { return; }
    const currentChannel = currentServer.recordingsData[device.artecoId];
    if (!currentChannel) { return; }

    const edgeRecInfo = currentChannel.mainRec.edgeRecInfo;

     /*
    Calcolare la differenza in ore tra la data di una telecamera ONVIF e l'orario corrente del browser. 
    La differenza viene quindi arrotondata verso l'alto per ottenere il numero di ore intere tra i due tempi.
    */
    var browserTime = new Date();
    let cameraDate = new Date(edgeRecInfo.cameraDate);


    if (device.showOnvifRemoteTrackPlayinUtc = !undefined && device.showOnvifRemoteTrackPlayinUtc == 0) {
      // applico fix per telecamere Hikvision
      cameraDate = new Date(edgeRecInfo.cameraDate.slice(0, -1));
      cameraDate = addHours(cameraDate, 1);

    }


    const deltaFromCameraToBroswer = diffInHours(toMoment(browserTime), toMoment(cameraDate));
    let rounderDelta = Math.ceil(deltaFromCameraToBroswer);
    const serverStartFormat = jsDateToServerDate(selectedTime);

    const startTimeWithDelta = addHours(serverStartFormat, rounderDelta);
    const startTimeParam = jsDateToServerDate(serverStringToDate(startTimeWithDelta), true, "YYYYMMDDtHHmmss")
    const rtspWithAuth = edgeRecInfo.recordingUrl.replace('rtsp://', `rtsp://${device.username}:${device.password}@`);

    let firstPartOfUrl = `?starttime=${startTimeParam}Z`;
    let outRtsp = `${rtspWithAuth}${firstPartOfUrl}`;


    let main = this;

    if (edgeRecInfo != undefined) {
      this.setState({
        framesReady:false,
        gotEdgeRecUrl: outRtsp,
      }, () => {
        main.createWebRTCInstanceAndConnect(this.props.streamUrl);

      })
    }


  }


  privacyHasChanged(nextProps, props) {
    const priv1 = JSON.stringify(nextProps.channelPrivacyData);
    const priv2 = JSON.stringify(props.channelPrivacyData);
    return priv1 !== priv2;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.shouldIZoom !== this.props.shouldIZoom ||
      nextProps.device?.metadataEnabled !== this.props.device?.metadataEnabled || 
      nextProps.device?.artecoId !== this.props.device?.artecoId || 
      nextProps.device?.id !== this.props.device?.id || 
      nextProps.device?.profiles?.length !== this.props.device?.profiles?.length || 
      nextProps.device?.descr !== this.props.device?.descr || 
      nextProps.device?.serverCodename !== this.props.device?.serverCodename || 
      nextProps.device?.showOnvifRemoteTrackPlayinUtc !== this.props.device?.showOnvifRemoteTrackPlayinUtc || 
      nextProps.device?.username !== this.props.device?.username || 
      nextProps.device?.password !== this.props.device?.password || 
      nextProps.device?.privacyPlgEnabled !== this.props.device?.privacyPlgEnabled || 
      nextProps.device?.rtspMain !== this.props.device?.rtspMain || 
      nextProps.className !== this.props.className ||
      nextProps.height !== this.props.height ||
      nextState.connected !== this.state.connected ||
      nextState.framesReady !== this.state.framesReady ||
      nextState.lowBW !== this.state.lowBW ||
      nextState.loaded !== this.state.loaded ||
      nextState.showPTZpanel !== this.state.showPTZpanel ||
      nextState.zoomDisabled !== this.state.zoomDisabled ||
      nextState.zoomed !== this.state.zoomed ||
      nextState.hasAudioTrack !== this.state.hasAudioTrack ||
      nextState.streamSelected !== this.state.streamSelected ||
      nextProps.channelZoomAndPan?.scale !== this.props.channelZoomAndPan?.scale ||
      nextProps.currentWebRTCStreamProfile !== this.props.currentWebRTCStreamProfile || 
      nextProps.webrtcDelayAmount !== this.props.webrtcDelayAmount ||
      nextProps.eventNum !== this.props.eventNum ||
      nextProps.activeLayout?.channels.length !== this.props.activeLayout?.channels.length ||
      nextProps.channelPrivacyShown !== this.props.channelPrivacyShown ||
      nextProps.selectedTime !== this.props.selectedTime ||
      nextProps.server.connectionStatus !== this.props.server.connectionStatus ||
      nextProps.isOmniaLight !== this.props.isOmniaLight ||
      nextState.linkedPeriperalBool !== this.state.linkedPeriperalBool ||
      nextProps.isAudioActive !== this.props.isAudioActive||
      this.privacyHasChanged(nextProps, this.props)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPopup, isPlayback , shouldIZoom, activeLayout, device, linkedPeripheralsServer, server, currentWebRTCStreamProfile } = this.props
    if (!isPopup) {
      if (
        prevState.zoomDisabled !== this.state.zoomDisabled ||
        prevState.zoomed !== this.state.zoomed ||
        prevProps.channelZoomAndPan?.scale !== this.props.channelZoomAndPan?.scale
      ) {
        this.zoomWrapperRef.current && this.resetTransform(this.zoomWrapperRef.current);
      }
    }

    //updating the state of linked peripherals when the server updates
    if(prevProps.linkedPeripheralsServer !== linkedPeripheralsServer){
      const status = this.props.server.connectionStatus;
      if(status !== 'cached' && status !== 'connecting'){
        if(getDeviceLinkedPeripherals(device, server).length == 0){
          //setting the state to false instead of evaluating the allowLinkedPeripherals function
          this.setState({linkedPeriperalBool: false })
        }else{
          this.setState({linkedPeriperalBool: this.allowLinkedPeripherals()})
        }
      }
    }

    if ((prevProps.eventNum !== this.props.eventNum && !isPlayback)) {
      if (this.parentContainer && this.parentContainer.current) {
        this.parentContainer.current.classList.remove('no-highlight');
        this.parentContainer.current.classList.add('highlight');
      }
    
      

      this.highlightTimer = setTimeout(() => {
        
        let foundChannel;

        if (activeLayout) foundChannel = activeLayout.channels.find(channel => channel.artecoId == device.artecoId);

        if(foundChannel && foundChannel.clicked == false && activeLayout.popupMode.active){
          this.parentContainer.current && this.parentContainer.current.classList.remove('no-highlight');
        }else{
            this.parentContainer.current && this.parentContainer.current.classList.remove('highlight');
            this.parentContainer.current && this.parentContainer.current.classList.add('no-highlight');
        }
      }, 10000)
    }


    if (prevProps.webrtcDelayAmount !== this.props.webrtcDelayAmount) {
      this.setDelay(this.props.webrtcDelayAmount, this.player.current)
    }

    if (prevProps.currentWebRTCStreamProfile !== this.props.currentWebRTCStreamProfile) {
    
      const previousStream = this.getPreviousStream();
      const selectedStreamNode = this.getSelectedStreamNode(currentWebRTCStreamProfile);

      this.updateStreamClasses(previousStream, selectedStreamNode);
      this.switchStream(currentWebRTCStreamProfile, this.player.current);
      
    }

    if (this.props.device?.privacyPlgEnabled !== prevProps.device?.privacyPlgEnabled || this.props.channelPrivacyShown !== prevProps.channelPrivacyShown) {

      //hide pip
      const pipSelector = this.videoContainer.current && this.videoContainer.current.querySelector('[data-plyr=pip]');

      if (pipSelector && document.pictureInPictureEnabled) {
        if (this.props.device?.privacyPlgEnabled && this.props.channelPrivacyShown) {
          if (document.pictureInPictureElement === this.video.current) {
            document.exitPictureInPicture().catch((error) => {
              console.error('Failed to close PiP:', error);
            });
          }
          pipSelector.classList.add('hidden');
        } else {
          pipSelector.classList.remove('hidden');
        }
      }

      //hide fullscreen on iphone (demmerdah)
      if (isIOS && isMobileOnly) {
        const fsSelector = this.videoContainer.current && this.videoContainer.current.querySelector('[data-plyr=fullscreen]');

        if (fsSelector) {
          if (this.props.device?.privacyPlgEnabled) {
            fsSelector.classList.add('hidden');
          } else {
            fsSelector.classList.remove('hidden');
          }
        }
      }
    }

    if (this.props.selectedTime != prevProps.selectedTime) {
      this.getRecFromEdge();
    }

    if ( shouldIZoom && this.videoContainer.current) {
      this.videoContainer.current.classList.add('located');
      this.selectedTimer = setTimeout(() => {
        this.videoContainer.current && this.videoContainer.current.classList.remove('located');
      }, 2000)
    }

    if(prevState.hasAudioTrack !== this.state.hasAudioTrack ){
      //if video has audio track  to show or hide oudio btn
      const audioBtnElemnent = this.videoContainer.current.querySelector('.audio-player-trigger');
      if(audioBtnElemnent &&  this.state.hasAudioTrack === false){
        audioBtnElemnent.setAttribute('hidden','hidden');
      }else if( this.state.hasAudioTrack === true &&(audioBtnElemnent?.hasAttribute('hidden'))){
        audioBtnElemnent.removeAttribute('hidden');
      }
    }

    //toggle audio
    if((
      (this.props.device.artecoId === prevProps.device.artecoId && this.props.isAudioActive !== prevProps.isAudioActive)||
      this.props.device.artecoId !== prevProps.device.artecoId)
      && this.player.current
    ){
      if(this.player.current && this.video.current) {
        this.player.current.volume = this.props.isAudioActive ? 1:0;
        this.player.current.muted = !this.props.isAudioActive;
        this.video.current.muted = !this.props.isAudioActive;
      }
    }
  }

  removeEventListeners() {
    this.video.current && this.video.current.removeEventListener('webrtcEvent', this.webRTCEventHandler)
  }

  componentWillUnmount() {

    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = null;
    }
    if(this.highlightTimer) {
      clearTimeout(this.highlightTimer);
      this.highlightTimer = null;
    }

    // Itera sull'array dei timer e cancellali
    this.playerTimers.forEach(timerId => {
      clearTimeout(timerId);
    });

    // Svuota l'array dei timer
    this.playerTimers = [];

    if (this.webRtcServer.current) {
      this.webRtcServer.current.disconnect();
    }
    if (this.player.current) {
      this.player.current.destroy()
    }

    this.removeEventListeners();

    //remove refs
    this.video.current = null;
    this.videoContainer.current = null;
    this.parentContainer.current = null;
    this.webRtcServer.current = null;
    this.zoomWrapperRef.current = null;
  }

  onZoom = () => {
    const { activeLayout, artecoId, setLayoutZoomAndPan, isPopup } = this.props

    if (!activeLayout) { return };
    if (!this.zoomWrapperRef.current) { return };

    const env = window.location.pathname;
    const currentScale = (this.zoomWrapperRef.current.state.scale <= 1) ? {
      positionX: 0,
      positionY: 0,
      previousScale: 1,
      scale: 1
    } : this.zoomWrapperRef.current.state;

    const zoomSetting = (env === appPaths.recordings) ?
      { "rec": currentScale } :
      { "live": currentScale };

    const zoomChannels = activeLayout.channels.map(channel => {
      if (channel.artecoId !== artecoId) return channel;

      return {
        ...channel,
        zoomAndPan: zoomSetting
      }
    })

    const layoutZoomAndPan = {
      ...activeLayout,
      channels: zoomChannels
    }

    if (!isPopup) {
      setLayoutZoomAndPan(layoutZoomAndPan);
    }
    this.setState({
      zoomed: zoomSetting.live.scale > 1
    });
  }

  resetTransform = (zoomWrapper, zoomOut = false) => {
    //if (isIOS && isMobileOnly) return false;

    const { activeLayout, artecoId, setLayoutZoomAndPan, isPopup } = this.props

    const env = window.location.pathname;
    const zoomSetting = (env === appPaths.recordings) ? {
      "rec": {
        positionX: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionX,
        positionY: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionY,
        scale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale,
        previousScale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale
      }
    } : {
      "live": {
        positionX: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionX,
        positionY: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionY,
        scale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale,
        previousScale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale
      }
    }

    const zoomChannels = activeLayout.channels.map(channel => {
      if (channel.artecoId !== artecoId) return channel;

      return {
        ...channel,
        zoomAndPan: zoomSetting
      }
    })

    const layoutZoomAndPan = {
      ...activeLayout,
      channels: zoomChannels
    }


    if (!isPopup) {
      setLayoutZoomAndPan(layoutZoomAndPan);
    }
    this.setState({
      zoomed: zoomWrapper.instance.props.initialScale > 1
    }, () => {
      zoomWrapper.resetTransform();
    })
  }

  setDelay = (delay, instance) => {
    const { activeLayout, updateChannel, t, channel } = this.props
    if (delay) {
      this.webRtcServer.current && this.webRtcServer.current.setDelay(parseFloat(delay));

      const bufferLabel = instance?.elements?.controls?.querySelector('.buffer-trigger span');
      if(bufferLabel) {
        bufferLabel.textContent = t(`DELAY_${delay}`);
      }

      //save switch on DB async for next reload

      updateChannel(activeLayout, {
        ...channel,
        webrtcDelayAmount: parseFloat(delay)
      })

      this.closeBufferMenu(instance);
    }
  }

  playerExternalControl = (event) => {

    const eventCmd = event.detail.command;

    if (eventCmd == 'pauseAll') {
      this.video.current.pause();
    }
    if (eventCmd == 'playAll') {
      this.video.current.play();
    }

  }

  allowLinkedPeripherals = () => {
    const { server, isPlayback, device, linkedPeripheralsServer} = this.props;
    const serverSupportEvents = (server.capabilities?.manageEvent_v1 == 2 && !server.offline);    
    const linkedPeripherals = linkedPeripheralsServer.length != 0;
    return (serverSupportEvents && !isPlayback && linkedPeripherals);
  }

  isMSESupported = () => {
    if ('MediaSource' in window || 'ManagedMediaSource' in window) {
      return true;
    } else {
      return false;
    }
  }

  toggleAudio = () => {
    const { isAudioActive,activeLayout,device,updateLayout} = this.props;

    const updatedLayout = {
      ...activeLayout,
      channels: activeLayout.channels.map((element) => {
        if (element.artecoId !== device.artecoId) {
          return { ...element, isAudioActive: false }
        } else {
          return { ...element, isAudioActive: !isAudioActive }
        }
      })
    };

    updateLayout(updatedLayout);
  
  }

  render() {
    if (!this.shouldComponentRender()) {
      return <></>
    }

    const { connected, lowBW,  framesReady, zoomDisabled, zoomed, showPTZpanel, loaded, posterImageUrl, streamSelectorOpen } = this.state;               
    const { device, activeLayoutVisualMode, channelZoomAndPan, isPopup, t, server, cameraThumbnailPath, channelPrivacyShown, isPlayback, isEdgeMode, userPrefs, hidePrivacy, isOmniaLight, additionalProperties, webGLEnabled } = this.props        

    const waitingPrivacyClass = device.privacyPlgEnabled ? 'wait-privacy' : '';

    const iPadClass = (isIOS && !isMobileOnly) ? 'is-ipad' : '';

    const linkedPeriperalBool = this.state.linkedPeriperalBool

    const zoomIcon = this.zoomWrapperRef.current && (zoomed || this.props.channelZoomAndPan?.scale > 1) &&
      <span className="zoom-icon-container">
        <ZoomIcon size={32} onClick={() => { this.resetTransform(this.zoomWrapperRef.current, true) }} />
      </span>;

    const  streamSelectorOpenClass = streamSelectorOpen? 'stream-Selector-Open' : '';

    const spinnerBackground = server.nodeServer ? posterImageUrl : posterImageUrl || cameraThumbnailPath;

    const spinnerClass = (!connected || !loaded || !framesReady) ? 'visible' : 'not-visible';

    const SpinnerElement = (
      <div className={`video-webrtc-loading ${spinnerClass} ${waitingPrivacyClass}`}>
        <Spinner />
        {!server.timeMismatch ? <div className={`sample-background ${waitingPrivacyClass}`}>
          {
            spinnerBackground && (
              <img src={spinnerBackground} alt={t('RECEIVING_FRAMES')} title={t('RECEIVING_FRAMES')} />
            )
          }
        </div> :
          <div className='time-mismatch-error'>
            {t("SERVER_TIME_MISMATCH_THUMBNAIL_ERROR")}
          </div>
        }
      </div>
    )


    const playerReady = connected && loaded && framesReady;
    const playerReadyClass = playerReady ? 'player-ready' : 'player-not-ready';
    const frameSpinnerClass = (playerReady) ? 'visible' : 'not-visible';
    const FramesSpinner = (
      <div className={`video-webrtc-loading frames-decoding ${frameSpinnerClass} ${waitingPrivacyClass}`}>
        <p>{t('RECEIVING_FRAMES')}...</p>
        <LineLoader status="active" />
        {!isPlayback && (<div className={`sample-background ${waitingPrivacyClass}`}>
          <img src={spinnerBackground} alt={t('RECEIVING_FRAMES')} title={t('RECEIVING_FRAMES')} />
        </div>)}
      </div>
    )

    const LowBWAlert = (lowBW) && (
      <div className='lowbw-alert'>
        <button className='btn icon-btn' onClick={() => this.downgradeStream(this.props.currentWebRTCStreamProfile, this.player.current)}>
          <LowBWIcon size={12} />
        </button>
      </div>
    )

    const panningOptions = {
      disabled: activeLayoutVisualMode === 'free' || !zoomed
    }
    
    const shouldAddLiveMeta = userPrefs.showLiveMeta && device.metadataEnabled;
    let shouldAddPrivacyLayer = device.privacyPlgEnabled && !hidePrivacy;

    if(shouldAddPrivacyLayer){
      const hasPrivacyCredential = isCredentialEnabled(CamerasPrivacyBlur,device);
      shouldAddPrivacyLayer = !hasPrivacyCredential || (hasPrivacyCredential && channelPrivacyShown);
    }

    const isMSESupported = this.isMSESupported();
    const supportsFastPlay = !!(isMSESupported && server.capabilities?.rawStream);    
    const isFisheye = device['is-fisheye'] && webGLEnabled;
    const isWall = additionalProperties && additionalProperties.fisheyeMountType === 'wall';
    
    const serverIp = (server.isLocal) ? getLanAddress(server.ip) : server.ip;
    const serverPort = server.port;

    const DewarpSupportingVideo = (
      <JBufferPlayer
        isLocalTesting={false}
        artecoId={device.artecoId}
        serverIp={serverIp}
        serverPort={serverPort}
        serverProtocol={server.protocol}
        deviceId={device.id}
        addPrivacy={shouldAddPrivacyLayer}
        poster={spinnerBackground}
        access_token={server.access_token}
        cameraThumbnailPath={cameraThumbnailPath}
        isFisheye={(activeLayoutVisualMode !== 'free') && isFisheye}
        mountType={isWall ? "wall" : "ceiling"}
        isWebGLEnabled={webGLEnabled}
      />
    )

    //default: no fisheye
    let VideoPreview = (
      <div className='transform-wrapper-container raw-player-wrapper-container no-fisheye'>
        <TransformWrapper
          ref={this.zoomWrapperRef}
          disabled={zoomDisabled}
          panning={panningOptions}
          onZoomStop={this.onZoom}
          onPanningStop={this.onZoom}
          initialPositionX={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionX : 0}
          initialPositionY={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionY : 0}
          initialScale={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.scale : 1}
        >
          <TransformComponent>
            {DewarpSupportingVideo}
          </TransformComponent>
        </TransformWrapper>
      </div>
    )

    //fisheye camera: default ceiling - disabled in free mode. Zoom on the dewarped image
    if (isFisheye && activeLayoutVisualMode !== 'free') {
      VideoPreview = (
        <div className='transform-wrapper-container raw-player-wrapper-container ceiling-fisheye'>
          {DewarpSupportingVideo}
        </div>
      )
    }
    

    //fisheye camera: wall mount - disabled in free mode. Digital zoom on the container cell
    if (isFisheye && isWall && activeLayoutVisualMode !== 'free') {
      VideoPreview = (
        <div className='transform-wrapper-container raw-player-wrapper-container wall-fisheye'>
          <TransformWrapper
            ref={this.zoomWrapperRef}
            disabled={zoomDisabled}
            panning={panningOptions}
            onZoomStop={this.onZoom}
            onPanningStop={this.onZoom}
            initialPositionX={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionX : 0}
            initialPositionY={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionY : 0}
            initialScale={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.scale : 1}
          >
            <TransformComponent>
              {DewarpSupportingVideo}
            </TransformComponent>
          </TransformWrapper>
        </div>
      )
    }

    const VideoDisplay = (
      <TransformWrapper
        ref={this.zoomWrapperRef}
        disabled={zoomDisabled}
        panning={panningOptions}
        onZoomStop={this.onZoom}
        onPanningStop={this.onZoom}
        initialPositionX={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionX : 0}
        initialPositionY={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionY : 0}
        initialScale={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.scale : 1}
      >
        <TransformComponent>
          <video
            className='js-plyr plyr video-webrtc'
            ref={this.video}
            autoPlay
            playsInline
          />
          {
            (shouldAddLiveMeta) && this.video.current && !shouldAddPrivacyLayer && 
            <ResizableContainer>
              <OnvifLiveMetaDisplay
                metadataLoaded={loaded}
                videoWidth={this.video.current.videoWidth}
                videoHeight={this.video.current.videoHeight}
                nodeRef={this.video.current}
                video={this.video.current}
                traceBorder={true}
                isWebRTC={true}
                liveOrRec={'live'}
                artecoId={device.artecoId}
                shouldAddLiveMeta={shouldAddLiveMeta}
                server={server}
                device={device}
              />
            </ResizableContainer>
          }
          {
            <QualityLogger videoInstance={this.video.current} />
          }
        </TransformComponent>
      </TransformWrapper>
    )

    const PTZPanel = device["is-dome"] ? server.nodeServer ?
      <DomeSocketController
        artecoId={this.props.artecoId}
        show={showPTZpanel}
      /> :
      <DomeController
        artecoId={this.props.artecoId}
        show={showPTZpanel}
      />
      : <></>

    const Controls = (
      <PlayerControls 
        videoContainer={this.videoContainer.current} 
        artecoId={device.artecoId} 
        isFisheye={!!device['is-fisheye']} 
        device={device}
        channelPrivacyShown={channelPrivacyShown}
      />
    )

    const shouldShowMSE = !!((supportsFastPlay && !playerReady) || (supportsFastPlay && device['is-fisheye']));

    let shouldShowWebrtc = !device['is-fisheye'];

    //read if the param hideWebRTC is set to true in the url
    const urlParams = new URLSearchParams(window.location.search);
    const hideWebRTC = urlParams.get('hideWebrtc');
    if(hideWebRTC === 'true'){
      shouldShowWebrtc = false;
    }

    return (
      <div className={`${this.props.className} ${iPadClass}`} ref={this.parentContainer}>
        <PlayerContextMenu
          artecoId={this.props.artecoId}
          isPlayback={this.props.isPlayback} 
          isEdgeMode={isEdgeMode} />
        {
          isEdgeMode && (
            <ArtecoPlayerTimeBadge
              isTranscoding={false}
              artecoId={this.props.artecoId}
              hideContextMenu={true}
              isPlayback={true}
              isEdgeMode={isEdgeMode}
            />
          )
        }

        {PTZPanel}
        {zoomIcon}
        <p className="name badge badge-danger">{this.props.name}</p>        
        {LowBWAlert}
        <div className={`video-webrtc-container ${device['is-fisheye'] && 'is-fisheye'} ${streamSelectorOpenClass} ${playerReadyClass}`} ref={this.videoContainer}>                              

          {
            (shouldShowMSE && this.videoContainer.current) && Controls
          }

          {/* //Media source extension player */}
          {(shouldShowMSE) && VideoPreview}

          {/* //WebRTC Player */}
          {(shouldShowWebrtc) && VideoDisplay}
                    
          {
            (shouldAddPrivacyLayer && this.video.current ) ?
            <PrivacyDisplay
              shouldAddPrivacyLayer={shouldAddPrivacyLayer}
            /> 
            :<></>           
          }
          {linkedPeriperalBool && server.connectionStatus !== "not-connected" && (<LinkedPeripheralReskin device={device} server={server} isOmniaLight={isOmniaLight} />)}
        </div>
      </div>
    )
  }
}


PlayerWebRTC_Styled.defaultProps = {
  options: {
    autoplay: true,
    hideControls: false,
    clickToPlay: false,
    controls: null,
    i18n: {
      restart: 'Restart',
      rewind: 'Rewind {seektime}s',
      play: 'Play',
      pause: 'Pause',
      fastForward: 'Forward {seektime}s',
      seek: 'Seek',
      seekLabel: '{currentTime} of {duration}',
      played: 'Played',
      buffered: 'Buffered',
      currentTime: 'Current time',
      duration: 'Duration',
      volume: 'Volume',
      mute: 'Mute',
      unmute: 'Unmute',
      enableCaptions: 'Enable captions',
      disableCaptions: 'Disable captions',
      download: 'Download',
      enterFullscreen: 'Enter fullscreen',
      exitFullscreen: 'Exit fullscreen',
      frameTitle: 'Player for {title}',
      captions: 'Captions',
      settings: 'Settings',
      menuBack: 'Go back to previous menu',
      speed: 'Speed',
      normal: 'Normal',
      quality: 'Quality',
      loop: 'Loop',
    },
  }
}

PlayerWebRTC_Styled.propTypes = {
  options: PropTypes.object,
  sources: PropTypes.object,
  source: PropTypes.func,
  destroy: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  const auth = state.auth;
  const isOmniaLight = OmniaLight(state);
  const eventNum = getEventsNumForDevice(state, ownProps.artecoId);
  const device = getDeviceByArtecoId(state, ownProps.artecoId);
  const currentWebRTCStreamProfile = getChannelWebRTCStreamProfile(state, ownProps.artecoId, device);
  const webrtcDelayAmount = getChannelWebRTCDelayAmount(state, ownProps.artecoId, device);
  const activeLayout = getActiveLayout(state);
  const server = getParentServerByArtecoId(state, ownProps.artecoId);
  const activeLayoutVisualMode = getActiveLayoutVisualMode(state);
  const channelZoomAndPan = getChannelZoomAndPan(state, ownProps.artecoId, window.location.pathname);
  const channel = getChannel(state, ownProps.artecoId);    
  const cameraThumbnailPath = getMostRecentThumb({server, deviceId: device.id});
  const channelPrivacyData = getPrivacyData(state, ownProps.artecoId);
  const channelPrivacyShown = getChannelPrivacyShown(state, ownProps.artecoId);
  const userPrefs = getUserPrefs(state);
  const recordings = getRecordings(state);
  const selectedTime = getTimeLineMarker(state);
  const isEdgeMode = getEdgeMode(state);
  const shouldIZoom = deviceShouldZoom(state, ownProps.artecoId);
  const linkedPeripheralsServer = device && server && getDeviceLinkedPeripherals(device, server)
  const isAudioActive = getIsAudioActiveByArtecoId(state, ownProps.artecoId);
  const additionalProperties = getAdditionalPropertiesPerChannel(state, ownProps.artecoId);
  const webGLEnabled = isWebGLEnabled(state);

  return {
    isOmniaLight,
    eventNum,
    device,
    channel,
    currentWebRTCStreamProfile,
    webrtcDelayAmount,
    server,
    activeLayout,
    activeLayoutVisualMode,
    channelZoomAndPan,
    cameraThumbnailPath,
    channelPrivacyData,
    channelPrivacyShown,
    userPrefs,
    recordings,
    selectedTime,
    isEdgeMode,
    shouldIZoom,
    linkedPeripheralsServer,
    isAudioActive,
    additionalProperties,
    webGLEnabled,
    auth
  }
};

export default connect(
  mapStateToProps,  
  { setChannelMode, updateChannelSession, updateChannel, setLayoutZoomAndPan, setEdgeIsPlaying , updateLayout, setCameraInFullscreen, addDiagnosticsEvent }
)(withTranslation()(PlayerWebRTC_Styled))
