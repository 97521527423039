import {
  FETCH_CONFIG_PENDING,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_ERRORS,
  FETCH_CONFIG_RESET,
  WEBGL_SUPPORT_UPDATE
} from "../actions/types";



const initialState = {
  pendingConfig: false, // in corso
  configurationCompleted: false, // completata con successo
  errorsHandling: null, // fallita (errori ...)
};

export function ConfigReducer(state = initialState, action) {
  switch (action.type) {
    case WEBGL_SUPPORT_UPDATE:
      return {
        ...state,
        webglEnabled: action.payload
      }
    case FETCH_CONFIG_PENDING:
      return {
        ...state,
        pendingConfig: true
      }
    case FETCH_CONFIG_SUCCESS:

      return {
        ...state,
        pendingConfig: false,
        configurationCompleted: true,
        errorsHandling: null,
      }

    case FETCH_CONFIG_RESET:

      return {
        ...state,
        pendingConfig: false,
        configurationCompleted: false,
        errorsHandling: null,
      }

    case FETCH_CONFIG_ERRORS:

      const currentError = {
        errorCode: action.errorCode ? action.errorCode : null,
        server_id: action.server_id
      };

      return {
        ...state,
        pendingConfig: false,
        configurationCompleted: false,
        errorsHandling: currentError
      }
    default:
      return state;
  }
}

export const getConfig = state => state.config;
export const isWebGLEnabled = state => state.config.webglEnabled;