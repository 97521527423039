import React from "react";
import { ReactComponent as UFollow } from "../svgIcons/ufollow.svg";
import { withTranslation } from "react-i18next";
import { appPaths } from "../../config/paths";
import { connect } from "react-redux";

const ArtecoPlayerUFollowAction = ({ uFollowGuiCamera }) => {
  const ufollowTab = "ufollowTab";
  const handleUFollowGui = () => {
    const tabExists = JSON.parse(localStorage.getItem(ufollowTab)) || false ;
    localStorage.setItem("uFollowGuiCamera", JSON.stringify(uFollowGuiCamera));
    if (!tabExists) {
      window.open(appPaths.ufollowGui, "_blank");
    }
  };
  return (
    <>
      <div
        className='badge ufollow-actions badge-danger'
        onClick={() => handleUFollowGui()}
      >
        <UFollow />
      </div>
    </>
  );
};

export default connect()(withTranslation()(ArtecoPlayerUFollowAction));
