import React, { Component } from 'react';
import { ReactComponent as Pause } from '../../svgIcons/grPauseFill.svg';
import { connect } from 'react-redux';
import { updateLayout} from '../../../actions/layoutActions';
import { LAYOUT_UPDATED} from '../../../actions/types';
import { getActiveLayout, getChannelMode } from '../../../reducers/layoutsReducer';

class PopupBadge extends Component {
	
	constructor(props) {
			super(props);
			this.ref = React.createRef();
			this.countRef = React.createRef();
			this.interval = undefined;
			this.deviceInTree = undefined;
			this.mouseLeave = undefined;
			this.state = {
				timer: 0,
				stopped: false,
			}
			this.stopHandler = this.stopHandler.bind(this);
		}


	componentDidMount() {
		const { activeLayout, currentState } = this.props;
		const channelMode = getChannelMode(currentState, this.props.artecoId);
		document.addEventListener(`pin-${this.props.artecoId}`, this.stopHandler)
		
		// Check if the channel is in instant player mode
		if(channelMode.mode == 'instant') {
			document.removeEventListener(`pin-${this.props.artecoId}`, this.stopHandler)
			if(this.countRef && this.countRef.current) {
				this.countRef.current.classList.add('d-none');
			}
			if(this.ref && this.ref.current) this.ref.current.closest('.clappr').removeEventListener('mouseleave', this.startCountdown)
			return;
		}
		// Restart the counter on new event if in layout
		document.addEventListener(this.props.artecoId, (e) => {
			this.setState({
				timer: activeLayout.popupMode.time,
			})
		})

		if(activeLayout && activeLayout.popupMode) {
			const modes = ['.webrtc-player', '.box-player', '.react-grid-item']
			
			this.setState({
				timer: activeLayout.popupMode.time,
			});
			
			modes.forEach(mode => {
				if(this.ref && this.ref.current) this.eventsHandler(this.ref.current.closest(mode))
			})
			this.countdown()
		}
	}

	countdown() {
		const {activeLayout} = this.props;
		this.interval = setInterval(() =>{
			this.setState({
				timer: this.state.timer - 1,
			})
			if(this.state.timer <= 0) {
				if(this.ref && this.ref.current !== null && activeLayout) {
						this.removeChannel(activeLayout)
				}
			}
		}, 1000)
		
	}

	eventsHandler(element) {
		if(element) {
			if(!element.classList.contains('highlight')) element.classList.add('highlight')
			element.addEventListener("mouseover", this.stopCountdown)
			element.addEventListener("mouseleave", this.startCountdown)
		}
	}

	// Events
	stopCountdown = () => {
		clearInterval(this.interval);
		if(this.ref && this.ref.current) this.ref.current.classList.remove('d-none')
	}
	startCountdown = () => {
		this.countdown();
		if(this.ref && this.ref.current) this.ref.current.classList.add('d-none')
	}

	// Remove the channel from layout
	removeChannel(layout) {
		const {activeLayout} = this.props;
		const getChannel = activeLayout.channels.find(channel => channel.artecoId == this.props.artecoId);
		if(getChannel && getChannel.clicked == false) {
			activeLayout.channels.splice(activeLayout.channels.indexOf(getChannel), 1);
			if(activeLayout) {
				this.props.dispatch({
					type: LAYOUT_UPDATED,
					payload: activeLayout
				})
			}
		}
	}

	// Stop the counter 
	stopHandler() {
		const getChannel = this.props.activeLayout.channels.find(channel => channel.artecoId == this.props.artecoId);

		if(getChannel && getChannel.clicked == false) {
			getChannel.clicked = true
			this.props.updateLayout(this.props.activeLayout);

			if(this.ref && this.ref.current && this.countRef && this.countRef.current) {
				const parentElement = this.ref.current.closest('.clappr')
				this.ref.current.style.display = 'none';
				this.countRef.current.style.display = 'none';
				parentElement.removeEventListener('mouseleave', this.startCountdown)
				parentElement.classList.remove('highlight')
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<span ref={this.countRef}>{this.state.timer}</span>
				<span ref={this.ref} className={'d-none'}><Pause size={16} /></span>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
		currentState: state,
		activeLayout: getActiveLayout(state),
})

const mapDispatchToProps = dispatch => {
	return {
	  updateLayout: (layout) => dispatch(updateLayout(layout)),
	  dispatch
	}
  }

export default connect(mapStateToProps, mapDispatchToProps)(PopupBadge);