/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Popup as PopupJS } from "reactjs-popup";
import { withTranslation } from "react-i18next";
import { X as XIcon } from "react-feather";
import { showSitesInformationPopup } from "../../../reducers/featuresReducer";
import { closeSitesInformationPopup } from "../../../actions/featuresActions";
import Scrollbars from "react-custom-scrollbars";
import classnames from "classnames";
import { setServerPassword } from "../../../actions/fetchServers";
import {
  getServers,
  serversWithoutLicense,
} from "../../../reducers/serversReducer";
import { setFilterServersWithOutLicence } from "../../../actions/serverActions";
import ServerNotLicence from "./ServerNotLicence";
import { getAuthentication } from "../../../reducers/authReducer";

class SitesInformationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      open: false,
    };
  }

  shouldComponentRender = () => {
    const { serversNotAssignedToSites, serversWithEmptyPassword } = this.props;
    return (
      serversNotAssignedToSites !== undefined &&
      serversWithEmptyPassword !== undefined
    );
  };

  closeMe = (e) => {
    const { closeSitesInformationPopup, setFilterServersWithOutLicence } =
      this.props;
    e !== undefined && e.stopPropagation();
    closeSitesInformationPopup(false, false);
    setFilterServersWithOutLicence();
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleServerPassword = (e) => {
    const {
      auth,
      serversWithEmptyPassword,
      closeSitesInformationPopup,
      setServerPassword,
    } = this.props;
    const { password } = this.state;
    if (!password) return false;
    setServerPassword(auth.user.id, serversWithEmptyPassword, password);
    closeSitesInformationPopup(false, false);
  };
  // handleFilterServersWithOutLicence =(e) => {
  //   const  {setFilterServersWithOutLicence} = this.props;
  //   setFilterServersWithOutLicence();
  //   this.closeMe();

  // }
  handleSubmit = (e) => {
    const {serversNotAssignedToSites,serversWithEmptyPassword,serversNotLicense,} = this.props;
    e.preventDefault();
    if (serversNotAssignedToSites.length === 0 && serversWithEmptyPassword.length > 0 && serversNotLicense.length === 0) {
    this.handleServerPassword();
    }
    
  }

  render() {
    const {
      t,
      SitesInformationPopup,
      serversNotAssignedToSites,
      serversWithEmptyPassword,
      serversNotLicense,
    } = this.props;

    const deviceLink = process.env.REACT_APP_CONTACTING_SERVER_ADMINISTRATORS;
    const deviceLinkText = deviceLink && new URL(deviceLink).hostname;

    if (!this.shouldComponentRender()) {
      return false;
    }

    return (
      <PopupJS
        open={SitesInformationPopup.showSitesInformationPopup}
        closeOnDocumentClick={false}
        className={`popup-content`}
        closeOnEscape={false}
      >
        <form className={`generic-popup export-digest-form sites-information-popup `} onSubmit={(e) => {this.handleSubmit(e)}}>
          <>
            <div className='form-row popup-header'>
              <div className='close' onClick={this.closeMe}>
                <XIcon size={22} />
              </div>              
            </div>

            {/* Servers not assigned to sites */}
            {serversNotAssignedToSites.length > 0 &&
              !SitesInformationPopup.serverNotAuthorzied &&
              !SitesInformationPopup.serverUsernameFiledEmpty &&
              !SitesInformationPopup.serverPasswordFieldEmpty && (
                <>
                  <div className='sites-information'>
                    {t("FOLLOWING_SERVERS")}{" "}
                    <a href={deviceLink} target='_blank' rel='noreferrer'>
                      {deviceLinkText}
                    </a>
                    {t("CONTACTING_SERVER_ADMINISTRATORS")}
                  </div>

                  <div className='servers-list'>
                    <Scrollbars>
                      {serversNotAssignedToSites?.map((server, index) => {
                        return (
                          <div className='server' key={index}>
                            {server.ip}{" "}
                            {server.name.startsWith("Server: ")
                              ? ""
                              : `[ ${server.name} ]`}
                          </div>
                        );
                      })}
                    </Scrollbars>
                  </div>
                </>
              )}
            {/* Servers list with Empty Password */}
            {serversWithEmptyPassword.length > 0 &&
              !SitesInformationPopup.serverNotAuthorzied &&
              !SitesInformationPopup.serverUsernameFiledEmpty &&
              !SitesInformationPopup.serverPasswordFieldEmpty && (
                <>
                  <div className='sites-information empty-password'>
                    {t("SERVERS_PASSWORD_RESET_INFORMATION")}
                  </div>

                  <div className='servers-list'>
                    <Scrollbars>
                      {serversWithEmptyPassword?.map((server, index) => {
                        return (
                          <div className='server' key={index}>
                            {server.license.serial}{" "}
                            {server.name.startsWith("Server: ")
                              ? ""
                              : `[ ${server.name} ]`}
                          </div>
                        );
                      })}
                    </Scrollbars>
                  </div>

                  <div className='server-password'>
                    <div className='server-password-form-element'>
                      <div className='form-group'>
                        <label htmlFor='FirstName'>
                          {t("SERVER_PASSWORD")}
                        </label>
                        <input
                          onChange={this.onChange}
                          id='password'
                          type='password'
                          className={classnames("form-control ", {})}
                        />
                      </div>
                    </div>
                    <div className='server-password-form-element'>
                      <div
                        className='btn btn-large'
                        onClick={this.handleServerPassword}
                      >
                        {t("SAVE_BTN")}
                      </div>
                    </div>
                  </div>
                </>
              )}

            {serversNotLicense.length > 0 &&
              !SitesInformationPopup.serverNotAuthorzied &&
              !SitesInformationPopup.serverUsernameFiledEmpty &&
              !SitesInformationPopup.serverPasswordFieldEmpty && (
                <>                  
                  <div className='sites-information empty-password'>
                    {t("SERVER_WITHOUT_LICENCE_INFORMATION")}
                  </div>
                  <div className='servers-notLicence-list'>
                    <Scrollbars>
                      {serversNotLicense.map((server) => {
                        return <ServerNotLicence server={server} key={server._id} />;
                      })}
                    </Scrollbars>
                  </div>
                </>
              )}

            {SitesInformationPopup.serverNotAuthorzied && (
              <div className='sites-information subscription'>
                {t("SERVER_SUBSCRIPTION_INFORMATION")}
              </div>
            )}
            {/* //serverUsernameFiledEmpty */}
            {SitesInformationPopup.serverUsernameFiledEmpty && (
              <div className='sites-information'>
                {t("USERNAME_SERVER_INFROMATIOM")}{" "}
                <a href={deviceLink} target='_blank' rel='noreferrer'>
                  {deviceLinkText}
                </a>{" "}
                {t("SERVER_USERNAME_ADMINISTRATOR_INFORMATION")}
              </div>
            )}

            {SitesInformationPopup.serverPasswordFieldEmpty && (
              <div className='sites-information empty-password'>
                {t("SERVER_EMPTY_PASSWORD_INFORMATION")}
              </div>
            )}
          </>
        </form>
      </PopupJS>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const SitesInformationPopup = showSitesInformationPopup(state);
  const serversNotLicense = serversWithoutLicense(state);

  return {
    SitesInformationPopup,
    auth: getAuthentication(state),
    serversNotLicense,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSitesInformationPopup: (
      showSitesInformationPopup,
      serverNotAuthorzied
    ) =>
      dispatch(
        closeSitesInformationPopup(
          showSitesInformationPopup,
          serverNotAuthorzied
        )
      ),
    setServerPassword: (id, servers, password) =>
      dispatch(setServerPassword(id, servers, password)),
    setFilterServersWithOutLicence: () =>
      dispatch(setFilterServersWithOutLicence()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SitesInformationPopup));
