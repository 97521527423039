import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import Add from '../servers/Add';

//actions
import { isAddServerPopupShown } from '../../reducers/featuresReducer';
import { showAddServerPopup } from '../../actions/featuresActions';

export class AddLayout extends Component {
  

  closePopup = () => {
    const { showAddServerPopup } = this.props;

    showAddServerPopup(false);
  }

  render() {
    const { isAddServerPopupShown } = this.props;

    return (
      <Popup
        open={isAddServerPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='arteco-popupediting-popup'>
          <Add 
            isPopup={true} 
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isAddServerPopupShown: isAddServerPopupShown(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showAddServerPopup: (show) => dispatch(showAddServerPopup(show)),
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddLayout)