import React, { Component } from 'react';
export class PrivacyDisplay extends Component {
  constructor(props) {

    super(props);
    this.privacyRef = React.createRef();
    this.observerInitialized = false;

  }

  componentDidMount() {  
    this.observePrivacyRemoval()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { shouldAddPrivacyLayer} = this.props;
  
    if (  nextProps.shouldAddPrivacyLayer !== shouldAddPrivacyLayer ) {
      return true;
    }

    return false;
  }
  

  componentDidUpdate() {

    if (this.privacyRef.current && !this.observerInitialized) {
      this.observePrivacyRemoval();
    }
  }

  componentWillUnmount() {
    if (this.privacyObserver) {
      this.privacyObserver.disconnect();
    }
  }

  observePrivacyRemoval() {
    const privacy = this.privacyRef.current;
    const privacyContainer = privacy.parentNode;
    if (!privacy || !privacyContainer) {
      return;
    }
  
    const observerCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (const removedNode of mutation.removedNodes) {
            if (removedNode === privacy || removedNode === privacyContainer) {
             window.location.reload();
            }
          }
        }else if(mutation.type ==='attributes' &&
          mutation.target.matches &&
          mutation.target == privacy ){
          window.location.reload();
        }
      }
    };
  
    const options = {
      attributes: true,
      childList: true,
      subtree: true,
      attributeOldValue: true,
      characterData: true };

    // Observe the parent of the privacy container and the privacy container itself
    this.privacyObserver = new MutationObserver(observerCallback);
    this.privacyObserver.observe(privacyContainer.parentNode, options);
    this.privacyObserver.observe(privacyContainer, options);
  
    this.observerInitialized = true;
  }

  shouldComponentRender = () => {
    const { shouldAddPrivacyLayer} = this.props;

    if(!shouldAddPrivacyLayer) return false;
    return true;
  }

  render() {
    const { setZIndex} = this.props;
    if(!this.shouldComponentRender()) return <></>

    const zetaIndex = setZIndex ? setZIndex : 0;
    //se lo stile rimane inline posso osservarlo in modo che non venga modificati dal devtool del browser
    const blurStyle={
      position: 'absolute',
      top: 0, 
      left: 0,
      width:'100%',
      height:'100%',
      backdropFilter: 'blur(10px)',     
      WebkitBackdropFilter: 'blur(10px)',
      pointerEvents: 'none',
      zIndex: zetaIndex

    } 
    return (
      <div className={`privacy-display`} ref={this.privacyRef} style={blurStyle} onContextMenu={(e) => {e.preventDefault()}}></div>  
    )
  }
}

export default PrivacyDisplay