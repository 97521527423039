import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { info, logger } from '../../helpers/logger';
import { Helmet } from 'react-helmet';

//actions
import { addServer } from "../../actions/serverActions";

//labels translation
import { withTranslation } from 'react-i18next'

//constants
import { appPaths } from '../../config/paths';
import { getAuthentication } from "../../reducers/authReducer";
import { getErrrors } from "../../reducers/errorReducer";


const loggerScope = "Add";
class Add extends Component {
  constructor() {
    super();

    this.state = {
      ip: "",
      port: "443",
      secure: true,
      password: "",
      username: "",
      public: true,
      errors: {}
    };

    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleProtocolChange = this.handleProtocolChange.bind(this);
    this.ipInput = React.createRef();

  }

  componentDidMount() {
    if (this.ipInput.current) {
      this.ipInput.current.addEventListener("keydown", (e) => {
        if (e.keyCode == 13) {
          e.preventDefault();
          this.onSubmit();
        }
      })

      this.ipInput.current.addEventListener("blur", (e) => {
        if (!this.ipInput.current) return false;
        this.ipInput.current.innerText = e.currentTarget.innerText.replace(appPaths.PUBLIC_HOST, "");
      })
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleProtocolChange(checked) {
    this.setState({
      secure: checked
    })
  }

  handleSwitchChange(checked) {
    this.setState({ public: checked });
  }

  onSubmit = e => {
    const { submitCallback, isPopup } = this.props

    if (e && e.target) {
      e.preventDefault();
    }

    let serverIp = `${this.ipInput.current.innerText}${appPaths.PUBLIC_HOST}`;
    if (this.ipInput.current.innerText == 'localhost') {
      serverIp = 'localhost';
    }

    const newServer = {
      owner: this.props.auth.user.id,
      ip: serverIp,
      port: this.state.port,
      protocol: this.state.secure ? 'https' : 'http',
      password: this.state.password,
      username: this.state.username,
      uptodate: false,
      public: this.state.public ,
      license: {
        serial: `${this.ipInput.current.innerText}`
      },     
    };

    const redirectTo = isPopup ? undefined :  this.props.history;    
    this.props.addServer(newServer, redirectTo, submitCallback);      
  };

  cancel = (e) => {
    e.preventDefault();
    const { isPopup, cancelCallback } = this.props

    if (!isPopup) {
      window.location.href = appPaths.dashboard
    }

    if (cancelCallback) {
      cancelCallback();
    }

    return false;
  }


  render() {
    const { t, isPopup } = this.props;
    const { errors } = this.state;


    const additionalClass = isPopup ? 'arteco-popupediting-popup-container' : 'col-md-4 mb-4';
    const additionalHeaderClass = isPopup ? 'arteco-popupediting-popup-wrapper' : '';           
    const title= document.title.split('|')[0].trim();
    return (
      <div>
        <Helmet>
          <title>${title} | {t("ADD_SERVER")}</title>
        </Helmet>
        <div className={`generic-popup ${additionalClass}`}>
          <form noValidate onSubmit={this.onSubmit}>
            <div className={`arteco-form-group ${additionalHeaderClass}`}>
              <h4 className="arteco-form-title main-title">{t('ADD_SERVER_TITLE')}</h4>
              <div className="arteco-form-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="ip">
                        {t('ADD_SERVER_IP')}
                      </label>
                      <div className={`ip-container`}>
                        <input type="hidden" />
                        <span
                          ref={this.ipInput}
                          value={this.state.ip}
                          error={errors.ip}
                          id="ip"
                          type="text"
                          contentEditable
                          suppressContentEditableWarning={true}
                          role="textbox"
                          className={classnames("form-control input single-line", {
                            invalid: errors.ip || errors.ipIncorrect
                          })}
                        >
                          {this.state.ip}
                        </span>                      
                      </div>
                      <small className="text-danger">
                        {errors.ip}
                        {errors.ipIncorrect}
                        {errors.ipexist ? t('SERVER_ALREADY_EXISTS') : null}
                      </small>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="port">  {t('ADD_SERVER_PORT')}</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.port}
                        error={errors.port}
                        id="port"
                        type="number"
                        className={classnames("form-control ", {
                          invalid: errors.port || errors.portIncorrect
                        })}
                      />
                      <small className="text-danger">
                        {errors.port}
                        {errors.portIncorrect}

                      </small>
                      {(Number.isInteger(Number(this.state.port)) != true && this.state.port != "") || (this.state.port < 0 && this.state.port != "") || (this.state.port > 65535) ? <small className="text-danger">
                        port is invalid
                      </small>
                        :
                        ""
                      }
                      {logger(info,loggerScope,"risultato" + this.state.port === parseInt(this.state.port, 10))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="username">{t('ADD_SERVER_US')}</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.username}
                        error={errors.username}
                        id="username"
                        type="username"
                        className={classnames("form-control ", {
                          invalid: errors.username || errors.passwordincorrect
                        })}
                      />
                      <small className="text-danger">
                        {errors.username}
                        {errors.passwordincorrect}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="FirstName">{t('ADD_SERVER_PW')}</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.password}
                        error={errors.password}
                        id="password"
                        type="password"
                        className={classnames("form-control ", {
                          invalid: errors.password || errors.passwordincorrect
                        })}
                      />
                      <small className="text-danger">
                        {errors.password}
                        {errors.passwordincorrect}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-1 d-flex justify-content-center pt-3">
                <a
                  onClick={this.cancel}
                  className="btn btn-large mr-2"
                >
                  {t('ADD_SERVER_CANCEL')}
                </a>
                <button
                  type="submit"
                  className="btn btn-large"
                >
                  {t('ADD_SERVER_ADD')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: getAuthentication(state),
  errors: getErrrors(state)
});

export default connect(
  mapStateToProps,
  { addServer }
)(withRouter(withTranslation()(Add)));