import axios from 'axios';
import { info, logger } from '../helpers/logger';

import {
  SHOW_DOWNLOADS,
  DOWNLOAD_DELETING,
  DOWNLOAD_DELETED,
  DOWNLOAD_UPDATE_WATCHLIST,
  DOWNLOAD_UPDATE_DOWNLOADLIST,
  DOWNLOAD_RESET_ERRORS,
  EXPORT_IS_DELETED,
  DOWNLOAD_UPDATE,
  EXPORT_IS_READY,
  DOWNLOAD_SSE_NOT_AVAILABLE,
  FILE_IS_DOWNLOADING,
  FILE_DOWNLOAD_PROGRESS,
  THUMB_POPUP_ENHANCING_IS_PENDING,
  THUMB_POPUP_ENHANCING_IS_READY
} from './types';

import {EXPORT_SERVICE_API, EXPORT_SERVICE_FILES_API} from '../config/ExportServiceConfg';
import { intervalOperations, serverTimeDiff } from '../helpers/timeHelpers';
import { getLanAddress, timeoutPromise } from '../helpers/network';
import { getPlaylist } from '../helpers/server';
import { fetchPlaylistError } from './fetchPlaylists';
export const toggleDownloadsPanel = (showDownloads) => dispatch => {
  dispatch({
    type: SHOW_DOWNLOADS,
    payload: showDownloads
  })
}

export const notifyExportDeleted = (playlistId) => dispatch => {
  dispatch({
    type: EXPORT_IS_DELETED,
    payload: {
      playlistId: playlistId
    }
  })
}

export const notifyFileDownload = (fileInfo) => dispatch => {
  dispatch({
    type: FILE_IS_DOWNLOADING,
    payload: {
      filename: fileInfo.filename,
      isDownloading: fileInfo.isDownloading
    }
  })
}

export const notifyDownloadDeleted = (path) => dispatch => {
  dispatch({
    type: DOWNLOAD_DELETED,
    payload: path
  })
}


export const notifyFileDownloadProgress = (fileInfo) => dispatch => {
  dispatch({
    type: FILE_DOWNLOAD_PROGRESS,
    payload: {
      filename: fileInfo.filename,
      downloadProgress: fileInfo.downloadProgress
    }
  })
}

export const cancelDownload = (path, controller) => dispatch => {
  controller.abort();
  dispatch({
    type: FILE_IS_DOWNLOADING,
    payload: {
      filename: path,
      isDownloading: false,
      downloadProgress: 0,
      abortController: null
    }
  })

}

export const getFile = (path, auth, userPrefs, tempFileName = path) => dispatch => {
  const DOWNLOAD_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/get-file`;

  const abortController = new AbortController();
  dispatch({
    type: FILE_IS_DOWNLOADING,
    payload: {
      filename: path,
      isDownloading: true,
      abortController: abortController
    }
  })

  axios({
    url: `${DOWNLOAD_API}?video=${path}`,
    method: "GET",
    responseType: 'blob',
    signal: abortController.signal,
    onDownloadProgress: (progressEvent) => {
      const downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      dispatch({
        type: FILE_DOWNLOAD_PROGRESS,
        payload: {
          filename: path,
          downloadProgress: downloadProgress
        }
      })
    }
  })
    .then((response) => {
      dispatch({
        type: FILE_IS_DOWNLOADING,
        payload: {
          filename: path,
          isDownloading: false,
          abortController: null
        }
      })

      updateDownloadList(path, auth.user.id, userPrefs);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', tempFileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(e => {
      abortController.abort()
    });
}

export const deleteVideo = (path, userPrefs, playlistId) => dispatch => {  
  const DELETE_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/delete-file`;  

  dispatch({
    type: DOWNLOAD_DELETING,
    payload: path
  })

  axios
    .post(DELETE_API, {
      video: path
    })
    .then(deleteResult => {
      const resultOk = deleteResult.data.result === 'ok';

      if (!resultOk) {
        logger(info , "DELETE | ", deleteResult.data.message);
      }

      if (resultOk) {

        dispatch(notifyDownloadDeleted(path));
        dispatch(notifyExportDeleted(playlistId));
      }

    })

}

export const abortExport = (path, userPrefs) => dispatch => {  
  const ABORT_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_API}/abort-video-ffmpeg`;    

  axios
    .post(ABORT_API, {
      path: path
    })
    .then(deleteResult => {      
      //no need to dispatch anything. Download treated as deleted
      logger(info , "ABORT | ", deleteResult.data.result);
    })

}

export const updateWatchList = (downloads, userId, userPrefs) => dispatch => {
  const SET_AS_WATCHED_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/set-as-watched`;  

  axios
    .post(SET_AS_WATCHED_API, {
      downloads,
      userId
    })
    .then(updated => {
      dispatch({
        type: DOWNLOAD_UPDATE_WATCHLIST,
        payload: updated.data
      })
    })


}


export const updateDownloadList = (path, userId, userPrefs) => dispatch => {
  const SET_AS_DOWNLOADED_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/set-as-downloaded`;

  axios
    .post(SET_AS_DOWNLOADED_API, {
      download: path,
      userId
    })
    .then(updated => {
      dispatch({
        type: DOWNLOAD_UPDATE_DOWNLOADLIST,
        payload: updated.data
      })
    })


}

export const resetDownloadsErrors = () => dispatch => {
  dispatch({
    type: DOWNLOAD_RESET_ERRORS
  })
}

export const notifyExportSSENotAvailable = () => dispatch => {
  dispatch({
    type: DOWNLOAD_SSE_NOT_AVAILABLE
  })
}

const ENHANCEMENT_PROCESS_TIMEOUT = 30000;
const API_CALL_TIMEOUT = 10000;
let enhancementTimeout = null;

export const notifyDownloadUpdate = (downloadData, userPrefs) => dispatch => {
  dispatch({
    type: DOWNLOAD_UPDATE,
    payload: downloadData
  })
  
  //notifica export complete per chi ascoltava il ritorno della vecchia API
  if(downloadData.status === 'complete') {

    const {exportData} = downloadData;

    dispatch({
      type: EXPORT_IS_READY,
      payload: {
        playlistId: exportData.playlistId,
        path: exportData.path,
        status: exportData.status,
      }
    })

    if(downloadData.exportData.isThumbRequest) {
      clearTimeout(enhancementTimeout);
      enhancementTimeout = null;

      //check if the thumb service is still available
      const thumbsAvailable = userPrefs.exportServiceFeatures.find(a => a.thumbs);
      if(thumbsAvailable) {
        const DISPLAY_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/display-file`;
        const DOWNLOAD_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/get-file`;
        const imagePath =  exportData.path.replace('.mp4', '.jpg');
        const DISPLAY_URL= `${DISPLAY_API}/${imagePath}`;
        const DOWNLOAD_IMAGE_URL = `${DOWNLOAD_API}/${imagePath}`;
        const DOWNLOAD_VIDEO_URL = `${DOWNLOAD_API}/${exportData.path}`;
        dispatch({
          type: THUMB_POPUP_ENHANCING_IS_READY,
          payload: {
            imagePath: imagePath,
            videoPath: exportData.path,
            enhancedImageSrc: DISPLAY_URL,
            enhanceImageDownloadUrl: DOWNLOAD_IMAGE_URL,
            videoDownloadUrl: DOWNLOAD_VIDEO_URL,
            origin: exportData.artecoId
          }
        })
      }

    }

  }
}
export const enhanceImage = (thumbTime, userPrefs, device, server, auth) => dispatch => {
  const THUMB_DELAY = 10;
  const EXPORT_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_API}/export-video-ffmpeg`;
  const thumbFrom = intervalOperations(thumbTime, `00:00:${THUMB_DELAY}`, 'subtract');
  const thumbTo = intervalOperations(thumbTime, `00:00:${THUMB_DELAY}`, 'add');

  const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;

  let hlsReq = {
    "serverProtocol": server.protocol,
    "serverIp": serverIp,
    "serverPort": server.port,
    "sessionId": server.sessionId,
    "access_token": server.access_token,
    "chId": device.id,    
    "from": thumbFrom,
    "to": thumbTo,
    "server": server
  }

  getPlaylist(hlsReq)
  .then(res => {
    if (res.error) {
      throw (res.error);
    }

    const response = res.root;

    //error handling!!
    if (response.result.code !== 0) {
      dispatch(fetchPlaylistError({
        artecoId: device.artecoId,
        reason: response.result.msg
      }));
      return response.result.msg;
    } else {
      const playlistResponse = response.info;
          
      let recPathParsed = playlistResponse.recpath || playlistResponse.recPath;
      let recPath = recPathParsed.replace(/\\/g, "/");

      const isTranscoding = recPath.includes("_M"); // convention over configuration, se c'è _M è una playlist ricodificata da FFMPEG

      const hlsPath = `${server.protocol}://${serverIp}:${server.port}/${recPath}`;

      //offset 
      const offset = playlistResponse.actualFrom ? serverTimeDiff(thumbFrom, playlistResponse.actualFrom, 'seconds') : 0;

      let thumbTimeResult = THUMB_DELAY;
      if(offset < 0) {
        thumbTimeResult = THUMB_DELAY + Math.abs(offset);
      } 
      if (offset > 0) {
        thumbTimeResult = THUMB_DELAY - Math.abs(offset);
      }
      

        const exportRequest = {
          playlistId: `${device.artecoId}_${thumbFrom}_${thumbTo}`,
          artecoId: device.artecoId,
          serverCodename: server.codeName,
          recPath: hlsPath,
          owner: auth.user.id,
          ownerName: auth.user.name,
          token: server.access_token,
          privacyData: [],
          actualFrom: thumbFrom,
          actualTo: thumbFrom,
          unlocked: false,
          privacy: false,
          thumbTime: thumbTimeResult
        }


        dispatch({
          type: THUMB_POPUP_ENHANCING_IS_PENDING    
        })        

        enhancementTimeout = setTimeout(() => {          
          dispatch({
            type: DOWNLOAD_SSE_NOT_AVAILABLE
          });
        }, ENHANCEMENT_PROCESS_TIMEOUT);

        try {
          
          const axiosRequest = axios.post(EXPORT_API, exportRequest);                    
          
          timeoutPromise(API_CALL_TIMEOUT, axiosRequest, new Error('timeout'))     
          .then(res => {
            if(res.status === 200) {

              const file = res.data.file;
              if(res.data.status === 'complete' && res.data.direct && file.isThumbRequest) {
                clearTimeout(enhancementTimeout);
                enhancementTimeout = null;

                const DISPLAY_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/display-file`;
                const DOWNLOAD_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/get-file`;
                const imagePath =  file.path.replace('.mp4', '.jpg');
                const DISPLAY_URL= `${DISPLAY_API}/${imagePath}`;
                const DOWNLOAD_IMAGE_URL = `${DOWNLOAD_API}/${imagePath}`;
                const DOWNLOAD_VIDEO_URL = `${DOWNLOAD_API}/${file.path}`;
                dispatch({
                  type: THUMB_POPUP_ENHANCING_IS_READY,
                  payload: {
                    imagePath: imagePath,
                    videoPath: file.path,
                    enhancedImageSrc: DISPLAY_URL,
                    enhanceImageDownloadUrl: DOWNLOAD_IMAGE_URL,
                    videoDownloadUrl: DOWNLOAD_VIDEO_URL,
                  }
                })

              }
              
            } else {
              dispatch({
                type: DOWNLOAD_SSE_NOT_AVAILABLE
              })
            }
          })
          .catch(err => {
            console.log(err);
            clearTimeout(enhancementTimeout);
            enhancementTimeout = null;
            dispatch({
              type: DOWNLOAD_SSE_NOT_AVAILABLE
            })
          })

        } catch (err) {
          console.log(err);
          clearTimeout(enhancementTimeout);
          enhancementTimeout = null;
          dispatch({
            type: DOWNLOAD_SSE_NOT_AVAILABLE
          })
        }
    }
  })
  .catch(error => {    
    clearTimeout(enhancementTimeout);
    enhancementTimeout = null;
    dispatch(fetchPlaylistError({
      artecoId: device.artecoId,
      reason: error
    }));
  })





}

// export const enhanceImage = (thumbTime, userPrefs, device, server, auth) => dispatch => {
//   const THUMB_DELAY = 10;
//   const EXPORT_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_API}/export-video-ffmpeg`;
//   const thumbFrom = intervalOperations(thumbTime, `00:00:${THUMB_DELAY}`, 'subtract');
//   const thumbTo = intervalOperations(thumbTime, `00:00:${THUMB_DELAY}`, 'add');

//   const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;

//   let hlsReq = {
//     "serverProtocol": server.protocol,
//     "serverIp": serverIp,
//     "serverPort": server.port,
//     "sessionId": server.sessionId,
//     "access_token": server.access_token,
//     "chId": device.id,    
//     "from": thumbFrom,
//     "to": thumbTo,
//     "server": server
//   }

//   getPlaylist(hlsReq)
//   .then(res => {
//     if (res.error) {
//       throw (res.error);
//     }

//     const response = res.root;

//     //error handling!!
//     if (response.result.code !== 0) {
//       dispatch(fetchPlaylistError({
//         artecoId: device.artecoId,
//         reason: response.result.msg
//       }));
//       return response.result.msg;
//     } else {
//       const playlistResponse = response.info;
          
//       let recPathParsed = playlistResponse.recpath || playlistResponse.recPath;
//       let recPath = recPathParsed.replace(/\\/g, "/");

//       const isTranscoding = recPath.includes("_M"); // convention over configuration, se c'è _M è una playlist ricodificata da FFMPEG

//       const hlsPath = `${server.protocol}://${serverIp}:${server.port}/${recPath}`;

//       //offset 
//       const offset = playlistResponse.actualFrom ? serverTimeDiff(thumbFrom, playlistResponse.actualFrom, 'seconds') : 0;

//       let thumbTimeResult = THUMB_DELAY;
//       if(offset < 0) {
//         thumbTimeResult = THUMB_DELAY + Math.abs(offset);
//       } 
//       if (offset > 0) {
//         thumbTimeResult = THUMB_DELAY - Math.abs(offset);
//       }
      

//         const exportRequest = {
//           playlistId: `${device.artecoId}_${thumbFrom}_${thumbTo}`,
//           artecoId: device.artecoId,
//           serverCodename: server.codeName,
//           recPath: hlsPath,
//           owner: auth.user.id,
//           ownerName: auth.user.name,
//           token: server.access_token,
//           privacyData: [],
//           actualFrom: thumbFrom,
//           actualTo: thumbFrom,
//           unlocked: false,
//           privacy: false,
//           thumbTime: thumbTimeResult
//         }


//         dispatch({
//           type: THUMB_POPUP_ENHANCING_IS_PENDING    
//         })
//         try {

//           axios
//             .post(EXPORT_API, exportRequest)
//             .then(res => {
              
//               if(res.status === 200) {

//                 const file = res.data.file;
//                 if(res.data.status === 'complete' && res.data.direct && file.isThumbRequest) {
//                   const DISPLAY_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/display-file`;
//                   const DOWNLOAD_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/get-file`;
//                   const imagePath =  file.path.replace('.mp4', '.jpg');
//                   const DISPLAY_URL= `${DISPLAY_API}/${imagePath}`;
//                   const DOWNLOAD_IMAGE_URL = `${DOWNLOAD_API}/${imagePath}`;
//                   const DOWNLOAD_VIDEO_URL = `${DOWNLOAD_API}/${file.path}`;
//                   dispatch({
//                     type: THUMB_POPUP_ENHANCING_IS_READY,
//                     payload: {
//                       imagePath: imagePath,
//                       videoPath: file.path,
//                       enhancedImageSrc: DISPLAY_URL,
//                       enhanceImageDownloadUrl: DOWNLOAD_IMAGE_URL,
//                       videoDownloadUrl: DOWNLOAD_VIDEO_URL,
//                     }
//                   })

//                 }
                
//               } else {
//                 dispatch({
//                   type: DOWNLOAD_SSE_NOT_AVAILABLE
//                 })
//               }
              
//             })
//             .catch(err => {
//               console.log(err);
//               dispatch({
//                 type: DOWNLOAD_SSE_NOT_AVAILABLE
//               })
//             });
//         } catch (err) {
//           console.log(err);
//           dispatch({
//             type: DOWNLOAD_SSE_NOT_AVAILABLE
//           })
//         }
//     }
//   })
//   .catch(error => {
//     dispatch(fetchPlaylistError({
//       artecoId: device.artecoId,
//       reason: error
//     }));
//   })





// }

// export const enhanceImageAI = (blobUrl) => async dispatch => {
//   dispatch({
//     type: THUMB_POPUP_ENHANCING_IS_PENDING    
//   })
//   try {
//     // Fetch the blob from the blob URL
//     const response = await fetch(blobUrl);
//     const blob = await response.blob();

//     // Use the blob as before
//     let formData = new FormData();
//     formData.append('image', blob);

//     axios
//       .post('/api/files/enhance', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       })
//       .then(res => {
//         if(res.status === 200) {
//           dispatch({
//             type: THUMB_POPUP_ENHANCING_IS_READY,
//             payload: {
//               enhancedImageSrc: res.data.output_url
//             }
//           })
//         }
        
//       })
//       .catch(err => {
//         console.log(err);
//       });
//   } catch (err) {
//     console.log(err);
//   }
// }


