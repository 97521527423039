export class ArtecoSocket {
  constructor(url) {
    this._id = null;
    this.url = url;
    this.socket = new WebSocket(url);
    this.eventListeners = {};    

    this.messageCallbacks = {};

    this.socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.type && this.messageCallbacks[data.type]) {
        this.messageCallbacks[data.type].forEach((callback) => {
          callback(data.value);
        });
      }
    });

    this.socket.onopen = () => {
      this.onOpen();
    };

    this.socket.onmessage = (event) => {
      this.onMessage(event.data);
    };

    this.socket.onclose = (event) => {
      this.onClose(event.code, event.reason);
    };

    this.socket.onerror = (error) => {
      this.onError(error);
    };

    // Aggiungi un gestore generale per gli eventi WebSocket
    this.socket.addEventListener("open", (event) => {
      this.dispatch("open", event);
    });

    this.socket.addEventListener("message", (event) => {
      this.dispatch("message", event);
    });

    this.socket.addEventListener("error", (event) => {
      this.dispatch("error", event);
    });

    this.socket.addEventListener("close", (event) => {
      this.dispatch("close", event);
    });

    //keepalive
    setInterval(() => {
      if (this.connected) {
        this.send('keep-alive');
      }
    }, 30000); // Invia un messaggio ogni 30 secondi
  }

  onOpen() {
    // Esegui azioni quando la connessione WebSocket è aperta
  }

  onMessage(data) {
    // Esegui azioni quando viene ricevuto un messaggio
  }

  onClose(code, reason) {
    // Esegui azioni quando la connessione WebSocket è chiusa    
  }

  onError(error) {
    // Esegui azioni quando si verifica un errore
  }

  send(type, data) {   
    if(this.socket.readyState === WebSocket.OPEN) {
      if (type === 'keep-alive') {
        this.socket.send(type);
      } else {
        this.socket.send(JSON.stringify({ type: type, value: data }));
      }
    }
  }

  close(code, reason) {
    this.socket.close(code, reason);
  }

  get readyState() {
    return this.socket.readyState;
  }

  get connected() {
    return this.socket.readyState === WebSocket.OPEN;
  }

  get id() {
    return this._id;
  }

  set id(newId) {
    this._id = newId
  }

  addEventListener(eventType, listener) {
    if (!this.eventListeners[eventType]) {
      this.eventListeners[eventType] = [];
    }
    this.eventListeners[eventType].push(listener);
  }

  dispatch(eventType, event) {
    if (this.eventListeners[eventType]) {
      this.eventListeners[eventType].forEach((listener) => {
        listener(event);
      });
    }
  }

  hasListener(eventType) {
    return !!this.eventListeners[eventType];
  }

  hasMessageListener(messageType) {
    if(!messageType) return false;
    const hasListener = this.messageCallbacks[messageType];
    
    return !!hasListener;
  }

  on(eventName, callback) {
    if (!this.messageCallbacks[eventName]) {
      this.messageCallbacks[eventName] = [];
    }
    this.messageCallbacks[eventName].push(callback);
  }

  off(eventName, callback) {
    if (this.messageCallbacks[eventName]) {
      const eventListeners = this.messageCallbacks[eventName];
      const index = eventListeners.indexOf(callback);
      if (index !== -1) {
        eventListeners.splice(index, 1);
      }
    }
  }
}
