import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

//import translations
import { withTranslation } from 'react-i18next';

import { appPaths } from '../../config/paths';
import { getActiveLayout, getActiveLayoutServerCodenames, getActiveLayoutShareList, getLayoutUserList, getUserlistPending } from "../../reducers/layoutsReducer";
import fetchUserlist from "../../actions/fetchLayoutUserlist";
import Spinner from "../Spinner/Spinner";
import ArtecoSwitch from "../ArtecoSwitch/ArtecoSwitch";
import Scrollbars from "react-custom-scrollbars";
import { updateLayout } from "../../actions/layoutActions";
import { customEvents } from "../../config/eventsAndStorage";
import { XSquare as StopSharingIcon } from "react-feather";
import { Eye as OnlySharedIcon } from "react-feather"
import { ReactComponent as BroadcastIcon } from './svg/aggiorna_layout_condiviso.svg';
import { getAuthentication } from "../../reducers/authReducer";
import { getErrrors } from "../../reducers/errorReducer";

class ShareLayoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      userList: {},
      mounted: false,
      showOnlyShared: false
    }

  }

  componentDidMount() {
    const { activeLayout, fetchUserlist } = this.props
    const outsideClick = document.querySelector('.outside-click');
    if (outsideClick) {
      document.body.removeChild(outsideClick);
    }

    fetchUserlist(activeLayout);
  }




  cancel = (e) => {
    e.preventDefault();
    const { isPopup, cancelCallback } = this.props

    if (!isPopup) {
      window.location.href = appPaths.dashboard
    }

    if (cancelCallback) {
      cancelCallback();
    }

    return false;
  }

  shouldComponentRender = () => {
    const { activeLayout, userListPending, isPopup, layoutToShare, shareList, userList } = this.props;

    if (!userList) return false;
    if (isPopup && !layoutToShare) return false;
    if (!isPopup && !activeLayout) return false;
    if (!shareList) return false;

    if (userListPending) return false;

    return true;
  }

  handleSwitchChange = (checked, id) => {

    const { userList } = this.state

    const updatedUserList = {
      ...userList
    }

    updatedUserList[id] = checked;

    this.setState({
      userList: updatedUserList
    });
  }

  submitChanges = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { layoutToShare, submitCallback, updateLayout, activeLayoutServerCodenames } = this.props;

    //conto quelli rimossi dalla sharelist
    const oldShared = layoutToShare.shareList;
    const newShared = Object.keys(this.state.userList).filter(user => this.state.userList[user]); //solo quelli a true 


    const layoutToUpdate = {
      ...layoutToShare,
      shareList: newShared,
    }

    const noMoreShared = oldShared ? oldShared.filter(user => !newShared.includes(user)) : [];

    updateLayout(layoutToUpdate, true, submitCallback);

    const event = new CustomEvent(customEvents.sendLayoutUpdates, {
      'detail': {
        layout: layoutToUpdate,
        serverCodenames: activeLayoutServerCodenames
      }
    });
    document.dispatchEvent(event);

  }

  stopSharing = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { layoutToShare, submitCallback, updateLayout } = this.props;
    const layoutToUpdate = {
      ...layoutToShare,
      shareList: []
    }

    updateLayout(layoutToUpdate, true, submitCallback);

    const event = new CustomEvent(customEvents.sendLayoutUpdates, {
      'detail': {
        layout: layoutToUpdate
      }
    });
    document.dispatchEvent(event);
  }

  toggleShowOnlyShared = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showOnlyShared: !this.state.showOnlyShared
    })
  }

  componentDidUpdate(prevProps) {

    if (this.props.userList && !this.state.mounted) {
      const { userList, shareList } = this.props;

      const updatedUserList = {};

      userList.map(user => {
        if (shareList && shareList.includes(user._id)) updatedUserList[user._id] = true;
        return false
      })

      this.setState({
        mounted: true
      }, () => {
        this.setState({
          userList: updatedUserList
        })
      })

    }
  }

  onChange = (event) => {
    this.setState({ search: event.target.value });
  }

  filterFunction = (user) => {
    const { search } = this.state;
    if (search == "") {
      return true;
    }
    const textMatch = (user && user.name && search) ? user.name.toUpperCase().indexOf(search.toString().toUpperCase()) > -1 : true;

    return textMatch;
  }

  applySharingfilter = (user) => {
    const { showOnlyShared, userList } = this.state;
    if (!showOnlyShared) {
      return true;
    }

    const sharedMatch = (userList[user._id]) ? true : false;

    return sharedMatch;
  }

  render() {
    const { t, isPopup, activeLayout, layoutToShare, userList, shareList } = this.props;
    const { errors, showOnlyShared } = this.state;

    const additionalClass = isPopup ? 'arteco-popupshare-popup-container' : 'col-md-4 mb-4';
    const additionalHeaderClass = isPopup ? 'arteco-popupshare-popup-wrapper' : '';

    const currentLayout = isPopup ? layoutToShare : activeLayout;

    let title= document.title.split('|')[0].trim();

    if (!this.shouldComponentRender()) {

      return (
        <div>
          <Helmet>
            <title>{title} | {t("LAYOUT_SHARE_LAYOUT_BTN_SHARE")}</title>
          </Helmet>
          <div className={`generic-popup ${additionalClass}`}>
            <Spinner />
            <div className="row mb-1 d-flex justify-content-center pt-3">
              <a onClick={this.cancel} type="button" className="btn btn-large mr-2">
                {t("LAYOUT_SHARE_LAYOUT_BTN_CANCEL")}
              </a>
            </div>
          </div>
        </div>
      )
    }

    const StopSharing = (shareList && shareList.length > 0) && (
      <a onClick={this.stopSharing} type="button" className="btn icon-btn stop-sharing">
        <StopSharingIcon />
        {t("LAYOUT_SHARE_STOP_SHARING")}
      </a>
    );

    const ShareButton = (shareList && shareList.length > 0) ? (
      <button type="submit" className="btn icon-btn share-button">
        <BroadcastIcon />
        {t("LAYOUT_SHARE_LAYOUT_BTN_EDIT_UPDATE")}
      </button>
    ) : (
      <button type="submit" className="btn share-button">
        {t("LAYOUT_SHARE_LAYOUT_BTN_EDIT_SHARE")}
      </button>
    )


    const showingOnlySharedClass = showOnlyShared ? 'active' : '';
    const showOnlyshared = (shareList && shareList.length > 0) && (
      <a onClick={this.toggleShowOnlyShared} className={`btn icon-btn toggle-sharing ${showingOnlySharedClass}`}>
        <OnlySharedIcon />
      </a>
    )

    const UserShareList = (userList && userList.length > 0) ? (
      <div className="col-md-12">
        <div className="arteco-form-inner">
          <div className="form-group">
            <p>{t('SHARE_LAYOUT_WITH', { layoutName: activeLayout.label.substring(0, 40) + '...' })}</p>
            <div className="user-search">
              <input
                value={this.state.search}
                onChange={this.onChange}
                id="share_layout_search"
                type="text"
                className={`search-input form-control`}
                placeholder={`${t('FILTER_USERS')}...`}
              />
              {showOnlyshared}
            </div>
            <div className="user-list">
              <Scrollbars>
                {
                  userList.filter(this.applySharingfilter).filter(this.filterFunction).map(user => {
                    return (
                      <div className="form-group user-switch" key={user._id}>
                        <label>{user.name} <small>({user.email})</small></label>
                        <ArtecoSwitch
                          id={user._id}
                          onHandleColor="#fff"
                          offHandleColor="#ccc"
                          onChange={this.handleSwitchChange}
                          checked={this.state.userList[user._id] || false}
                        />
                      </div>
                    )
                  })
                }
              </Scrollbars>
            </div>
          </div>
        </div>
        <div className="ctas">
          <a onClick={this.cancel} type="button" className="btn">
            {t("LAYOUT_SHARE_LAYOUT_BTN_CANCEL")}
          </a>
          {StopSharing}
          {ShareButton}
        </div>
      </div>
    ) : (
      <div className="col-md-12">
        <div className="arteco-form-inner">
          <div className="form-group">
            <p>{t('SHARE_LAYOUT_NO_USERS')}</p>
          </div>
        </div>
        <div className="ctas">
          {StopSharing}
          <a onClick={this.cancel} type="button" className="btn">
            {t("LAYOUT_SHARE_LAYOUT_BTN_CANCEL")}
          </a>
        </div>
      </div>
    )

    return (

      <div>
        <Helmet>
          <title>uSee | Share layout</title>
        </Helmet>
        <div className={`generic-popup ${additionalClass}`}>
          <form noValidate onSubmit={(e) => this.submitChanges(e, currentLayout)}>
            <div className={`arteco-form-group ${additionalHeaderClass}`}>
              <h4 className="arteco-form-title main-title share-title">{t('LAYOUT_SHARE_TITLE')}</h4>
              <div className="row">
                {UserShareList}
              </div>
            </div>
          </form>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: getAuthentication(state),
  layouts: state.layouts,
  activeLayout: getActiveLayout(state),
  activeLayoutServerCodenames: getActiveLayoutServerCodenames(state),
  shareList: getActiveLayoutShareList(state),
  userList: getLayoutUserList(state),
  userListPending: getUserlistPending(state),
  errors: getErrrors(state)
});

export default connect(
  mapStateToProps,
  { fetchUserlist, updateLayout }
)(withRouter(withTranslation()(ShareLayoutForm)));