import {
  FETCH_TAGS_PENDING,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR,
  TAGS_UPDATED,
  TAGS_REMOVE
} from "../actions/types";
import { info, logger } from "../helpers/logger";


const initialState = {
  pendingTags: false,
  deviceTagsAndNotes: {},
  suggestions: {},
  allTags: [],
  errors: {}
};

export function TagsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TAGS_PENDING:
      return {
        ...state,
        pendingTags: true
      }
    case FETCH_TAGS_SUCCESS:

      const foundTags = Array.isArray(action.payload.tags) && action.payload.tags.length > 0;

      let deviceTagsAndNotes = {};

      if(foundTags) {
        deviceTagsAndNotes = action.payload.tags.find((tags) => tags.artecoId === action.payload.artecoId);
      }

      let allTags = [];
      action.payload.tags.map(entry => {
        const tags = entry.tags;
        if(tags && Array.isArray(tags)) {
          tags.map(singleTag => {
            allTags.push(singleTag.text);
          });        
        }
      })
      
      let suggestions = {};

      try {
        allTags.map(singleTag => {          
  
          suggestions[singleTag] = action.payload.tags.map(entry => {
            const found = entry.tags && entry.tags.find(tag => tag.text === singleTag);
            if(found) {
              return entry.artecoId;
            }
  
            return false;
          }).filter(artecoId => artecoId);

        });
      }
      catch(err) {
        logger(info, 'tagReducer',err);        
      }

      

      return {
        ...state,
        pendingTags: false,
        deviceTagsAndNotes: deviceTagsAndNotes || {},
        suggestions: suggestions,
        allTags: action.payload.tags,
        errors: {}
      }
    case TAGS_UPDATED:
      return {
        ...state,
        pendingTags: false,
        deviceTagsAndNotes: action.payload,                
        errors: {}
      }  
    case TAGS_REMOVE: 
    return {
      ...state,
      pendingTags:false,
      deviceTagsAndNotes:{},
      allTags: state.allTags.filter(tag => tag.artecoId !== action.payload.artecoId),
      errors: {}

    }
    case FETCH_TAGS_ERROR:
      return {
        ...state,
        pendingTags: false,
        error: action.error
      }
    default:
      return state;
  }
}

export const getTagsPending = state => state.tags.pendingTags;
export const getDeviceTagsAndNotes = state => state.tags.deviceTagsAndNotes;
export const getAllTags = (state, deviceIds) => {  
  return deviceIds && Array.isArray(deviceIds) ? state.tags.allTags.filter(tag => deviceIds.includes(tag.artecoId)) : state.tags.allTags;  
}

export const getFilteredTags = (state, deviceIds) => {
  const filteredSuggestions =  Object.keys(state.tags.suggestions).map(tag => {
      return state.tags.suggestions[tag].filter(deviceId => deviceIds.includes(deviceId))    
  })

  const filteredTags = Object.keys(state.tags.suggestions).filter((tag, index) => filteredSuggestions[index].length > 0);

  return filteredTags;
}

export const getSuggestions = (state, deviceIds) => {
  if(!state.tags.suggestions) return [];    

  const filteredTags = getFilteredTags(state, deviceIds);

  return filteredTags.sort();
}
export const getTagCounts = (state, deviceIds) => {
  if(!state.tags.suggestions) return {};  

  const filteredTags = getFilteredTags(state, deviceIds);

  const filtered = Object.keys(state.tags.suggestions)
  .filter(key => filteredTags.includes(key))
  .reduce((obj, key) => {
    obj[key] = state.tags.suggestions[key].filter(deviceId => deviceIds.includes(deviceId));
    return obj;
  }, {});

  return filtered;
} 