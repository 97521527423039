import {
  FETCH_PLUGINS_PENDING,
  FETCH_PLUGINS_SUCCESS,
  FETCH_PLUGINS_ERROR,
  PLUGINS_UPDATED,
  PLUGIN_REMOVED,
  PLUGIN_ADDED
} from "../actions/types";
import { info, logger } from "../helpers/logger";
import { getIntegrationsByArtecoId } from "./integrationsReducer";

const initialState = {
  pendingPlugins: false,
  allPlugins: [],
  errors: {}
};

export function PluginsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PLUGINS_PENDING:
      return {
        ...state,
        pendingPlugins: true
      }
    case FETCH_PLUGINS_SUCCESS:

      const newPlugins = {
        ...state.allPlugins
      }

      action.payload.plugins.forEach(plugin => {
        let listOfPlugins = {};
        plugin.plugins.forEach(subPlugin => {
          listOfPlugins[subPlugin.brand] = subPlugin;
        })
        newPlugins[plugin.artecoId] = listOfPlugins;
      });


      return {
        ...state,
        pendingPlugins: false,
        allPlugins: newPlugins,
        errors: {}
      };

    case PLUGINS_UPDATED:

      const newPluginsAfterUpdate = {
        ...state.allPlugins
      }

      const pluginsList = {};

      action.payload.plugins.forEach(plugin => {
        pluginsList[plugin.brand] = plugin;
      });

      newPluginsAfterUpdate[action.payload.artecoId] = pluginsList;


      return {
        ...state,
        pendingPlugins: false,
        allPlugins: newPluginsAfterUpdate,
        errors: {}
      }
    case FETCH_PLUGINS_ERROR:
      return {
        ...state,
        pendingPlugins: false,
        error: action.error
      }
    default:
      return state;
  }
}

export const getPluginsPending = state => state.plugins.pendingPlugins;
export const getAllPlugins = state => state.plugins.allPlugins;

export const getPluginsByArtecoId = (state, artecoId) => {

  const listOfPluginsForThisArtecoId = state.plugins.allPlugins[artecoId];

  //adding integrations
  const integrationsForThisArtecoID = getIntegrationsByArtecoId(state, artecoId);

  const mergedPlugins = {...listOfPluginsForThisArtecoId, ...integrationsForThisArtecoID}

  return mergedPlugins;
}

export const getPluginsNamesByArtecoId = (state, artecoId) => {

  const listOfPluginsForThisArtecoId = getPluginsByArtecoId(state, artecoId);
  const listOfNames = listOfPluginsForThisArtecoId != undefined ? Object.keys(listOfPluginsForThisArtecoId) : [];
  
  return listOfNames;
}
