import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getDeviceByArtecoId, getServerByCodeName } from "../../../reducers/serversReducer";
import { PER_TYPE } from "../../dashboard/DeviceList/DeviceInfoVariables";
import ExternalIO from "./ExternalIO";
import Pin from "./Pin";
class LinkedPeripherals extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.nodeRef = React.createRef();
  }
  shouldComponentRender = () => {
    const { device, peripheral } = this.props;
    return device != undefined;
  };
  render() {
    const { device, server, peripheral } = this.props;

    if (!this.shouldComponentRender()) {
      return <></>;
    }
    if (device?.subType == PER_TYPE.ATCIOEXT) {
      return (
        <div ref={this.nodeRef} className="linkedPeripheral-display">
          <ExternalIO device={device} serverId={server._id} server={server} />
        </div>
      );
    }
    if (device.subType == PER_TYPE.ATCARTECOEVERYWHERE) {
      return (
        <>
          {peripheral.visiblePins.map((pin, index) => (
            <div
              key={index}
              ref={this.nodeRef}
              className="linkedPeripheral-display"
            >
              <Pin device={device} pin={pin} serverId={server._id} server={server} />
            </div>
          ))}
        </>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  const device = getDeviceByArtecoId(state, ownProps.peripheral.artecoId);
  const server = device && getServerByCodeName(state, device.serverCodename)

  return {
    device,
    server
  };
};

export default connect(mapStateToProps)(withTranslation()(LinkedPeripherals));