import axios from 'axios';
import {error, logger} from '../helpers/logger';

import {
  MAP_SERVER_REMOVED,
  MAP_SHOULD_ZOOM
} from './types';


export const removeMapsFromThisServer = (serverData) => dispatch => {
  axios
    .post('/api/maps/delete-server/', serverData)
    .then(res => {
      dispatch({
        type: MAP_SERVER_REMOVED,
        payload: serverData
      });
      return res.data;
    })
    .catch(e => {
       logger(error,'removeMapFromThisServer', e);
    })
}

export const zoomOnDevices = (devicesToZoom, shouldScroll) => dispatch => {
  const payload = {
    devicesToZoom,
    shouldZoom: true,
    shouldScroll
  }
  dispatch({
    type: MAP_SHOULD_ZOOM,
    payload: payload
  })
}

export const zoomDone = () => dispatch => {  
  dispatch({
    type: MAP_SHOULD_ZOOM,
    payload: {
      shouldZoom: false,
      shouldScroll: false,
      devicesToZoom: []
    }
  })
}
