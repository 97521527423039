import React, { Component } from 'react';
import { ReactComponent as SpeakerOn } from '../../components/svgIcons/volume-high.svg';
import { ReactComponent as SpeakerOff } from '../../components/svgIcons/volume-off.svg';

export class AudioPlugin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {toggleAudio,isAudioActive} = this.props.children;
    const AudioIcon = !isAudioActive ? <SpeakerOff size={32} /> : <SpeakerOn size={32} />;
    return (
      <>
        <button type="button" className={`media-control-button media-control-icon audio-player-trigger`} aria-label="audio plugin button" onClick={toggleAudio}>
          {AudioIcon}
        </button>
      </>
    )
  }
}
export default AudioPlugin
