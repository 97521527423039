import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import Add from '../layouts/Add';

//actions
import { getAddingLayoutGroupName, isAddLayoutPopupShown } from '../../reducers/featuresReducer';
import { showAddLayoutPopup } from '../../actions/featuresActions';

export class AddLayout extends Component {
  

  closePopup = () => {
    const { showAddLayoutPopup } = this.props;

    showAddLayoutPopup(false);
  }

  render() {
    const { isAddLayoutPopupShown, layoutGroupName } = this.props;

    return (
      <Popup
        open={isAddLayoutPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='arteco-popupediting-popup'>
          <Add 
            isPopup={true} 
            groupName={layoutGroupName}
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isAddLayoutPopupShown: isAddLayoutPopupShown(state),
  layoutGroupName: getAddingLayoutGroupName(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showAddLayoutPopup: (show) => dispatch(showAddLayoutPopup(show)),
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddLayout)