import { artecoVars } from "../../../config/MUIThemes";


export const style = {

    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    container: (base) => ({
      ...base,
      height: "auto",
      minHeight: "auto"
    }),
    valueContainer: (base) => ({
      ...base,
      height: "auto",
      minHeight: 25,
      paddingTop:0,
      paddingBottom:0,
    }),
    control: (base, state) => ({
      ...base,
      background: artecoVars.reskin_grey_2,
      borderRadius: '4px',
      border: 0,
      boxShadow: 'none',
      fontSize: artecoVars.font_size_text,
      color: artecoVars.reskin_grey_2,
      height: "auto",
      minHeight: "auto",
      cursor: 'pointer',
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      background: artecoVars.reskin_grey_2
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      paddingBottom: 0,
      fontSize: 10,
      height: "auto",
      minHeight: "auto",
      maxHeight: "250px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? data.color
            : isFocused
              ? artecoVars.arteco_red
              : null
      };
    },
    singleValue: base => ({
      ...base,
      color: artecoVars.device_online
    }),
    input: base => ({
      ...base,
      color: artecoVars.device_online
    }),
    placeholder: base => ({
      ...base,
      color: artecoVars.device_offline,
    }),

    groupHeading: base =>({
      ...base,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize:artecoVars.font_size_text,
      fontWeight: 'normal',
      color: '#EBECF0',
      lineHeight: '1',
      textAlign: 'center',
    })
  
  
  };