import { appPaths } from "../config/paths";

export function getLanAddress(url) {

  if(!url) {
    return '';
  }

  return url.replace(appPaths.PUBLIC_HOST, appPaths.LAN_HOST);

}

export function timeoutPromise(ms, promise, error) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(error)
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  })
}

export function getWanAddress(url) {

  if(!url) {
    return '';
  }

  return url.replace(appPaths.LAN_HOST, appPaths.PUBLIC_HOST);

}

export function getServerUrlByConnectionType(server) {

  if (server.connectionType === "LAN") {

    return getLanAddress(server.ip);

  }

  return server.ip;
}

export function getOemClass() {
 
    const host = window.location.hostname; //stampa example.com
    let oemHosts = process.env.REACT_APP_OEM_HOSTS ?  process.env.REACT_APP_OEM_HOSTS.split(',').map(item => item.trim()) : [];
    const oemClasses = process.env.REACT_APP_OEM_CLASSES ? process.env.REACT_APP_OEM_CLASSES.split(',').map(item => item.trim()) : [];
    //check if the current url is an oem url and set the oem class
    if(oemHosts.length > 0 && oemClasses.length > 0) {
      let oemIndex = oemHosts.findIndex(envUrl => host === envUrl);
      if (oemIndex !== -1) {
       return `oem-${oemClasses[oemIndex]}`;
      }else{
        return null;
      }
    }
    return null;
}

export function getHostClassNameClientEnv(hostname) {

  const hostsEnv = process.env.REACT_APP_OEM_HOSTS;
  const classesEnv = process.env.REACT_APP_OEM_CLASSES;
  const hostsArray = (hostsEnv  && hostsEnv !== "") ? hostsEnv.split(',').map(item => item.trim()) : [];
  const classesArray = (classesEnv  && classesEnv !=="") ? classesEnv.split(',').map(item => item.trim()) : [];

  if(hostsArray.length> 0 && classesArray.length > 0){
    const index = hostsArray.indexOf(hostname);
    if (index < classesArray.length) {
      return classesArray[index];
    }
  }
  return null;
}

export function getOemPageTitleFromClientEnv(hostname) {
 
  const hostsEnv = process.env.REACT_APP_OEM_HOSTS;
  const titlesEnv = process.env.REACT_APP_OEM_PAGE_TITLE;
  const hostsArray = (hostsEnv  && hostsEnv !== "") ? hostsEnv.split(',').map(item => item.trim()) : [];
  const titlesArray = (titlesEnv  && titlesEnv !=="") ? titlesEnv.split(',') : [];

  if(hostsArray.length> 0 && titlesArray.length > 0){
    const index = hostsArray.indexOf(hostname);
    if (index < titlesArray.length) {
      return titlesArray[index];
    }
  }
  return null;
}

export function isProductionFz() {
  const productionHosts = ['omniaweb.cloud','vms.usee.cloud','usp.inquiris.it'];
  const host = window.location.hostname;
  return productionHosts.includes(host);
}