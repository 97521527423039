import React, { Component } from "react";

class Spinner extends Component {

  render() {

    const { size } = this.props
    return (
      <div className={`preloader-wrapper active ${size}`}>
        <div className="spinner-layer">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div><div className="gap-patch">
            <div className="circle"></div>
          </div><div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spinner