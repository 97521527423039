/** @format */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

//import translations
import { withTranslation } from 'react-i18next';
import { setLayoutPopupMode } from '../../actions/layoutActions';
import { appPaths } from '../../config/paths';

import Switch from 'react-switch';
import { getActiveLayout } from '../../reducers/layoutsReducer';
import ArtecoSwitch from '../ArtecoSwitch/ArtecoSwitch';
import { getAuthentication } from '../../reducers/authReducer';

class LayoutPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      isActive: true,
      timer: 20,
      disableTimer: false,
      isSubmitted: false,
      currentLayout: {}      
    };
    this.popupTimer = this.popupTimer.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }


  setActive = (mode) => {
    this.setState({
      isActive: mode
    })
  }

  popupTimer = (e) => {
    this.setState({
      timer: e.target.value,
    });
  };

  componentDidMount() {
    const { activeLayout, layoutToEdit } = this.props;

    const currentLayout = layoutToEdit || activeLayout;

    this.setState({
      timer:
        currentLayout && currentLayout.popupMode
          ? currentLayout.popupMode.time
          : this.state.timer,
      isActive:
        currentLayout && currentLayout.popupMode && currentLayout.popupMode.active
          ? true
          : false,
    });
  }

  submitChanges(e) {
    const { auth, activeLayout, layoutToEdit, setLayoutPopupMode, submitCallback } = this.props;    

    e.preventDefault();

    const currentLayout = layoutToEdit || activeLayout;
    
    this.setState({
      isSubmitted: true,
    }, () => {
      const layoutToUpdate = {        
        _id: currentLayout._id,
        isActive: this.state.isActive ? this.state.isActive : false,
        time: this.state.isActive ? this.state.timer : 20,
      };
  
      setLayoutPopupMode(layoutToUpdate, submitCallback);      
    });
  }

  cancel = (e) => {
    e.preventDefault();
    const { isPopup, cancelCallback } = this.props

    if(!isPopup) {
      window.location.href = appPaths.dashboard
    }

    if(cancelCallback) {
      cancelCallback();
    }

    return false;
   }

  handleSwitchChange(checked) {
    this.setActive(checked);
  }

  render() {
    const { t, layouts, isPopup } = this.props;

    const additionalClass = isPopup ? 'arteco-popupediting-popup-container' : 'col-md-4 mb-4';
    const additionalHeaderClass = isPopup ? 'arteco-popupediting-popup-wrapper' : '';


    if(!isPopup) {
      if (layouts.layouts.length == 0) {
        return <Redirect push to={{pathname: appPaths.dashboard}} />;
      }
      if (this.state.isSubmitted) {
        window.location.href = appPaths.dashboard;
      }
    }
    
    return (
      <div>
        <div className={`generic-popup ${additionalClass}`}>
          <form onSubmit={this.submitChanges}>
          <div className={`arteco-form-group ${additionalHeaderClass}`}>
              <h4 className="arteco-form-title">{t('LAYOUT_POPUP_MODE')}</h4>
              <div className='arteco-form-inner'>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div key={`default-radio`} className="mb-3">
                        <ArtecoSwitch 
                          onChange={this.handleSwitchChange} 
                          checked={this.state.isActive} 
                        />
                      </div>
                      <div>
                        <label htmlFor="popup-timer">
                          {t('LAYOUT_POPUP_TIMER')}
                        </label>
                        <input
                          className={'form-control current-layout-name'}
                          onChange={this.popupTimer}
                          id="popup-timer"
                          type="number"
                          value={this.state.timer}
                          disabled={!this.state.isActive}
                        />
                        <small className="text-danger">{this.state.timer < 10 && this.state.isActive ? t('LAYOUT_POPUP_TIMER_ERROR') : null}</small>
                        <small className="text-danger">{this.state.timer > 86400 && this.state.isActive ? t('LAYOUT_POPUP_MAX_TIMER_ERROR') : null}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-1 d-flex justify-content-center pt-3">
                <a
                  onClick={this.cancel}
                  type="button"
                  className="btn btn-large mr-2"
                >
                  {t('CANCEL_BUTTON')}
                </a>
                {this.state.isActive ? (
                  <button type="submit" className="btn btn-large" disabled={!this.state.isActive || this.state.timer < 10 || this.state.timer > 86400}>
                    {t('ENABLE_BUTTON_LAYOUT_POPUP_MODE')}
                  </button>
                ) : (
                  <button type="submit" className="btn btn-large">
                    {t('DISABLE_BUTTON_LAYOUT_POPUP_MODE')}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuthentication(state),
  layouts: state.layouts,
  activeLayout: getActiveLayout(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLayoutPopupMode: (layoutInfos, submitCallback) => dispatch(setLayoutPopupMode(layoutInfos, submitCallback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(LayoutPopup)));
