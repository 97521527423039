


const initialState = {    
    device: {},
    loading: false
};

export function DeviceReducer(state = initialState, action) {
    switch (action.type) {
        // case DEVICE_INFO:
        //     let deviceType = action.payload.type;
        //     let props = action.payload.properties;
        //     switch (deviceType) {
        //         case "server":
        //             return {
        //                 ...state,                        
        //                 selectedDevice: {
        //                     type: deviceType,
        //                     properties: {
        //                         name: props.name,
        //                         timezone: props.timezone,
        //                         ip: `${props.ip}:${props.port}`,                                
        //                         serial: props.license?.serial,
        //                         version: `${props.license?.base} (${props.software?.mainversion}.${props.software?.mainversion}.${props.software?.releaseversion}.b${props.software?.buildnum})`,                                
        //                         accessType: (props.public) ? 'Public' : 'Private',
        //                         channelsNum: props.channels.length,
        //                         peripheralsNum: props.peripherals.length,
        //                         connectionStatus: props.connectionStatus,
        //                         status: props.status                                
        //                     }
        //                 },
        //             };     
        //         case "camera":
        //             return {
        //                 ...state,
        //                 selectedDevice: {
        //                     type: deviceType,
        //                     properties: {   
        //                         serverIp: props.serverIp,  
        //                         serverId: props.serverId,                           
        //                         name: props.descr,
        //                         channelId: props.id,
        //                         type: props.atcSrcType,
        //                         rtspMain: props.rtspMain,
        //                         rtspSub: props.rtspSub,
        //                         hlsMain: `http://${props.serverIp}:8080/hls/${props.id}/stream.m3u8`,
        //                         streamType: props.streamType,
        //                         lat: props.lat,
        //                         long: props.long
        //                     }
        //                 }   
        //             }
        //         default:
        //             return {
        //                 ...state,
        //                 selectedDevice: action.payload
        //             };
        //     }
        default:
            return state;
    }
}

//export const getSelectedDevice = state => state.device.selectedDevice;