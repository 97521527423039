import {
  SET_CURRENT_USER,
  USER_LOADING,
  AUTHENTICATION_ERROR,
  USER_INFO_UPDATE,
  UPDATE_USER_SITES,
  UPDATE_USER_LIST,
  UPDATE_USER_POSITION,
  RESET_AUTHENTICATION_ERROR
} from "../actions/types";
import { ChatVideocall } from "../helpers/serverCredentials";
import { OmniaLight, getServers, isCredentialEnabled } from "./serversReducer";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  error: {
    gotError: false,
    code: 0,
    message: ''
  },
  user: {},
  loading: false,
};

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        error: {
          gotError: true,
          code: action.payload.code,
          message: action.payload.message
        }
      };
    case RESET_AUTHENTICATION_ERROR:
      return {
        ...state,
        error: {
          gotError: false,
          code: 0,
          message: ''
        },
      };
    case USER_INFO_UPDATE: {
      return {
        ...state,
        user: {
          ...state.user,
          socketId: action.payload.socketId,
        }
      }
    }
    case UPDATE_USER_SITES: {
      const data = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          sites: data.sites || state.user.sites,
          site_users: data.site_users || state.user.site_users
        }
      }
    }
    case UPDATE_USER_POSITION:
      const usersWithPosition = state.user.visible_users.map(user => {
        if (user.socketId !== action.payload.socketId) return user;
        return {
          ...user,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude
        }
      })

      return {
        ...state,
        user: {
          ...state.user,
          visible_users: usersWithPosition
        }
      }
    case UPDATE_USER_LIST: {
      const data = action.payload && action.payload.userList;
      const users = Object.values(data);
      let visibleUsers = users && users.length > 0 && users.filter(user => user && user.visible);   
      if(state.user.site_users) {        
        visibleUsers = visibleUsers.filter(user => state.user.site_users.includes(user.email));
      } else {
        visibleUsers = [];
      }
           
      //the current user is not present in arteco-global SSO response, putting it back
      const myUser = users.filter(user => user && (user.email === state.user?.email)); 

      
      
      //using filter instead of find since it's possible to have several instances of the current user (different sockets, same email) if 
      //the user opened several tabs.
      //since they've got the same email we pick just the first to check if we already added it
      const alreadyIn = myUser && myUser[0] && visibleUsers.find(user => user.email === myUser[0].email);

      if (myUser && !alreadyIn) {
        visibleUsers = visibleUsers.concat(myUser);
      }
      return {
        ...state,
        user: {
          ...state.user,
          visible_users: visibleUsers
        }
      }
    }
    default:
      return state;
  }
}

export const getAuthentication = state => state.auth;
export const getAuthenticationError = state => state.auth.error;
export const getAuthenticationUserId = state => state.auth.user.id;
export const getAuthenticationUser = state => state.auth.user;

export const checkExpiredUser = (state) => {
  return false;
  let today = new Date().toISOString();
  if (state.auth.user.expiration_date < today || state.auth.user.is_active === false) {
    return true
  }
}
export const getServerConnectedUsers = (state, splitUsersByDeviceClass = false) => {
  const isChatEnabled = isAbleToChat(state);
  if (!isChatEnabled) return [];

  //get the first occurrence of every user by email, the backend will broadcast the same user with different sockets
  //if the user opened several tabs
  //if the same email is present in several sockets, if splitUsersByDeviceClass is true, we will pick the same user once for mobile and once for desktop, 
  //else we will pick just the first occurrence
  let seenEmails = {};
  let filteredUsers = state.auth.user.visible_users ? state.auth.user.visible_users.filter((item) => {
      // create a key for each user based on email and isMobile or just the email according to the splitUsersByDeviceClass flag
      let key = splitUsersByDeviceClass ? `${item.email}-${item.isMobile}` : item.email;
      if (seenEmails[key]) {
          return false;
      } else {
          seenEmails[key] = true;
          return true;
      }
  }) : [];
  
  return filteredUsers || [];
} 

export const getUserBySocketId = (state, socketId) => {
  if(!socketId) return null; 
  return state.auth.user.visible_users && state.auth.user.visible_users.find(user => user.socketId === socketId)
}

export const getUserByEmail = (state, email) => {
  return state.auth.user.visible_users && state.auth.user.visible_users.find(user => user.email === email)
}

export const isAbleToChat = (state) => {
  const servers = getServers(state);
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return false;
  
  const isChatEnabled = servers && servers.servers?.find(server => {
    const canChatHere = isCredentialEnabled(ChatVideocall, server);

    return canChatHere;
  })
  return !!isChatEnabled;
}