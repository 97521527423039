export function isFilterSupported() {
  const canvas = document.createElement('canvas');
  if (!canvas.getContext) {
    return false;
  }

  const ctx = canvas.getContext('2d');
  return typeof ctx.filter !== 'undefined';
}


export const translatePoint = (point, canvas, privacyData) => {

  let transY = privacyData.frameHeight - point.pointY;
  let transX = point.pointX;

  let scaleY = (transY * canvas.height) / privacyData.frameHeight;
  let scaleX = (transX * canvas.width) / privacyData.frameWidth;

  return {
    pointX: scaleX,
    pointY: scaleY
  }
}

export const drawPrivacyZone = (points, ctx, canvas, privacyData, video, traceBorder = false) => {
  if (isFilterSupported()) {
    drawBlurredPrivacyZone(points, ctx, canvas, privacyData, video, traceBorder);
  } else {
    drawSemiTransparentPrivacyZone(points, ctx, canvas, privacyData, traceBorder);
  }
};

export const drawOnvifMetadata = (meta, ctx, canvas, video, traceBorder = false, objectId) => {
  const boundingBox = meta.BoundingBox;
  if(!boundingBox) {
    return false;
  }

  const isSelected = meta.ObjectId === objectId;

  // Ottieni le dimensioni del canvas e del video
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const videoWidth = video.videoWidth;
  const videoHeight = video.videoHeight;

  // Calcola la scala e il punto di origine (centro del canvas)
  const scaleX = canvasWidth / 2;
  const scaleY = canvasHeight / 2;
  const centerX = canvasWidth / 2;
  const centerY = canvasHeight / 2;

  // Trasforma le coordinate in percentuale in coordinate del canvas
  const left = centerX + (boundingBox.left * scaleX);
  const top = centerY - (boundingBox.top * scaleY);
  const right = centerX + (boundingBox.right * scaleX);
  const bottom = centerY - (boundingBox.bottom * scaleY);

  // Calcola la larghezza e l'altezza del rettangolo
  const width = right - left;
  const height = top - bottom;

  // Disegna il rettangolo
  if (isSelected) {
    ctx.strokeStyle = 'red';
    ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';
  } else {
    ctx.strokeStyle = traceBorder ? 'lime' : 'rgba(0, 0, 0, 0)';
    ctx.fillStyle = 'rgba(0, 0, 0, 0)';
  }
  ctx.lineWidth = 2;
  ctx.fillRect(left, bottom, width, height);
  ctx.strokeRect(left, bottom, width, height);

  const fontSize = (30 * canvasWidth) / 1920;
  const offset = (40 * fontSize) / 30;

  // Imposta lo stile del testo
  ctx.font = `${fontSize}pt Arial`;
  ctx.fillStyle = isSelected ? 'red' : 'lime';
  ctx.textBaseline = 'top';

  // Disegna il testo sopra al rettangolo
  const text = meta.Descr.text;
  //const text = meta.ObjectId;
  ctx.fillText(text, left, top - offset);

  return {
    left: left,
    top: top,
    right: right,
    bottom: bottom,
    width: width,
    height: height,
    objectId: meta.ObjectId
  };
};


export const drawSemiTransparentPrivacyZone = (points, ctx, canvas, privacyData, traceBorder) => {
  ctx.fillStyle = 'rgba(125, 125, 125, 1)';
  ctx.strokeStyle = traceBorder ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)';
  ctx.lineWidth = 2;

  // Inizia un nuovo percorso
  ctx.beginPath();

  const translatedPoints = points.map(point => translatePoint(point, canvas, privacyData));

  // Muovi al primo punto
  ctx.moveTo(translatedPoints[0].pointX, translatedPoints[0].pointY);

  // Disegna una linea verso ogni punto successivo
  for (let i = 1; i < translatedPoints.length; i++) {
    let currentPoint = translatedPoints[i];
    ctx.lineTo(currentPoint.pointX, currentPoint.pointY);
  }

  // Chiudi il percorso
  ctx.closePath();

  // Riempimento e tracciatura del poligono
  ctx.fill();
  ctx.stroke();
};

export const drawBlurredPrivacyZone = (points, ctx, canvas, privacyData, video, traceBorder) => {
  // Crea un canvas temporaneo
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = canvas.width;
  tempCanvas.height = canvas.height;
  const tempCtx = tempCanvas.getContext('2d');

  // Disegna il video sul canvas temporaneo
  if (video.videoWidth === 0 || video.videoHeight === 0 || tempCanvas.width === 0 || tempCanvas.height === 0) {
    return;
  }

  tempCtx.drawImage(video, 0, 0, canvas.width, canvas.height);

  // Applica l'effetto di sfocatura al canvas temporaneo
  tempCtx.filter = 'blur(10px)'; // Imposta l'intensità della sfocatura
  tempCtx.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);

  // Inizia un nuovo percorso sul canvas principale
  ctx.beginPath();

  const translatedPoints = points.map(point => translatePoint(point, canvas, privacyData));

  // Muovi al primo punto
  ctx.moveTo(translatedPoints[0].pointX, translatedPoints[0].pointY);

  // Disegna una linea verso ogni punto successivo
  for (let i = 1; i < translatedPoints.length; i++) {
    let currentPoint = translatedPoints[i];
    ctx.lineTo(currentPoint.pointX, currentPoint.pointY);
  }

  // Chiudi il percorso
  ctx.closePath();

  // Salva il contesto del canvas
  ctx.save();

  // Utilizza il poligono come maschera
  ctx.clip();

  // Disegna il canvas temporaneo sfocato sul canvas principale, all'interno della maschera
  ctx.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);

  // Ripristina il contesto del canvas
  ctx.restore();

  // Disegna il contorno del poligono
  if(traceBorder) {
    ctx.beginPath();
    ctx.moveTo(translatedPoints[0].pointX, translatedPoints[0].pointY);
    for (let i = 1; i < translatedPoints.length; i++) {
      let currentPoint = translatedPoints[i];
      ctx.lineTo(currentPoint.pointX, currentPoint.pointY);
    }
    ctx.closePath();
    ctx.strokeStyle = 'rgba(125, 125, 125, 0.99)';
    ctx.lineWidth = 1;
    ctx.stroke();
  }
};

export const highlightRect = (ctx, rectangle) => {
  // Disegna il bordo
  ctx.strokeStyle = 'rgba(255, 0, 0, 1)'; // ad esempio, lo rende giallo
  ctx.lineWidth = 2; // imposta la larghezza del bordo
  ctx.strokeRect(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);

  // Riempimento semi-trasparente
  ctx.save(); // salva lo stato attuale del contesto
  ctx.fillStyle = 'rgba(255, 0, 0, 0.2)'; // impostazione del colore di riempimento semi-trasparente
  ctx.fillRect(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);
  ctx.restore(); // ripristina lo stato originale del contesto
}


export const pointIsInRect = (x, y, rectangle) => {
  // Verifica se il punto è all'interno del rettangolo
  return x >= rectangle.left && x <= rectangle.right && y >= rectangle.top && y <= rectangle.bottom;
}

export const clearCanvas = (ctx, canvas) => {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
}

export const interpolateBoundingBoxes = (currentTime, metaBuffer) => {
  const times = Object.keys(metaBuffer).sort((a, b) => a - b);

  if (times.length === 0) return null;

  let previousTime = null;
  let nextTime = null;

  // Trova i metadati più vicini prima e dopo il tempo corrente
  for (let i = 0; i < times.length; i++) {
    if (times[i] <= currentTime) previousTime = times[i];
    if (times[i] > currentTime) {
      nextTime = times[i];
      break;
    }
  }

  // Se manca uno dei due metadati, non interpolare
  if (!previousTime || !nextTime) return metaBuffer[previousTime] || metaBuffer[nextTime];

  const previousMeta = metaBuffer[previousTime];
  const nextMeta = metaBuffer[nextTime];

  // Calcola la frazione temporale
  const timeRange = nextTime - previousTime;
  const timeFraction = (currentTime - previousTime) / timeRange;

  // Interpola ogni bounding box
  return previousMeta.map((prevMeta) => {
    const nextMetaForObject = nextMeta.find((m) => m.ObjectId === prevMeta.ObjectId) || prevMeta;

    const interpolatedBoundingBox = {
      left: interpolateValue(prevMeta.BoundingBox.left, nextMetaForObject.BoundingBox.left, timeFraction),
      top: interpolateValue(prevMeta.BoundingBox.top, nextMetaForObject.BoundingBox.top, timeFraction),
      right: interpolateValue(prevMeta.BoundingBox.right, nextMetaForObject.BoundingBox.right, timeFraction),
      bottom: interpolateValue(prevMeta.BoundingBox.bottom, nextMetaForObject.BoundingBox.bottom, timeFraction)
    };

    return {
      ...prevMeta,
      BoundingBox: interpolatedBoundingBox
    };
  });
};

const interpolateValue = (start, end, fraction) => start + (end - start) * fraction;

export const drawMeta = (time, video, traceBorder, canvasRef, stateUpdateCB, liveOrRec, metaBuffer, onvifMetadataHistory, onvifData = null, currentObjectId = null) => {
  if(time) {            
    const metaToDisplay = liveOrRec === 'live'
      ? interpolateBoundingBoxes(time, metaBuffer)  // Usa l'interpolazione per la modalità live
      : pickCurrentMeta(time, liveOrRec, metaBuffer, onvifMetadataHistory);  // Usa pickCurrentMeta per la modalità non live
    
    if (!metaToDisplay || metaToDisplay.length === 0) return;

    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);  // Cancella il canvas
    
    stateUpdateCB({ rectangles: [], lastTime: time });
    
    metaToDisplay.map(meta => {
      const rectangle = drawOnvifMetadata(meta, ctx, canvasRef.current, video, traceBorder, currentObjectId);
      
      stateUpdateCB(prevState => ({
        rectangles: [...prevState.rectangles, rectangle]
      }));
    });
  }
};


export const resizeCanvas = (videoWidth, videoHeight, nodeRef, metadataLoaded, lastVideoContainerWidth, lastVideoContainerHeight, lastvideoWidth, lastVideovideoHeight, stateUpdateCB, canvasRef) => { 

  const videoContainerWidth = nodeRef.clientWidth;
  const videoContainerHeight = nodeRef.clientHeight;

  if(
    metadataLoaded &&
    (
      videoContainerWidth !== lastVideoContainerWidth || 
      videoContainerHeight !== lastVideoContainerHeight ||
      videoWidth !== lastvideoWidth || 
      videoHeight !== lastVideovideoHeight
    )
  ) {

    stateUpdateCB({
      lastVideoContainerWidth: videoContainerWidth,
      lastVideoContainerHeight: videoContainerHeight,
      lastVideoWidth: videoWidth,
      lastVideoHeight:videoHeight 
    })

    drawCanvas(videoContainerWidth, videoContainerHeight, videoWidth, videoHeight, canvasRef);
  }

}

export const drawCanvas = (videoContainerWidth, videoContainerHeight, videoWidth, videoHeight, canvasRef) => {


  const videoRatio = videoWidth / videoHeight;      
  const containerRatio = videoContainerWidth / videoContainerHeight;

  //videoRatio < containerRatio = bande verticali
  //videoRatio > containerRatio = bande orizzontali
  //videoRatio = containerRatio = no bande
    
  if(videoRatio > containerRatio) {
    canvasRef.current && canvasRef.current.classList.add("vertical");
  } else {
    canvasRef.current && canvasRef.current.classList.remove("vertical");
  }

  // Set canvas height and width
  canvasRef.current.width = videoWidth;
  canvasRef.current.height = videoHeight;
  
}

export const pickCurrentMeta = (currentTime, liveOrRec, metaBuffer, onvifMetadataHistory) => {
  let meta = [];
  if(!currentTime) return meta;

  if(liveOrRec === 'live') {
    const currentMeta = metaBuffer[currentTime];
    
    meta = currentMeta || [];

    // Rimuovi i metadati precedenti dal buffer
    for(let timestamp in metaBuffer) {
      if(timestamp < currentTime) {
        delete metaBuffer[timestamp];
      }
    }
  }

  if(liveOrRec !== 'live') {
    
    if(onvifMetadataHistory) {
      const currentMeta = onvifMetadataHistory[currentTime];
  
      meta = currentMeta || [];

    } 
  }

  return meta;
}

export const rectangleClick = (server, device,  event, canvasRef, rectangles, popupOpenFunction, video, mode, currentObjectId, showActionsInOnvifPopup = false) => {
    // Ottieni le coordinate del click relative al canvas
  const rect = canvasRef.current.getBoundingClientRect();

  // Calcola i fattori di scala
  const scaleX = canvasRef.current.width / rect.width;
  const scaleY = canvasRef.current.height / rect.height;

  // Ottieni le coordinate del click relative al canvas e le trasforma in base alla scala
  const x = (event.clientX - rect.left) * scaleX;
  const y = (event.clientY - rect.top) * scaleY;

  // Controlla se il click è all'interno di uno dei rettangoli
  for (const rectangle of rectangles) {
    if (pointIsInRect(x, y, rectangle, canvasRef.current)) {
      //console.log(`Clicked on rect with ObjectId: ${rectangle.objectId}`);

      const rectangleHeight = Math.abs(rectangle.height);
      const rectangleWidth = Math.abs(rectangle.width);

      // Crea un nuovo canvas
      const newCanvas = document.createElement('canvas');
      newCanvas.width = rectangleWidth;
      newCanvas.height = rectangleHeight;

      const context = newCanvas.getContext('2d');
      context.drawImage(video, rectangle.left, rectangle.top, rectangleWidth, rectangleHeight, 0, 0, rectangleWidth, rectangleHeight);
      const imgUrl = newCanvas.toDataURL();

      const isSelected = currentObjectId === rectangle?.objectId;
      const device_Id = server.capabilities?.isHyperNode == 1 ? device.id : parseInt(device.id);
      popupOpenFunction(true, server, {
        objectId: rectangle.objectId,
        imgUrl: imgUrl,
        deviceId: device_Id,
        artecoId: device.artecoId,
        popupMode: mode,
        isSelected: isSelected,
        showActionsInOnvifPopup: showActionsInOnvifPopup
      });      

      break;
    }
  }
}
