import React, { Component } from 'react'
import { connect } from 'react-redux';
import gtag from "ga-gtag";

import { getConnectedServers, getServers, getServersPending } from '../reducers/serversReducer';
import { setServerIsLocal, setServerOffline } from '../actions/serverActions';

import { CIVETWEB_ENTRYPOINT } from '../config/backend';
import { getLanAddress, getWanAddress } from '../helpers/network';
import { getAuthentication } from '../reducers/authReducer';

export class LanWanCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localChecked: false
    }
  }

  componentDidUpdate(prevProps) {
    const { connectedServers, setServerIsLocal, setServerOffline } = this.props

    if (prevProps.connectedServers.length !== this.props.connectedServers.length) {
      this.setState({
        localChecked: false
      })
    }

    if (!this.state.localChecked) {
      this.setState({
        localChecked: true
      }, () => {
        const knownHosts = JSON.parse(localStorage.getItem('knownHosts')) || [];

        connectedServers.map(server => {
          //check if server url is present on localStorage table
          const knownHost = this.hostIsKnown(server.codeName, knownHosts);
          if (knownHost) {
            const API = `${server.protocol}://${knownHost.lastWorkingIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/`;
            this.checkUrl(API)
              .then(ans => {
                if (knownHost.isLocal) {
                  server.isLocal = true;

                  //GA TRACK
                  gtag('event', 'devicetree_my_lan_server_is_local');
                  setServerIsLocal(server);
                }
                //GA TRACK
                gtag('event', 'devicetree_my_lan_no_switch');
              })
              .catch(err => {
                //GA TRACK
                gtag('event', 'devicetree_my_lan_switch');

                if (knownHost.isLocal) {
                  const serverWanIp = getWanAddress(knownHost.lastWorkingIp);
                  const API_WAN = `${server.protocol}://${serverWanIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/`;

                  this.checkUrl(API_WAN)
                    .then(ans => {
                      //now the host is remote
                      //server reachable, store on local storage
                      const updatedHosts = knownHosts.map(host => {
                        if (host.serverCodename !== server.codeName) return host;
                        return {
                          isLocal: false,
                          serverCodename: server.codeName,
                          lastWorkingIp: serverWanIp,
                          lastWorkingUrl: `${server.protocol}://${serverWanIp}:${server.port}/`
                        }
                      })

                      localStorage.setItem('knownHosts', JSON.stringify(updatedHosts));

                      //server not local, tag on DB
                      server.isLocal = false;
                      gtag('event', 'devicetree_my_lan_server_is_not_local');
                      setServerIsLocal(server);
                    })
                    .catch(err => {
                      // server not reachable
                      setServerOffline(server);
                    })

                } else {
                  const serverLanIp = getLanAddress(knownHost.lastWorkingIp);
                  const API_LAN = `${server.protocol}://${serverLanIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/`;

                  this.checkUrl(API_LAN)
                    .then(ans => {
                      //now the host is local
                      //server reachable, store on local storage
                      const updatedHosts = knownHosts.map(host => {
                        if (host.serverCodename !== server.codeName) return host;
                        return {
                          isLocal: true,
                          serverCodename: server.codeName,
                          lastWorkingIp: serverLanIp,
                          lastWorkingUrl: `${server.protocol}://${serverLanIp}:${server.port}/`
                        }
                      })
                      localStorage.setItem('knownHosts', JSON.stringify(updatedHosts));

                      //server local, tag on DB
                      server.isLocal = true;
                      gtag('event', 'devicetree_my_lan_server_is_local');
                      setServerIsLocal(server);
                    })
                    .catch(err => {
                      // server not reachable
                      setServerOffline(server);
                    })
                }
              })

          } else {
            //GA TRACK
            gtag('event', 'devicetree_my_lan_first_time_check');
            
            const serverLanIp = getLanAddress(server.ip);
            const API_LAN = `${server.protocol}://${serverLanIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/`;

            this.checkUrl(API_LAN)
              .then(ans => {
                //server reachable, store on local storage
                knownHosts.push({
                  isLocal: true,
                  serverCodename: server.codeName,
                  lastWorkingIp: serverLanIp,
                  lastWorkingUrl: `${server.protocol}://${server.ip}:${server.port}/`
                });
                localStorage.setItem('knownHosts', JSON.stringify(knownHosts));

                //server is local, tag on DB
                server.isLocal = true;
                gtag('event', 'devicetree_my_lan_server_is_local');
                setServerIsLocal(server);
              })
              .catch(err => {
                const API = `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/`;                
                this.checkUrl(API)
                  .then(ans => {
                    //server reachable, store on local storage
                    knownHosts.push({
                      isLocal: false,
                      serverCodename: server.codeName,
                      lastWorkingIp: server.ip,
                      lastWorkingUrl: `${server.protocol}://${serverLanIp}:${server.port}/`
                    });
                    localStorage.setItem('knownHosts', JSON.stringify(knownHosts));

                    //server is remote, tag on DB
                    server.isLocal = false;
                    gtag('event', 'devicetree_my_lan_server_is_not_local');
                    setServerIsLocal(server);
                  })
                  .catch(() => {
                    // server not reachable
                    setServerOffline(server);
                  })
              })
          }
        })
      })
    }
  }

  hostIsKnown(serverCodename, knownHosts) {
    if (knownHosts.length === 0) return false;
    return knownHosts.find(host => host.serverCodename === serverCodename);
  }

  checkUrl = (serverUrl) => {

    return new Promise((resolve, reject) => {

      this
        .test(serverUrl)
        .then(ans => {
          resolve('ok');
        })
        .catch(err => {
          reject('ko');
        });
    });

  }

  test = (API) => {

    const connectionTimeout = 5000;

    return new Promise((resolve, reject) => {

      const promiseTimeout = setTimeout(() => {
        reject(new Error(`API ${API} timeout`))
      }, connectionTimeout);

      fetch(API)
        .then(res => {
          clearTimeout(promiseTimeout);
          resolve(`Resolve: API ${API} test OK`);
        })
        .catch(err => {
          clearTimeout(promiseTimeout);
          reject(`Reject: API ${API} test KO`);
        });

    });

  }

  render() {
    return (
      <></>
    )
  }
}

const mapStateToProps = state => ({
  servers: getServers(state),
  pendingServers: getServersPending(state),
  connectedServers: getConnectedServers(state),
  auth: getAuthentication(state)
});

const mapDispatchToProps = dispatch => {
  return {
    setServerIsLocal: (server) => dispatch(setServerIsLocal(server)),
    setServerOffline: (server) => dispatch(setServerOffline(server))
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanWanCheck);