import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import { Checkbox } from '@mui/material';


//actions
import { getEditingServer, isComingSoonPopupShown } from '../../reducers/featuresReducer';
import { showComingSoonPopup } from '../../actions/featuresActions';

//misc
// import { ChevronsRight } from 'react-feather';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import { X as XIcon } from "react-feather";

export class ComingSoonPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remember: false
    }

    this.hasSecondStep = process.env.REACT_APP_COMING_SOON_LINK;
    this.hasThirdStep = process.env.REACT_APP_COMING_SOON_CTA;
  }

  handleRememberToggle = (e) => {
    this.setState({
      remember: e.target.checked
    }, () => {
      const { remember } = this.state;

      if (remember) {
        localStorage.useeComingSoonShown1 = true
        if (this.hasSecondStep) {
          localStorage.useeComingSoonShown2 = true
        }
        if (this.hasThirdStep) {
          localStorage.useeComingSoonShown3 = true
        }
      }
    })
  }

  closePopup = () => {
    const { showComingSoonPopup } = this.props;

    showComingSoonPopup(false);
  }

  render() {
    const { isComingSoonPopupShown, t } = this.props;

    return (
      <Popup
        open={isComingSoonPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={true}
      >
        <div className='generic-popup coming-soon'>
          <div className='form-row popup-header'>
            <div className='close' onClick={this.closePopup}>
              <XIcon size={22} />
            </div>
          </div>
          <div className='coming-soon-content'>
            <div className='coming-soon-logos'>
              <div className='coming-soon-logo omnia'></div>
              <div className='coming-soon-arrows'>
                <ArrowIcon />
              </div>
              <div className='coming-soon-logo usee'></div>
            </div>
            <div className='coming-soon-copy'>
              <p>
                {t('COMING_SOON_COPY_1')}
              </p>
            </div>
            {
              this.hasSecondStep && (
                <div className='coming-soon-copy'>
                  <p>
                    {t('COMING_SOON_COPY_2')}
                  </p>
                </div>
              )
            }
            {
              this.hasSecondStep && (
                <div className='coming-soon-link'>
                  <a href={this.hasSecondStep} target="_blank">
                    {t('COMING_SOON_LINK_LABEL')}
                  </a>
                </div>
              )
            }
            <div className='coming-soon-remember'>
              <Checkbox
                id="remember-coming-soon"
                disableRipple
                onChange={this.handleRememberToggle}
                checked={this.state.remember}
                value={this.state.remember}
                defaultChecked={false}
              />
              {t('COMING_SOON_REMEMBER_LABEL')}
            </div>
            {
              this.hasThirdStep && (
                <div className='coming-soon-ctas'>
                  <a className='btn' target="_blank" href={this.hasThirdStep}>{process.env.REACT_APP_COMING_SOON_CTA_LABEL || 'Discover more'}</a>
                </div>
              )
            }
          </div>
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isComingSoonPopupShown: isComingSoonPopupShown(state),
  editingServer: getEditingServer(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showComingSoonPopup: (show) => dispatch(showComingSoonPopup(show)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ComingSoonPopup))