import React, { Component } from 'react';
import Select from 'react-select'
import { withTranslation } from 'react-i18next';
import { style } from './style';
export class ArtecoSelect extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { options, onChange, t, placeholder, noOptionsMessage, isMulti, isSearchable,selectedValue } = this.props;

    return <Select
      {...this.props}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isSearchable={isSearchable}
      onChange={onChange}
      value={selectedValue}
      className="arteco-select"
      classNamePrefix="select"
      styles={style}
      noOptionsMessage={noOptionsMessage} // messaggio se finita la selezione
      maxMenuHeight={100} 
    />;
  }
}

export default withTranslation()(ArtecoSelect);

