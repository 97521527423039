import React, { useEffect, useRef, useState } from 'react';
import JMuxer from 'jmuxer';
import Spinner from '../Spinner/Spinner';
import { initializeThreeScene } from './ThreeScene';
import { useTranslation } from 'react-i18next';
import { Video } from 'kaleidoscopejs'; // Importazione statica
import { isIOS, isMobileOnly } from 'react-device-detect';

const JBufferPlayer = ({ isLocalTesting, serverProtocol, serverIp, serverPort, artecoId, deviceId, addPrivacy, poster, access_token, cameraThumbnailPath, isFisheye, mountType, isWebGLEnabled }) => {
  const { t, i18n } = useTranslation();
  const videoRef = useRef(null);
  const threeContainerRef = useRef(null);
  const [socketUrl, setSocketUrl] = useState('');
  const [posterImageUrl, setPosterImageUrl] = useState(poster);
  const [loading, setLoading] = useState(true);
  const viewerRef = useRef(null);

  const fisheyeSupported = isWebGLEnabled && isFisheye;
  const WebGLAlert = isFisheye && !isWebGLEnabled;

  useEffect(() => {
    const protocol = (serverProtocol === 'https' && !isLocalTesting) ? 'wss' : 'ws';
    const hostname = isLocalTesting ? '192.168.0.67' : serverIp;
    const port = isLocalTesting ? parseInt(20000 + deviceId) : serverPort;
    const url = isLocalTesting ?
      `${protocol}://${hostname}:${port}` :
      `${protocol}://${hostname}:${port}/websocket/${artecoId}`;

    setSocketUrl(url);
  }, [isLocalTesting, serverProtocol, serverIp, serverPort, artecoId, deviceId, poster, addPrivacy]);

  const setPosterImage = () => {
    if (access_token) {
      fetch(cameraThumbnailPath, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error('JfBuffer - Network response was not ok');
        })
        .then(blob => {
          setPosterImageUrl(URL.createObjectURL(blob));
        })
        .catch(error => {
          console.error('JfBuffer - Error retrieving poster image:', error);
        });
    }
  };

  useEffect(() => {
    if (!poster) {
      setPosterImage();
    }
  }, [poster]);

  useEffect(() => {
    if (socketUrl) {
      const videoElement = videoRef.current;

      // Set attributes similar to Clappr.js
      videoElement.setAttribute('crossorigin', 'anonymous');
      videoElement.setAttribute('preload', 'metadata');
      videoElement.setAttribute('playsinline', 'true');

      const ws = new WebSocket(socketUrl, `${access_token}`);
      ws.binaryType = 'arraybuffer';      

      const cleanupJmuxer = initializeJmuxer(videoElement, ws, () => {
        if (fisheyeSupported && mountType === 'ceiling') {
          initializeThreeSceneForCeiling(videoElement);
        }
      });

      const disableKaleidoscope = isIOS && mountType === 'wall' && isMobileOnly;
      if (fisheyeSupported && mountType === 'wall' && !disableKaleidoscope) {
        initializeKaleidoscopeViewer(videoElement);
        initializeJmuxer(videoElement, ws);
      }

      return () => {
        cleanupJmuxer();
        ws.close();
      };
    }
  }, [socketUrl, access_token]);

  const initializeJmuxer = (videoElement, ws, onLoadedCallback) => {
    const jmuxer = new JMuxer({
      node: videoElement,
      mode: 'video',
      flushingTime: 0,
      fps: 30,
      debug: false,
      clearBuffer: false,
      onError: function (data) {
        if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
          jmuxer.reset();
        }
      }
    });

    ws.addEventListener('message', function (event) {
      const data = new Uint8Array(event.data);
      const naluType = data[4] & 0x1F;

      if (naluType === 1 || naluType === 5 || naluType === 7 || naluType === 8) {
        jmuxer.feed({ video: data });
      }
    });

    ws.addEventListener('error', function (e) {
      console.error('JfBuffer - Socket Error', e);
    });

    const bufferCheckInterval = setInterval(() => {
      if (videoElement.buffered.length > 0) {
        //('Buffered length:', videoElement.buffered.length);
        setLoading(false);
        clearInterval(bufferCheckInterval);

        videoElement.play().then(() => {
          if (onLoadedCallback) {
            onLoadedCallback();
          }
        }).catch(error => {
          console.error('JfBuffer - Error playing video:', error);
        });
      } else {
        //console.log('Buffered length is 0');
      }
    }, 1000);

    return () => {
      clearInterval(bufferCheckInterval);
      jmuxer.destroy();
      if (viewerRef.current && !loading) {
        if (viewerRef.current.renderer && viewerRef.current.renderer.dispose) {
          viewerRef.current.renderer.dispose();
        }
        viewerRef.current.destroy();
        viewerRef.current = null;
      }
    };
  };

  const initializeKaleidoscopeViewer = (videoElement) => {
    if (viewerRef.current) {
      if (viewerRef.current.renderer && viewerRef.current.renderer.dispose) {
        viewerRef.current.renderer.dispose();
      }
      viewerRef.current.destroy();
      viewerRef.current = null;
    }

    if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
      console.log('Safari detected, using static import for Kaleidoscope');
      viewerRef.current = new Video({
        source: videoElement,
        container: threeContainerRef.current,
        width: threeContainerRef.current.clientWidth,
        height: threeContainerRef.current.clientHeight,
        verticalPanning: true,
      });
      viewerRef.current.render();
    } else {
      requestAnimationFrame(() => {
        import('kaleidoscopejs')
          .then(({ Video }) => {
            console.log('Kaleidoscope module loaded dynamically');
            viewerRef.current = new Video({
              source: videoElement,
              container: threeContainerRef.current,
              width: threeContainerRef.current.clientWidth,
              height: threeContainerRef.current.clientHeight,
              verticalPanning: true,
            });
            viewerRef.current.render();
          })
          .catch(error => {
            console.error('Error loading Kaleidoscope module:', error);
          });
      });
    }
  };

  const initializeThreeSceneForCeiling = (videoElement) => {
    initializeThreeScene(videoElement, threeContainerRef.current);
  };

  useEffect(() => {
    const updateSize = () => {
      if (viewerRef.current) {
        viewerRef.current.setSize({
          width: threeContainerRef.current.clientWidth,
          height: threeContainerRef.current.clientHeight,
        });
      }
    };
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
      if (viewerRef.current && !loading) {
        if (viewerRef.current.renderer && viewerRef.current.renderer.dispose) {
          viewerRef.current.renderer.dispose();
        }
        try {
          viewerRef.current.destroy();
        } catch (error) {
          console.error('Error during destroy:', error);
        }
        viewerRef.current = null;
      }
    };
  }, [loading]);

  return (
    <>
      {addPrivacy ? (<canvas className={`privacy-display`}></canvas>) : null}
      {loading && (
        <div className='raw-player-loading'>
          <div className='spinner-container'>
            <Spinner />
          </div>
        </div>
      )}
      <div className='raw-player-container' ref={threeContainerRef} style={{ backgroundColor: '#000' }}>
        {!loading && (
          <div className='live-label'>
            <span className='icon'></span>
          </div>
        )}
        {
          WebGLAlert ? (
            <div className='webgl-alert'>
              <span className='icon'></span>
              <span className='text'>{t('WEBGL_ALERT')}</span>
            </div>
          ) : null
        }
        <video ref={videoRef} className='raw-player js-plyr plyr video-webrtc' poster={posterImageUrl} autoPlay muted playsInline crossOrigin="anonymous" preload="metadata" />
      </div>
    </>
  );
};

export default JBufferPlayer;
