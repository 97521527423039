import React, { Component } from 'react'
import { customEvents } from "../../config/eventsAndStorage";
import { Zap as ThunderIcon } from 'react-feather';
import PlayerContextMenu from '../dashboard/Stage/PlayerContextMenu';
import { ReactComponent as ZoomIcon } from "../svgIcons/siZoom.svg";
import { appPaths } from '../../config/paths';

class ArtecoPlayerTimeBadge extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentTime: '00:00:00',
    }
  }

  componentDidMount() {
    document.addEventListener(customEvents.updatePlayersTime, (event) => {
      this.handleCamerasTimestamp(event);
    });
  }

  handleCamerasTimestamp = (event) => {
    // evento di cambio dell'orario
    const { artecoId } = this.props
    const detail = event.detail;
    if (detail.artecoId == artecoId) {
      this.setState({
        currentTime: detail.displayTime,
      })
    };
  }
 
  render() {

    const { isTranscoding, hideContextMenu, artecoId, isPlayback, zoomed, channelZoomAndPan, resetZoom,isEdgeMode ,origin, marker } = this.props;
    const { currentTime } = this.state;
    const additionalClass = isPlayback ? 'playback' : '';
    //removing the date part for the recordings environment dd/mm/yyyy hh:mm:ss  or in ene format yyyy/mm/dd h:m:s pm if rec want only the time not the date
    const timeDisplay = (currentTime !== '00:00:00' && appPaths.recordings === window.location.pathname) ?currentTime.split(" ").slice(1).join(" ") : currentTime;

    return (
      <>
        {(zoomed || channelZoomAndPan?.scale > 1) &&
          <span className="zoom-icon-container">
            <ZoomIcon size={32} onClick={resetZoom} />
          </span>}

        {!hideContextMenu &&
          <PlayerContextMenu
            isPlayback={isPlayback} 
            artecoId={artecoId}
            additionalClass={additionalClass}
            origin={origin} 
            marker={marker}
            />
        }

        {isPlayback && (
          <span className="badge display-time badge-success">
            {timeDisplay}
            {!isTranscoding && <ThunderIcon size={12}/>}
          </span>
        )}
      </>

    )
  }
}

export default ArtecoPlayerTimeBadge