import React, { useEffect, useState } from 'react'

export default function QualityLogger({ videoInstance }) {
  const [jitter, setJitter] = useState(0);
  const [packetLoss, setPacketLoss] = useState(0);
  const [fps, setFps] = useState(0);
  const [bitrate, setBitrate] = useState(0);

  useEffect(() => {
    if (!videoInstance) return

    videoInstance.addEventListener('webrtcQuality', (e) => {      
      const { jitter, packetLoss, fps, bitrate } = e.detail;
      setJitter(jitter.toFixed(2));
      setPacketLoss(packetLoss.toFixed(2));
      setFps(fps.toFixed(2));
      setBitrate(bitrate.toFixed(2));
    });
  }, [videoInstance])


  //print debug infon only if the param showInfo is true in the url
  const urlParams = new URLSearchParams(window.location.search);
  const showInfo = urlParams.get('showInfo');
  if (!showInfo) {
    return null;
  }  
  
  return (
    <div className='quality-logger'>
      <span>Jitter: {jitter}</span>
      <span>Packet Loss: {packetLoss}</span>
      <span>FPS: {fps}</span>
      <span>Bitrate: {bitrate}</span>      
    </div>
  )
}
