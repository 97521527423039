import axios from "axios";

export const FETCH_LAYOUTS_PENDING = 'FETCH_LAYOUTS_PENDING';
export const LAYOUTS_UPDATING = 'LAYOUTS_UPDATING';
export const FETCH_LAYOUTS_SUCCESS = 'FETCH_LAYOUTS_SUCCESS';
export const FETCH_LAYOUTS_ERROR = 'FETCH_LAYOUTS_ERROR';

function fetchLayoutsPending() {
    return {
        type: FETCH_LAYOUTS_PENDING
    }
}

function fetchLayoutsUpdating() {
    return {
        type: LAYOUTS_UPDATING
    }
}

function fetchLayoutsSuccess(layouts, userId) {
    return {
        type: FETCH_LAYOUTS_SUCCESS,
        payload: {
            layouts: layouts,
            userId: userId
        }
    }
}

function fetchLayoutsError(error) {
    return {
        type: FETCH_LAYOUTS_ERROR,
        error: error
    }
}

function fetchLayouts(userId) {    

    return dispatch => {
        dispatch(fetchLayoutsPending());        

        let req = {
            "owner": userId
        }        

        const API = '/api/layouts/get-by-user';

        axios
        .post(API, req)        
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            
            dispatch(fetchLayoutsSuccess(res.data, userId));
            return res.data;
        })
        .catch(error => {
            dispatch(fetchLayoutsError(error));
        })
    }
}

export default fetchLayouts;