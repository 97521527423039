import * as THREE from "three";
import _OrbitControls from 'three-orbit-controls';
const OrbitControls = _OrbitControls(THREE);

function getPowerOfTwo(value, pow) {
  pow = pow || 1;
  while (pow < value) {
    pow *= 2;
  }
  return pow;
}

export function initializeThreeScene(videoElement, container) {
  //console.log('>>>>>> Inizializzazione della scena three.js con telecamera montata sul soffitto');

  // Dimensioni della canvas come potenza di due
  const canvasWidth = getPowerOfTwo(container.clientWidth);
  const canvasHeight = getPowerOfTwo(container.clientHeight);

  // Prima scena per la conversione da fisheye a equirettangolare
  const scena1 = new THREE.Scene();
  const camera1 = new THREE.OrthographicCamera(canvasWidth / -2, canvasWidth / 2, canvasHeight / 2, canvasHeight / -2, 1, 10000);
  camera1.position.z = 500;

  const renderer1 = new THREE.WebGLRenderer();
  renderer1.setSize(canvasWidth, canvasHeight);
  renderer1.domElement.className = 'hidden-canvas'; // Assegna una classe per nasconderlo
  container.appendChild(renderer1.domElement);

  const videoTexture = new THREE.VideoTexture(videoElement);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;
  videoTexture.format = THREE.RGBFormat;

  const shaderMaterial = new THREE.ShaderMaterial({
    uniforms: {
      fisheyeTexture: { value: videoTexture }
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying vec2 vUv;
      uniform sampler2D fisheyeTexture;
      const float PI = 3.14159265359;
      void main() {
        vec2 uv = vUv;

        float theta = uv.x * 2.0 * PI;
        float phi = uv.y * PI;

        phi = phi * 0.8;

        float r = phi / (PI / 2.0);
        float x = r * cos(theta);
        float y = r * sin(theta);

        vec2 fisheyeUV = vec2(x * 0.5 + 0.5, y * 0.5 + 0.5);

        vec4 color = texture2D(fisheyeTexture, fisheyeUV);
        
        gl_FragColor = color;
      }
    `
  });

  const planeGeo = new THREE.PlaneGeometry(canvasWidth, canvasHeight);
  const plane = new THREE.Mesh(planeGeo, shaderMaterial);
  scena1.add(plane);

  camera1.left = canvasWidth / -2;
  camera1.right = canvasWidth / 2;
  camera1.top = canvasHeight / 2;
  camera1.bottom = canvasHeight / -2;
  camera1.updateProjectionMatrix();

  const animate1 = () => {
    requestAnimationFrame(animate1);
    renderer1.render(scena1, camera1);
  };
  animate1();

  //console.log('>>>>>> Prima scena renderizzata');

  const canvasCopy = document.createElement('canvas');
  canvasCopy.width = canvasWidth;
  canvasCopy.height = canvasHeight;
  const ctx = canvasCopy.getContext('2d');

  const updateCanvasCopy = () => {
    ctx.drawImage(renderer1.domElement, 0, 0, canvasWidth, canvasHeight);
  };

  const textureEquirectangular = new THREE.Texture(canvasCopy);
  textureEquirectangular.minFilter = THREE.LinearFilter;
  textureEquirectangular.magFilter = THREE.LinearFilter;
  textureEquirectangular.format = THREE.RGBFormat;
  textureEquirectangular.needsUpdate = true;

  //console.log('>>>>>> Texture equirettangolare creata:', textureEquirectangular);

  // Seconda scena per la proiezione equirettangolare sulla sfera
  const scena2 = new THREE.Scene();
  const camera2 = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
  const renderer2 = new THREE.WebGLRenderer({ alpha: true }); // Abilita il canale alpha per l'opacità
  renderer2.setSize(container.clientWidth, container.clientHeight);
  renderer2.domElement.className = 'interactive-canvas';
  renderer2.domElement.style.opacity = 0;
  container.appendChild(renderer2.domElement);

  const sphereGeo = new THREE.SphereGeometry(500, 60, 40);
  sphereGeo.scale(-1, 1, 1);
  const sphereMat = new THREE.MeshBasicMaterial({ map: textureEquirectangular });
  const sphere = new THREE.Mesh(sphereGeo, sphereMat);
  scena2.add(sphere);

  const controls = new OrbitControls(camera2, renderer2.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.25;
  controls.enableZoom = true;
  controls.minDistance = 10; // Distanza minima per lo zoom
  controls.maxDistance = 400; // Distanza massima per lo zoom
  controls.maxPolarAngle = Math.PI / 2;

  camera2.position.set(0, -150, 0);
  controls.target.set(0, -150, 100);
  controls.update();

  const animate2 = () => {
    requestAnimationFrame(animate2);
    updateCanvasCopy();
    textureEquirectangular.needsUpdate = true;
    controls.update();
    renderer2.render(scena2, camera2);
  };
  animate2();

  //console.log('>>>>>> Setup della seconda scena completo');

  // Animazione di dissolvenza incrociata
  const animateTransition = () => {
    const duration = 200; // Durata dell'animazione in millisecondi
    const start = performance.now();

    const animateFrame = (timestamp) => {
      const progress = Math.min((timestamp - start) / duration, 1);
      renderer2.domElement.style.opacity = progress;
      if (progress < 1) {
        requestAnimationFrame(animateFrame);
      }
    };
    requestAnimationFrame(animateFrame);
  };
  animateTransition();

  return () => {
    //console.log('>>>>>> Pulizia della scena three.js');
    container.removeChild(renderer1.domElement);
    container.removeChild(renderer2.domElement);
  };
}
