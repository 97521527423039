/** @format */

import {
  FETCH_UFOLLOW_SEQUENCE_LIST,
  FETCH_UFOLLOW_CHANGE_SELECTED_SEQUENCE,
  GET_UFOLLOW_SEQUENCE,
  TOGGLE_UFOLLOW_CAMERA_PICKER,
  UPDATE_SEQUENCE,
  TOGGLE_CREATE_SEQUENCE_POPUP,
  FETCH_UFOLLOW_CHANGE_SELECTED_SITE,
  RESET_UFOLLOW_SEQUENCE,
  ERROR_UFOLLOW,
  LOADING_UFOLLOW,
  TOGGLE_SEQUENCE_TUITORAIL_POPUP,
  RESET_UFOLLOW_SEQUENCE_LIST,
} from "../actions/types";
import { UFollowConfiguration } from "../helpers/serverCredentials";
import { getSiteInfoList } from "./serversReducer";

const initialState = {
  cameraPicker: false,
  sequence: {},
  selectedSequence: "",
  selectedSite: "",
  sequenceList: [],
  isOpenSequncePopup: false,
  error: false,
  loading: false,
  isOpenTutorialPopup: false,
};

export function ufollowReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UFOLLOW_SEQUENCE_LIST:
      return {
        ...state,
        sequenceList: action.payload,
        error: false,
        loading: false,
      };
    case FETCH_UFOLLOW_CHANGE_SELECTED_SEQUENCE:
      return {
        ...state,
        selectedSequence: action.payload,
      };
    case FETCH_UFOLLOW_CHANGE_SELECTED_SITE:
      return {
        ...state,
        selectedSite: action.payload,
      };
    case GET_UFOLLOW_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
      };
    case RESET_UFOLLOW_SEQUENCE:
      return {
        ...state,
        sequence: {},
      };
    case RESET_UFOLLOW_SEQUENCE_LIST:
      return {
        ...state,
        sequenceList: [],
      };
    case TOGGLE_UFOLLOW_CAMERA_PICKER:
      return {
        ...state,
        cameraPicker: action.payload,
      };
    case UPDATE_SEQUENCE:
      const updatedSequence = { ...state.sequence };
      Object.assign(updatedSequence, action.payload);
      return {
        ...state,
        sequence: updatedSequence,
      };
    case TOGGLE_CREATE_SEQUENCE_POPUP:
      return {
        ...state,
        isOpenSequncePopup: action.payload,
      };
    case TOGGLE_SEQUENCE_TUITORAIL_POPUP:
      return {
        ...state,
        isOpenTutorialPopup: action.payload,
      };
    case ERROR_UFOLLOW:
      return {
        ...state,
        error: action.payload,
      };
    case LOADING_UFOLLOW:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

export const getSequenceList = (state) => {
  return state.ufollow.sequenceList;
};

export const getSelectedSequence = (state) => {
  return state.ufollow.selectedSequence;
};

export const getSelectedSite = (state) => {
  return state.ufollow.selectedSite;
};

export const getSequence = (state) => {
  return state.ufollow.sequence;
};

export const getCameraPicker = (state) => {
  return state.ufollow.cameraPicker;
};

const isInSequence = (sequence, checkFunction) => {
  if (Object.keys(sequence).length === 0) return false;

  for (const row of sequence?.sequenceGrid) {
    for (const cell of row) {
      if (checkFunction(cell)) {
        return true;
      }
    }
  }

  return false;
};

export const hasServerCamerasInSequence = (server, sequence) => {
  return isInSequence(sequence, (cell) => {
    return (
      cell?.serverCodename === server?.codeName &&
      cell?.serverCodename &&
      server?.codeName
    );
  });
};

export const hasChannelInSequence = (channel, sequence) => {
  return isInSequence(sequence, (cell) => {
    return cell?.artecoId === channel?.artecoId;
  });
};

export const getNeighborValues = (sequenceGrid, row, col) => {
  const neighbors = [
    { direction: "north", dx: -1, dy: 0 },
    { direction: "south", dx: 1, dy: 0 },
    { direction: "west", dx: 0, dy: -1 },
    { direction: "east", dx: 0, dy: 1 },
    { direction: "northwest", dx: -1, dy: -1 },
    { direction: "northeast", dx: -1, dy: 1 },
    { direction: "southwest", dx: 1, dy: -1 },
    { direction: "southeast", dx: 1, dy: 1 },
  ];

  const neighborValues = {};

  neighbors.forEach(({ direction, dx, dy }) => {
    const newRow = row + dx;
    const newCol = col + dy;

    if (
      newRow >= 0 &&
      newRow < sequenceGrid.length &&
      newCol >= 0 &&
      newCol < sequenceGrid[0].length
    ) {
      const { artecoId, serverCodename, sequenceName } =
        sequenceGrid[newRow][newCol];
      if (artecoId || serverCodename || sequenceName) {
        neighborValues[direction] = { artecoId, serverCodename, sequenceName };
      } else {
        neighborValues[direction] = {}; // Set an empty object when data is empty
      }
    }
  });

  return neighborValues;
};

export const breakConnectionSequence = (sequenceGrid) => {
  const colsToRemove = sequenceGrid[0]
    .map((col, index) => {
      return sequenceGrid.some((row) => row[index].connectedBy) ? index : null;
    })
    .filter((col) => col !== null);

  // Remove rows for each marked column
  colsToRemove.forEach((col) => {
    sequenceGrid.forEach((row) => row.splice(col, 1));
  });

  // Remove connectedBy property for cells where connectedBy is true
  sequenceGrid.forEach((row) => {
    row.forEach((cell) => {
      if (cell.connectedTo) {
        delete cell.connectedTo;
      }
    });
  });
  const updateSequenceGrid = cellNeighbors(sequenceGrid)
  return updateSequenceGrid;
};

export const cellNeighbors = (cells) => {
  cells.forEach((row, rowIndx) => {
    row.forEach((col, colIndex) => {
      if (Object.keys(col).length !== 0) {
        const neighborValues = getNeighborValues(cells, rowIndx, colIndex);
        // Preserve existing properties and update the neighbors property
        cells[rowIndx][colIndex] = {
          ...col, // Spread existing properties
          neighbors: neighborValues, // Update neighbors property
        };
      }
    });
  });

  return cells;
};

export const isOpenSequncePopup = (state) => {
  return state.ufollow.isOpenSequncePopup;
};

export const isOpenTuitorialPopup = (state) => {
  return state.ufollow.isOpenTutorialPopup;
};

export const getSitesList = (state) => {
  const sites = getSiteInfoList(state);
  return sites
    ?.filter((site) => {
      const uFollowConfig = site?.credentials[UFollowConfiguration];
      return uFollowConfig === "1" || uFollowConfig === 1;
    })
    .map((site) => ({
      site_id: site.site_id,
      site_name: site.site_name,
    }));
};

export const hasUFollowConfiguration = (state) => {
  const sites = getSiteInfoList(state);
  return sites?.some(
    (site) => site?.credentials[UFollowConfiguration] == 1
  );
};

export const getUFollowError = (state) => {
  return state.ufollow.error;
};

export const getUFollowLoading = (state) => {
  return state.ufollow.loading;
};

export const hasSiteCamerasInSequence = (servers, sequence) => {
  return servers.some((server) => hasServerCamerasInSequence(server, sequence));
};

export const hasServerCameraInUFollowGui = (server, ufollowGuiCamera) => {
  return server.channels.some(
    (channel) => channel.artecoId === ufollowGuiCamera
  );
};
export const hasSiteCamerasInUFollowGui = (servers, ufollowGuiCamera) => {
  return servers.some((server) => {
    return hasServerCameraInUFollowGui(server, ufollowGuiCamera);
  });
};

export const extractArtecoIds = (grid) => {
  return grid.reduce((accumulator, row) => {
      row.forEach(item => {
          if (item.artecoId !== undefined) {
              accumulator.push(item.artecoId);
          }
      });
      return accumulator;
  }, []); 
}