import axios from "axios";

import {
  FETCH_ADDITIONAL_PROPERTIES_PENDING,
  FETCH_ADDITIONAL_PROPERTIES_SUCCESS,
  FETCH_ADDITIONAL_PROPERTIES_ERROR,
} from "./types";

function fetchAdditionalPropertiesPending() {
    return {
        type: FETCH_ADDITIONAL_PROPERTIES_PENDING
    }
}

function fetchAdditionalPropertiesSuccess(userPrefs) {
    return {
        type: FETCH_ADDITIONAL_PROPERTIES_SUCCESS,
        payload: userPrefs
    }
}

function fetchAdditionalPropertiesError(error) {
    return {
        type: FETCH_ADDITIONAL_PROPERTIES_ERROR,
        error: error
    }
}

function fetchAdditionalProperties(userId) {    

    return dispatch => {
        dispatch(fetchAdditionalPropertiesPending());        

        let req = {
            "owner": userId
        }        

        const API = '/api/additional-properties/get-by-user';

        axios
        .post(API, req)        
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            
            const payload = Array.isArray(res.data) ? res.data[0] : res.data;
            dispatch(fetchAdditionalPropertiesSuccess(payload));
            return res.data;
        })
        .catch(error => {
            dispatch(fetchAdditionalPropertiesError(error));
        })
    }
}

export default fetchAdditionalProperties;