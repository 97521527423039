
export const PINS_TYPE = {
  INPUT: 1,
  OUTPUT: 2,
}

export const PER_TYPE = {
  ATCCIASIBSYSTEM: 10001,
  ATCCIASQUASAR: 10002,
  ATCARTECOEVERYWHERE: 20001,
  ATCARTECOSMARTIP: 20002,
  ATCWEBDEVICEIE: 40001,
  ATC_CONNECTOR: 60001,
  ATCIOEXT: 70001,
  ATCINPUTEXTERNAL_STATE: 90001,
  ATCBOSCHIO: 20003,
  ATCBOSCHTASK: 80001,
  ATCWISENETIO: 20004,
  ATCSAMSUNGIO: 20006,
  ATCAXISIO: 20005,
  ATCAXISA1001: 50001,
  ATCCONTACTIDEAD4: 30001,
  ATCFACIALRECONGITION: 160001,
  ATCLPR:150001,
  ATCANALYTICS: 140001,
  ATCINTRUSION: 130001,
  ATCELETRICFENCING:120001,
  ATCIOT: 110001,
  ATCACCESSCONTROL: 100001,
}

export const PER_TYPE_NAMES = [
  {
    id: 20001,
    name: "Arteco Everywhere",
    var: PER_TYPE.ATCARTECOEVERYWHERE,
  },
  {
    id: 20002,
    name: "Arteco Smart IP",
    var: PER_TYPE.ATCARTECOSMARTIP,
  },
  {
    id: 70001,
    name: "External IO",
    var: PER_TYPE.ATCIOEXT,
  },
]

export const INTEGRATIONS_PER_SUBTYPE_LIST = [
  160001, // Facial Recognition
  150001, // LPR
  140001, // Analytics
  130001, // Intrusion System
  120001, // Electric Fencing
  100001, // Access Control
  110001, // IoT  
]

export const INTEGRATIONS_PER_DICTIONARY = [
  {
    id: 160001,
    nam: "Facial Recognition",
  },
  {
    id: 150001,
    nam: "LPR",
  },
  {
    id: 140001,
    nam: "Analytics",
  },
  {
    id: 130001,
    nam: "Intrusion System",
  },
  {
    id: 120001,
    nam: "Electric Fencing",
  },
  {
    id: 100001,
    nam: "Access Control",
  },
  {
    id: 110001,
    nam: "IoT",
  },
]

export const CAM_TYPE = {
  SRC_WCAM: "Webcam",
  SRC_FILE: "File",
  SRC_ACTI: "Acti",
  SRC_LUMENERA: "Lumenera",
  SRC_AXIS: "Axis",
  SRC_PROVIDEO: "Provideo",
  SRC_IBP: "Ibp",
  SRC_PANASONIC: "Panasonic",
  SRC_MOBOTIX: "Mobotix",
  SRC_FXVD4: "FXVD4",
  SRC_BOSCH: "Bosch",
  SRC_ARECONT: "Arecont",
  SRC_SONY: "Sony",
  SRC_ELVOXIP: "Elvox IP",
  SRC_HIKVISIONIP: "Hikvision",
  SRC_SAMSUNG: "Samsung",
  SRCEUKLIS1: "Euklis 1",
  SRC_DYNACOLOR: "Dynacolor",
  SRC_DLINK: "Dlink",
  SRCEUKLIS2: "Euklis 2",
  SRC_GRUNDIG: "Grunding",
  SRC_VISIONHITECH: "Visionhitech",
  SRC_SENTRY: "Sentry",
  SRC_ORCA: "Orca",
  SRC_CANON: "Canon",
  SRC_ONVIF: "Onvif",
  SRCSENTRY2: "Sentry 2",
  SRC_SELEA: "Selea",
  SRC_ELVOXHD: "Elvox HD",
  SRCDAHUAPCI: "Dahua",
  SRC_IQINVISION: "IQ Vision",
  SRCGENRTSP: "Generic RTSP",
  SRC_TATTILE: "Tattile",
}

