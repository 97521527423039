import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';

//icons
import { X as XIcon } from "react-feather";

//actions
import { getThumbData, isThumbPopupShown } from '../../reducers/featuresReducer';
import { showThumbPopup } from '../../actions/featuresActions';

import { withTranslation } from 'react-i18next'
import ThumbDataDisplay from '../Popup/ThumbDataDisplay';

export class ThumbPopup extends Component {


  closePopup = () => {
    const { showThumbPopup } = this.props;

    showThumbPopup(false);
  }

  render() {
    const { isThumbPopupShown, t, thumbData} = this.props;

    return (
      <Popup
        open={isThumbPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='generic-popup arteco-thumb-popup'>  
          <div className='popup-header'>          
            <h4></h4>
            <div className="close" onClick={this.closePopup}>
              <XIcon size={22} />
            </div>
          </div>   
          {
            (thumbData && isThumbPopupShown) && (
              <ThumbDataDisplay 
                closeCB={this.closePopup}                    
              />              
            )
          }  
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isThumbPopupShown: isThumbPopupShown(state),
  thumbData: getThumbData(state)  
})

const mapDispatchToProps = dispatch => {
  return {
    showThumbPopup: (show) => dispatch(showThumbPopup(show)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ThumbPopup));