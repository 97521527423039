import axios from 'axios';
import { updateDeviceConfig } from '../helpers/server';
import { ARTECO_CAM_CODE, WEBGL_SUPPORT_UPDATE } from './types';

export const FETCH_CONFIG_PENDING = 'FETCH_CONFIG_PENDING';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERRORS = 'FETCH_CONFIG_ERRORS';
export const FETCH_CONFIG_RESET = 'FETCH_CONFIG_RESET';
export const SERVER_INFO = 'SERVER_INFO';

function fetchConfigUpdateDevice(res) {
  return {
    type: SERVER_INFO,
    payload: res
  }
}

function fetchConfigPending() {
  return {
    type: FETCH_CONFIG_PENDING
  }
}

export function fetchConfigReset() {
  return {
    type: FETCH_CONFIG_RESET
  }
}

function fetchConfigSuccess() {
  return {
    type: FETCH_CONFIG_SUCCESS,
  }
}

function fetchConfigError(errorInfo) {

  return {
    type: FETCH_CONFIG_ERRORS,
    errorCode: errorInfo.errorCode,
    server_id: errorInfo.server_id
  }
}


export const editDevice = (deviceConfig) => {

  // sto mandato il dato con un sessionID differente da quello salvato nel db, devo usare il session ID preso dal db e non dal 'parent server'

  return dispatch => {

    dispatch(fetchConfigReset());
    dispatch(fetchConfigPending());

    let latitudeOfDevice = parseFloat(deviceConfig.lat);
    let longitudeOfDevice = parseFloat(deviceConfig.long);

    let isCamera = (deviceConfig.type === ARTECO_CAM_CODE);

    //update device on Arteco Server
    updateDeviceConfig(deviceConfig)
      .then(res => {


        if (res.error) {
          throw (res.error);
        }

        const response = res.root;

        if (response.result.code !== 0) {

          const errorInfo = {
            errorCode: response.result.code,
            server_id: deviceConfig.parentServer._id,
          }

          dispatch(fetchConfigError(errorInfo));
          return response.result.msg;
        }

        const updatedServer = {
          ...deviceConfig.parentServer,
          channels: isCamera ? deviceConfig.parentServer.channels.map(channel => {
            if (channel.id !== deviceConfig.id) {
              return channel
            }
            return {
              ...channel,
              lat: latitudeOfDevice,
              long: longitudeOfDevice
            }
          }) : deviceConfig.parentServer.channels,
          peripherals: !isCamera ? deviceConfig.parentServer.peripherals.map(peripheral => {
            if (peripheral.id !== deviceConfig.id) {
              return peripheral
            }
            return {
              ...peripheral,
              lat: latitudeOfDevice,
              long: longitudeOfDevice
            }
          }) : deviceConfig.parentServer.peripherals
        };


        //update device on mongo
        axios
          .post('/api/servers/update/', updatedServer)
          .then(server => {

            dispatch(fetchConfigUpdateDevice(server.data));
            dispatch(fetchConfigSuccess());

            return response;
          })
          .catch(error => {
            const errorInfo = {
              errorCode: null,
              server_id: deviceConfig.parentServer._id,
            }

            dispatch(fetchConfigError(errorInfo));
          })

      })
      .catch(error => {
        const errorInfo = {
          errorCode: null,
          server_id: deviceConfig.parentServer._id,
        }

        dispatch(fetchConfigError(errorInfo));

        return error
      })

  }

}

export const updateWebGLSupport = (webGLSupport) => {
  return {
    type: WEBGL_SUPPORT_UPDATE,
    payload: webGLSupport
  }
}