import axios from "axios";
import { timeoutPromise } from '../helpers/network';

import { EXPORT_SERVICE_FILES_API } from '../config/ExportServiceConfg';


export const FETCH_DOWNLOADS_PENDING = 'FETCH_DOWNLOADS_PENDING';
export const FETCH_DOWNLOADS_SUCCESS = 'FETCH_DOWNLOADS_SUCCESS';
export const FETCH_DOWNLOADS_ERROR = 'FETCH_DOWNLOADS_ERROR';


function fetchDownloadsPending() {
    return {
        type: FETCH_DOWNLOADS_PENDING
    }
}

function fetchDownloadsSuccess(downloads) {
    return {
        type: FETCH_DOWNLOADS_SUCCESS,
        payload: downloads
    }
}

function fetchDownloadsError(error) {
    return {
        type: FETCH_DOWNLOADS_ERROR,
        error: error
    }
}

function performDownloadFetch(API, req, source) {
    return axios.post(API, req, {
        cancelToken: source.token
      });
}

function fetchDownloads(servers, userPrefs) {        

   return dispatch => {
       dispatch(fetchDownloadsPending());        

       //no connected servers
       if(!Array.isArray(servers) || servers.length == 0) {
           dispatch(fetchDownloadsSuccess([]));
           return false;
       }

       const serverList = servers.map(server => server.codeName);                

       let req = {           
           "servers": serverList
       }        

       const API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_FILES_API}/get-servers-exports`;     
       
       const FETCH_TIMEOUT = 20000;
       const CancelToken = axios.CancelToken;
       const source = CancelToken.source();

       timeoutPromise(FETCH_TIMEOUT, performDownloadFetch(API, req, source), new Error('timeout'))     
       .then(res => {
           if(res.error) {
               throw(res.error);
           }           
           dispatch(fetchDownloadsSuccess(res.data));
           return res.data;
       })
       .catch(error => {
            source.cancel('Operation canceled by the user');
           dispatch(fetchDownloadsError(error));
       })
   }
}

export default fetchDownloads;