import React from 'react';

const OfflineMessage = () => {

  const offlineMessage =  (
    <span className="offline-message">Offline</span>
  )

  return (
    <div className={`offline-message-container`}>
      {offlineMessage}
    </div>
  );

} // end of component

export default OfflineMessage;
