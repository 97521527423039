import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageEN from './locate/en.json'
import languageIT from './locate/it.json'
import languageES from './locate/es.json'
import languagePT from './locate/pt.json'
// import languageDE from './locate/de.json'
// import languageFR from './locate/fr.json'
// import languageJA from './locate/ja.json'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      it: languageIT,
      es: languageES,
      pt: languagePT,
      // de: languageDE,
      // fr: languageFR,
      // ja: languageJA,
    },    
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false //TEMP - REMOVE AFTER MERGE W. LAZY LOAD
    }
  })

  i18n.getPureLanguage = function() {
    return this.language.split("-")[0];    
  }

  export default i18n;