import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Helmet } from 'react-helmet';

//actions
import { getServersError } from '../../reducers/serversReducer';
import { updateServer } from "../../actions/serverActions";

import Spinner from '../Spinner/Spinner';

//labels translation
import { withTranslation } from 'react-i18next'

//constants

import { appPaths } from '../../config/paths';
import { getAuthentication } from "../../reducers/authReducer";
import { getErrrors } from "../../reducers/errorReducer";

class Edit extends Component {
  constructor() {
    super();

    this.state = {
      _id: null,
      ip: "",
      port: "",
      secure: true,
      password: "",
      username: "",
      public: false,
      errors: {},
      uptodate: false,
      submitted: false
    };
    
    this.ipInput = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  componentDidMount() {
    if (this.ipInput.current) {

      this.ipInput.current.addEventListener("keydown", (e) => {
        if (e.keyCode == 13) {
          e.preventDefault();
          this.onSubmit();
        }
      })

      this.ipInput.current.addEventListener("blur", (e) => {
        if (!this.ipInput.current) return false;
        this.ipInput.current.innerText = e.currentTarget.innerText.replace(appPaths.PUBLIC_HOST, "");
      })
    }

  }


  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  somethingsChanged = (oldValues, newValues) => {
    if(oldValues.ip && oldValues.ip !== newValues.ip) return true;
    if(newValues.port && oldValues.port !== newValues.port) return true;
    if(newValues.password && oldValues.password !== newValues.password) return true;
    if(newValues.username && oldValues.username !== newValues.username) return true;

    return false;
  }

  onSubmit = e => {
    if (e && e.target) {
    e.preventDefault();
    }    

    const { updateServer, submitCallback, isPopup, server, cancelCallback } = this.props;

    let serverIp = `${this.ipInput.current.innerText}${appPaths.PUBLIC_HOST}`;

    const updatedValues = {
      ip: serverIp,
      port: this.state.port,
      password: this.state.password,
      username: this.state.username
    }

    if(this.somethingsChanged(server, updatedValues)) {

      const passwordHasChanged = updatedValues.password;

      const newServer = {
        _id: server._id,
        owner: this.props.auth.user.id,
        ip: updatedValues.ip,
        port: updatedValues.port || server.port,
        protocol: this.state.secure ? 'https' : 'http',
        password: updatedValues.password || server.password,
        username: updatedValues.username || server.username,
        uptodate: false,
        public: server.public,
        needsRelogin: !!passwordHasChanged
      };
  
      const redirectTo = isPopup ? undefined :  this.props.history;    
  
      updateServer(newServer, redirectTo, submitCallback);

    } else {
      cancelCallback && cancelCallback();
    }

    
  };


  cancel = (e) => {
    e.preventDefault();
    const { isPopup, cancelCallback } = this.props

    if (!isPopup) {
      window.location.href = appPaths.dashboard
    }

    if (cancelCallback) {
      cancelCallback();
    }

    return false;
  }

  shouldComponentRender() {
    if (this.props.server) return true;

    return false;
  }

  render() {
    const { t, isPopup, server } = this.props;

    if (!this.shouldComponentRender()) return <Spinner />

    const { errors } = this.state;

    const additionalClass = isPopup ? 'arteco-popupediting-popup-container' : 'col-md-4 mb-4';
    const additionalHeaderClass = isPopup ? 'arteco-popupediting-popup-wrapper' : '';

    const serverIp = this.state.ip == '' ? server.ip.replace(appPaths.PUBLIC_HOST, "") : this.state.ip;
    const serverPort = this.state.port == '' ? server.port : this.state.port;
    const serverUsername = this.state.username == '' ? server.username : this.state.username;
    const serverPassword = this.state.password == '' ? server.password : this.state.password;  
    const title= document.title.split('|')[0].trim();          

    return (
      <div>
        <Helmet>
          <title>{title} | {t("EDIT_SERVER")}</title>
        </Helmet>
        <div className={`generic-popup ${additionalClass}`}>
          <form noValidate onSubmit={this.onSubmit}>
            <div className={`arteco-form-group ${additionalHeaderClass}`}>
              <h4 className="arteco-form-title main-title">{t('EDIT_SERVER')}</h4>
              <div className="arteco-form-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="ip">{t('ADD_SERVER_IP')}</label>
                      <div className={`ip-container`}>
                        <input type="hidden" />
                        <span
                          ref={this.ipInput}
                          defaultValue={serverIp}
                          error={errors.ip}
                          id="ip"
                          type="text"
                          contentEditable
                          suppressContentEditableWarning={true}
                          role="textbox"
                          className={classnames("form-control input single-line", {
                            invalid: errors.ip || errors.ipIncorrect
                          })}
                          readOnly
                          onInput={(e) => {
                            if(e.target) {
                              e.target.textContent = serverIp;
                            }
                          }}
                        
                        >
                          {serverIp}
                        </span>                                              
                      </div>
                      <small className="text-danger">
                        {errors.ip}
                        {errors.ipIncorrect}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="ip">{t('SERVER_PORT')}</label>
                      <input
                        disabled 
                        onChange={this.onChange}
                        defaultValue={serverPort}
                        error={errors.port}
                        id="port"
                        type="number"
                        className={classnames("form-control ", {
                          invalid: errors.port || errors.portIncorrect
                        })}
                      />
                      <small className="text-danger">
                        {errors.port}
                        {errors.portIncorrect}
                        {errors.servererror}
                      </small>
                      {(Number.isInteger(Number(serverPort)) != true && serverPort != "") || (serverPort < 0 && serverPort != "") || (serverPort > 65535) ? <small className="text-danger">
                        {t('INVALID_PORT_ERROR')}
                      </small>
                        :
                        ""
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="username">{t('SERVER_USERNAME')}</label>
                      <input
                        readOnly 
                        onChange={this.onChange}
                        defaultValue={serverUsername}
                        error={errors.username}
                        id="username"
                        type="username"
                        className={classnames("form-control ", {
                          invalid: errors.username || errors.passwordincorrect
                        })}
                      />
                      <small className="text-danger">
                        {errors.username}
                        {errors.passwordincorrect}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="FirstName">{t('SERVER_PASSWORD')}</label>
                      <input
                        onChange={this.onChange}
                        defaultValue={serverPassword}
                        error={errors.password}
                        id="password"
                        type="password"
                        className={classnames("form-control ", {
                          invalid: errors.password || errors.passwordincorrect
                        })}
                      />
                      <small className="text-danger">
                        {errors.password}
                        {errors.passwordincorrect}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-1 d-flex justify-content-center pt-3">
                <a
                  onClick={this.cancel}
                  className="btn btn-large mr-2"
                >
                  {t('CANCEL_SERVER')}
                </a>
                <button
                  type="submit"
                  className="btn btn-large"
                >
                  {t('SAVE_BTN')}
                </button>

              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  error: getServersError(state),
  auth: getAuthentication(state),
  errors: getErrrors(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateServer: (newServer, history, callback) => dispatch(updateServer(newServer, history, callback)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Edit))