import React, { Component } from 'react'
import { connect } from 'react-redux'

//import translations
import { withTranslation } from 'react-i18next';
import { getThumbData, isEnhanceInProgress } from '../../reducers/featuresReducer';
import { intervalOperations, serverStringToDate } from '../../helpers/timeHelpers';
import Spinner from '../Spinner/Spinner';
import ImageLazyLoad from '../CustomLazyLoadImages/CustomLazyLoadImages';
import i18n from '../../i18n';
import ArtecoPlayer from '../ArtecoPlayer/ArtecoPlayer';
import { enhanceImage } from '../../actions/downloadsActions';
import { getUserPrefs, isThumbEnhancementSupported } from '../../reducers/userPrefsReducer';
import { TrendingUp as ScaleUpIcon } from 'react-feather';
import { PlayCircle as PlayIcon } from 'react-feather';
import { Image as ImageIcon } from 'react-feather';
import { Aperture as CreateEventIcon } from 'react-feather';
import { Download as DownloadImageIcon } from 'react-feather';
import { Camera as ScreenshotIcon } from 'react-feather';
import { CheckCircle as OkIcon } from 'react-feather';
import { getFile } from '../../actions/downloadsActions';
import ExportButton from './ExportButton';
import html2canvas from 'html2canvas';
import { customEvents, socketEvents } from '../../config/eventsAndStorage';
import { ArtecoToast } from '../Toast/ArtecoToast';
import { wsOldEventPayLoad } from '../dashboard/wsActionsPayloads/wsPayloads';
import { isSSEAvailable } from '../../reducers/downloadsReducer';

import { X as CloseIcon } from 'react-feather';
import { getAuthentication } from '../../reducers/authReducer';
import PrivacyDisplay from '../ArtecoPlayer/PrivacyDisplay';

import { Shield as PrivacyIcon } from 'react-feather';
import { ShieldOff as PrivacyOffIcon } from 'react-feather';
import { CamerasPrivacyBlur } from '../../helpers/serverCredentials';


class ThumbDataDisplay extends Component {
  constructor(props) {
    super(props);    

    this.state = {
      privacy: true,    
      showPlayer: false,
      eventFormShown: false,
      eventDescription: '',
      eventCreated: false ,
      error: false,
      blurme:false
    }

    this.PLAY_INTERVAL = 3;

  }

  componentDidMount() {
    document.addEventListener(customEvents.updatePlayersTime, this.attachTimeListener);   
    this.setState({
      blurme:this.props.thumbData?.shouldIBlur
    }) 
  }
  componentWillUnmount() {    
    document.removeEventListener(customEvents.updatePlayersTime, this.attachTimeListener);
  }  

  attachTimeListener = (event) => {    
    this.handleTimeUpdate(event);    
  }

  handleTimeUpdate = (event) => {
    const { thumbData } = this.props;      
    const { now, server, device } = thumbData;

    const detail = event.detail;


    if (detail.artecoId === device.artecoId && detail.currentServerTime) {     
      
      this.setState({
        videoTime: detail.currentServerTime
      })
    }
  }
  

  getFileAction = () => {
    const { userPrefs, auth, thumbData, getFile } = this.props
    const {imagePath} = thumbData;

    getFile(imagePath, auth, userPrefs);
  }

  getVideoScreenshot = async (download = false) => {
    const displayedVideo = document.querySelector('.thumbdata-display video');
  
    if (!displayedVideo || !(displayedVideo instanceof HTMLVideoElement)) {
      console.error('Invalid or missing video element.');
      return;
    }
  
    try {
      const canvas = document.createElement('canvas');
      canvas.width = displayedVideo.videoWidth;
      canvas.height = displayedVideo.videoHeight;
  
      const context = canvas.getContext('2d');
      context.drawImage(displayedVideo, 0, 0, canvas.width, canvas.height);
  
      const dataURL = canvas.toDataURL('image/jpeg');
      this.setState({ base64Image: dataURL });

      if(download) {
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'div_screenshot.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      return dataURL;
  
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      return null;
    }
  }
  

  togglePlayer = () => {
    this.setState({
      showPlayer: !this.state.showPlayer
    })
  }

  toggleEventForm = () => {
    this.setState({
      eventFormShown: !this.state.eventFormShown
    })
  }

  enhance = () => {
    const { thumbData, enhanceImage, userPrefs, auth } = this.props
    const { now, device, server } = thumbData;

    enhanceImage(now, userPrefs, device, server, auth);
  }

  onThumbLoad = (img) => {
    const image = img.target;
    this.setState({
      imageWidth: image.naturalWidth,
      imageHeight: image.naturalHeight
    })    

    // Creazione di un canvas per ottenere la stringa base64 dell'immagine
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    const base64Image = canvas.toDataURL('image/jpeg', 0.8);
    this.setState({ base64Image });
  }

  createEvent = async () => {
    const { userPrefs, thumbData, t } = this.props;
    if (!thumbData || !userPrefs) return false;
  
    const { now, server, device } = thumbData;
    const { showPlayer, videoTime } = this.state;
  
    const eventEndTime = intervalOperations(now, `00:${Math.floor(this.PLAY_INTERVAL).toString().padStart(2, '0')}:00`, 'add');
  
    const {
      eventDescription,
      imageHeight,
      imageWidth,
    } = this.state;
  
    let eventImage;
  
    if (showPlayer) {
      eventImage = await this.getVideoScreenshot(false);
    } else {
      eventImage = this.state.base64Image;
    }
  
    this.setState({
      eventCreated: true,
      eventDescription: '',
      eventFormShown: false,
    }, () => {
      const payloadToSend = wsOldEventPayLoad(
        device.id,
        eventDescription,
        undefined,
        showPlayer ? videoTime : now,
        eventEndTime,
        userPrefs.evtDefaultColor ? userPrefs.evtDefaultColor : "#000000",
        eventImage,
        imageWidth,
        imageHeight
      );
  
      const event = new CustomEvent(socketEvents.eventManagement, {
        detail: {
          payload: payloadToSend,
          server_id: server._id,
        },
      });
  
      document.dispatchEvent(event);
      ArtecoToast("success", t("GENERATE_BOOKMARK_EVENT_SUCCESS"));
    });
  }
  


  shouldComponentRender() {
    const { thumbData } = this.props

    if (!thumbData) return false;    

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.thumbData.mediaUri !== prevProps.thumbData.mediaUri) {
      this.setState({
        enhanceImage: false,
        eventCreated: false,
        eventFormShown: false,
        error: false
      })
    }

    if(this.props.sseAvailable !== prevProps.sseAvailable || this.props.isEnhancementSupported !== prevProps.isEnhancementSupported ) { 
      if(!this.props.sseAvailable) {
        this.setState({
          error: true
        })
      }
    }

    if(prevState.videoTime !== this.state.videoTime) {
      this.setState({
        eventCreated: false
      })
    }

    if(prevState.blurme !== this.state.blurme){
      this.setState({
        blurme:this.state.blurme
      }) 
    }
  }
  
  togglePrivacy = () => {
    this.setState({
      blurme:!this.state.blurme
    })
  }

  render() {
    const { t, thumbData, enhanceInProgress, isEnhancementSupported } = this.props;
    const { showPlayer, eventFormShown, error ,blurme} = this.state

    const { device, mediaUri, server, localNow, now, shouldIBlur } = thumbData;

    const additionalClass = 'arteco-thumb-popup-container';

    if (!this.shouldComponentRender()) return <Spinner />

    const eventTime = (thumbData.thumbData && thumbData.thumbData.thumbTime) && `${serverStringToDate(thumbData.thumbData.thumbTime).toLocaleDateString(i18n.language)} ${serverStringToDate(thumbData.thumbData.thumbTime).toLocaleTimeString(i18n.language)}`;

    const badgeTime = (eventTime) ? (<span className='badge time'>{eventTime}</span>) : (<span className='badge time'>NO DATA</span>);

    const videoFrom = intervalOperations(now, `00:00:10`, 'subtract');
    const videoTo = intervalOperations(now, `00:${Math.floor(this.PLAY_INTERVAL).toString().padStart(2, '0')}:00`, 'add');

    const isEnhanced = thumbData && thumbData.enhancedImageSrc && thumbData.origin === device.artecoId;    
    let containerClass = isEnhanced ? 'enhancing-ready' : 'enhancing-not-ready';
    containerClass = enhanceInProgress ? 'enhance-in-progress' : containerClass;

    const playlistId = `${device.artecoId}_${videoFrom}_${videoTo}`;

    const eventBtnClass = eventFormShown ? 'event-form-open' : '';

    const ToggleButton = showPlayer ? (
      <div className='thumbdata-action'>
        <button className='btn icon-btn' onClick={this.togglePlayer} disabled={!isEnhanced}>
          <ImageIcon size={24} />
          <span className='thumbdata-action-label'>{t('THUMB_POPUP_IMAGE')}</span>
        </button>
      </div>
    ) : (
      <div className={`thumbdata-action ${containerClass}`}>
        <button className='btn icon-btn' onClick={this.togglePlayer} disabled={!isEnhanced}>
          <PlayIcon size={24} />
          <span className='thumbdata-action-label'>{t('THUMB_POPUP_VIDEO')}</span>
        </button>
      </div>
    );
    //mostro il pulsante solo se ho le credenziali sul sito e se la tlc ha la privacy attiva
    const showPrivacyBtn =  server?.siteInfo.credentials[CamerasPrivacyBlur] && device?.privacyData?.metadataType === 'PrivacyZone';
    const privcyIcon =  blurme ? <PrivacyOffIcon size={24}/> : <PrivacyIcon size={24} />;
    const privacyLabel = blurme ? t('REMOVE_PRIVACY') : t('SHOW_PRIVACY');

    const displayedImage = (
      <div className={`thumbdata-display-container ${containerClass}`}>       
        {
          enhanceInProgress && (
            <div className='spinner'>
              <Spinner />
            </div>
          )
        }    
        {
          error && (
            <div className='error-message'>
              <div className='error-close'>
                <button className='btn icon-btn' onClick={() => this.setState({ error: false })}>
                  <CloseIcon size={24} />
                </button>
              </div>
              <span>{t('THUMB_POPUP_ENHANCEMENT_ERROR')}</span>
            </div>
          )
        }    
          
        {
          isEnhanced ? (
            <ImageLazyLoad
            token={server.access_token}
            placeholderSrc="/sample/image-placeholder.png"                
            threshold={.3}
            src={thumbData.enhancedImageSrc}
            width={'100%'} 
            className='thumb enhanced-thumb'            
            thumbType='thumb'  
            afterLoadCB={this.onThumbLoad}            
            />
          ):(
            <ImageLazyLoad
              token={server.access_token}
              placeholderSrc="/sample/image-placeholder.png"                
              threshold={.3}
              src={mediaUri}
              width={'100%'} 
              className='thumb original-thumb'
              now={localNow}
              thumbType='thumb'  
              afterLoadCB={this.onThumbLoad}           
            />
          )
        } 
        {blurme && <PrivacyDisplay shouldAddPrivacyLayer={ blurme}/>}
      </div>
    );

    return (
      <div className={`thumbdata-display ${additionalClass}`}>
        <div className='thumbdata-body'>
          <div className={`thumbdata-actions ${eventBtnClass}`}>
            {
              isEnhancementSupported && (
                <div className='thumbdata-action'>
                  <button className={`btn icon-btn enhance-btn ${containerClass}`} onClick={this.enhance} disabled={isEnhanced || enhanceInProgress}>
                    <ScaleUpIcon size={24} />
                    <span className='thumbdata-action-label'>{t('THUMB_POPUP_ENHANCE')}</span>
                  </button>
                </div>
              )
            }
            {isEnhancementSupported && ToggleButton}
            {
              isEnhanced && showPlayer && (
                <div className='thumbdata-action'>
                  <ExportButton playlistId={playlistId} deviceData={device} />
                </div> 
              )
            }
            {
              showPlayer && (
                <div className='thumbdata-action'>
                  <button className='btn icon-btn' onClick={() => this.getVideoScreenshot(true)} disabled={!isEnhanced}>
                    <ScreenshotIcon size={24} />
                    <span className='thumbdata-action-label'>{t('THUMB_POPUP_SCREENSHOT')}</span>
                  </button>
                </div>
              )
            }  
            {
              (!showPlayer && isEnhancementSupported) && (
                <div className={`thumbdata-action ${containerClass}`}>
                  <button className={`btn icon-btn `} onClick={this.getFileAction} disabled={!isEnhanced}>
                    <DownloadImageIcon size={24} />
                    <span className='thumbdata-action-label'>{t('THUMB_POPUP_DOWNLOAD_IMAGE')}</span>
                  </button>
                </div>
              )
            }         
            <div className={`thumbdata-action event-form-action ${eventBtnClass}`} >
              <button className='btn icon-btn' onClick={this.toggleEventForm} disabled={this.state.eventCreated}>
                <CreateEventIcon size={24} />
                <span className='thumbdata-action-label'>{t('THUMB_POPUP_TAG_EVENT')}</span>
              </button>
              <input
                type='text'
                placeholder={t('THUMB_EVENT_PLACEHOLDER')}
                className='form-control event-description'
                value={this.state.eventDescription}   // Aggiunto valore dallo stato
                onChange={(e) => this.setState({ eventDescription: e.target.value })}  // Aggiunto gestore per aggiornare lo stato
              />
              <button className='btn icon-btn ok-btn' onClick={this.createEvent}>
                <OkIcon size={24} className='ok'/>
              </button>
            </div>
            { showPrivacyBtn ? (
              <div className='thumbdata-action'>
                <button className="btn icon-button privacy-button"  onClick={this.togglePrivacy} >
                 {privcyIcon}
                <span className='thumbdata-action-label'>{privacyLabel}</span>
              </button>
              </div>
              ) : ''
            }
          </div>
          <div className='thumbdata-image'>
            <div className='thumbdata-badge'>
              {!showPlayer && badgeTime}
              <span className='badge camera-name'>{device.descr}</span>
            </div>
            {!showPlayer && displayedImage}
            {
              showPlayer && (
                <ArtecoPlayer
                  artecoId={device.artecoId} // arteco id of the element
                  key={device.artecoId} // key of the element
                  from={videoFrom} // start of stream (if rec)
                  to={videoTo} // end if stream (if rec)
                  isPopup={true} // ispopup mode
                  useWebRTC={false} // force webrtc for live
                  allowBackToLive={false} // play di un evento ?
                  allowOnlyPIP={false}
                  forcePlayBackMode={true} 
                  isPlayback={true}
                  //hidePrivacy={true}
              />
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  thumbData: getThumbData(state),
  enhanceInProgress: isEnhanceInProgress(state),
  userPrefs: getUserPrefs(state),
  auth: getAuthentication(state),
  isEnhancementSupported: isThumbEnhancementSupported(state),
  sseAvailable: isSSEAvailable(state)
})


export default connect(
  mapStateToProps,
  {enhanceImage, getFile}
)(withTranslation()(ThumbDataDisplay))