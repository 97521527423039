import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { RESET_AUTHENTICATION_ERROR, RESET_STATE_ON_OFFLINE } from "./actions/types";

const initialState = {};
const middleware = [thunk];



const resetEnhancer = rootReducer => (state, action) => {
  if (action.type !== RESET_AUTHENTICATION_ERROR && action.type !== RESET_STATE_ON_OFFLINE) return rootReducer(state, action);
  // Resets state logging out the user
  const newState = rootReducer(undefined, {});
  // Resets the state keeping the user logged in
  const newOfflineState = rootReducer(Object.assign({}, {
    auth: state.auth, 
    networkStatus: {offline: true}, 
    shouldAppUpdate: state.shouldAppUpdate,
    // Keep the user prefs at disconnection
    userPrefs: {...state.userPrefs},
    events: {...state.events, showPopup: false, showEvents: false},

  }), {});
  return action.type === RESET_AUTHENTICATION_ERROR ? newState : newOfflineState;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  resetEnhancer(rootReducer), 
  initialState, 
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
