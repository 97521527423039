import {
  ADD_TO_CALL,
  ADD_TO_CHAT,
  REMOVE_FROM_CHAT,
  CLOSE_CALL,
  SHOW_CHAT_PANEL,
  SHOW_CHAT_CONTAINER,
  SHOW_CALL_PANEL,  
  SOMEONE_IS_CALLING,
  NEW_MESSAGE,
  MESSAGE_READ,
  ACTIVATE_LAST_CHAT,
  ACTIVATE_CALL_CHAT,
  FETCH_CHAT_MSGS_PENDING,
  FETCH_CHAT_MSGS_SUCCESS,
  FETCH_CHAT_MSGS_ERROR,
  CHAT_DELETED,
  SERVER_UPDATE_USER_LIST,
  SHOW_CHAT_LIST
} from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  chat: [],
  textChat: [],
  activeChat: null,
  pendingChatMsgs: false,
  error: null,
  showChatPanel: false,
  showCallPanel: false,
  showChatContainer: false,
  showChatList: false
};

function isChattingAlready(textChat, fromID) {
  if(!textChat) return false;
  return textChat.find(user => user.socketId === fromID);
}

export function ChatReducer(state = initialState, action) {
  switch (action.type) {
    case SERVER_UPDATE_USER_LIST:
      
      let updatedActiveChat = state.activeChat;
      if(updatedActiveChat) {
        const activeChatUser = action.payload.userList.find(user => user.socketId === updatedActiveChat);
        if(activeChatUser && !activeChatUser.visible) {
          updatedActiveChat = null;
        }
      }
      return {
        ...state,
        activeChat: updatedActiveChat
      }
    case FETCH_CHAT_MSGS_ERROR:
      return {
        ...state,
        pendingChatMsgs: false,
        error: action.error
      }
    case FETCH_CHAT_MSGS_PENDING:
      return {
        ...state,
        pendingChatMsgs: true
      }
    case FETCH_CHAT_MSGS_SUCCESS:
      const {messages, users} = action.payload;

      const normalizedMessages = messages.map(message => {
        return {          
          timestamp: message.timestamp,
          text: message.message,
          sentOn: message.sentOn,
          fromEmail: message.from.email,
          displayName: message.from.displayName
        }
      })

      let textChatWithHistory = state.textChat.map(user => {
        if(user.email !== users.to_login) return user;

        return {
          ...user,
          oldMessages: normalizedMessages 
        }
      })

      return {
        ...state,
        textChat: textChatWithHistory,
        pendingChatMsgs: false
      }
    case CHAT_DELETED:
      let deletedChat = state.textChat.map(user => {        
        if(user.email !== action.payload.toEmail) return user;
        
        return {
          ...user,          
          messageList: {
            ingoingMessages: [],
            outgoingMessages: []
          },
          oldMessages: []
        }        
        
      })
      return {
        ...state,
        textChat: deletedChat
      }
    case MESSAGE_READ:
      let readMessages = state.textChat.map(user => {
        if(user.socketId !== state.activeChat) return user;

        return {
          ...user,
          messageList: {
            ingoingMessages: undefined,
            outgoingMessages: undefined
          },
        }
      })
      return {
        ...state,
        textChat: readMessages
      }
    case NEW_MESSAGE:  
      //if not added, add to chat      
      let updatedTextChat = null;
      if(!isChattingAlready(state.textChat, action.payload.message.fromID)) {

        const messageList = action.payload.direction === 'ingoing' ? 
        {
          ingoingMessages: [{
            timestamp: action.payload.message.timestamp,
            text: action.payload.message.text
          }]
        } : 
        {
          outgoingMessages: [{
            timestamp: action.payload.message.timestamp,
            text: action.payload.message.text
          }]
        }

        const userChat =  {
          ...action.payload.message.user,
          messageList
        }

        let currentTextChatMsg = Array.isArray(state.textChat) ? state.textChat : [];
        updatedTextChat = currentTextChatMsg.concat(userChat);
      } else {

        updatedTextChat = state.textChat.map(user => {
          if(user.socketId !== action.payload.message.fromID) return user;
          
          const messageList = action.payload.direction === 'ingoing' ? 
          {
            ingoingMessages: user.ingoingMessages ? [...user.ingoingMessages, {
              timestamp: action.payload.message.timestamp,
              text: action.payload.message.text
            }] : [{
              timestamp: action.payload.message.timestamp,
              text: action.payload.message.text
            }]
          } : 
          {
            outgoingMessages: user.outgoingMessages ? [...user.outgoingMessages, {
              timestamp: action.payload.message.timestamp,
              text: action.payload.message.text
            }] : [{
              timestamp: action.payload.message.timestamp,
              text: action.payload.message.text
            }]
          }

          return {
            ...user,
            messageList
          }
        })        
      }
    
      return {
        ...state,
        textChat: updatedTextChat
      }
    case SOMEONE_IS_CALLING:
      let newUser = false;
      let updatedTextChatCaller = [];

      if(!action.payload.mode) {
        return {
          ...state,
          someonesCalling: action.payload.mode,
          who: action.payload.who
        }
      }

      if(!isChattingAlready(state.textChat, action.payload.who)) { 
        newUser = true;
        let currentTextChat = Array.isArray(state.textChat) ? state.textChat : [];
        updatedTextChatCaller = action.payload.user && currentTextChat.concat(action.payload.user);
      }

      let updatedActiveChatStatus = state.activeChat;
      if(!action.payload.who && !state.showChatPanel) {
        updatedActiveChatStatus = null;
      }

      return {
        ...state,
        someonesCalling: action.payload.mode,
        who: action.payload.who,
        activeChat: updatedActiveChatStatus,
        textChat: newUser ?  updatedTextChatCaller : state.textChat 
      }
    case SHOW_CHAT_PANEL:
      return {
        ...state,
        showChatPanel: action.payload,
        activeChat: (!action.payload) ? null : state.activeChat     
      }
    case SHOW_CHAT_CONTAINER:
      return {
        ...state,
        showChatContainer: action.payload,
      }
    case SHOW_CALL_PANEL:
      return {
        ...state,
        showCallPanel: action.payload        
      }
    case ADD_TO_CALL:
      let chat = Array.isArray(state.chat) ? state.chat : [];

      let mergedUsers = Array.isArray(action.payload.users) && action.payload.users.length > 0 ? action.payload.users : chat;

      let textChat = Array.isArray(state.textChat) ? state.textChat : [];

      let mergedChatUsers = Array.isArray(action.payload.users) && action.payload.users.length > 0 ? action.payload.users : textChat;

      return {
        ...state,
        chat: mergedUsers,
        textChat: mergedChatUsers,
        activeChat: action.payload.users[0].socketId
      }
      case ADD_TO_CHAT:
 
        let textOnlyChat = Array.isArray(state.textChat) ? state.textChat : [];
      
        var ids = new Set(textOnlyChat.map(d => d.socketId));        
        let mergedOnlyChatUsers = Array.isArray(action.payload.users) && action.payload.users.length > 0 ? [...textOnlyChat, ...action.payload.users.filter(d => !ids.has(d.socketId))] : textOnlyChat;
  
        return {
          ...state,          
          textChat: mergedOnlyChatUsers,
          activeChat: action.payload.users[0].socketId
        }
      case REMOVE_FROM_CHAT:

        const strippedChats = state.textChat.filter(user => user.socketId !== action.payload);
        const wasActiveChat = action.payload === state.activeChat;
        return {
          ...state,
          textChat: strippedChats,
          activeChat: wasActiveChat ? null : state.activeChat,
          showChatPanel: wasActiveChat ? false : state.showChatPanel
        }
    case CLOSE_CALL:

      let updatedActiveChatStatusOnClose = state.activeChat;
      if(!state.showChatPanel) {
        updatedActiveChatStatusOnClose = null;
      }

      return {
        ...state,
        chat: [],
        activeChat: updatedActiveChatStatusOnClose
      }
    case ACTIVATE_CALL_CHAT:      
      let activeCallUserId = state.chat.map(user => user.socketId)[0];

      return {
        ...state,
        activeChat: activeCallUserId || (state.someonesCalling && state.who)
      }
    case ACTIVATE_LAST_CHAT:
        let mostRecentMessageSender = null;
        let mostRecentTimestamp = null;
  
        state.textChat.map(user => {
          if(!user.messageList || !user.messageList.ingoingMessages || !Array.isArray(user.messageList.ingoingMessages)) return false;
  
          let moreRecentMessage = user.messageList.ingoingMessages.find(message => message.timestamp > mostRecentTimestamp)
  
          if(moreRecentMessage) {
            mostRecentTimestamp = moreRecentMessage.timestamp;
            mostRecentMessageSender = user.socketId;
          }
        })
  
        return {
          ...state,
          activeChat: mostRecentMessageSender
        }
    case SHOW_CHAT_LIST:
      return {
        ...state,
        showChatList: action.payload
      }
    default:
      return state;
  }
}

export const getUsersInCall = state => {
  return state.chat.chat || [];
}

export const isInCall = (state, socketId) => {

  if(state.chat.someonesCalling && state.chat.who === socketId) return true;

  if (state.chat.chat.length === 0) return false;
  return state.chat.chat.find(user => user.socketId === socketId) || state.chat.who === socketId;
}

export const isInChat = (state, socketId) => {

  if(state.chat.someonesCalling && state.chat.who === socketId) return true;

  if (state.chat.textChat.length === 0) return false;
  return state.chat.textChat.find(user => user.socketId === socketId) || state.chat.who === socketId;
}

export const isActiveChat = (state, socketId) => {
  if(!state.chat.activeChat) return false;
  return state.chat.activeChat === socketId;
}

export const getActiveChatUser = (state) => {
  const activeChatId = state.chat.activeChat;

  if(!state.chat.textChat) return {}

  return state.chat.textChat.find(user => user.socketId === activeChatId) || {}
}

export const getActiveCallId = (state) => {    
  if(state.chat.someonesCalling) return state.chat.who;
  if(state.chat.chat.length === 0) return undefined;  

  return state.chat.chat[0].socketId || undefined;
}

export const getLastMessageUser = (state) => {
  if(!state.chat.textChat) return null;

  let mostRecentMessageSender = null;
  let mostRecentTimestamp = null;

  state.chat.textChat.map(user => {
    if(!user.messageList || !user.messageList.ingoingMessages || !Array.isArray(user.ingoingMessages)) return false;

    let moreRecentMessage = user.messageList.ingoingMessages.find(message => message.timestamp > mostRecentTimestamp)

    if(moreRecentMessage) {
      mostRecentTimestamp = moreRecentMessage.timestamp;
      mostRecentMessageSender = user.socketId;
    }
  })

  return mostRecentMessageSender;
}

export const isChatPanelShown = (state) => {
  return state.chat.showChatPanel !== undefined ? state.chat.showChatPanel : false;
}

export const isCallPanelShown = (state) => {
  return state.chat.showCallPanel !== undefined ? state.chat.showCallPanel : false;
}

export const isChatContainerShown = (state) => {
  return state.chat.showChatContainer !== undefined ? state.chat.showChatContainer : false;
}

export const isSomeoneCalling = (state) => {
  return state.chat.someonesCalling !== undefined ? state.chat.someonesCalling : false;
}

export const callInProgress = (state) => {
  return getUsersInCall(state).length || isSomeoneCalling(state)
}

export const gotUnreadMessages = (state) => {
  
  if(!state.chat.textChat || !state.chat.textChat.length) return false;
  
  //somma di tutti i messaggi in arrivo nelle varie chat
  let messageCount = 0;
  state.chat.textChat.map(user => {
    if(!user) return false;
    
    const userMsgCount = (user.messageList && user.messageList.ingoingMessages) ? user.messageList.ingoingMessages.length : 0;
    messageCount += userMsgCount;

    return messageCount;
  })

  return messageCount;
  
}

export const gotUnreadMessagesFromUser = (state, email) => {
  if(!state.chat.textChat || !state.chat.textChat.length) return false;
  
  let messageCount = 0;  

  state.chat.textChat.map(user => {
    if(!user) return false;

    if(user.email === email) {
      const userMsgCount = (user.messageList && user.messageList.ingoingMessages) ? user.messageList.ingoingMessages.length : 0;
      messageCount += userMsgCount;
    }

    return messageCount;
  })

  return messageCount;
}

export const getOldMessagesFromUser = (state, socketId) => {  

  if(!state.chat.textChat || !state.chat.textChat.length) return false;
  
  let messages = [];  

  state.chat.textChat.map(user => {
    if(!user) return [];

    if(user.socketId === socketId) {
      messages = user.oldMessages ? user.oldMessages : [];      
    }
    
  })

  return messages;
}

export const gotUnreadMessagesFromActiveChat = (state) => {
  
  if(!state.chat.textChat || !state.chat.textChat.length) return false;
  
  let messageCount = 0;
  let activeChat = state.chat.activeChat;

  if(!activeChat) return false;

  state.chat.textChat.map(user => {
    if(!user) return false;

    if(user.socketId === activeChat) {
      const userMsgCount = (user.messageList && user.messageList.ingoingMessages) ? user.messageList.ingoingMessages.length : 0;
      messageCount += userMsgCount;
    }

    return messageCount;
  })

  return messageCount;
  
}

export const getChatMsgsPending = state => state.chat.pendingChatMsgs;
export const getChatMsgsError = state => state.error;

export const isChatListShown = (state) => {
  return state.chat.showChatList;
}
