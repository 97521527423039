import {
  UPDATE_APP
} from "../actions/types";

const initialState = {
  shouldAppUpdate: false,
};

export const AppStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APP:
      return {
        ...state,
        shouldAppUpdate: action.payload,
      }
    default:
      return state;
  }  
}

export const checkAppUpdates = state => state.appStatus.shouldAppUpdate;