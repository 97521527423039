import axios from 'axios';
import { info, error, logger } from '../helpers/logger';
import { getLanAddress } from '../helpers/network';

import {
  FETCH_RECORDING_PENDING,
  FETCH_RECORDING_SUCCESS,
  FETCH_RECORDING_ERROR,
} from '../actions/types';
import { getOnvifData, getRecordingData } from '../helpers/server';
import { jsDateToArtecoTime } from '../helpers/timeHelpers';

const loggerScope = "fecthRecordings";

function fetchSearchRecordingsPending() {
  return {
    type: FETCH_RECORDING_PENDING,
  }
}

function fetchSearchRecordingsSuccess(searchResults, search) {

  return {
    type: FETCH_RECORDING_SUCCESS,
    payload: searchResults,
    search: search
  }

}

function fetchSearchRecordingErrors(data) {
  return {
    type: FETCH_RECORDING_ERROR,
    payload: data,
  }
}

export const fetchRecordings = (search) => {

  return async dispatch => {

    dispatch(fetchSearchRecordingsPending());


    getRecordingData(search)
      .then(res => {
        const responseData = res;

        if (responseData.result.code !== 0) {

          logger(info, loggerScope, "Errore durante la fetch con server " + search.server.ip + ", codice: " + responseData.result.code);


          const errorData = {
            error: "Error during fetch data, see code for more information",
            code: responseData.result.code,
            serverId: search.server.serverId,
            serverCodename: search.server.codeName,
            serverIp: search.server.ip,
            serverName: search.server.serverName
          }


          dispatch(fetchSearchRecordingErrors(errorData));

          return errorData;
        }



        responseData.server = search.server
        dispatch(fetchSearchRecordingsSuccess(responseData, search));

        return responseData;
      })
      .catch(err => {
        const errorData = {
          error: "Timeout",
          serverId: search.server.serverId,
          serverCodename: search.server.codeName,
          serverIp: search.server.ip,
          serverName: search.server.name,
          code: 403,
        }
        logger(error, loggerScope, "Errore con questo server: " + search.server.ip);
        dispatch(fetchSearchRecordingErrors(errorData));
      })
  }

}



export const fetchRecordingsForEdge = (servers, recSearchFormData, edgeChannels) => {

  // NB
  // per non modificare completamente la struttura dei dati ho fatto in modo
  // che le registrazioni edge vengano vista dalla timeline come se fossero
  // registrazioni native arteco (taggate come 'onvif')
  // questo ha permesso il recupero della struttura dati originaria

  return async dispatch => {

    dispatch(fetchSearchRecordingsPending());

    const fetchSessionInfos = server => {
      const serverPromises = server.edgeChannels.map(singleChannel => {

        const data = {
          server: singleChannel.serverObj,
          deviceData: singleChannel,
        }

        return getOnvifData(data)
          .then(res => {

            if (server.nodeServer && res.root && res.root.result.code === 0) {
              const onvifSession = {
                "id": singleChannel.id,
                "type": 3,
                "eyeId": 0,
                "deviceId": singleChannel.id,
                "deviceType": 1,
                "serverCodename": server.codeName,
                "serverRealname": server.name,
                "typeInHumanForm": "MAIN_RECORDER_SESSION",
                "startTime": jsDateToArtecoTime(new Date(res.root.info.urlInfo.dataFrom)),
                "endTime": jsDateToArtecoTime(new Date(res.root.info.urlInfo.dataUntil)),
                "addInfo": res.root.info.urlInfo
              }

              return onvifSession;
            } else {
              const errorData = {
                error: "Timeout",
                serverId: server.serverId,
                serverCodename: server.codeName,
                serverIp: server.ip,
                serverName: server.name,
                code: 403,
              }
              logger(error, loggerScope, "Errore con questo server: " + server.ip);
              dispatch(fetchSearchRecordingErrors(errorData));
            }
          });
      });

      return Promise.all(serverPromises)
        .then(results => {

          return {
            "sessions": {
              "session": results
            },
            "events": {
              "event": []
            },
            "info": { // useless data ...
              "totalEvents": 0,
              "totalSessions": 0,
              "from": recSearchFormData.start,
              "to": recSearchFormData.end,
              "channels": 0,
              "devices": 0,
            },
            "result": { // faking the code ... if we've got an answer is ok
              "code": 0,
              "msg": "Operation Succeeded"
            },
            "server": {
              "codeName": server.codeName,
              "protocol": server.protocol,
              "ip": server.ip,
              "isLocal": server.isLocal,
              "port": server.port,
              "serverId": server._id,
              "serverName": server.name,
              "mediaSecret": server.MediaSecret,
              "access_token": server.access_token,
              "nodeServer": server.nodeServer,
              "timezone": server.timezone
            }
          };
        })
        .catch(error => {
          console.log(error);
          const errorData = {
            error: "Timeout",
            serverId: server.serverId,
            serverCodename: server.codeName,
            serverIp: server.ip,
            serverName: server.name,
            code: 403,
          }
          logger(error, loggerScope, "Errore con questo server: " + server.ip);
          dispatch(fetchSearchRecordingErrors(errorData));
        
        });
    };


    servers.forEach(server => {
      // aggiorno la lista dei server aggiungendo la chiave edgeChannels che contiene solo telecaemre edge e per ognuna aggungo al chiave serverObj
      const updatedServer = { ...server };
      updatedServer.edgeChannels = edgeChannels.filter(channel => channel.serverCodename === server.codeName);
      updatedServer.edgeChannels.forEach(channel => {
        channel.serverObj = updatedServer;
      });

      const searchData = {
        "startTime": recSearchFormData.start,
        "endTime": recSearchFormData.end,
        "recSearchFormData": recSearchFormData,
        "server": {
          "codeName": server.codeName,
          "protocol": server.protocol,
          "ip": server.ip,
          "isLocal": server.isLocal,
          "port": server.port,
          "serverId": server._id,
          "serverName": server.name,
          "mediaSecret": server.MediaSecret,
          "access_token": server.access_token,
          "nodeServer": server.nodeServer
        }
      };

      const mydataPromise = fetchSessionInfos(updatedServer);

      return mydataPromise.then(mydata => {
        dispatch(fetchSearchRecordingsSuccess(mydata, searchData));
      });
    });
  };

}
