import React, { useState, useEffect, useRef } from 'react';

const ResizableContainer = ({ children }) => {
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      setContainerSize({ width, height });
    });
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <span ref={containerRef} className="resize-container">
      {React.cloneElement(children, { containerSize })}
    </span>
  );
};

export default ResizableContainer;
