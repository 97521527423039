import React, { Component, } from "react"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setShouldAppUpdate } from "../../actions/appStatusActions";
import { serviceWorkerEvents } from "../../config/eventsAndStorage";
import { appPaths } from "../../config/paths";
import { checkAppUpdates } from "../../reducers/appStatusReducer";
import { ArtecoToast } from "../Toast/ArtecoToast";


class ServiceWorkerUpdater extends Component {
  constructor() {
    super();
    this.state = {
      registration: null,
      sw: null,
      refreshPage: false,
      showToast: false,
      redirect: false,
    }
  }

  componentDidMount() {
    const { setShouldAppUpdate } = this.props;
    // listens to new service worker updates sent by ServiceWorkerRegistration.js
    window.addEventListener(serviceWorkerEvents.serviceWorkerUpdated, (data) => {
      const registration = data.detail;

      if (!!registration.waiting) {
        // Wait for the service worker to activate to refresh the page 
        registration.waiting.addEventListener('statechange', (e) => {
          if (e.target.state === 'activated' && (!!registration.waiting === false)) {
            this.setState({
              refreshPage: true,
            })
          }
        })

        setShouldAppUpdate(true);

        this.setState({ registration, showToast: true });
      }
    });

    // if there's a page reload with a waiting service worker
    // rebuild the ServiceWorkerRegistration object
    if (!this.state.registration && navigator.serviceWorker != null) {
      navigator.serviceWorker
        .getRegistration(`${process.env.PUBLIC_URL}/service-worker.js`)
        .then(serviceWorker => {
          if (serviceWorker) {
            // if there's a waiting service worker (after page reload)
            if (!!serviceWorker.waiting) {
              // Wait for the service worker to be activated to refresh the page 
              serviceWorker.waiting.addEventListener('statechange', (e) => {

                if (e.target.state === 'activated' && (!!serviceWorker.waiting === false)) {
                  this.setState({
                    refreshPage: true,
                  })
                }
              })
              setShouldAppUpdate(true);
              this.setState({ sw: serviceWorker, showToast: true })
            } // endif serviceWorker.waiting
          } // endif serviceWorker
        });
    }
  }


  componentDidUpdate(prevProps, prevState) {

    if (prevProps.shouldAppUpdate !== this.props.shouldAppUpdate) {

      if (this.state.registration || (!this.state.registration && this.state.sw)) {
        this.updateApp(this.state.registration ?? this.state.sw);
      } // endif state
    } // endif props

    // The service worker has been detected therefore it is time to show the arteco toast
    if ((prevState.showToast !== this.state.showToast) && this.state.showToast) {
      const { t } = this.props;
      ArtecoToast("update-app", t('NEW_UPDATE_AVAILABLE'), '', () => {
        this.setState({
          redirect: true,
        })
      }); // end artecoToast
    } // endif
  }

  updateApp(registration) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }

  render() {
    if (this.state.refreshPage) window.location.reload();

    if (this.state.redirect) {
      localStorage.removeItem('filters');
      window.location.href = appPaths.myaccount;
      return (
        <></>
      );
    }
    return (
      <></>
    )
  }
}


const mapStateToProps = state => {
  return {
    shouldAppUpdate: checkAppUpdates(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShouldAppUpdate: shouldAppUpdate => dispatch(setShouldAppUpdate(shouldAppUpdate)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ServiceWorkerUpdater));