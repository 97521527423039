
import axios from 'axios';

import {
  SHOW_TIME_SEARCH_PANEL,
  UPDATE_PLAYLIST,
  RESET_PLAYLISTS,
  EXPORT_IS_PENDING, 
  EXPORT_IS_READY,
  DOWNLOAD_READY,
  DOWNLOAD_IN_PROGRESS,
  THUMB_POPUP_ENHANCING_IS_READY,
  SET_EDGE_PLAYING,
  RESET_PLAYLISTS_ERRORS_BY_ARTECOID
} from "../actions/types";

import {EXPORT_SERVICE_API, EXPORT_SERVICE_FILES_API} from '../config/ExportServiceConfg';
import { error, logger } from '../helpers/logger';


export const updatePlaylistStatus = (payload) => dispatch => {  
  dispatch({
    type: UPDATE_PLAYLIST,
    payload: payload
  })
}

export const resetPlaylists = () => dispatch => {
  dispatch({
    type: RESET_PLAYLISTS
  })
}

export const resetPlaylistsErrorByArtecoId = (artecoId) => dispatch => {
  dispatch({
    type: RESET_PLAYLISTS_ERRORS_BY_ARTECOID,
    artecoId
  })
}

export const toggleTimeSearchPanel = (showTimeSearch) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_TIME_SEARCH_PANEL,
    payload: showTimeSearch
  })
}

export const exportVideo = (playlistInfo, userPrefs) => dispatch => {
  const EXPORT_API = `${userPrefs.exportServiceEndpoint}${EXPORT_SERVICE_API}/export-video-ffmpeg`;

  dispatch({
    type: EXPORT_IS_PENDING,
    payload: playlistInfo.playlistId
  })

  // dispatch({
  //   type: DOWNLOAD_IN_PROGRESS,
  //   payload: {
  //     owner: playlistInfo.owner,
  //     ownerName: playlistInfo.ownerName,
  //     playlistId: playlistInfo.playlistId,
  //     artecoId: playlistInfo.artecoId,
  //     serverCodename: playlistInfo.serverCodename,
  //     recPath: playlistInfo.recPath,
  //     privacyData: playlistInfo.metadataHistory,
  //     status: "in progress",
  //     path: "",
  //   }
  // })

  axios
  .post(EXPORT_API, playlistInfo)
  .then((exportResult) => {
    dispatch({
      type: EXPORT_IS_READY,
      payload: {
        playlistId: playlistInfo.playlistId,
        path: exportResult.data.path,
        status: exportResult.data.status,
      }
    })

    dispatch({
      type: DOWNLOAD_READY,
      payload: {
        file: exportResult.data.file
      }
    })
  })
  .catch(err => {
    logger(error, 'exportVideo',err);
  })
}

export const setEdgeIsPlaying = (payload) => dispatch => {  
  dispatch({
    type: SET_EDGE_PLAYING,
    payload: payload
  })
}