import {
  SET_OFFLINE
} from "../actions/types";

const initialState = {
  offline: false,
};

export const NetworkStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFLINE:
      return {
        ...state,
        offline: action.payload,
      }
    default:
      return state;
  }  
}

export const getNetworkStatus = state => state.networkStatus;

export const amIOffline = (state) => {
  return state.networkStatus && state.networkStatus.offline;
}