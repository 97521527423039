import React, { Component, createRef } from 'react';
import { logger, error, info } from '../../helpers/logger';
import Spinner from '../Spinner/Spinner';
import { getOemClass } from '../../helpers/network';


class ImageLazyLoad extends Component {
  state = {
    imageSrc: this.props.placeholder,
    loaded: false,
    error: false,
    opacity: 0,
    dropping: false
  };

  imageMap = new Map();

  imageRef = createRef();

  shouldComponentUpdate(nextProps, nextState) {
    if (      
      nextProps.src !== this.props.src ||
      nextProps.token !== this.props.token ||      
      nextState.opacity !== this.state.opacity ||
      nextState.error !== this.state.error ||
      nextState.loaded !== this.state.loaded || 
      nextState.imageSrc !== this.state.imageSrc || 
      nextProps.force
    ) 
    {
      return true;
    }

    return false;
  }

  extractDateFromFileRequest = (request) => {
    const lastSlashIndex = request.lastIndexOf("/");
    const lastDotIndex = request.lastIndexOf(".jpg");
    const filename = request.substring(lastSlashIndex + 1, lastDotIndex);

    return filename;
  }

  handleLoading = () => {
    const { src } = this.props;

    this.setState({loaded: false}, () => {   
      //check also before fetch  
      const imageDate = this.extractDateFromFileRequest(src);      
      const shouldLoad = src && (!this.props.now || imageDate === this.props.now || this.props.thumbType === 'eventThumb');
      if(shouldLoad) {

        this.loadImage(src, this.props.token)
        .then(imageUrl => {                

          //check if it's still needed
          const imageDate = this.extractDateFromFileRequest(src);     
          const shouldLoad = src && (!this.props.now || imageDate === this.props.now || this.props.thumbType === 'eventThumb');

          if(shouldLoad) {

            this.setState({
              error: false,
              imageSrc: imageUrl,
              opacity: 1,
              loaded: true,
              dropping: false
            });
          } else {
            logger(info, "lazy load", "drop image for " + imageDate + "arrived @:" + this.props.now);
            this.setState({              
              dropping: true
            });
          }
          
        })
        .catch(e => {
          this.setState({
            error: true,
          });
          this.props.handleError && this.props.handleError({
            imageError : true
          })
        });
      } else {
        logger(info, "lazy load", "drop fetch for " + imageDate + " @:" + this.props.now);
        this.setState({              
          dropping: true
        });
      }

    })
  }

  setUpObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.handleLoading();
          }
        });
      },
      { threshold: [(this.props.threshold || 100) / 100] }
    );

    if(this.imageRef.current) {
      this.observer.observe(this.imageRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.src !== this.props.src && (this.props.errorFallback === null || this.props.errorFallback === undefined)) {
      if(!this.props.now) {
        this.setUpObserver();
      } else {
        this.handleLoading();
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.props.errorFallback === null || this.props.errorFallback === undefined){
      if (!this.props.now) {
        //carica subito immagine se now non c'è. 
        //Aggiunto perchè l'IntersectionObserver non è istantaneo e quindi in alcuni casi come in alcuni mobile non rileva l'intersezione del componente con  il viewport.
        //succedeva ad esempio nel caso delle mappe statiche.
        this.handleLoading(); 
      } else {
        this.setUpObserver(); // caricamento ritardato
      } 
    } 
  }

  componentWillUnmount() {
    this._isMounted = false; // Imposta il flag prima di smontare
    if (this.observer && typeof this.observer.disconnect === 'function') {
      this.observer.disconnect();
    }
  
    // Annulla l'elaborazione dell'immagine se è ancora in corso
    if (this.imageLoadingPromise) {
      this.imageLoadingPromise.cancelled = true;
    }
  
    this.imageRef = null; // Azzeramento del riferimento
  
    // Revoca l'oggetto URL se è stato creato
    if (this.state.imageSrc) {
      URL.revokeObjectURL(this.state.imageSrc);     
    }
  
    // Rimuovi l'immagine dalla cache
    this.imageMap.delete(this.props.src);
     
  }
  

  onImageClick = () => {
    const { imageClickCB } = this.props

    imageClickCB && imageClickCB(this.state.imageSrc)
  }

  
  loadImage = async (url, token) => {
    if (this._isMounted) {
      if (!this.imageMap.has(url)) {
        // Crea una promessa cancellabile
        const imagePromise = new Promise(async (resolve, reject) => {
          try {
            const authHeader = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }

            const authHeaderData = url.includes("omniaweb") ? authHeader : {};

            const response = await fetch(url, authHeaderData);
  
            if (!response.ok) throw new Error('Could not load image ' + url);
            const blob = await response.blob();
  
            if (blob.size === 0) throw new Error('Corrupted image' + url);
  
            if (!this.imageLoadingPromise.cancelled) {
              const imageUrl = URL.createObjectURL(blob); // Crea l'oggetto URL
              resolve(imageUrl);
            } else {
              reject(new Error('Image loading cancelled'));
            }
          } catch (error) {
            if (!this.imageLoadingPromise.cancelled) {
              reject(error);
            } else {
              reject(new Error('Image loading cancelled'));
            }
          }
        });
  
        this.imageMap.set(url, imagePromise);
        this.imageLoadingPromise = imagePromise; // Memorizza la promessa corrente
      }
  
      return this.imageMap.get(url);
    } else {
      return Promise.reject(new Error('Component is unmounted'));
    }
  }
  

  onLoad = (img) => {
    const { afterLoadCB } = this.props
    afterLoadCB && afterLoadCB(img);
  }
  
  render() {
    const { fallbackImage, src, errorFallback } = this.props;
    const { dropping } = this.state

    const oemClass= getOemClass();
    const oemClassClean = oemClass &&  oemClass.replace("oem-","");
    const imngPath = "/Images/oem/" + oemClassClean +'/'+oemClassClean+ "-logo-login.png";
    const defaultImage= oemClassClean ? imngPath : '/pdfAssets/logoNoPayoff.png';


    if(errorFallback){
      return (
        <div className='lazy-missing-image-error'>
            <p>{errorFallback}</p>
        </div>
      )
    }

    // Se props.src è vuoto, restituisci immediatamente l'immagine di fallback
    if (!src) {
      if (fallbackImage) {
        return (
          <div className='lazy-missing-image'>
            <img
              ref={this.imageRef}
              src={fallbackImage}
              alt={this.props.alt}
              height={this.props.height}
              width={this.props.width}
              className={`${this.props.className} fallback`}
              style={{ opacity: 1 }} // Mostra subito l'immagine di fallback
            />
          </div>
        );
      }
      
      return (
        <div className='lazy-missing-image'>
          <img
            ref={this.imageRef}
            src={defaultImage}
            alt={this.props.alt}
            height={this.props.height}
            width={this.props.width}
            className={`${this.props.className} fallback`}
          />
        </div>
      )
    }

    if (this.state.error) {
      if(fallbackImage) {
        return (
          <ImageLazyLoad            
            src={fallbackImage}
            alt={this.props.alt}
            height={this.props.height}
            width={this.props.width}
            className={`${this.props.className} fallback`}
            placeholder={this.props.placeholder}
            threshold={this.props.threshold}
            token={this.props.token}
          />
        )
      }

      return (
        <div className='lazy-missing-image'>
          <img
            ref={this.imageRef}
            src={defaultImage}
            alt={this.props.alt}
            height={this.props.height}
            width={this.props.width}
            className={`${this.props.className} fallback`}
            style={{ opacity: defaultImage ? 1 :0 }}
          />
        </div>
      )
    }

    return (      
      <div className='lazy-image-container' onClick={this.onImageClick}>
        {
          (dropping) && (
            <Spinner />
          )
        }
        <img
          onLoad={this.onLoad}
          ref={this.imageRef}
          src={this.state.imageSrc}
          alt={this.props.alt}
          height={this.props.height}
          width={this.props.width}
          className={`${this.props.className} lazy-image ${this.state.loaded ? 'loaded' : ''}`}
          style={{ opacity: this.state.opacity }}
        />
      </div>
    );
}

}

export default ImageLazyLoad;
