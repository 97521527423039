import React, { Component } from 'react';

//labels translation
import { withTranslation } from 'react-i18next'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

export class ComponentLoader extends Component {
  render() {
    const {t} = this.props;

    return (
      <ErrorBoundary>
      <div className="component-loader">
        <span>{t('LOADING')} {this.props.what}...</span>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
      </ErrorBoundary>
    )
  }
}

export default withTranslation()(ComponentLoader)
