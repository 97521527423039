import React from 'react';
import { withTranslation } from 'react-i18next'

//https://chan.dev/posts/catch-your-suspense-errors/

/*
I see a lot of people immediately jump to wrapping ErrorBoundary and Suspense together.

It's not a good look.

Separate, they give you nuanced control over your entire view.
Stick layout components in between,
Handle errors closely,
Or have only one ErrorBoundary around a bunch of Suspense components.

The world is your oyster.
Keep 'em separate and keep control.
*/

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    }, () => {})
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { t } = this.props;

    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="error-boundary" >
          <h2>{t('SOMETHING_WENT_WRONG')}</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withTranslation() (ErrorBoundary) ;

