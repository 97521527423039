
import moment from 'moment'
import 'moment/locale/it'  // without this line it didn't work
import 'moment/locale/en-gb'  // without this line it didn't work
import 'moment/locale/es'
import 'moment-timezone';

import i18n from '../i18n.js' // IMPORT YOUR CONFIGURATION

const it_TimeFormat = "L HH:mm:ss";
const en_TimeFormat = "L hh:mm:ss a";

export function getTimeLocalFormat() {
  if (i18n.getPureLanguage() == 'en') {
    return en_TimeFormat;
  }
  return it_TimeFormat;
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isAValidServerDate(date) {
  // Check the format using regex
  if (!/^\d{14}$/.test(date)) {
    return false;
  }

  // Use moment to check if the date is valid
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  const hour = date.substring(8, 10);
  const minute = date.substring(10, 12);
  const second = date.substring(12, 14);

  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;

  return moment(formattedDate, "YYYY-MM-DDTHH:mm:ss").isValid();
}

export function toMoment(date) {
  return moment(date).locale(i18n.language);
}

export function formatDateWithString(date, formatString) {
  return moment(date).locale(i18n.language).format(formatString);
}

export function formatDate(date) {
  return formatDateWithString(date, 'L');
}

export function utcDate(date) {
  return moment(date).valueOf();
}

export function connectorDateToServerDate(time) {
  //serve la specifica "YYYY-MM-DD hh:mm:ss" perchè senza è stato deprecato e su alcuni browser ed ios non funziona senza
  return moment(`${time.year}-${time.month}-${time.day} ${time.hour}:${time.min}:${time.sec}`,"YYYY-MM-DD hh:mm:ss").utc();
}

export function serverStringToDate(serverDate) {
  if(!serverDate) return '';
  let stringServerDate = serverDate.toString();
  let convertedDate = (stringServerDate) ? new Date(stringServerDate.replace(
    /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1/$2/$3 $4:$5:$6+0000'
  )) : '';

  //check date + format (safari)
  if (isValidDate(convertedDate)) return convertedDate;

  //firefox fallback
  convertedDate = (stringServerDate) ? new Date(stringServerDate.replace(
    /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1-$2-$3 $4:$5:$6+0000'
  )) : '';

  return convertedDate;
}

export function serverDateToDisplayDate(serverDate) {
  let convertedDate = serverStringToDate(serverDate);
  let displayDate = moment.utc(Math.floor(convertedDate));
  return displayDate;
}

export function serverDateToUnixDate(serverDate) {
  return serverDateToDisplayDate(serverDate).valueOf();
}

export function unixDateToFormattedDate(unixDate) {
  const dateObject = new Date(unixDate);
  return displayDateToFormattedDate(moment(dateObject));
}

export function displayDateToFormattedDate(displayDate, toLocal = false) {
  if (toLocal) {
    return (displayDate) ? displayDate.local().locale(i18n.language).format(getTimeLocalFormat()).toUpperCase() : '';
  }
  return (displayDate) ? displayDate.locale(i18n.language).format(getTimeLocalFormat()).toUpperCase() : '';
}

export function serverDateToFormattedDate(serverDate, toLocal = false) {

  if (!serverDate) return '';
  return displayDateToFormattedDate(serverDateToDisplayDate(serverDate), toLocal);
}

export function jsDateToArtecoTime(jsDate) {
  try {
    jsDate.toISOString()
  } catch (error) {
    console.error(error);
  }

  const date = jsDate.toISOString().split('T')[0].replace(/-/g, '');
  const time = jsDate.toTimeString().split(' ')[0].replace(/:/g, '');
  return `${date}${time}`
}

export function jsDateToServerDate(jsDate, isUtc = false, format = 'YYYYMMDDHHmmss') {
  if (isUtc) {
    return moment(jsDate).local().format(format);
  }
  return moment(jsDate).utc().format(format);
}


export function jsDateToHumanDate(jsDate) {
  return moment.utc(jsDate).format('HH:mm:ss');
}

export function addDays(serverDate, days) {
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  let convertedDate = serverStringToDate(serverDate);
  return jsDateToArtecoTime(convertedDate.addDays(days))
}

export function getCurrentServerTime() {
  return moment.utc(new Date()).format('YYYYMMDDHHmmss');
}

export function addHours(serverDate, hours) {
  let time = moment.duration(`${Math.abs(hours).toString().padStart(2, '0')}:00:00`);
  let serverDateJs = serverStringToDate(serverDate);
  let dateMoment = moment.utc(serverDateJs);

  let newDate = (hours < 0) ? dateMoment.subtract(time) : dateMoment.add(time);

  let serverNewDate = newDate.format('YYYYMMDDHHmmss');

  return serverNewDate;
}

export function addSecondsToDate(date, seconds) {

  let dateMoment = moment.utc(date);

  return dateMoment.add(seconds, 'seconds');
}

export function intervalOperations(serverDate, duration, operation) {
  let time = moment.duration(duration);
  let serverDateJs = serverStringToDate(serverDate);
  let dateMoment = moment.utc(serverDateJs);

  let newDate = (operation === 'add') ? dateMoment.add(time) : dateMoment.subtract(time);

  let serverNewDate = newDate.format('YYYYMMDDHHmmss');

  return serverNewDate;
}

export function addInterval(serverDate, duration) {
  return intervalOperations(serverDate, duration, 'add');
}

export function intervalOperationsWithMillis(serverDate, duration, operation) {
  let time = moment.duration(duration);
  let serverDateJs = serverStringToDate(serverDate);
  let dateMoment = moment.utc(serverDateJs);

  let newDate = operation === "add" ? dateMoment.add(time) : dateMoment.subtract(time);

  // Aggiunge i millisecondi al formato della data
  let serverNewDate = newDate.format("YYYYMMDDHHmmssSSS");

  return serverNewDate;
}

export function addIntervalWithMillis(serverDate, duration) {
  const [durationHours, durationMinutes, durationSecondsAndMillis] = duration.split(':');
  const durationSeconds = Math.floor(parseFloat(durationSecondsAndMillis));
  const durationMillis = Math.floor(parseFloat(durationSecondsAndMillis) * 1000) % 1000;
  
  const serverDateJs = serverStringToDate(serverDate);
  const dateMoment = moment.utc(serverDateJs);
  
  dateMoment.add({
    hours: parseInt(durationHours),
    minutes: parseInt(durationMinutes),
    seconds: durationSeconds,
    milliseconds: durationMillis,
  });
  
  const serverNewDate = dateMoment.format('YYYYMMDDHHmmss') + dateMoment.millisecond().toString().padStart(3, '0');

  return serverNewDate;
}

export function subtractInterval(serverDate, duration) {
  return intervalOperations(serverDate, duration, 'subtract');
}

export function serverTimeDiff(from, serverTime2, as = 'hours') {
  let moment1 = serverDateToDisplayDate(from);
  let moment2 = serverDateToDisplayDate(serverTime2);
  var duration = moment.duration(moment2.diff(moment1));

  if (as === 'minutes') return duration.asMinutes();
  if (as === 'seconds') return duration.asSeconds();

  return duration.asHours();
}

export function serverTimeDiffMilliseconds(tempo1, tempo2) {
  if (tempo1.length !== 17 || tempo2.length !== 17) {
    throw new Error("I tempi devono essere stringhe di 17 caratteri nel formato YYYYMMDDHHmmssSSS");
  }

  const createDate = (tempo) => {
    return new Date(Date.UTC(
      parseInt(tempo.substring(0, 4), 10),
      parseInt(tempo.substring(4, 6), 10) - 1, // Mesi in JavaScript sono 0-indexed
      parseInt(tempo.substring(6, 8), 10),
      parseInt(tempo.substring(8, 10), 10),
      parseInt(tempo.substring(10, 12), 10),
      parseInt(tempo.substring(12, 14), 10),
      parseInt(tempo.substring(14, 17), 10)
    ));
  };

  const date1 = createDate(tempo1);
  const date2 = createDate(tempo2);

  // Restituisce la differenza (positiva se date1 > date2, negativa altrimenti)
  return date1 - date2;
}

export function getMaxDate(arrayOfDates) {
  // max date from an array of dates
  return moment.max(arrayOfDates);
}

export function getMinDate(arrayOfDates) {
  // max date from an array of dates
  return moment.min(arrayOfDates);
}


export function getDiffBetweenDates(startDate, endDate) {
  return moment(endDate).diff(moment(startDate), 'seconds') // 44700
}

export function getIntervalFromPlaylistId(playlistId) {
  let parts = playlistId.split('_');
  let from = parts[parts.length - 2];
  let to = parts[parts.length - 1];

  return {
    from,
    to
  }
}

export function mongoDateToFormattedDate(date) {
  return displayDateToFormattedDate(new Date(date), true)
}

export function serverTimeFormattedDuration(from, to) {
  let moment1 = serverDateToDisplayDate(to);
  let moment2 = serverDateToDisplayDate(from);

  var duration = moment.duration(moment1.diff(moment2));

  var s = Math.floor(duration.asHours()) + moment.utc(moment1.diff(moment2)).format(":mm:ss");


  return s;
}

export function dateSort(a, b, descReturn, ascReturn) {
  if (moment.isMoment(a) && moment.isMoment(b)) {
    return (a.diff(b) > 0) ? descReturn : ascReturn
  }

  if (moment.isMoment(a) && !moment.isMoment(b)) return descReturn;

  if (!moment.isMoment(a) && moment.isMoment(b)) return ascReturn;

  return 1;

}

export function diffInHours(startTime, endTime) {
  var duration = moment.duration(endTime.diff(startTime));
  var hours = duration.asHours();

  return hours;
}

export function diffInSeconds(startTime, endTime) {
  var duration = moment.duration(endTime.diff(startTime));
  var seconds = duration.asSeconds();

  return seconds;
}


export function addSecondsToServerDate(time, seconds) {

  let unixDate = serverDateToUnixDate(time);
  unixDate = addSecondsToDate(unixDate, seconds);
  let out = jsDateToServerDate(unixDate, false);

  return out;
}


export function getHumanDate() {
  const dateObject = new Date();
  return displayDateToFormattedDate(moment(dateObject));
}

export function serverStringMinor(serverString1, serverString2) {
  if (!serverString1 || !serverString2) {
    return false;
  }
  
  const date1 = new Date(
    serverString1.substring(0, 4),
    parseInt(serverString1.substring(4, 6)) - 1,
    serverString1.substring(6, 8),
    serverString1.substring(8, 10),
    serverString1.substring(10, 12),
    serverString1.substring(12, 14)
  );

  const date2 = new Date(
    serverString2.substring(0, 4),
    parseInt(serverString2.substring(4, 6)) - 1,
    serverString2.substring(6, 8),
    serverString2.substring(8, 10),
    serverString2.substring(10, 12),
    serverString2.substring(12, 14)
  );

  return date1.getTime() < date2.getTime();
}

export function serverStringMajorEqual(serverString1, serverString2) {
  if (!serverString1 || !serverString2) {
    return false;
  }

  const date1 = new Date(
    serverString1.substring(0, 4),
    parseInt(serverString1.substring(4, 6)) - 1,
    serverString1.substring(6, 8),
    serverString1.substring(8, 10),
    serverString1.substring(10, 12),
    serverString1.substring(12, 14)
  );

  const date2 = new Date(
    serverString2.substring(0, 4),
    parseInt(serverString2.substring(4, 6)) - 1,
    serverString2.substring(6, 8),
    serverString2.substring(8, 10),
    serverString2.substring(10, 12),
    serverString2.substring(12, 14)
  );

  return date1.getTime() >= date2.getTime();
}

export function convertUTCToLocal(time, timeZone, outFormat = "YYYYMMDDHHmmss") {
  // Estrai anno, mese, giorno, ora, minuti, secondi dalla stringa di input
  const year = time.substring(0, 4);
  const month = time.substring(4, 6);
  const day = time.substring(6, 8);
  const hour = time.substring(8, 10);
  const minute = time.substring(10, 12);
  const second = time.substring(12, 14);

  // Crea un oggetto data UTC
  const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

  // Utilizza la libreria moment.js per la conversione del fuso orario
  const localDate = moment(utcDate).tz(timeZone);

  // Ricombina la data e l'ora nel formato yyyyMMddHHmmss
  const localTime = localDate.format(outFormat);

  return localTime;
}

