import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import RenameLayout from '../layouts/RenameLayout';


//actions
import { getEditingLayout, isEditLayoutPopupShown } from '../../reducers/featuresReducer';
import { showEditLayoutPopup } from '../../actions/featuresActions';

export class EditLayout extends Component {
  

  closePopup = () => {
    const { showEditLayoutPopup } = this.props;

    showEditLayoutPopup(false);
  }

  render() {
    const { isEditLayoutPopupShown, editingLayout } = this.props;

    return (
      <Popup
        open={isEditLayoutPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='arteco-popupediting-popup'>          
          <RenameLayout 
            isPopup={true}
            layoutToEdit={editingLayout}
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isEditLayoutPopupShown: isEditLayoutPopupShown(state),
  editingLayout: getEditingLayout(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showEditLayoutPopup: (show) => dispatch(showEditLayoutPopup(show)),
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditLayout)