import axios from 'axios';
import { error, logger } from '../helpers/logger';

import {
    ADD_TO_CALL,
    ADD_TO_CHAT,
    CLOSE_CALL,
    NEW_MESSAGE,
    SHOW_CALL_PANEL,
    SHOW_CHAT_PANEL,
    SOMEONE_IS_CALLING,
    MESSAGE_READ,
    ACTIVATE_LAST_CHAT,    
    ACTIVATE_CALL_CHAT,
    REMOVE_FROM_CHAT,
    CHAT_DELETED,
    SHOW_CHAT_CONTAINER,
    SHOW_CHAT_LIST
} from './types';

export const addToCall = (user) => dispatch => {
  dispatch({
    type: ADD_TO_CALL,
    payload: {
        users: [user]
    }
  })
}

export const addToChat = (user) => dispatch => {
  dispatch({
    type: ADD_TO_CHAT,
    payload: {
        users: [user]
    }
  })
}

export const removeFromChat = (socketId) => dispatch => {
  dispatch({
    type: REMOVE_FROM_CHAT,
    payload: socketId
  })
}
  
export const closeCall = () => dispatch => {
  dispatch({
    type: CLOSE_CALL
  })
}

export const showChatContainer = (show) => dispatch => {
  dispatch({
    type: SHOW_CHAT_CONTAINER,
    payload: show
  })
}

export const showChatPanel = (show) => dispatch => {
  dispatch({
    type: SHOW_CHAT_PANEL,
    payload: show
  })
}

export const showCallPanel = (show) => dispatch => {
  dispatch({
    type: SHOW_CALL_PANEL,
    payload: show
  })
}

export const someonesCalling = (mode, who, user) => dispatch => {
  dispatch({
    type: SOMEONE_IS_CALLING,
    payload: {
      mode: mode,
      who: who,
      user: user
    }
  })
}

export const sendChatMessage = (message, direction) => dispatch => {
  dispatch({
    type: NEW_MESSAGE,
    payload: {
      message,
      direction
    }
  })
}

export const readMessages = () => dispatch => {
  dispatch({
    type: MESSAGE_READ
  })
}


export const activateLastChat = () => dispatch => {
  dispatch({
    type: ACTIVATE_LAST_CHAT
  })
}

export const activateCallChat = () => dispatch => {
  dispatch({
    type: ACTIVATE_CALL_CHAT
  })
}

export const clearChatWithUser = (email) => dispatch => {
  dispatch({
    type: CHAT_DELETED,
    payload: {
      toEmail: email
    }
  })
}

export const deleteChat = (usersData, timestamp) => dispatch => {
  
  let req = {
      "user_login": usersData.user_login,
      "user_password": usersData.user_password,
      "to_login": usersData.to_login,
      "min_timestamp": timestamp
  }

  axios
  .post('api/chat-messages/delete-between-users', req)
  .then(res => {
    dispatch({
      type: CHAT_DELETED,
      payload: {
        deleteData: res.data,
        toSocketId: usersData.to_socket_id
      }
    });
    return res.data;
  })
  .catch(err => logger(error,'chatActions deleteChat',err)  )
}

export const showChatList = (show) => dispatch => {  
  dispatch({
    type: SHOW_CHAT_LIST,
    payload: show
  })
}