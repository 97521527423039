import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';

//icons
import { X as XIcon } from "react-feather";

//actions
import { isExportFeaturesPopupShown } from '../../reducers/featuresReducer';
import { showExportFeaturesPopup } from '../../actions/featuresActions';
import ExportFeaturesPicker from '../Popup/ExportFeaturesPicker';

import { withTranslation } from 'react-i18next'

export class ExportFeatures extends Component {


  closePopup = () => {
    const { showExportFeaturesPopup } = this.props;

    showExportFeaturesPopup(false);
  }

  pickFeatures = (e) => {
    const { showExportFeaturesPopup } = this.props;
    
    
    showExportFeaturesPopup(false);
  }

  render() {
    const { isExportFeaturesPopupShown, t} = this.props;

    return (
      <Popup
        open={isExportFeaturesPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='generic-popup arteco-export-features-popup'>  
          <div className='popup-header'>          
            <h4>{t('EXPORT_FEATURES')}</h4>
            <div className="close" onClick={this.closePopup}>
              <XIcon size={22} />
            </div>
          </div>     
          <p>{t('EXPORT_FEATURES_DESC')}</p>         
          <ExportFeaturesPicker                         
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}            
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isExportFeaturesPopupShown: isExportFeaturesPopupShown(state)  
})

const mapDispatchToProps = dispatch => {
  return {
    showExportFeaturesPopup: (show) => dispatch(showExportFeaturesPopup(show)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ExportFeatures));