import {
  FETCH_ADDITIONAL_PROPERTIES_PENDING,
  FETCH_ADDITIONAL_PROPERTIES_SUCCESS,
  FETCH_SERVER_ADDITIONAL_PROPERTIES_SUCCESS,
  FETCH_ADDITIONAL_PROPERTIES_ERROR,
  FETCH_ADDITIONAL_PROPERTIES_UPDATED,
} from "../actions/types";

const initialState = {
  channels: [],
  peripherals: [],
  error: null,
  pendingAdditionalProperties: false,
};

const isEmpty = require("is-empty");

const globalProperties = ['fisheyeMountType']; // Proprietà globali che devono essere aggiornate per tutti gli utenti

export function additionalPropertiesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVER_ADDITIONAL_PROPERTIES_SUCCESS: {

      // Crea un oggetto per accesso rapido ai canali esistenti nello stato
      let channelsById = state.channels.reduce((acc, channel) => {
        acc[channel.artecoId] = channel;
        return acc;
      }, {});
    
      // Mappa i canali dal payload
      let updatedChannels = Object.values(action.payload).map((payloadChannel) => {
        
        let existingChannel = channelsById[payloadChannel.artecoId];
        
        if (existingChannel) {
          // Se il canale esiste già, aggiorna solo le proprietà globali
          let updatedChannel = { ...existingChannel };
          
          // Aggiorna solo le proprietà globali, se esistono nel payload
          globalProperties.forEach(prop => {
            if (payloadChannel[prop] !== undefined) {
              updatedChannel[prop] = payloadChannel[prop];
            }
          });
    
          return updatedChannel;
        } else {
          // Se il canale non esiste nello stato, aggiungilo direttamente
          return { ...payloadChannel };
        }
      });
    
      // Aggiungi canali che non sono stati aggiornati
      let newChannels = state.channels.filter(channel => 
        !updatedChannels.some(updated => updated.artecoId === channel.artecoId)
      );
    
      return {
        ...state,
        channels: [...updatedChannels, ...newChannels]
      };
    }     
    case FETCH_ADDITIONAL_PROPERTIES_SUCCESS:
    case FETCH_ADDITIONAL_PROPERTIES_UPDATED:

      return {
        ...state,
        pendingAdditionalProperties: false,
        channels: action.payload.channels,
      }
    case FETCH_ADDITIONAL_PROPERTIES_PENDING:
      return {
        ...state,
        pendingAdditionalProperties: true
      };
    case FETCH_ADDITIONAL_PROPERTIES_ERROR:
      return {
        ...state,
        error: action.error,
        pendingAdditionalProperties: false
      }
    default:
      return state;
  }
};



export const getAdditionalProperties = state => state.additionalProperties;
export const getAdditionalPropertiesPending = state => state.additionalProperties.pendingAdditionalProperties;
export const getAdditionalPropertiesError = state => state.error;

export const getAdditionalPropertiesPerChannel = (state, artecoId) => {
  // getChannelAdditionalPropertiesbyArtecoId
  let desiredElement = null;

  for (const element of state.additionalProperties.channels) {
    if (element.artecoId === artecoId) {
      desiredElement = element;
      break;
    }
  }

  return desiredElement ? desiredElement : undefined;
};
