import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import Popup from 'reactjs-popup';
import Edit from '../servers/Edit';


//actions
import { getEditingServer, isEditServerPopupShown } from '../../reducers/featuresReducer';
import { showEditServerPopup } from '../../actions/featuresActions';

export class EditServer extends Component {


  closePopup = () => {
    const { showEditServerPopup } = this.props;

    showEditServerPopup(false);
  }

  render() {
    const { isEditServerPopupShown, editingServer } = this.props;

    return (
      <Popup
        open={isEditServerPopupShown}
        modal={true}
        closeOnDocumentClick={false}
        defaultOpen={false}
        closeOnEscape={false}
      >
        <div className='arteco-popupediting-popup'>
          <Edit
            isPopup={true}
            server={editingServer}
            cancelCallback={this.closePopup}
            submitCallback={this.closePopup}
          />
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  isEditServerPopupShown: isEditServerPopupShown(state),
  editingServer: getEditingServer(state)
})

const mapDispatchToProps = dispatch => {
  return {
    showEditServerPopup: (show) => dispatch(showEditServerPopup(show)),
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditServer)