import React, { Component } from 'react';
import { connect } from 'react-redux';

//components
import AddLayout from './AddLayout';
import AddServer from './AddServer';
import EditLayout from './EditLayout';
import EditServer from './EditServer';
import PopupProperties from './PopupProperties';
import ShareLayout from './ShareLayout';
import ExportFeatures from './ExportFeatures';
import OnvifDataPopup from './OnvifDataPopup';
import ThumbPopup from './ThumbPopup';
import ComingSoonPopup from './ComingSoonPopup';

export class PopupWrapper extends Component {
  render() {
    return (
      <>
        <AddLayout />
        <AddServer />
        <EditLayout />
        <ShareLayout />
        <EditServer />
        <PopupProperties />
        <ExportFeatures />
        <OnvifDataPopup />
        <ThumbPopup />
        {/* <ComingSoonPopup /> */}
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PopupWrapper)