/** @format */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//labels translation
import { withTranslation } from "react-i18next";
import { appPaths } from "../../../config/paths";
import classNames from "classnames";
import { getServers } from "../../../reducers/serversReducer";
import {
  duplicateSerialServerRemove,
  updateServer,
} from "../../../actions/serverActions";
import { isArtecoSerial } from "../../../helpers/server";
import { getAuthentication } from "../../../reducers/authReducer";

//constants

const loggerScope = "ServerNotLicence";
class ServerNotLicence extends Component {
  constructor() {
    super();

    this.state = {
      serial: "",
    };
  }

  shouldComponentRender = () => {
    const { server } = this.props;
    return (
      server._id !== undefined 
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  checkSerial = (serial) => {
    const { servers } = this.props;

    // Iterate through the servers to find a match
    for (const server of servers.servers) {
      if (server?.license?.serial.toLowerCase() === serial.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  onSubmit = (e) => {
    const { server, auth, updateServer, duplicateSerialServerRemove,submitCallback } =
      this.props;
    const { serial } = this.state;

    if (e && e.target) {
      e.preventDefault();
    }
    let matchLicense =
      auth.user.products &&
      serial &&
      auth.user.products.find(
        (product) =>
          product.serial_number.toLowerCase() === serial.toLowerCase()
      );
    let serverIp = `${serial.toUpperCase()}${appPaths.PUBLIC_HOST}`;
    let serverName = `Server: ${serverIp}`;
    const updatedServer = {
      ...server,
      ip: serverIp,
      license: {
        serial: serial.toUpperCase(),
      },
      name: serverName,
      icon: matchLicense ? matchLicense.license_type.toLowerCase() : "onprem",
      licenseType: matchLicense
        ? matchLicense.license_type.toLowerCase()
        : "onprem",
    };
    if (!this.checkSerial(serial)) {
      updateServer(updatedServer, undefined,submitCallback);
      this.setState({
        serial: "",
      });
    } else {
      duplicateSerialServerRemove(server._id);
      this.setState({
        serial: "",
      });
    }
  };

  render() {
    const { server, t } = this.props;
    const { serial } = this.state;
    if (!this.shouldComponentRender()) {
      return false;
    }

    return (
        <div className='server-withoutLicense'>
          <div className='form-group'>
            <label htmlFor='username'>{'Alias Hostname'}</label>
            <input
              readOnly
              defaultValue={server.ip.replace(appPaths.PUBLIC_HOST, "")}
              id='username'
              type='username'
              className={classNames("form-control ")}
            />
          </div>
          <div className='form-group'>
          <label htmlFor='username'>{'S/N Hostname'}</label>
            <input
              onChange={this.onChange}
              id='serial'
              type='text'
              value={serial}
              className={classNames("form-control ", {})}
            />
          </div>
          <button
            className='btn'
            disabled={!isArtecoSerial(serial)}
            onClick={this.onSubmit}
          >
            {t("UPDATE")}
          </button>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuthentication(state),
  servers: getServers(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateServer: (newServer, history, callback) =>
      dispatch(updateServer(newServer, history, callback)),
    duplicateSerialServerRemove: (_id) =>
      dispatch(duplicateSerialServerRemove(_id)),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ServerNotLicence)));
