import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser, resetAuthError } from "../../actions/authActions";
import { Trans, withTranslation } from 'react-i18next'
import { ReactComponent as ArtecoIcon } from './arteco.svg';
import { appPaths } from '../../config/paths';
import Spinner from "../Spinner/Spinner";
import { getAuthentication, getAuthenticationError } from "../../reducers/authReducer";
import queryString from 'query-string';

class Login2023 extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      submitting: false,
      validationErrors: {}
     };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push(appPaths.dashboard); // push user to dashboard when they login
    }
    this.setState({
      submitting: false
    });
  }

  componentDidMount() {
    const { resetAuthError } = this.props;

    if (this.props.auth.isAuthenticated) {
      this.props.history.push(appPaths.dashboard);
    } else {
      resetAuthError();
    }

    const urlParams = queryString.parse(this.props.location.search);

    if (urlParams.email != undefined && urlParams.email != "") {

      this.setState({
        email: urlParams.email,
      })
    }

  }

  onChange = e => {
    const { resetAuthError } = this.props
    this.setState({ [e.target.id]: e.target.value, submitting: false }, () => {
      resetAuthError();
    });

    if (this.state.validationErrors) {
      this.setState({
        validationErrors: {},
      })
    }
  };


  validateUserData = (userData) => {
    // validating the input data 
    const formErrors = {};

    // Check if email is present and valid
    if (!userData.email) {
      formErrors.email = 'LOGIN_MISSING_EMAIL';
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      formErrors.email = 'LOGIN_MALFORMED_EMAIL';
    }

    // Check if password is present
    if (!userData.password) {
      formErrors.password = 'LOGIN_MISSING_PASSWORD';
    }

    // Return the formErrors object if there are formErrors, otherwise return null
    return Object.keys(formErrors).length === 0 ? null : formErrors;
  }


  onSubmit = e => {
    const { resetAuthError } = this.props;

    e.preventDefault();
    resetAuthError();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    // check if any error is in the form
    const formErrors = this.validateUserData(userData);

    if (formErrors != null) {
      this.setState({
        validationErrors: formErrors,
      })
      return;
    }

    this.setState({
      submitting: true
    }, () => {
      this.props.loginUser(userData);
    })

  };
  render() {
    const { t, authError } = this.props;
    const { submitting, validationErrors } = this.state;

    const submitBtnClassName = submitting ? 'btn btn-large btn-submitting' : 'btn btn-large btn-spinner';


    return (
      <div className="loginpage">
        <div className="loginpage-usee-logo-container">
          <div className="loginpage-usee-logo"></div>
        </div>
        <div className="loginpage-form-container">
          <h1>{t('LOGIN_WELCOME')}</h1>
          <div className="info">
            <Trans
              i18nKey="LOGIN_WELCOME_TEXT"
              values={{
                linkText: t('LOGIN_REGISTER_LINK'),
              }}
              components={{
                registationLink: <a
                  href={appPaths.arteco_global_my_arteco} target="_blank" >
                  {t('LOGIN_REGISTER_LINK')}
                </a>
              }}
            />
          </div>
          <form noValidate onSubmit={this.onSubmit}>
            <div className="loginpage-form-element">
              <div className="form-group">
                <label htmlFor="email">{t('LOGIN_EMAIL')}</label>
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  id="email"
                  type="email"
                  placeholder={t('LOGIN_EMAIL')}
                  required={true}
                  disabled={false}
                  data-testid={"email"}
                />
              </div>
              <div className="error-text">
                {validationErrors.email && (
                  <small className="text-danger">{t(validationErrors.email)}</small>
                )}
              </div>


            </div>
            <div className="loginpage-form-element">
              <div className="form-group">
                <label htmlFor="password">{t('LOGIN_PASSWORD')}</label>
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  id="password"
                  type="password"
                  className={"form-control "}
                  placeholder={t('LOGIN_PASSWORD')}
                  required={true}
                  data-testid={"password"}
                />

              </div>
              <div className="error-text">
                 {validationErrors.password && (
                <small className="text-danger">{t(validationErrors.password)}</small>
              )}
              </div>

           
            </div>
            <div className="loginpage-form-errors">

              {
                ( authError.gotError && authError.code ) &&
                 (<Trans
                   i18nKey="LOGIN_ERR_AUTH_ERROR"
                   values={{
                     lostPasswordLinkText: t('LOGIN_LOST_PASSWORD_LINK'),
                   }}
                   components={{
                     resetPasswordLink: <a
                       href={appPaths.arteco_global_lost_password} target="_blank" >
                       {t('LOGIN_LOST_PASSWORD_LINK')}
                     </a>
                   }}
                 />) 
              }
              {
                (authError.gotError && authError.code != 401) &&
                  (<p className="text-danger">{t(authError?.message)}</p>)
              }
            </div>
            <div className="loginpage-form-element">
              <button
                id="login"
                type="submit"
                className={submitBtnClassName}
                disabled={submitting}
                data-testid={"login"}
              >
                {submitting ? <Spinner /> : t('LOGIN_BUTTON')}
              </button>
            </div>


          </form>
          {
            authError.gotError && (
              <div className={`loginpage-form-help`}>
                <Trans
                  i18nKey="LOGIN_FOOTER_TEXT"
                  values={{
                    linkText: t('LOGIN_REGISTER_LINK'),
                    signUpLinkText: t('LOGIN_SIGNUP_LINK'),

                  }}
                  components={{
                    registationLink: <a
                      href={appPaths.arteco_global_register_new_user} target="_blank" >
                      {t('LOGIN_REGISTER_LINK')}
                    </a>
                  }}
                />
              </div>
            )
          }
        </div>
        
       
        <div className="by-arteco-container">
          <span>by</span>
          <ArtecoIcon className="by-arteco" />
        </div>
        
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (userData) => dispatch(loginUser(userData)),
    resetAuthError: () => dispatch(resetAuthError()),
    dispatch
  }
}

const mapStateToProps = state => ({
  auth: getAuthentication(state),
  authError: getAuthenticationError(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login2023));